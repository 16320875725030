import React from "react";
import { Paper, Typography, Stack, Box } from "@mui/material";
import styles from "../styling/EverydayServices.module.css";

const ServicesCard = ({ icon, title, description }) => {
  return (
    <Paper elevation={2} className={styles["services-paper"]}>
      <Stack spacing={1} p={2}  >
        <Box component="img" src={icon} className={styles["icon-img"]} />
        <Typography
          variant="h4"
          align="left"
          className={styles["services-bold"]}
        >
          {title}
        </Typography>
        <Typography variant="body2" color="textSecondary" align="left">
          {description}
        </Typography>
      </Stack>
    </Paper>
  );
};

export default ServicesCard;
