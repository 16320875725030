import React, { useEffect } from "react";
import BreadCrumb from "../molecules/BreadCrumb";
import {
  Grid,
  Typography,
  AccordionSummary,
  Stack,
  Box,
  useTheme,
} from "@mui/material";
import { useSharedData } from "../Context/ShareContext";
import styles from "../styling/MyAccount.module.css";
import { accountinfo } from "../json/Accountinfo";
import { AccountInformation } from "../molecules/AccountInformation";
import { HealthProfile } from "../molecules/HealthProfile";
import { Myappointment } from "../molecules/Myappointment";
import { MyReports } from "../molecules/MyReports";
import { MyOrderHistory } from "../molecules/MyOrderHistory";
import { Membership } from "../molecules/Membership";
import { MyPackages } from "../molecules/MyPackages";
import { Notifications } from "../molecules/Notifications";
import { Settings } from "../molecules/Settings";
import Help  from "../pages/Help";
import useMediaQuery from "@mui/material/useMediaQuery";
import defaultStyles from "../styling/DefaultStyles.module.css";

export const MyAccount = () => {

  const { itemSelected, setitemSelected } = useSharedData();
  const { itemSelectedPassed, setitemSelectedPassed, setShowPackage, setShowSummary, setShowNotifications } = useSharedData();
  useEffect(() => {
    window.scrollTo(0,0);
    return () => {
      
    };
  }, []); 
  const theme = useTheme();
  const desktop = useMediaQuery(theme.breakpoints.up("sm"));

  const handleDrawerClick = (item) => {
    setitemSelected(item);
    setitemSelectedPassed(item);

    switch (itemSelected) {
      case "My Appointments":
        return setShowSummary(false);
      case "My Packages":
        return setShowPackage(false);
      case "Membership":
        return setShowPackage(false);
      case "Notifications":
        return setShowNotifications(false);
      default:
        return null;
    }
  };

  const rendercontent = () => {
    switch (itemSelected) {
      case "Account Information":
        return <AccountInformation />;
      case "Health Profile":
        return <HealthProfile />;
      case "My Appointments":
        return <Myappointment />;
      case "My Reports":
        return <MyReports />;
      case "My Order History":
        return <MyOrderHistory />;
      case "Membership":
        return <Membership />;
      case "My Packages":
        return <MyPackages />;
      case "Notifications":
        return <Notifications />;
      case "Settings":
        return <Settings />;
        case "Help & Support":
        return <Help/>;
      default:
        return null;
    }
  };

  useEffect(() => {
    setitemSelected(itemSelectedPassed);
  }, [itemSelectedPassed]);

  return (
    <>
     <Stack className={defaultStyles.max_width_account}>
      <BreadCrumb
        crumb={{
          name: "My Account",
          path: "/myaccount",
        }}
      />
      <Box sx={{margin:"24px 10px"}} data-testid="my_account_container">
        <Grid container className={styles["myacc-containerGrid"]} data-testid="my_account_main_grid">
          {desktop && (
            <Grid item xs={12} sm={3} mt={5}>
              {accountinfo?.map((item, index) => (
                 <AccordionSummary
                  key={index}
                  aria-controls={`panel${index + 1}a-content`}
                  id={`panel${index + 1}a-header`}
                  className={
                    itemSelected === item?.name
                      ? styles["myacc-sidebarBtnClr"]
                      : styles["myacc-sidebarBtn"]
                  }
                  onClick={() => handleDrawerClick(item?.name)}
                >
                  <Stack spacing={2} direction="row" alignItems="center">
                    {item?.icon}
                    <Typography variant="h5">{item?.name}</Typography>
                  </Stack>
                </AccordionSummary>
              ))}
            </Grid>
          )}
          {desktop && <div className={styles["myacc-verticalDivider"]}></div>}
          <Grid item xs={12} sm={8} mt={5}>
            {rendercontent()}
          </Grid>
        </Grid>
      </Box>
      </Stack>
    </ >
  );
};
