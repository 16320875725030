import React, { useState } from "react";
import { Box, Typography, Stack, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import styles from "../styling/NewsandMedia.module.css";
import CardsBlog from "../molecules/CardsBlog";
import { useSharedData } from "../Context/ShareContext";
import { landing_dynamic } from "../json/landingDynamic";
import { SiteContent } from "../assets/content/SiteContent";
export default function NewsMedia() {
  const [selectedButton, setSelectedButton] = useState("Blogs");
  const navigate = useNavigate();

  const handleButtonClick = (button) => {
    setSelectedButton(button);
  };

  const handleReadMoreClick = () => {
    navigate("/login/blogs");
    setIndexOfSubheader(false);
  };
  const openInNewTab = (url) => {
    window.open(url, "_blank", "noreferrer");
  };
  const { setIndexOfSubheader } = useSharedData();

  return (
    <Box data-testid="newsmedia">
      <Stack spacing={3} className={styles["news-media"]} alignItems="center">
        <Typography variant="h2" className={styles["news-media-bold"]}>
          {landing_dynamic?.BANNER_6?.TITLE?.TITLE_1}
        </Typography>
        <Stack
          direction="row"
          className={styles["news-stack"]}
          justifyContent="space-between"
        >
          <Button
            onClick={() => handleButtonClick("Blogs")}
            variant={selectedButton === "Blogs" ? "contained" : ""}
            className={styles["pill"]}
          >
            <Typography variant="body4" className={styles["news-media-bold2"]}>
              {landing_dynamic?.BANNER_6?.TITLE?.TITLE_TOGGLE?.TOGGLE_1}
            </Typography>
          </Button>
          <Button
            disabled
            // onClick={() => handleButtonClick("media")}
            variant={selectedButton === "News" ? "contained" : ""}
            className={styles["pill"]}
          >
            <Typography
              variant="body4"
              textTransform="none"
              className={styles["news-media-bold2"]}
            >
              {landing_dynamic?.BANNER_6?.TITLE?.TITLE_TOGGLE?.TOGGLE_2}
            </Typography>
          </Button>
        </Stack>
      </Stack>
      <Box mt={5}>
        {selectedButton === "Blogs" && <CardsBlog numberOfCards={3} />}
        <Button
          className={styles["news-media-btn"]}
          // onClick={handleReadMoreClick}
          onClick={() =>
            openInNewTab(
              "https://www.healthspring.in/media/category/latest-news-events/"
            )
          }
          data-testid="read-more-main"
        >
          {SiteContent?.READ_MORE}
        </Button>
      </Box>
    </Box>
  );
}
