import React from "react";
import { Paper, Stack, Box, Typography } from "@mui/material";
import ArrowOutwardOutlinedIcon from "@mui/icons-material/ArrowOutwardOutlined";
import styles from "../../styling/DocCard.module.css";
export default function DocCard(props) {
  return (
    <Paper key={props?.key} className={styles["main-paper"]} elevation={5} data-testid="doc_card">
      <Stack direction="column" alignItems="flex-start" spacing={2}>
        <Box component="img" src={props.icon} alt=" " className={styles["cons_img"]} />

        <Typography className={styles["docCard_typ"]} align="left">
          {props.text}
        </Typography>
        <Stack alignSelf="flex-end">
          <ArrowOutwardOutlinedIcon className={styles["arrow"]} />
        </Stack>
      </Stack>
    </Paper>
  );
}
