import React from "react";
import {
  AppBar,
  Box,
  Button,
  InputBase,
  Stack,
  Typography,
  alpha,
  styled,
  useTheme,
  Menu,
  MenuItem,
} from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useSharedData } from "../Context/ShareContext";
import { useNavigate, useOutlet, Link } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import { Navbar } from "../molecules/Navbar";
import { Footer } from "../molecules/Footer";
import useAppStyles from "../hooks/useAppStyles";
import help from "../assets/images/140-help-center.svg";
import notification from "../assets/images/46-notification-bell.svg";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import PhoneAndroidOutlinedIcon from "@mui/icons-material/PhoneAndroidOutlined";
import WindowIcon from "@mui/icons-material/Window";
import mainlogo from "../assets/company_logo.svg";
import styles from "../styling/Layout.module.css";
import { useState } from "react";
import defaultStyles from "../styling/DefaultStyles.module.css";
import cell from "../assets/images/58-call-phone.svg";
import micro from "../assets/images/microscopenew.svg";
import collab from "../assets/images/collaborate.svg";
import blood from "../assets/images/bblood.svg";
import group from "../assets/images/Group 40767.svg";
import search from "../assets/images/42-search.svg";
import { PrivateFooter } from "../molecules/PrivateFooter";

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  float: "right",
}));

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "15ch",
    },
    [theme.breakpoints.up("md")]: {
      width: "30ch",
    },
  },
}));
export const Layout = (props) => {
  const navigate = useNavigate();
  const outlet = useOutlet();
  const appStyles = useAppStyles();
  const theme = useTheme();
 

  const { itemSelected, setitemSelected, value, setValue } = useSharedData();
  const [anchorEl, setAnchorEl] = useState(null);
  const handleLogout = () => {
    setAnchorEl(null);
    navigate("/login/loginpage");
  };
  const handleCheckoutHelp = () => {
    setAnchorEl(null);
    navigate("/help");
  };
  const handleChange = (event) => {
    setitemSelected(event?.target?.getAttribute("value"));
    setAnchorEl(null);
    navigate("/myaccount");
  };

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event?.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  function a11yProps(index) {
    return {
      id: `nav-tab-${index}`,
      "aria-controls": `nav-tabpanel-${index}`,
    };
  }
  const handleHelp = () => {
    setValue(null);
    navigate("/help");
  };

  return (
    <Box>
      <Stack direction="column">
        <Stack className={styles.layoutstackmaindiv}>
          <AppBar
            position="static"
            className={styles.layout_appbar}
            data-testid="main-header"
          >
            <Box>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                spacing={2}
                className={defaultStyles.max_width_stack}
              >
                <Stack direction="row">
                  <img
                    src={mainlogo}
                    className={styles.main_logo}
                    alt="healthfarm logo"
                  />
                </Stack>

                <Stack direction="row">
                  <Search className={styles.search_section}>
                    <StyledInputBase
                      className={styles.searchinput_section_layout}
                      placeholder="Search by anything"
                      inputProps={{ "aria-label": "search" }}
                    />
                    <SearchIconWrapper>
                      <SearchIcon className={styles.search_section_layout} />
                    </SearchIconWrapper>
                  </Search>
                  <Button
                    size="medium"
                    sx={appStyles.typographyStyle2}
                    className={styles.download_app_button}
                    startIcon={
                      <PhoneAndroidOutlinedIcon
                        sx={{ color: theme.palette.primary.main }}
                      />
                    }
                  >
                    <Typography variant="h5">Download App</Typography>
                  </Button>

                  <Button
                    className={styles.lay_out_button}
                    color="primary"
                    size="medium"
                    sx={appStyles.typographyStyle2}
                    startIcon={
                      <HelpOutlineOutlinedIcon
                        sx={{ color: theme.palette.primary.main }}
                      />
                    }
                    onClick={handleHelp}
                  >
                    <Typography variant="h5">Help</Typography>
                  </Button>
                </Stack>
              </Stack>
            </Box>
          </AppBar>
          <Navbar {...{ a11yProps, value, setValue }} />
        </Stack>
        <Stack className={styles.layoutstackmaindivtwo}>
          <AppBar
            position="static"
            className={styles.layout_appbar}
            data-testid="main-header"
          >
            <Box>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                spacing={2}
                className={defaultStyles.max_width_stack}
              >
                <Stack direction="row">
                  <img
                    src={mainlogo}
                    className={styles.main_logo}
                    alt="healthfarm logo"
                  />
                </Stack>

                <Stack direction="row" className={styles.mainboxdirec}>
                  <img src={help} alt="" className={styles.helpimg} />

                  <img
                    src={notification}
                    alt=""
                    className={styles.notification}
                    onClick={handleHelp}
                  />

                  <Button
                    id="basic-button"
                    aria-controls={open ? "basic-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                    onClick={handleClick}
                  >
                    <WindowIcon className={styles.windowicons} />
                  </Button>
                  <Menu
                    className={styles.menuwindows}
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    sx={{
                      "& .MuiMenu-paper": {
                        backgroundColor: "#006F45",
                        width: "100vw",
                        height: "100vh",
                      },
                    }}
                    style={{ top: 10 }}
                  >
                    <Stack
                      sx={{
                        backgroundColor: "#006F45",
                      }}
                    >
                      <Stack
                        direction="row"
                        spacing={2}
                        mb={3}
                        ml={6}
                        marginTop="51px"
                      >
                        <Stack className={styles.imagesnewlayout}>
                          <img src={micro} alt="" />
                        </Stack>
                        <Link
                          to="/test_and_packages"
                          onClick={handleClose}
                          style={{ textDecoration: "none" }}
                        >
                          <Stack className={styles.typonewlayout}>
                            <Typography className={styles.homeopacitytext}>
                              Tests & Packages
                            </Typography>
                          </Stack>
                        </Link>
                      </Stack>

                      <Stack direction="row" spacing={2} mb={3} ml={6}>
                        <Stack className={styles.imagesnewlayout}>
                          <img src={collab} alt="" />
                        </Stack>
                        <Link
                          to="/consult"
                          onClick={handleClose}
                          style={{ textDecoration: "none" }}
                        >
                          <Stack className={styles.typonewlayout}>
                            <Typography className={styles.homeopacitytext}>
                              Consult
                            </Typography>
                          </Stack>
                        </Link>
                      </Stack>

                      <Stack direction="row" spacing={2} mb={3} ml={6}>
                        <Stack className={styles.imagesnewlayout}>
                          <img src={blood} alt="" />
                        </Stack>
                        <Link
                          to="/outpatient_care"
                          onClick={handleClose}
                          style={{ textDecoration: "none" }}
                        >
                          <Stack className={styles.typonewlayout}>
                            <Typography className={styles.homeopacitytext}>
                              Out-Patient Care
                            </Typography>
                          </Stack>
                        </Link>
                      </Stack>

                      <Stack direction="row" spacing={2} mb={3} ml={6}>
                        <Stack className={styles.imagesnewlayout}>
                          <img src={cell} alt="" />
                        </Stack>
                        <Link
                          to="/contactus"
                          onClick={handleClose}
                          style={{ textDecoration: "none" }}
                        >
                          <Stack className={styles.typonewlayout}>
                            <Typography className={styles.homeopacitytext}>
                              Contact Us
                            </Typography>
                          </Stack>
                        </Link>
                      </Stack>

                      <Stack direction="row" spacing={2} mb={3} ml={6}>
                        <Stack className={styles.imagesnewlayout}>
                          <img src={group} alt="" />
                        </Stack>

                        <Stack className={styles.dropdownnewone}>
                          <Accordion
                            className={styles.accordiannewdropdown}
                            style={{ boxShadow: "none", border: "none" }}
                            value={itemSelected}
                          >
                            <AccordionSummary
                              expandIcon={
                                <ExpandMoreIcon
                                  sx={{ backgroundColor: "#fffff" }}
                                />
                              }
                              aria-controls="panel1a-content"
                              id="panel1a-header"
                            >
                              <Typography className={styles.homeopacitytext}>
                                My Account
                              </Typography>
                            </AccordionSummary>
                            <AccordionDetails
                              className={styles.accordiandetailsicon}
                              value={itemSelected}
                            >
                              <MenuItem
                                className={styles.homeopacitytext}
                                value="My Appointments"
                                onClick={(e) => handleChange(e)}
                              >
                                My Appointments
                              </MenuItem>
                              <MenuItem
                                className={styles.homeopacitytext}
                                value="My Reports"
                                onClick={(e) => handleChange(e)}
                              >
                                My Reports
                              </MenuItem>
                              <MenuItem
                                className={styles.homeopacitytext}
                                value="My Order History"
                                onClick={(e) => handleChange(e)}
                              >
                                My Order History
                              </MenuItem>
                              <MenuItem
                                className={styles.homeopacitytext}
                                value="Membership"
                                onClick={(e) => handleChange(e)}
                              >
                                MemberShip
                              </MenuItem>
                              <MenuItem
                                className={styles.homeopacitytext}
                                value="My Packages"
                                onClick={(e) => handleChange(e)}
                              >
                                My Packages
                              </MenuItem>
                              <MenuItem
                                className={styles.homeopacitytext}
                                value="Health Profile"
                                onClick={(e) => handleChange(e)}
                              >
                                Health Profile
                              </MenuItem>
                              <MenuItem
                                className={styles.homeopacitytext}
                                value="Account Information"
                                onClick={(e) => handleChange(e)}
                              >
                                Account Information
                              </MenuItem>
                              <MenuItem
                                className={styles.homeopacitytext}
                                value="Notifications"
                                onClick={(e) => handleChange(e)}
                              >
                                Notifications
                              </MenuItem>
                              <MenuItem
                                className={styles.homeopacitytext}
                                value="Settings"
                                onClick={(e) => handleChange(e)}
                              >
                                Settings
                              </MenuItem>
                              <MenuItem
                                className={styles.homeopacitytext}
                                value="Help & Support"
                                onClick={handleCheckoutHelp}
                              >
                                Help & Support
                              </MenuItem>
                              <MenuItem
                                className={styles.homeopacitytext}
                                value="Logout"
                                onClick={handleLogout}
                              >
                                Logout
                              </MenuItem>
                            </AccordionDetails>
                          </Accordion>
                        </Stack>
                      </Stack>

                      <Stack sx={{ marginLeft: "36px" }}>
                        <input
                          className={styles.inputforsearch}
                          type="text"
                          placeholder="Search By Anything"
                        />
                        <img
                          src={search}
                          alt=""
                          className={styles.searchbarimges}
                        />
                      </Stack>
                    </Stack>
                  </Menu>
                  <Stack className={styles.boxstacknew}></Stack>
                </Stack>
              </Stack>
            </Box>
          </AppBar>
        </Stack>

        {outlet}
        
       <PrivateFooter/>
      </Stack>
    </Box>
  );
};
