import Avatar from "@mui/material/Avatar";

import pic6 from "../assets/myreports.svg";

import appointment from "../assets/appointment.svg";
import orderhistory from "../assets/orderhistory.svg";
import membership from "../assets/membership.svg";
import packages from "../assets/packages.svg";
import healthprofile from "../assets/healthprofile.svg";
import styles from "../styling/AccountInfo.module.css";
import {
  NotificationsRounded,
  Person,
  SettingsRounded,
} from "@mui/icons-material";
import { Box } from "@mui/system";
export const accountinfo = [
  {
    name: "Account Information",
    icon: (
      <Avatar className={styles.avatarclass}  >
        <Person className={styles.personclass}  />
      </Avatar>
    ),
  },
  {
    name: "Health Profile",
    icon: (
      <Box
        component="img"
        src={healthprofile}
        className={styles.accountbox}
       
      />
    ),
  },
  {
    name: "My Appointments",
    icon: (
      <Box
        component="img"
        src={appointment}
        className={styles.accountbox}
       
      />
    ),
  },
  {
    name: "My Reports",
    icon: 
    (
      <Box
        component="img"
        src={pic6}
        className={styles["reports"]}
        
      />
    ),
  },
  {
    name: "My Order History",
    icon: (
      <Box
        component="img"
        src={orderhistory}
        className={styles.accountbox}
      />
    ),
  },
  {
    name: "Membership",
    icon: (
      <Box
        component="img"
        src={membership}
        className={styles.memberbox}
        
      />
    ),
  },
  {
    name: "My Packages",
    icon: (
      <Box
        component="img"
        src={packages}
        className={styles.packagesbox}
       
      />
    ),
  },
  {
    name: "Notifications",
    icon: (
      <Avatar className={styles.avatarsecondclass}  >
        <NotificationsRounded className={styles.notifications}  />
      </Avatar>
    ),
  },
  {
    name: "Settings",
    icon: (
      <Avatar className={styles.avatarnewfirstclass} >
        <SettingsRounded  className={styles.stylesettings}  />
      </Avatar>
    ),
  },
];

export const acc = {
  acc: "Account Information",
  name: "Elina Jhonson",
  email: "elinajhonson@gmail.com",
  details: "Your Details",
  emergency: "Emergency Details",
};
