import React, { forwardRef, useRef, useState } from "react";
import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";
import theme from "../components/Themeify";
import {
  Box,
  Stack,
  Typography,
  Tab,
  Tabs,
  AppBar,
  TextField,
  styled,
  Button,
  IconButton,
  InputAdornment,
} from "@mui/material";
import VideoCallIcon from "@mui/icons-material/VideoCall";
import LocalHospitalIcon from "@mui/icons-material/LocalHospital";
import RoofingIcon from "@mui/icons-material/Roofing";
import doctorstyles from "../styling/DoctorSchedule.module.css";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import Tab3DoctorSchedule from "./Tab3DoctorSchedule";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import CustomDatePicker from "./CustomDatePicker";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box className={doctorstyles.tab_root}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}
const CssTextField = styled(TextField)({
  input: {
    color: theme.palette.primary.main,
    fontWeight: "600",
    fontSize: "20px",
    padding: "none",
  },
  "& .MuiOutlinedInput-root": {
    display: "flex",
    flexDirection: "row-reverse",
    width: "max-content",
    "& fieldset": {
      border: "none",
    },
  },
});

export default function DoctorScheduleForTest({
  openLocationMenu,
  openCheckoutPage,
}) {
  const [value, setValue] = useState(0);
  const [date, setDate] = React.useState(dayjs("2022-04-17"));

  const handlePrevDate = () => {
    setDate((prev) => prev.subtract(1, "day"));
  };

  const handleNextDate = () => {
    setDate((prev) => prev.add(1, "day"));
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  const [isOpen, setIsOpen] = useState(false);
  const ItemOne = () => {
    return (
      <>
        <Stack
          direction="row"
          spacing={2}
          className={doctorstyles.small_card_tab}
        >
          <RoofingIcon color="success" className={doctorstyles.tab_icons} />
          <span className={doctorstyles.captions_tab}> On Site</span>
        </Stack>
      </>
    );
  };

  const ItemTwo = () => {
    return (
      <>
        <Stack
          direction="row"
          spacing={1}
          className={doctorstyles.small_card_tab}
        >
          <VideoCallIcon color="success" className={doctorstyles.tab_icons} />
          <span className={doctorstyles.captions_tab}> Call</span>
        </Stack>
      </>
    );
  };

  const ItemThree = () => {
    return (
      <>
        <Stack
          direction="row"
          spacing={2}
          className={doctorstyles.small_card_tab}
        >
          <LocalHospitalIcon
            color="success"
            className={doctorstyles.tab_icons}
          />
          <span className={doctorstyles.captions_tab}> In Clinic</span>
        </Stack>
      </>
    );
  };

  const openLocationMenuHandler = (bool) => {
    openLocationMenu(bool);
  };

  return (
    <Box className={doctorstyles.main_box_layout}>
      <AppBar position="static" className={doctorstyles.appbar_layout}>
        <Stack
          direction="row"
          className={doctorstyles.calendar_layout}
          justifyContent="space-between"
        >
          <ArrowBackIosIcon
            size="small"
            fontSize="10px"
            className={doctorstyles.icons_ouline_tab}
            onClick={handlePrevDate}
          />
          <CustomDatePicker
            value={date}
            onChange={(newValue) => setDate(newValue)}
          />
          <ArrowForwardIosIcon
            size="small"
            fontSize="10px"
            className={doctorstyles.icons_ouline_tab}
            onClick={handleNextDate}
          />
        </Stack>

        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="secondary"
          textColor="inherit"
          variant="fullWidth"
          aria-label="full width tabs example"
          className={doctorstyles.tabs_layout}
        >
          <Tab label={ItemOne()} {...a11yProps(0)} />
          <Tab label={ItemTwo()} {...a11yProps(1)} />
          <Tab label={ItemThree()} {...a11yProps(2)} />
        </Tabs>
      </AppBar>
      <SwipeableViews
        axis={theme.direction === "rtl" ? "x-reverse" : "x"}
        index={value}
        onChangeIndex={handleChangeIndex}
      >
        <TabPanel value={value} index={0} dir={theme.direction}>
          <Tab3DoctorSchedule tab="tab1" openCheckoutPage={openCheckoutPage} />
        </TabPanel>
        <TabPanel value={value} index={1} dir={theme.direction}>
          <Tab3DoctorSchedule tab="tab2" openCheckoutPage={openCheckoutPage} />
        </TabPanel>
        <TabPanel value={value} index={2} dir={theme.direction}>
          <Tab3DoctorSchedule
            tab="tab3"
            openLocationMenu={openLocationMenuHandler}
            openCheckoutPage={openCheckoutPage}
            Btn="Book Test"
          />
        </TabPanel>
      </SwipeableViews>
    </Box>
  );
}
