import general from "../assets/images/doctorsimage.svg";
import specialist from "../assets/images/specialistimage.svg";
import diet from "../assets/images/appleimage.svg";
import mental from "../assets/images/mentalimage.svg";
import generalphysician from "../assets/images/generalphysician.svg";
import specialists from "../assets/images/specialist.svg";
import dietchart from "../assets/images/diet.svg";
import mentalhealth from "../assets/images/mental.svg";
export const categories = [
  {
    name: "General Physician",
    color: "#e1f5f1",
    id: 1,
    icon: general,
    path: "general_physician",
    image: generalphysician,
    accordian: false,
  },
  {
    name: "Specialists",
    color: "#f9e4e5",
    id: 2,
    icon: specialist,
    path: "specialists",
    image: specialists,
    accordian: true,
  },
  {
    name: "Diet & Nutrition",
    color: "#ffece2",
    id: 3,
    icon: diet,
    path: "diet_and_nutrition",
    image: dietchart,
    accordian: false,
  },
  {
    name: "Mental Health",
    color: "#e7f3dc",
    id: 4,
    icon: mental,
    path: "mental_health",
    image: mentalhealth,
    accordian: false,
  },
];
