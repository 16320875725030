import React, { useState } from "react";
import { Tab, Tabs, Typography, Avatar, Stack, Box } from "@mui/material";
import capture from "../assets/images/Capture.png";
import styles from "../styling/AccountInformation.module.css";
import { YourDetails } from "./YourDetails";
import { EmergencyDetails } from "./EmergencyDetails"; // Corrected import name
import { acc } from "../json/Accountinfo";

function a11yProps(index) {
  return {
    id: `nav-tab-${index}`,
    "aria-controls": `nav-tabpanel-${index}`,
  };
}

export const AccountInformation = () => {
  const [tabValue, setTabValue] = useState(0);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <Box>
      <Stack>
        <Typography className={styles["accinfo-title"]} variant="h4">
          {acc?.acc}
        </Typography>
        <Box className={styles["accinfo-boxCard"]}>
          <Stack direction="row" p={2} alignItems="center">
            <Avatar
              alt="Avatar"
              src={capture}
              className={styles["accinfo-avatarImage"]}
            />
            <Stack p={2}>
              <Typography className={styles["accinfo-name"]} variant="h3">
                {acc?.name}
              </Typography>
              <Typography className={styles["accinfo-email"]} variant="h5">
                {acc?.email}
              </Typography>
            </Stack>
          </Stack>
        </Box>
      </Stack>

      <Stack direction="row" className={styles["accinfo-tabStack"]}>
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          aria-label="nav tabs example"
        >
          <Tab label={<Typography variant="h5">{acc?.details}</Typography>} {...a11yProps(0)} />
          <Tab label={<Typography variant="h5">{acc?.emergency}</Typography>} {...a11yProps(1)} />
        </Tabs>
      </Stack>

      <Stack>{tabValue === 0 && <YourDetails value={tabValue} index={0}  />}</Stack>
      <Stack>{tabValue === 1 && <EmergencyDetails value={tabValue} index={1} />}</Stack>
    </Box>
  );
};
