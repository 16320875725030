import { Box, Drawer, Stack, Typography } from "@mui/material";
import React from "react";
import DoctorSchedule from "./DoctorSchedule";
import defaultStyles from "../styling/DefaultStyles.module.css";
import styles from "../styling/Booking.module.css";
import Bread from "./Bread";
import LocationMenu from "./LocationMenu";
import { useState } from "react";

export default function Booking({ iconImage, headerText }) {
  const [locationMenu, setLocationMenu] = useState(false);

  const openLocationMenu = (bool) => {
    setLocationMenu(bool);
  };

  const handleCloseLocationMenu = () => {
    setLocationMenu(false);
  };
  return (
    <>
      <Stack className={defaultStyles.max_width_stack}>
        <Stack className={defaultStyles.bread_stack}>
          <Bread />
        </Stack>
        <Stack className={styles.centered_Stack_container}>
          <Stack spacing={2} className={styles.booking_main_stack}>
            <Box className={styles.initialImage}>
              <Stack direction="row" className={styles.workhealthspring_stack}>
                <img src={iconImage} alt="Icon" className={styles.iconImage} />
                <Typography
                  className={styles.workhealthspring_typoone}
                  variant="h4"
                >
                  {headerText}
                </Typography>
              </Stack>
            </Box>

            <DoctorSchedule openLocationMenu={openLocationMenu} />
          </Stack>
        </Stack>
        <Drawer
          anchor="right"
          open={locationMenu}
          onClose={() => handleCloseLocationMenu()}
          sx={{
            display: { xs: "block", sm: "block" },
            "& .MuiDrawer-paper": { boxSizing: "border-box", width: "350px" },
          }}
        >
          <LocationMenu onClose={() => handleCloseLocationMenu()} />
        </Drawer>
      </Stack>
    </>
  );
}
