import React from "react";
import { Card, CardContent, Stack, Typography } from "@mui/material";
import FavoriteIcon from "@mui/icons-material/Favorite";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import TranslateOutlinedIcon from "@mui/icons-material/TranslateOutlined";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import styles from "../styling/DoctorCard.module.css";
import theme from "../components/Themeify";
import useAppStyles from "../hooks/useAppStyles";
import { Link, useParams } from "react-router-dom";
import { DoctorCard } from "../json/DoctorCards";

export default function DoctorCards() {
  const appStyles = useAppStyles();
  const { cat } = useParams();

  return (
    <>
      {DoctorCard?.map((data, index) => {
        return (
          <Card
            className={styles["card-root"]}
            key={index}
            data-testid="doctor_card_container"
          >
            <CardContent className={styles["card-content-bg"]}>
              <Stack
                direction={{ xs: "row", sm: "row" }}
                justifyContent=" space-between"
              >
                <Stack direction={{ xs: "column", sm: "row" }}>
                  <Stack className={styles.doctorscardrightstack} p={2} pb={0}>
                    <img
                      src={data.img}
                      className={styles["avatar-icon"]}
                      alt="img"
                    />
                  </Stack>

                  <Stack pt={2}>
                    <Stack spacing={2}>
                      <Stack spacing={0.7}>
                        <Stack direction="row" spacing={1}>
                          <Typography
                            data-testid="doctor_name"
                            className={styles.docName}
                            sx={{
                              fontWeight: 600,
                              color: appStyles.typographyStyle2,
                            }}
                          >
                            {data.name}
                          </Typography>
                          {(data.name == "Dr. Raj Avasthi" ||
                            data.name == "Dr. Rajesh Vibhore") && (
                            <FavoriteIcon fontSize="small" color="error" />
                          )}
                        </Stack>
                        <Stack direction="row">
                          <Typography variant="h5" data-testid="speciality">
                            {data.domain} &bull; {data.years}
                          </Typography>
                        </Stack>
                      </Stack>
                      <Stack
                        direction={{ xs: "column", sm: "row" }}
                        spacing={{ xs: 0.5, sm: 2 }}
                      >
                        <Stack direction="row" spacing={{ xs: 0.5, sm: 1 }}>
                          <LocationOnOutlinedIcon
                            fontSize="small"
                            color="success"
                          />
                          <Typography
                            sx={{
                              color: appStyles.cardSubCaptions,
                            }}
                            variant="h5"
                          >
                            {data.place} &nbsp;&nbsp;{" "}
                          </Typography>
                        </Stack>
                        <Stack direction="row" spacing={{ xs: 0.5, sm: 1 }}>
                          <TranslateOutlinedIcon
                            fontSize="small"
                            color="success"
                          />
                          <Typography
                            sx={{
                              color: appStyles.cardSubCaptions,
                            }}
                            variant="h5"
                          >
                            {data.lang}
                          </Typography>
                        </Stack>
                      </Stack>

                      <Stack direction="row" spacing={1}>
                        {data.onsite !== "" && (
                          <Stack
                            direction="row"
                            spacing={{ xs: 1 }}
                            className={styles["small-card"]}
                          >
                            <img
                              src={data.avatarone}
                              className={styles.roofingicon}
                            />
                            <Typography className={styles["captions"]}>
                              {" "}
                              {data.onsite}
                            </Typography>
                          </Stack>
                        )}

                        {data.call !== "" && (
                          <Stack
                            direction="row"
                            spacing={{ xs: 1 }}
                            className={styles["small-cards"]}
                          >
                            <img
                              src={data.video}
                              className={styles.videocallicon}
                            />

                            <Typography className={styles["caption"]}>
                              {" "}
                              {data.call}
                            </Typography>
                          </Stack>
                        )}

                        <Stack
                          direction="row"
                          spacing={{ xs: 1 }}
                          className={styles["small-card"]}
                        >
                          {/* <LocalHospitalIcon className={styles.localhospital} color="success" /> */}
                          <img
                            src={data.home}
                            className={styles.localhospital}
                          />
                          <Typography className={styles["captions"]}>
                            {" "}
                            {data.InClinic}
                          </Typography>
                        </Stack>
                      </Stack>
                    </Stack>
                  </Stack>
                </Stack>

                <Stack
                  className={styles.doctorscardleftstack}
                  spacing={1}
                  alignItems="center"
                  pt={2}
                >
                  <Link
                    to={`/consult/${cat}/doctor_details`}
                    className={styles.linkNoUnderline}
                  >
                    <ArrowForwardIosIcon className={styles["icons-ouline"]} />
                  </Link>
                  <Typography
                    align="left"
                    sx={{
                      color: theme.palette.primary.main,
                      fontWeight: "bold",
                    }}
                    variant="h4"
                  >
                    {data.price}
                    <br />
                    <del className={styles["striked-div"]}>
                      <h4 className={styles["striked-captions"]}>
                        {" "}
                        {data.prices}
                      </h4>
                    </del>
                  </Typography>
                </Stack>
              </Stack>
            </CardContent>
          </Card>
        );
      })}
    </>
  );
}
