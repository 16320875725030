import React, {useEffect} from "react";
import { Box, Typography, Stack } from "@mui/material";
import useAppStyles from "../hooks/useAppStyles";
import styles from "../styling/Consult.module.css";
import { categories } from "../json/categories";
import { SiteContent } from "../assets/content/SiteContent";
import ConsultService from "../molecules/ConsultService";
import Bread from "../molecules/Bread";
import defaultStyles from "../styling/DefaultStyles.module.css";

export const Consult = () => {
  const appStyles = useAppStyles();
  useEffect(() => {
    window.scrollTo(0,0);
    return () => {
      
    };
  }, []); 
  return (
    <>
      <Stack className={defaultStyles.max_width_stack}>
        <Stack>
          <Bread />
          <Box className={styles.box_styling}>
            <Stack className={styles.grid_styling}>
              <Stack>
                <Typography
                  variant="h1"
                  sx={{ color: appStyles.typographyStyle2 }}
                  className={styles.page_heading_text}
                >
                  {SiteContent.LETS_FIND}
                  <br />
                  {SiteContent.OUR_SPECIALIST_DOCTOR}
                </Typography>
              </Stack>
              <Stack
                direction="row"
                flexWrap="wrap"
                spacing={5}
                useFlexGap
                className={styles.cards_stack}
              >
                {categories?.map((item, index) => {
                  return <ConsultService key={index} category={item} />;
                })}
              </Stack>
            </Stack>
          </Box>
        </Stack>
      </Stack>
    </>
  );
};
