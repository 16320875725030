import React from "react";
import { cards } from "../json/cards";
import useAppStyles from "../hooks/useAppStyles";
import { Box, Typography, Stack } from "@mui/material";
import styles from "../styling/Consult.module.css";
import OutPatientCareServices from "../molecules/OutPatientCareServices";
import Bread from "../molecules/Bread";
import defaultStyles from "../styling/DefaultStyles.module.css";

export const OutPatientCare = () => {
  const appStyles = useAppStyles();
  const lets = "Let's Find";
  const patient = "Out-Patient Care Services";
  return (
    <>
      <Stack className={defaultStyles.max_width_stack}>
        <Stack>
          <Bread />
          <Box className={styles.box_styling}>
            <Stack className={styles.grid_styling}>
              <Stack>
                <Typography
                  variant="h1"
                  sx={{ color: appStyles.typographyStyle2 }}
                  className={styles.page_heading_text}
                >
                  {lets}
                  <br />
                  {patient}
                </Typography>
              </Stack>
              <Stack
                direction="row"
                spacing={5}
                useFlexGap
                flexWrap="wrap"
                className={styles.cards_stack}
              >
                {cards?.map((item, index) => {
                  return <OutPatientCareServices key={index} category={item} />;
                })}
              </Stack>
            </Stack>
          </Box>
        </Stack>
      </Stack>
    </>
  );
};
