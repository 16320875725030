import { Box, IconButton, Popover, Typography, useTheme } from "@mui/material"
import { DateCalendar, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useMemo, useState } from "react";
import dayjs from "dayjs";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";

const CustomDatePicker = ({ value: valueProp, onChange }) => {
    const theme = useTheme();
    const [value, setValue] = useState(valueProp ?? dayjs('2022-04-07'));
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleChange = (newValue) => {
        setValue(newValue);
        onChange?.(newValue);
        handleClose();
    }

    const parsedText = useMemo(() => (valueProp ?? value)?.format?.("ddd, DD MMM") ?? "", [valueProp, value]);
    const open = Boolean(anchorEl);

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Box display="flex" alignItems="center" gap={1}>
                <IconButton onClick={handleClick}>
                    <CalendarMonthIcon
                        size="small"
                        sx={{ color: theme.palette.primary.main }}
                    />
                </IconButton>
                <Typography
                    fontWeight={600}
                    fontSize={20}
                    color="primary.main"
                >
                    {parsedText}
                </Typography>
                <Popover
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                >
                    <DateCalendar
                        value={valueProp ?? value}
                        onChange={handleChange}
                    />
                </Popover>
            </Box>
        </LocalizationProvider>
    )
}

export default CustomDatePicker;