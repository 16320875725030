import { Box, Divider, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import React from "react";
import { RecentSearch,  Recommendation, Popular } from "../json/XraySearch";
import styles from "../styling/HealthCardSearch.module.css";
import Bread from "./Bread";
import GenericHeaderCard from "./GenericHeaderCard";
import GenericSearch from "./GenericSearch";
import { useNavigate } from "react-router-dom";
import defaultStyles from "../styling/DefaultStyles.module.css";
import xray from "../assets/lungs-xray.png";

const TestPackageXrayDetails = () => {
  const navigate = useNavigate();
  return (
    <>
      <Stack className={defaultStyles.stack_with_max_width}>
        <Stack className={defaultStyles.bread_stack}>
          <Bread />
        </Stack>
        <Box
          className={styles.lab_test_new_box_main}
          data-testid="TestPackageXrayDetailsContainer"
        >
          <Stack spacing={4}>
            <GenericHeaderCard headingtext={"X-rays and Scans"} banner={xray} />
            <GenericSearch />
          </Stack>
          <Stack>
            <Stack className={styles.main_stack_health_card_search}>
              <Stack>
                <Typography className={styles.recent_serch_healthcard}>
                  Recent Search
                </Typography>
              </Stack>
              <Stack className={styles.clearall_stack_healthcard}>
                <Typography className={styles.clear_allll_healthcard}>
                  Clear All
                </Typography>
              </Stack>
            </Stack>

            <Stack direction="column" spacing={2}>
              {RecentSearch?.map((data, i) => {
                return (
                  <>
                    <Stack direction="row" spacing={2} key={i}>
                      {data?.icon}
                      <Typography
                        sx={{ cursor: "pointer" }}
                        varient="h4"
                        className={styles.health_search_typo}
                        onClick={() =>
                          navigate("/test_and_packages/xray/booking")
                        }
                      >
                        {data?.val}
                      </Typography>
                    </Stack>
                    <Stack className={styles.health_search_divider}>
                      <Divider variant="middle" />
                    </Stack>
                  </>
                );
              })}
            </Stack>
            <Stack className={styles.recomendations_allll_healthcard}>
              <Typography className={styles.recent_serch_healthcard}>
                Recommendation
              </Typography>
            </Stack>
            <Stack direction="column" spacing={2}>
              {Recommendation?.map((data, i) => {
                return (
                  <>
                    <Stack direction="row" spacing={2} key={i}>
                      {data?.icon}
                      <Typography className={styles.health_search_typodata}>
                        {data?.val}
                      </Typography>
                    </Stack>
                    <Stack className={styles.health_search_divider}>
                      <Divider variant="middle" />
                    </Stack>
                  </>
                );
              })}
            </Stack>
            <Stack className={styles.recomendations_allll_healthcard}>
              <Typography className={styles.recent_serch_healthcard}>
                Popular
              </Typography>
            </Stack>
            <Stack direction="column" spacing={2}>
              {Popular?.map((data, i) => {
                return (
                  <>
                    <Stack direction="row" spacing={2} key={i}>
                      {data?.icon}
                      <Typography className={styles.health_search_typo}>
                        {data?.val}
                      </Typography>
                    </Stack>
                    <Stack className={styles.health_search_divider}>
                      <Divider variant="middle" />
                    </Stack>
                  </>
                );
              })}
            </Stack>
          </Stack>
        </Box>
      </Stack>
    </>
  );
};

export default TestPackageXrayDetails;
