export const contactUs = {
  TITLE: "Get In Touch",
  HEADERS: {
    HEAD_OFFICE: "Head Office",
    CONTACT_US: "Contact Us",
    INDIVIDUAL_INQUIRY: "Individual Inquiry",
    CORPORATE_INQUIRY: "Corporate Inquiry",
  },
};

export const contactUs_dynamic = {
  PHONE: "+91 22 6130 3434",
  ADDRESS: {
    LINE1: "5th Floor, East Wing Forbes Building,",
    LINE2: "Charanjit Rai Marg, Fort,",
    LINE3: "Mumbai - 400001",
  },
  EMAIL: "info@healthspring.in",
  WEBSITE: "corporatewellness@healthspring.in",
};

export const LocationArr_dynamic = {
  Mumbai: [
    {
      name: "Andheri West",
      address1: "Shree Krishna,G Wing- 104 1st flr,  ",
      address2: "Opp. Laxmi Industrial Estate, Nr. Fun Republic Link",
      address3: "Road, Oshiwara, Andheri (W),",
      address4: "Mumbai 400053",
      phone: "+91 8097513005",
    },
    {
      name: "Goregaon",
      address1: "Omkar Building, Shop No # 1 & 2,",
      address2: "1st Floor, Gen AK Vaidya Marg,",
      address3: "Opp Oberoi Mall, Goregaon,",
      address4: "Mumbai 400097",
      phone: "+91 8097513010",
    },
    {
      name: "Parel",
      address1: "Mehta Mansion , Shop # 10 & 11,",
      address2: " Dr. BSA road , Near Titan Eye Plus, Lalbaug",
      address3: "",
      address4: "Parel, Mumbai 400036",
      phone: "+91 7304990176",
    },
    {
      name: "Powai",
      address1: "Galleria Shopping Mall, 205, ",
      address2: "A Wing, 2nd Floor, Opp To KFC, ",
      address3: "Hiranandani Gardens, Powai, ",
      address4: "Mumbai 400076",
      phone: "+91 7304990081",
    },
    {
      name: "Khar",
      address1: "Shyam Kunj, Flat No- 3 & 4, ",
      address2: "705 Linking Road, Near Telephone Exchange, Opp.",
      address3: "RBL Bank, Khar West, ",
      address4: "Mumbai 400052",
      phone: "+91 7208476733",
    },
    // {
    //   name: "Wadala Lab",
    //   address1: "Unit No. 15, Wadala Udyog Bhavan",
    //   address2: "",
    //   address3: "",
    //   address4: "Wadala, Mumbai 400031",
    //   phone: "+91 9702116839",
    // },
    {
      name: "Mulund",
      address1: "Shop No 6, Gr Floor, Runwal Anthurium, ",
      address2: "LBS Marg, Mulund (West), Land Mark: ",
      address3: "Next to Reliance Digital, ",
      address4: "Mumbai 400080",
      phone: "+91 7304990169",
    },

    {
      name: "Cuffe Parade",
      address1: "Shop # 1, Ground Floor, Saideep, 17,",
      address2: "Old Cuffe Parade,",
      address3: "Mumbai 400005",
      address4: "",
      phone: "+91 2222174545",
    },
    // {
    //   name: "Andheri, S.V. Road",
    //   address1: "Shop no- 04, Shiv Ashish CHS,",
    //   address2: "SV Rd, Next to ICICI Bank, Bharucha Baug,",
    //   address3: "Parsi Colony, Andheri West,",
    //   address4: "Mumbai 400058",
    //   phone: "+91 8828116194",
    // },
    {
      name: "Borivali",
      address1: "Shop No. 1, The Emerald, Opposite Canon",
      address2: "Image Square, Near Starbucks cafe,",
      address3: "Roshan Nagar, Borivali (West),",
      address4: "Mumbai 400091",
      phone: "+91 8097513006",
    },
  ],
  "Navi Mumbai": [
    {
      name: "Nerul",
      address1: "Shop No.19-21, Gr. Floor, ",
      address2: "Meredien Apartment, Sector 6 Nerul Rd, ",
      address3: "Navi Mumbai 400706",
      address4: "",
      phone: "+91 8828114381",
    },
    {
      name: "Kharghar",
      address1: "Gayatri Sankul CHS Ltd., Rekhi Sai Daffodil CHS ",
      address2: "Ltd, Shop No. 3 & 4, Daffodil, Plot No. 17, 18 & 19 ",
      address3: "Rekhi Sai Daffodil, Ramsheth Thakur School Rd, Sector-19,",
      address4: "Kharghar, Navi Mumbai 410210",
      phone: "+91 7208476736",
    },
    {
      name: "Vashi",
      address1: "Plot no. 7, Shah Signature, Shop No. 104,",
      address2: "1st Floor, above Titan Eye Plus, Sector 17",
      address3: "Vashi, Navi Mumbai 400703",
      address4: "",
      phone: "+91 8828116193",
    },
  ],
  Pune: [
    {
      name: "Aundh",
      address1: "Ground Floor, Sr.No-163/1+2A/2B,",
      address2: "Ashiyana Apartment, No.1, DP Rd,",
      address3: "Opp. Saishree Hospital, Wireless Colony,",
      address4: "Aundh, Pune 411007",
      phone: "+91 7208993454",
    },
  ],
  Thane: [
    {
      name: "Thane Ghodbunder",
      address1: "Shop No 4, Pataskar Eclat, At Service Road,",
      address2: "Opp D-Mart Next to Axis Bank,",
      address3: "Ghodbunder Rd, Kavesar,",
      address4: "Thane West 400607",
      phone: "+91 7208476731",
    },
    {
      name: "Lodha Palava",
      address1: "1st Floor, PCMA Building,",
      address2: "Near Ganesh Mandir Casa Rio,",
      address3: "Lodha Palava, Dombivli East,",
      address4: "Thane 421204",
      phone: "+91 7777016942",
    },
    // {
    //   name: "Lodha Palava",
    //   address1: "Gr floor, E-wing, Elitra Building, Casa Bella Main Rd, ",
    //   address2: "Kalyan - Shilphata Rd, ",
    //   address3: "Dombivli East,",
    //   address4: "Thane 421204",
    //   phone: "+91 7777016942",
    // },
  ],
};
