import React, { useEffect, useState } from "react";
import StarIcon from "@mui/icons-material/Star";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import { Stack } from "@mui/system";
import styles from "../styling/SidebarPopup.module.css";
export default function StarRating({ count = 5, setValue = () => {} }) {
  let [star, setStar] = useState([]);
  const [selectedCount, setSelectedCount] = useState(0);
  const initialization = () => {
    let starArr = [];
    for (let i = 0; i < count; i++) {
      starArr.push(i);
    }
    setStar(starArr);
  };
  useEffect(() => {
    initialization();
  }, []); 

  return (
    <Stack direction={"row"}>
      {star?.map((item, index) => (
        <StarShow
          key={index}
          onClick={() => {
            setSelectedCount(index + 1);
            setValue(index + 1);
          }}
          index={item}
          selectedCount={selectedCount}
        />
      ))}
    </Stack>
  );
}

const StarShow = ({ index, selectedCount, onClick = () => {} }) => {
  if (selectedCount > index) {
    return <StarIcon className={styles.star_icon_new_one} onClick={onClick} />;
  } else {
    return (
      <StarBorderIcon className={styles.star_icon_new_one} onClick={onClick} />
    );
  }
};
