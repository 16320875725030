import React, { useState } from "react";
import styles from "../styling/Slider.module.css";

export const Slider = (content) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [expandedItem, setExpandedItem] = useState(null);
  const [transitionClass, setTransitionClass] = useState("");

  const handleNextClick = () => {
    setTransitionClass(styles.transitionOut);
    setTimeout(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % content?.length);
      setExpandedItem(null);
      setTransitionClass(styles.transitionIn);
    }, 300);
  };

  const handleBackClick = () => {
    setTransitionClass(styles.transitionOut_back);
    setTimeout(() => {
      setCurrentIndex((prevIndex) =>
        prevIndex === 0 ? content?.length - 1 : prevIndex - 1
      );
      setExpandedItem(null);
      setTransitionClass(styles.transitionIn);
    }, 300);
  };

  return {
    currentIndex,
    expandedItem,
    transitionClass,
    handleNextClick,
    handleBackClick,
    setExpandedItem,
  };
};
