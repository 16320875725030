import girl from "../assets/images/girl.jpg";
import bowl from "../assets/images/bowl.jpg";
import mosquito from "../assets/images/mosquito.jpg";
import img from "../assets/images/ico98.png";

export const blogs_dynamic = {
  BANNER_1: {
    TITLE: "The Recent Blog",
    CARD: {
      TITLE: "Fitness regime that helps you stay healthy",
      CATEGORY: "Health & Wellness",
      IMAGE: img,
      ALT:"blog-banner-img",
      DESCRIPTION:
        "Proactively ensure your family's health is taken care of. Join Healthspring community today for a healthier, happier future for your loved ones",
      DAY: "24",
      MONTH: "DEC",
    },
    FEATURE_POST : [
      {
        ID: "01",
        DESCRIPTION: "Health benefits of flax seeds are a great source of fiber",
      },
      {
        ID: "02",
        DESCRIPTION: "What you can do to protect yourself from dengue fever",
      },
      {
        ID: "03",
        DESCRIPTION:
          "Sed ut perspiciatis unde omnis iste natus error sit voluptatem",
      },
    ],
    FITNESS : {
      FITNESS_REGIME_HEADER: "Fitness regime that helps you stay healthy",
      DATE: "24 Dec, 2022 . Health & Wellness",
      BENEFIT:`The benefits of flax seeds are numerous, and this tiny brownish
      seed is packed with micronutrients, vitamins, minerals.`,
      FLAX: [
        {
          ID: "01",
          HEADER: "Flaxseeds are a great source of fiber",
          DESCRIPTION: `Do you pursue a fitness regime that helps you stay healthy? Do you
        intend on starting one soon?`,
        },
        {
          ID: "02",
          HEADER: " Flaxseeds are Plant source of omega-3",
          DESCRIPTION: `Do you pursue a fitness regime that helps you stay healthy? Do you
        intend on starting one soon?`,
        },
        {
          ID: "03",
          HEADER: "Benefits of women",
          DESCRIPTION: `Do you pursue a fitness regime that helps you stay healthy? Do you
        intend on starting one soon?`,
        },
        {
          HEADER: "Foods for the heart",
          DESCRIPTION: `Do you pursue a fitness regime that helps you stay healthy? Do you
        intend on starting one soon?`,
        },
        {
          HEADER: "Rich in antioxidants",
          DESCRIPTION: `Do you pursue a fitness regime that helps you stay healthy? Do you
        intend on starting one soon?`,
        },
        {
          HEADER: "How to eat flax seeds?",
          DESCRIPTION: `Do you pursue a fitness regime that helps you stay healthy? Do you
        intend on starting one soon?`,
        },
      ],
      MEAL_HEAD:`How to include flax seeds in your daily meal`,
      MEAL_DESC:`Do you pursue a fitness regime that helps you stay healthy? Do you
      intend on starting one soon?`,
      PROCEDURE:[
        { TEXT: "Add to your favorite juice, energy drink, smoothie" },
        { TEXT: "Mix it with green chutney while making a sandwich" },
        { TEXT: "Use flax seed powder to bake cookies, bread, cakes" },
      ],
      CONCLUSION:"Conclusion",
      CONCLUSIONDESC:`Do you pursue a fitness regime that helps you stay healthy? Do you
      intend on starting one soon?`,
    },
    BLOGS_READ_MORE : {
      BACK_BUTTON: "Back",
      SHARE: "Share On",
      CATEGORY_TEXTS: "Categories",
      CATEGORY_NAMES: [
        "Category Name 1",
        "Category Name 2",
        "Category Name 3",
        "Category Name 4",
        "Category Name 5",
        "Category Name 6",
        "Category Name 7",
      ],
    }
  },
  BANNER_2: {
    SEARCH_KEYWORD: "Search by Keywords",
    EXPLORE_CATEGORY: "Explore Categories",
    FIRST_PAGE: "First",
    LAST_PAGE: "Last",
    PAGES: "Page %currentPage% of %totalPages%",
    MEDIA_CARDS: [
      {
        ID: 1,
        IMAGE: girl,
        CATEGORY: "Category Name",
        DAY: "22",
        MONTH: "Dec",
        TITLE: "Fitness regime that helps you stay healthy",
        DESCRIPTION:
          "Do you pursue a fitness regime that helps you stay healthy? Do you intend on starting one soon? Do you intend on starting one soon?...",
      },
      {
        ID: 2,
        IMAGE: bowl,
        CATEGORY: "Category Name",
        DAY: "20",
        MONTH: "Dec",
        TITLE: "Health benefits of flax seeds are a great source of fiber",
        DESCRIPTION:
          "The benefits of Flax seed are numerous and this tiny brownish seed is packed with micronutrients, vitamins...",
      },
      {
        ID: 3,
        IMAGE: mosquito,
        CATEGORY: "Category Name",
        DAY: "08",
        MONTH: "Dec",
        TITLE: "What you can do to protect you from Dengue Fever",
        DESCRIPTION:
          "Dengue fever is caused by a virus and is transmitted through the bite of an infected Aedes mosquito...",
      },
      {
        ID: 4,
        IMAGE: bowl,
        CATEGORY: "Category Name",
        DAY: "07",
        MONTH: "Dec",
        TITLE: "Health benefits of flax seeds are a great source of fiber",
        DESCRIPTION:
          "The benefits of Flax seed are numerous and this tiny brownish seed is packed with micronutrients, vitamins...",
      },
      {
        ID: 5,
        IMAGE: mosquito,
        CATEGORY: "Category Name",
        DAY: "06",
        MONTH: "Dec",
        TITLE: "What you can do to protect you from Dengue Fever",
        DESCRIPTION:
          "Dengue fever is caused by a virus and is transmitted through the bite of an infected Aedes mosquito...",
      },
      {
        ID: 6,
        IMAGE: girl,
        CATEGORY: "Category Name",
        DAY: "05",
        MONTH: "Dec",
        TITLE: "Fitness regime that helps you stay healthy",
        DESCRIPTION:
          "Do you pursue a fitness regime that helps you stay healthy? Do you intend on starting one soon? Do you intend on starting one soon? ...",
      },
      {
        ID: 7,
        IMAGE: girl,
        CATEGORY: "Category Name",
        DAY: "04",
        MONTH: "Dec",
        TITLE: "Fitness regime that helps you stay healthy",
        DESCRIPTION:
          "Do you pursue a fitness regime that helps you stay healthy? Do you intend on starting one soon?Do you intend on starting one soon?...",
      },
      {
        ID: 8,
        IMAGE: bowl,
        CATEGORY: "Category Name",
        DAY: "03",
        MONTH: "Dec",
        TITLE: "Health benefits of flax seeds are a great source of fiber",
        DESCRIPTION:
          "The benefits of Flax seed are numerous and this tiny brownish seed is packed with micronutrients, vitamins...",
      },
      {
        ID: 9,
        IMAGE: mosquito,
        CATEGORY: "Category Name",
        DAY: "02",
        MONTH: "Dec",
        TITLE: "What you can do to protect you from Dengue Fever",
        DESCRIPTION:
          "Dengue fever is caused by a virus and is transmitted through the bite of an infected Aedes mosquito...",
      },
    ],
  },
};
