import * as React from "react";

import styles from "../styling/CustomStack.module.css";
import { styled } from "@mui/material/styles";
import { Typography, Stack, Avatar, Paper, Box } from "@mui/material";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
  maxWidth: 400,
}));

export default function CustomStack({ title, iconPath, number, color="gray", ...props }) {
  let activity_item_style = styles.activity_item_style;
  let activity_desc = styles.activity_desc;
  switch (color) {
    case "green":
      activity_item_style = `${activity_item_style} ${styles.activity_item_style_green}`;
      activity_desc = `${activity_desc} ${styles.activity_desc_green}`;
      break;
    case "red":
      activity_item_style = `${activity_item_style} ${styles.activity_item_style_red}`;
      activity_desc = `${activity_desc} ${styles.activity_desc_red}`;
      break;
    case "light_green":
      activity_item_style = `${activity_item_style} ${styles.activity_item_style_light_green}`;
      activity_desc = `${activity_desc} ${styles.activity_desc_light_green}`;
      break;
    default:
      activity_item_style = `${activity_item_style} ${styles.activity_item_style_grey}`;
      activity_desc = `${activity_desc} ${styles.activity_desc_grey}`;
  }

  return (
    <>
      <Box className={styles.activity_container}>
        <Item className={activity_item_style}>
          <Stack spacing={2} className={styles.activity_stack}>
            <Avatar src={iconPath}></Avatar>
            <Typography noWrap className={styles.activity_title}>
              {title}
            </Typography>
            <Box className={activity_desc}>{number}</Box>
          </Stack>
        </Item>
      </Box>
    </>
  );
}
