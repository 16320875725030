import React from "react";
import { Typography, Box, Divider } from "@mui/material";
import styles from "../styling/PaymentInfo.module.css";
import { SiteContent } from "../assets/content/SiteContent";

export const PaymentInfo = ({ data }) => {
  return (
    <Box className={styles.main_container}>
      <Typography className={styles.bold_text}>{SiteContent?.PAYMENT_INFO_TITLE}</Typography>
      <Box padding={{ xs: "15px", lg: "25px" }} className={styles.sub_container}>
        <Box className={styles.price_details}>
          <Typography>{SiteContent?.TOTAL_PRICE_LABEL}</Typography>
          <Typography className={styles.bold_text}>2000.00</Typography>
        </Box>
        <Box className={styles.price_details}>
          <Typography>{SiteContent?.COUPON_LABEL}</Typography>
          <Typography className={styles.danger_text}>-1000.00</Typography>
        </Box>

        <Box className={styles.price_details}>
          <Typography>{SiteContent?.TAX_LABEL}</Typography>
          <Typography className={styles.bold_text}>0.00</Typography>
        </Box>

        <Divider className={styles.dashed_divider}/>
        <Box className={`${styles.price_details} ${styles.grand_total}`}>
          <Typography className={styles.bold_text}>{SiteContent?.GRAND_TOTAL_LABEL}</Typography>
          <Typography className={`${styles.bold_text} ${styles.danger_text}`}>1000.00</Typography>
        </Box>
      </Box>
    </Box>
  );
};
