import React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import ArrowOutwardOutlinedIcon from '@mui/icons-material/ArrowOutwardOutlined';
import styles from '../styling/Cards.module.css';
import { SiteContent } from '../assets/content/SiteContent';
import { useNavigate } from 'react-router-dom';
import { useSharedData } from '../Context/ShareContext';
import { landing_dynamic } from '../json/landingDynamic';

export default function LandingCards({ numberOfCards }) {
  const navigate = useNavigate();
  const { setIndexOfSubheader, setActiveButtonIndex } = useSharedData();

  const handleEverydayPageLocation = (index) => {
    setIndexOfSubheader(0);
    setActiveButtonIndex(false);
    navigate("/login/everydayhealth");
  };

  const handleCorporatePageLocation = (index) => {
    setIndexOfSubheader(1);
    setActiveButtonIndex(false);
    navigate("/login/corporatehealth");
  };

  
  const slicedCards = landing_dynamic?.BANNER_7?.INCLUSIVE_CONTENT?.slice(0, numberOfCards);
 
  const renderCard = (card,index) => (
    <Card key={card?.ID} className={styles['photo-card']} elevation={1} >
      <Box className={styles['card-media-container']}>
        <CardMedia
          className={styles['blog-card-pic']}
          component="img"
          image={card?.IMAGE}
          title="green iguana"
        />
      </Box>
      <CardContent className={styles['content-photo-card']}>
        <Stack spacing={1}>
          <Typography align="left" className={styles['blog-title']}>
            {card?.TITLE}
          </Typography>
          <Typography variant="body2" color="text.secondary" className={styles.desc} align="left">
            {card?.DESCRIPTION}
          </Typography>
        </Stack>
      </CardContent>
      <CardActions>
        <Button size="small" onClick={() => index === 0 ? handleEverydayPageLocation(index) : handleCorporatePageLocation(index)}>
          <Typography textTransform="none" fontWeight="bold">
            {SiteContent.READ_MORE}
          </Typography>
        </Button>
        <ArrowOutwardOutlinedIcon className={styles['blogsnewArrows']} />
      </CardActions>
    </Card>
  );

  const renderCardStack = () => (
    <Stack direction={{ xs: 'column', sm: 'row' }} alignItems="center" spacing={2} mb={2} justifyContent="center">
      {slicedCards?.map((card, index) => (
        index % 2 === 0 && (
          <React.Fragment key={card?.ID}>
            {renderCard(card, index) }
            {slicedCards[index + 1] && renderCard(slicedCards[index + 1], index + 1)}
          </React.Fragment>
        )
      ))}
    </Stack>
  );

  return (
    <Box data-testid="landing-cards">
      <Stack className='blog-cards-stack'>
        <Stack direction="column">
          {renderCardStack()}
        </Stack>
      </Stack>
    </Box>
  );
}
