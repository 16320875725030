import React, { useState } from "react";
import { useSharedData } from "../Context/ShareContext";
import { useNavigate } from "react-router-dom";
import {
  Typography,
  Button,
  Menu,
  MenuItem,
  Stack,
  Avatar,
  Divider,
} from "@mui/material";
import theme from "../components/Themeify";
import IconButton from "@mui/material/IconButton";
import {
  AccountCircleOutlined,
  KeyboardArrowUp,
  KeyboardArrowDown,
  NotificationsActiveOutlined,
  KeyboardArrowRight,
} from "@mui/icons-material";
import styles from "../styling/MyAccountSection.module.css";
import {
  MyAccDropdownCardLeft,
  MyAccDropdownCardRight,
  MyAccDropdownCardRightBottom,
  NotificationData,
} from "../json/DropdownCard";
import makeStyles from "@material-ui/styles/makeStyles";

const useStyles = makeStyles({
  popOverRoot: {
    pointerEvents: "none",
  },
});

export const MyAccountSection = ({ setValue }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [bellAnchorEl, setBellAnchorEl] = useState(null);
  const [showUpIcon, setShowUPIcon] = useState(false);
  const { setitemSelectedPassed } = useSharedData();
  const open = Boolean(anchorEl);
  const bellOpen = Boolean(bellAnchorEl);
  const navigate = useNavigate();

  const handleShowUpIcon = (event) => {
    setAnchorEl(event?.currentTarget);
    setShowUPIcon(true);
  };

  const handleCloseIcon = () => {
    setShowUPIcon(false);
    setTimeout(() => {
      if (!showUpIcon) {
        setAnchorEl(null);
      }
    }, 50);
  };

  const handleSelectedIconClose = (selected) => {
    if (selected === "Help & Support") {
      navigate("/help");
      setAnchorEl(null);
      setShowUPIcon(false);
      setitemSelectedPassed(selected);
    } else if (selected !== "Help & Support") {
      navigate("/myaccount");
      setAnchorEl(null);
      setShowUPIcon(false);
      setitemSelectedPassed(selected);
    }
    setAnchorEl(null);
  };
  const handleCloseModal = (selected) => {
    setShowUPIcon(false);
    setAnchorEl(null);
  };
  const handleBellClick = (event) => {
    setBellAnchorEl(event?.currentTarget);
  };
  const handleCloseBell = () => {
    setBellAnchorEl(null);
  };
  const handleViewAll = (selected) => {
    navigate("/myaccount");
    setitemSelectedPassed(selected);
    setBellAnchorEl(null);
  };
  const handleLogout = () => {
    setAnchorEl(null);
    navigate("/login/loginpage");
  };

  const style = useStyles();

  return (
    <>
      <Button
        id="basic-button"
        startIcon={<AccountCircleOutlined />}
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onMouseEnter={handleShowUpIcon}
        onMouseLeave={handleCloseIcon}
        endIcon={
          showUpIcon === true || anchorEl !== null ? (
            <KeyboardArrowUp />
          ) : (
            <KeyboardArrowDown sx={{ color: theme.palette.info.main }} />
          )
        }
      >
        <Typography
          variant="h5"
          className={
            showUpIcon
              ? styles.myacountsection_typoTitleActive
              : styles.myacountsection_typoTitle
          }
        >
          My Account
        </Typography>
      </Button>
      <IconButton
        id="bell-button"
        aria-controls={bellOpen ? "notification-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={bellOpen ? "true" : undefined}
        onClick={handleBellClick}
      >
        <NotificationsActiveOutlined sx={{ color: theme.palette.info.main }} />
      </IconButton>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleCloseModal}
        MenuListProps={{
          "aria-labelledby": "basic-button",
          onMouseLeave: handleCloseIcon,
          style: { pointerEvents: "auto" },
          sx: { py: 0 },
        }}
        PopoverClasses={{
          root: style.popOverRoot,
        }}
        sx={{
          "& .MuiPaper-root": {
            borderRadius: "20px",
          },
        }}
      >
        <Stack direction="row">
          <Stack className={styles.myacountsection_mainStack}>
            {MyAccDropdownCardLeft?.map((item, index) => {
              return (
                <MenuItem
                  className={styles.myacountsection_menuItem}
                  key={index}
                  onClick={() => {
                    handleSelectedIconClose(item?.name);
                    setValue(null);
                  }}
                >
                  <Avatar
                    className={styles.myacountsection_avatarIcon}
                    sx={{ bgcolor: item?.iconBG }}
                  >
                    {item?.icon}
                  </Avatar>
                  <Typography className={styles.myacountsection_menuTitle}>
                    {item?.name}
                  </Typography>
                </MenuItem>
              );
            })}
          </Stack>
          <Divider orientation="vertical" flexItem variant="fullWidth" />
          <Stack className={styles.myacountsection_mainStack}>
            {MyAccDropdownCardRight?.map((item, index) => {
              return (
                <MenuItem
                  className={styles.myacountsection_menuItem}
                  key={index}
                  onClick={() => {
                    handleSelectedIconClose(item?.name);
                    setValue(null);
                  }}
                >
                  <Avatar
                    className={styles.myacountsection_avatarIcon}
                    sx={{ bgcolor: item?.iconBG }}
                  >
                    {item?.icon}
                  </Avatar>
                  <Typography className={styles.myacountsection_menuTitle}>
                    {item?.name}
                  </Typography>
                </MenuItem>
              );
            })}
            <Divider variant="fullWidth" />
            {MyAccDropdownCardRightBottom?.map((item, index) => {
              return (
                <MenuItem
                  className={styles.myacountsection_menuItem}
                  key={index}
                  onClick={handleLogout}
                >
                  <Avatar
                    className={styles.myacountsection_avatarIcon}
                    sx={{ bgcolor: item?.iconBG }}
                  >
                    {item?.icon}
                  </Avatar>
                  <Typography className={styles.myacountsection_menuTitle}>
                    {item?.name}
                  </Typography>
                </MenuItem>
              );
            })}
          </Stack>
        </Stack>
      </Menu>

      <Menu
        id="notification-menu"
        anchorEl={bellAnchorEl}
        open={bellOpen}
        onClose={handleCloseBell}
        MenuListProps={{
          "aria-labelledby": "bell-button",
        }}
        sx={{
          "& .MuiPaper-root": {
            borderRadius: "20px",
          },
        }}
      >
        {NotificationData?.map((item, index) => {
          return (
            <>
              <MenuItem
                className={`${styles.myacountsection_menuItem} ${styles.Noti}`}
                key={index}
                onClick={handleCloseBell}
              >
                <Stack className={styles.myacountsection_notiMainStack}>
                  <Avatar
                    className={styles.myacountsection_avatarIcon}
                    sx={{ bgcolor: item?.iconBG }}
                  >
                    {item?.icon}
                  </Avatar>
                  <Stack>
                    <Typography
                      className={`${styles.myacountsection_menuTitle} ${styles.Noti}`}
                    >
                      {item?.name}
                    </Typography>
                    <Typography className={styles.myacountsection_typoDesc}>
                      {item?.time}
                    </Typography>
                  </Stack>
                </Stack>
              </MenuItem>
              {index !== NotificationData.length - 1 && (
                <Divider variant="inset" />
              )}
            </>
          );
        })}
        <MenuItem
          className={`${styles.myacountsection_menuItem} ${styles.Noti} ${styles.ViewAll}`}
          onClick={() => handleViewAll("Notifications")}
        >
          <Typography className={styles.myacountsection_notiViewAll}>
            View All
            <KeyboardArrowRight />
          </Typography>
        </MenuItem>
      </Menu>
    </>
  );
};
