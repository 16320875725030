export const accordionData = [
    {name:"Albumin/Globulin Ratio",id:1},
    {name:"Alkaline Phosphatase (ALP)",id:2},
    {name:"Bilirubin Direct",id:3},
    {name:"Bilirubin-Indirect",id:4},
    {name:"Bilirubin Total",id:5},
    {name:"Gamma Glutamyl Transferase (GGT)",id:6},
    {name:"Proteins",id:7},
    {name: "Albumin",id:8},
    {name:"Alanine Transaminase (SGPT / ALT)",id:9},
    {name:"Globulin",id:10},

  ];