import React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import styles from "../styling/CheckoutAccordian.module.css";
import { Stack } from "@mui/material";
const CheckoutAccordian = ( {title, data, icon} ) => {
  
  return (
    <div>
      <Accordion className={styles.accordian} >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        > 
        <img src={icon} alt=''/>
          <Typography className={styles.accordian_Header}>{title}</Typography>
        </AccordionSummary>
        <AccordionDetails>
        {data && (
            <ul className={styles.accul}>
              {data?.map((list, index) => (
                (!list?.dis)  ? 
                <li key={index} className={styles.accd_List}>
                 
                {list?.name}
                {list?.val && `: `}
                {list?.val && <strong>{list?.val}</strong>}
              
                      {list?.submenu && (
                        <ul className={styles.mainList}>
                          {list?.submenu.map((subItem, subIndex) => (
                            <li key={subIndex} className={styles.submenu_List}>
                              {subItem}
                            </li>
                          ))}
                        </ul>
                      )}
                    </li>
                 : 
                 <Stack className={styles.discolumn}>
                  <Stack>
                     <Typography className={styles.typetwo}> {list?.name}</Typography>
                  </Stack>
                  <Stack>
                  <Typography className={styles.typoone}>{list?.dis}</Typography>
                  </Stack>
                 </Stack>
                
              
               
              
              ))}
            </ul>
          )}
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default CheckoutAccordian;
