import React from "react";
import { Typography, Stack, Paper, Box } from "@mui/material";
import styles from "../styling/Marque.module.css";
import { corporate_dynamic } from "../json/corporateDynamic";

export const Marque = () => {
  return (
    <Box data-testid="marque" className={styles.marqueContainer}>
      <Typography
        variant="h4"
        className={styles.marqueTypos}
        component="div"
        mb={5}
      >
        {corporate_dynamic?.BANNER_8?.TITLE}
      </Typography>
      <Stack
        direction={{ xs: "column", sm: "row" }}
        spacing={3}
        justifyContent="space-between"
        alignContent="center"
        flexWrap="wrap"
        className={styles.Marque_main}
        useFlexGap
      >
        {corporate_dynamic?.BANNER_8?.CLIENTS_LOGO?.map((item) => {
          return (
            <Paper className={styles.Marqueboxicon}>
              <Box
                key={item.ID}
                component="img"
                className={styles.Marqueboximage}
                src={item?.IMAGE}
                alt={item?.ALT}
              />
            </Paper>
          );
        })}
      </Stack>
    </Box>
  );
};
