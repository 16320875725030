import { Box, Grid, Stack } from "@mui/material";
import React from "react";
import {
  DotLinesWithTxt,
  HeadingTxt,
  SubHeadingDescTxtWithUrl,
  SubHeadingTxt,
  TableTop,
  TableTopDesc,
} from "./TermsAndPolicyMolecules";
import styles from "../styling/TermsAndPolicyMolecules.module.css";
import {
  TermsAndConditionContentData,
  TermTableData,
} from "../assets/content/TermsConditions";
import { useEffect } from "react";
const TermsAndCondition = () => {
  const handleNavigate = (item) => {
    const element = document.getElementById(`${item}`);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Box className={styles.box_styling} data-testid="terms_main">
      <Grid container  className={styles.maingridtersm}>
        <Grid item  xs={11} sm={8}>
          <Stack>
            <HeadingTxt title={TermsAndConditionContentData.heading1} />
            <SubHeadingDescTxtWithUrl
              descVal={TermsAndConditionContentData.heading1Desc}
            />
            <div id={TermsAndConditionContentData.heading2}>
              <SubHeadingTxt subTitle={TermsAndConditionContentData.heading2} />
            </div>

            {TermsAndConditionContentData.heading2ArrayData.map((item) => (
              <DotLinesWithTxt text={item} />
            ))}
            <div id={TermsAndConditionContentData.heading3}>
              <SubHeadingTxt subTitle={TermsAndConditionContentData.heading3} />
            </div>

            <SubHeadingDescTxtWithUrl
              descVal={TermsAndConditionContentData.heading3Desc}
            />
            <div id={TermsAndConditionContentData.heading4}>
              <SubHeadingTxt subTitle={TermsAndConditionContentData.heading4} />
            </div>

            <SubHeadingDescTxtWithUrl
              descVal={TermsAndConditionContentData.heading4Desc}
            />
            <div id={TermsAndConditionContentData.heading8}>
              <SubHeadingTxt subTitle={TermsAndConditionContentData.heading8} />
            </div>

            <SubHeadingDescTxtWithUrl
              descVal={TermsAndConditionContentData.heading8Desc}
            />
            <div id={TermsAndConditionContentData.heading5}>
              <SubHeadingTxt subTitle={TermsAndConditionContentData.heading5} />
            </div>

            {TermsAndConditionContentData.heading5Desc.map((item) => (
              <DotLinesWithTxt text={item} />
            ))}
            <div id={TermsAndConditionContentData.heading6}>
              <SubHeadingTxt subTitle={TermsAndConditionContentData.heading6} />
            </div>
            <SubHeadingDescTxtWithUrl
              descVal={TermsAndConditionContentData.heading6Desc}
            />
            <div id={TermsAndConditionContentData.heading7}>
              <SubHeadingTxt subTitle={TermsAndConditionContentData.heading7} />
            </div>

            <SubHeadingDescTxtWithUrl
              descVal={TermsAndConditionContentData.heading7Desc}
            />
          </Stack>
        </Grid>
        <Grid item xs={0} sm={1}></Grid>

        <Grid item xs={12} sm={3}>
          <Stack className={styles.paperStyle}>
            <TableTop Title={TermTableData.heading} />

            {TermTableData.arrOfObj.map((item) => (
              <TableTopDesc Title={item} handleNavigate={handleNavigate} />
            ))}
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
};

export default TermsAndCondition;
