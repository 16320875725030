import React, { createContext, useContext, useState } from "react";
const SharedContext = createContext({});

export const ShareContextProvider = ({ children }) => {
  function a11yProps(index = false) {
    return {
      id: `nav-tab-${index}`,
      "aria-controls": `nav-tabpanel-${index}`,
    };
  }  ;
  const [indexOfSubheader, setIndexOfSubheader] = useState(false);
  const [activeButtonIndex, setActiveButtonIndex] = useState(null);
  const [value, setValue] = useState(0);
  const [itemSelectedPassed, setitemSelectedPassed] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [showSignPopup, setShowSignPopup] = useState(false);

  const [showCancel, setShowCancel] = useState(false);

  const [showCongratulationsPopup, setShowCongratulationsPopup] = useState(false);
  const [currentSteps, setCurrentSteps] = useState(1);
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [showSummary, setShowSummary] = useState(false);
  const [itemSelected, setitemSelected] = useState(null);
  const [showPackage, setShowPackage] = useState(false);
  const [showNotifications, setShowNotifications] = useState(false);
  const [showMembershipSummary, setShowMembershipSummary] = useState(true);
  const [appointmentSummaryData, setAppointmentSummaryData] = useState({});
  const [packageSummaryData, setPackageSummaryData] = useState({});
  const [membershipSummaryData, setMembershipSummaryData] = useState({});
  const [openModal, setOpenModal] = useState(false);
  const [rescheduleSuccessModal, setRescheduleSuccessModal] = useState(false);
  const [appointmentRescheduleModal, setAppointmentRescheduleModal] = useState(false);
  const [bookActivityModal, setBookActivityModal] = useState(false);
  const [privatetabvalue, setPrivatetabvalue] = useState(false);
  const [drawer, setDrawer] = useState(true);
  const [modalValue, setModalValue]=useState(false)
  function PrivatePropspass(index) {
    return {
      id: `nav-tab-${index}`,
      "aria-controls": `nav-tabpanel-${index}`,
    };
   
  }
  return (
    
    <SharedContext.Provider
      value={{
        a11yProps,
        indexOfSubheader, 
        setIndexOfSubheader,
        modalValue,
        setModalValue,
        activeButtonIndex, 
        setActiveButtonIndex,
        appointmentRescheduleModal,
        setAppointmentRescheduleModal,
        rescheduleSuccessModal,
        setRescheduleSuccessModal,
        bookActivityModal,
        setBookActivityModal,
        itemSelectedPassed,
        setitemSelectedPassed,
        showPopup,
        setShowPopup,
        showSignPopup,
        setShowSignPopup,
        showCancel,
        setShowCancel,
        showCongratulationsPopup, 
        setShowCongratulationsPopup,
        currentSteps,
        setCurrentSteps,
        showSuccessPopup,
        setShowSuccessPopup,
        showSummary,
        setShowSummary,
        setitemSelected,
        itemSelected,

        showPackage,
        setShowPackage,
        showMembershipSummary,
        setShowMembershipSummary,
        appointmentSummaryData,
        setAppointmentSummaryData,
        packageSummaryData,
        setPackageSummaryData,
        membershipSummaryData,
        setMembershipSummaryData,
        openModal,
        drawer,
        setDrawer,
        setOpenModal,
        setShowNotifications,
        showNotifications,
        privatetabvalue, 
        setPrivatetabvalue,
        PrivatePropspass,
        value,
        setValue
      }}
    >
      {children}
    </SharedContext.Provider>
  );
};
export const useSharedData = () => useContext(SharedContext);
