import { Stack } from "@mui/system";
import React from "react";
import { Typography } from "@mui/material";
import styles from "../styling/LabtestAppointment.module.css";
import { Link, useNavigate } from "react-router-dom";
import Divider from "@mui/material/Divider";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import ScheduleOutlinedIcon from "@mui/icons-material/ScheduleOutlined";
import PlaceOutlinedIcon from "@mui/icons-material/PlaceOutlined";
import { constants } from "../json/labappo";

const LabtestAppointment = (props) => {
  const navigate = useNavigate();

  const navigateToUrl = (url) => {
    navigate(url);
  };
  return (
    <>
      <Stack>
        <Stack
          direction="row"
          spacing={0.5}
          className={styles.labatestAppointment_main_stack}
        >
          <Stack className={styles.labtest_stack_maintwo}>
            <img className={styles.ico_21_img} src={props.img} alt="" />
          </Stack>
          <Stack className={styles.onsiteappoint_stack_oness}>
            <Typography
              align="left"
              variant="h4"
              className={styles.onsiteappoint_typo_oness}
            >
              {props.textone}
            </Typography>
          </Stack>
        </Stack>
        <Divider />
        <Stack
          spacing={8}
          direction={{
            xs: "column",
            sm: "row",
            md: "row",
            lg: "row",
          }}
          className={styles.onsite_stacke_innerOne}
        >
          <Stack>
            <Stack
              spacing={2}
              direction="row"
              className={styles.onsite_stacke_innerTwo}
            >
              <Stack>
                <CalendarMonthOutlinedIcon />
              </Stack>
              <Stack>
                <Typography
                  align="left"
                  variant="h4"
                  className={styles.today_17_nov_date}
                >
                  {constants.todayDateLabel}
                </Typography>
              </Stack>
            </Stack>
            <Stack className={styles.onsite_stacke_innerThree}>
              <span
                onClick={() => navigateToUrl('/test_and_packages/lab_tests/details')}
                className={styles.onsite_stacke_innerThree_typoss}
              >
                <Typography align="left" variant="h5">
                  {constants.changeDateTimeLabel}
                </Typography>
              </span>
            </Stack>
          </Stack>

          <Stack spacing={2} direction="row">
            <Stack>
              <ScheduleOutlinedIcon />
            </Stack>
            <Stack>
              <Typography variant="h4" className={styles.today_17_nov_date}>
                At 1:30 PM
              </Typography>
            </Stack>
          </Stack>
        </Stack>
        <Divider />
        <Stack className={styles.stack_incline_appointment_one}>
          <Typography
            align="left"
            variant="h4"
            className={styles.onsiteappoint_typo_oness}
          >
            {props.textTwo}
          </Typography>
        </Stack>
        <Divider />
        <Stack
          spacing={2}
          direction="row"
          className={styles.inclinic_left_stack_inner}
        >
          <Stack>
            <PlaceOutlinedIcon className={styles.place_outlined_icon} />
          </Stack>
          <Stack>
            <Typography
              align="left"
              className={styles.onsiteappoint_typo_oness}
            >
              {constants.appointmentLocationLabel}
            </Typography>
            <Typography align="left" mb={1}>
              {constants.appointmentAddressLabel}
            </Typography>
            <Link to={`https://www.google.com/maps`} target="_blank">
              <Typography
                align="left"
                variant="h5"
                className={styles.onsite_stacke_innerThree_typoss}
              >
                {constants.getDirectionsLabel}
              </Typography>
            </Link>
          </Stack>
        </Stack>
      </Stack>
    </>
  );
};

export default LabtestAppointment;
