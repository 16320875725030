export const locationsDummyData = [
    {id:1,name:'FITU30' ,off:'Get Flat 30% Off',text:"You will save ₹ 150 on your booking with this code",button:"Apply"},
    {id:2,name:'FIT387K' ,off:'Get Flat 20% Off',text:"You will save ₹ 100 on your booking with this code",button:"Apply"},
    {id:3,name:'FITU30' ,off:'Get Flat 30% Off',text:"You will save ₹ 150 on your booking with this code",button:"Apply"}
    
  ];

 export const coupon={
    apply:"Apply Coupon",

  }