import React from "react";
import { Breadcrumbs, Link, Typography } from "@mui/material";
import theme from "../components/Themeify";
import styles from "../styling/BreadCrumb.module.css";

function BreadCrumb(props) {
  function handleClick(event) {
    event?.preventDefault();
  }
  const home="Home"
  return (
    <div
      data-testid="breadcrumb"
      role="presentation"
      onClick={handleClick}
      className={styles.bread_crumb}
    >
      <Breadcrumbs aria-label="breadcrumb">
        <Link color="inherit" href="/" underline="none">
          {home}
        </Link>
        <Typography color={theme.palette.primary.light}>
          {props.crumb.name}
        </Typography>
      </Breadcrumbs>
    </div>
  );
}

export default BreadCrumb;
