import React from "react";
import { Typography, Box, Paper, Stack, Avatar } from "@mui/material";
import styles from "../styling/UseCoupon.module.css";
import { CheckCircle } from "@mui/icons-material";
import offerIcon from "../assets/offericon.svg"

export const UseCoupon = ({ data }) => {
  return (
    <Box className={styles.main_container}>
      <Paper className={styles.sub_container}>
        <Stack className={styles.use_coupon_stack} spacing={2}>
          <Box className={styles.left_side}>
            <Avatar className={styles.percent_icon_avatar} src={offerIcon}/>
            <Typography className={styles.use_coupon_label}>Use Coupon</Typography>
          </Box>
          <Box className={styles.right_side}>
            <Typography className={styles.code_applied_label}>Code FITU30 applied</Typography>
            <CheckCircle className={styles.check_circle_icon} />
          </Box>
        </Stack>
      </Paper>
    </Box>
  );
};
