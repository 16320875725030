export const dentList = [
  { index: 1, name: "Teeth Cleaning & Polishing", action: () => {} },
  { index: 2, name: "Dental Implants", action: () => {} },
  { index: 3, name: "Root Canal Treatment", action: () => {} },
  { index: 4, name: "Teeth Fillings", action: () => {} },
  { index: 5, name: "Dental Braces/Aligners", action: () => {} },
  { index: 6, name: "Dentures", action: () => {} },
  { index: 7, name: "Teeth Whitening/Bleaching", action: () => {} },
  { index: 8, name: "Cosmetic Treatment", action: () => {} },
  { index: 9, name: "Others", action: () => {} },
];
