import React from 'react'
import {
    Box,
    Stack,
    Typography,
    IconButton,
    SvgIcon,
    Divider,
  } from "@mui/material";
  import styles from "../../src/styling/footer.module.css";
  import theme from "../components/Themeify";
  import { SiteContent } from "../assets/content/SiteContent";
  import { useNavigate } from "react-router-dom";
const SignupFooter = () => {
    const navigate = useNavigate();
     return (
    <div>
      <Divider sx={{ my: 4 }} />
    <Stack
    direction="row"
    justifyContent="space-between"
    className={styles["divider-stack"]}
  >
    <Stack>
      <Typography
        className={styles.footer_last_stack}
        variant="body2"
        color={theme.palette.text.secondary}
      >
      {SiteContent?.COPYRIGHT}
      </Typography>
    </Stack>

    <Stack direction="row" spacing={3} className={styles.newstyle}>
      <Typography
        onClick={() => navigate("/login/policy")}
        className={styles["text-typo2"]}
        color={theme.palette.text.secondary}
      >
        {SiteContent?.PRIVACY_POLICY}
      </Typography>
      <Typography
        onClick={() => navigate("/login/terms")}
        className={styles["text-typo2"]}
        color={theme.palette.text.secondary}
      >
        {SiteContent.TERMS_AND_CONDITIONS}
      </Typography>
      <Typography
        className={styles["text-typo2"]}
        color={theme.palette.text.secondary}
      >
        {SiteContent?.SITEMAP}
      </Typography>
    </Stack>
  </Stack>
  </div>
  )
}

export default SignupFooter