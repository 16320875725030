import React from "react";
import { Box, Stack, Typography, Button } from "@mui/material";
import AccessTimeFilledIcon from "@mui/icons-material/AccessTimeFilled";
import styles from "../styling/CategoryCard.module.css";

import { slots } from "../json/slots";
import LightModeIcon from "@mui/icons-material/LightMode";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import MyLocationIcon from "@mui/icons-material/MyLocation";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import doctorstyles from "../styling/DoctorSchedule.module.css";
import { Link } from "react-router-dom";
export default function Tab1DoctorSchedule({
  tab,
  openLocationMenu,
}) {
  const handleDrawerToggle = () => {
    openLocationMenu(true);
  };
 

  return (
    <div>
      {tab === "tab1" && (
        <>
          <Stack
            spacing={2}
            direction="row"
            className={doctorstyles.location_dropdown_schedule}
            onClick={handleDrawerToggle}
          >
            <MyLocationIcon color="success" />
            <Typography>Navi Mumbai</Typography>
            <span className={doctorstyles.dropdown_span_schedule} />

            <KeyboardArrowDownIcon />
          </Stack>
        </>
      )}

      <Stack direction="row" className={doctorstyles.available_layout}>
        <Typography variant="h5">Available Sessions</Typography>
        <span className={doctorstyles.dummy_div} />
        <Stack direction="row" className={doctorstyles.duration_layout}>
          <AccessTimeFilledIcon
            className={doctorstyles.timer_icon}
            size="small"
            color="primary"
          />

          <Typography variant="h5" color="primary">
            15 min duration
          </Typography>
        </Stack>
      </Stack>
      <Box className={doctorstyles.box_scroll}>
        {slots?.map((item) => {
          return (
            <>
              <Stack spacing={2} justifyContent="space-between" mt={2}>
                <Stack direction="row" spacing={2}>
                  <LightModeIcon
                    size="small"
                    className={doctorstyles.icons_slot}
                    color="primary"
                  />
                  <Typography variant="h5" align="left" color="primary">
                    {" "}
                    {item?.name}
                  </Typography>
                </Stack>
                <Stack
                  direction="row"
                  spacing={{ xs: 2, sm: 2 }}
                  useFlexGap
                  flexWrap="wrap"
                >
                  {item?.slots?.map((ele, index) => {
                    return (
                      <>
                        <Typography
                          className={`${doctorstyles.border} ${
                            index === 2 ? doctorstyles.selected_slot : ``
                          }`}
                        >
                          {ele}
                        </Typography>
                      </>
                    );
                  })}
                </Stack>
              </Stack>
            </>
          );
        })}
      </Box>
      <Link to={`/test_and_packages/xray/booking/checkout`}  className={styles.linkNoUnderline}>
      <Button
        className={doctorstyles.proceed_btn}
        endIcon={
          <Stack className={doctorstyles.backView}>
            <KeyboardArrowRightIcon className={doctorstyles.svg_image_white} />
          </Stack>
        }
      >
      {tab === "tab1" ? 'Book Test'  : 'Proceed   to Book'} 
      </Button>
      </Link>
    </div>
  );
}
