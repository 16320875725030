import React, { useEffect } from "react";
import UsBanner from "../molecules/UsBanner";
import { Stack, Box, Typography, Button } from "@mui/material";
import OurPurpose from "../molecules/OurPurpose";
import TeamCard from "../molecules/TeamCard";
import { Milestone } from "../molecules/Milestones";
import styles from "../styling/AboutUs.module.css";
import { useNavigate } from "react-router-dom";
import { aboutUs_Dynamic } from "../json/aboutus";
import { SiteContent } from "../assets/content/SiteContent";

export const AboutUs = () => {
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
    return () => {};
  }, []);

  const handleOurTeamClick = () => {
    navigate("/login/ourteam");
  };
  return (
    <Stack spacing={5} marginBottom={3}>
      <UsBanner />
      <OurPurpose />
      <Box>
        <Stack className={styles["about-stack"]}>
          <Typography variant="h1" fontWeight="bold" mb={2} data-testid="title">
            {SiteContent.OUR_TEAM}
          </Typography>
          {aboutUs_Dynamic?.BANNER_3?.TEAMARRAY?.slice(0, 2).map(
            (item, index) => {
              const descriptionLines = item?.DESCRIPTION.split("\n");
              return (
                <TeamCard
                  key={index}
                  image={item?.IMAGE}
                  name={item?.NAME}
                  designation={item?.DESIGNATION}
                  description={descriptionLines[0]}
                  setdirection={item?.SETDIRECTION}
                />
              );
            }
          )}
        </Stack>

        <Button
          variant="contained"
          size="small"
          className={styles["book-btn"]}
          onClick={handleOurTeamClick}
        >
          <Typography variant="h4" fontWeight="bolder">
            {SiteContent.MEET_OUR_TEAM}
          </Typography>
        </Button>
      </Box>
      <Milestone />
    </Stack>
  );
};
