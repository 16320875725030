import * as React from "react";
import { Box } from "@mui/system";
import bookingConfirm from "../assets/images/booking_confirm.svg";
import GenericPopup from "./GenericPopup";
export const CongratulationPopup = () => {
  
  return (
    <Box>
      <GenericPopup
        Icon={bookingConfirm}
        MainTxt={"Congratulations!"}
        SubTxt={
          "Your Package Successfully booked.You will receive a notification"
        }
        MainBtnTxt={"View Package Details"}
        SubBtnTxt={"Back to Home"}
        ShowSubBtn={true}
        PageName={"Congratulations"}
        PlaceholderTxt={""}
      />
    </Box>
  );
};
