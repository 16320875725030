import { Box, Drawer, Stack, Typography } from "@mui/material";
import React from "react";
import defaultStyles from "../styling/DefaultStyles.module.css";
import theme from "../components/Themeify";
import styles from "../styling/Booking.module.css";
import Bread from "./Bread";
import DoctorScheduleForTeethCleaning from "./DoctorScheduleForTeethCleaning";
import bgimage from "../assets/black_leaf.png";

import LocationMenu from "./LocationMenu";
import { useState } from "react";
import DoctorScheduleForNew from "./DoctorScheduleForNew";
export const InternalBanner = (props) => {
  return (
    <Box>
      <Box>
        <Box
          className={styles.bg_img}
          style={{
            backgroundImage: `url(${bgimage})`,
          }}
        >
          <Stack
            direction="row-reverse"
            justifyContent="flex-end"
            alignItems="center"
            spacing={2}
          >
            <Typography
              color={theme.palette.light.main}
              className={styles.typourgent_care}
              align="left"
            >
              {props?.text}
            </Typography>

            <Box
              component="img"
              alt="Image"
              src={props?.img}
              className={styles.img_height}
            />
          </Stack>
        </Box>
      </Box>
    </Box>
  );
};

export default function BookingForTeethClening({ iconImage, headerText }) {
  const [locationMenu, setLocationMenu] = useState(false);

  const openLocationMenu = (bool) => {
    setLocationMenu(bool);
  };

  const handleCloseLocationMenu = () => {
    setLocationMenu(false);
  };

  return (
    <>
      <Stack className={defaultStyles.max_bread_14_5}>
        <Bread />
      </Stack>

      <InternalBanner text={headerText} img={iconImage} />
      <Stack justifyContent="center" alignItems="center" mt={1}>
        <DoctorScheduleForNew
          url={`/outpatient_care/dental_care/teeth_cleaning_polishing/checkout`}
          layout="outpatient"
        />
      </Stack>
      <Drawer
        anchor="right"
        open={locationMenu}
        onClose={() => handleCloseLocationMenu()}
        sx={{
          display: { xs: "block", sm: "block" },
          "& .MuiDrawer-paper": { boxSizing: "border-box", width: "350px" },
        }}
      >
        <LocationMenu onClose={() => handleCloseLocationMenu()} />
      </Drawer>
    </>
  );
}
