import React from "react";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { corporate_dynamic } from "../json/corporateDynamic";
import WhyChooseUsCard from "./WhyChooseUsCard";
import styles from "../styling/WhyChooseUs.module.css";

export const WhyChooseus = () => {

  return (
    <Box className={styles["why_main_box"]} data-testid="whychooseus">
      <Stack spacing={2} className={styles["stack-box"]}>
        <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
          <Stack >
            <Stack className={styles["stack-box-two"]} >
            <Typography variant="h1" fontWeight={600} align="left" pt={4} data-testid="title">
           {corporate_dynamic?.BANNER_3?.TITLE?.TITLE_1}<br />{corporate_dynamic?.BANNER_3?.TITLE?.TITLE_2}
            </Typography>
            </Stack>
          </Stack>
          <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
            <WhyChooseUsCard
              icon={corporate_dynamic?.BANNER_3?.FARD_DATA[0]?.ICON}
              title={corporate_dynamic?.BANNER_3?.FARD_DATA[0]?.TITLE}
              description={corporate_dynamic?.BANNER_3?.FARD_DATA[0]?.CONTENT}
            />
            <WhyChooseUsCard
              icon={corporate_dynamic?.BANNER_3?.FARD_DATA[1]?.ICON}
              title={corporate_dynamic?.BANNER_3?.FARD_DATA[1]?.TITLE}
              description={corporate_dynamic?.BANNER_3?.FARD_DATA[1]?.CONTENT}
            />
          </Stack>
        </Stack>

        <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
          <WhyChooseUsCard
            icon={corporate_dynamic?.BANNER_3?.FARD_DATA[2].ICON}
            title={corporate_dynamic?.BANNER_3?.FARD_DATA[2].TITLE}
            description={corporate_dynamic?.BANNER_3?.FARD_DATA[2].CONTENT}
          />
          <WhyChooseUsCard
            icon={corporate_dynamic?.BANNER_3?.FARD_DATA[3].ICON}
            title={corporate_dynamic?.BANNER_3?.FARD_DATA[3].TITLE}
            description={corporate_dynamic?.BANNER_3?.FARD_DATA[3].CONTENT}
          />
          <WhyChooseUsCard
            icon={corporate_dynamic?.BANNER_3?.FARD_DATA[4].ICON}
            title={corporate_dynamic?.BANNER_3?.FARD_DATA[4].TITLE}
            description={corporate_dynamic?.BANNER_3?.FARD_DATA[4].CONTENT}
          />
        </Stack>
      </Stack>
    </Box>
  );
};
