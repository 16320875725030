import { Button, Drawer, Stack, Typography } from "@mui/material";
import React from "react";
import CheckoutCard from "../molecules/CheckoutCard";
import CheckoutAccordian from "../molecules/CheckoutAccordian";
import CoverageCards from "../molecules/CoverageCards";
import ChevronRight from "@mui/icons-material/ChevronRight";
import cardImg from "../assets/images/nutritionist.jpg";
import comprihensive from "../assets/Comprehensive.svg";
import chemistry from "../assets/chemistry.svg";
import useRazorpay from "react-razorpay";
import {
  chkoutAccordianList,
  bloodtestsList,
} from "../json/chkoutAccordianList";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import styles from "../styling/Checkout.module.css";
import Bread from "../molecules/Bread";
import defaultStyles from "../styling/DefaultStyles.module.css";
import { CongratulationPopup } from "../molecules/CongratulationPopup";
import { useSharedData } from "../Context/ShareContext";
import { useState } from "react";
import percentage from "../assets/images/percentage.png";
import ApplyPopupMenu from "../molecules/ApplyPopupMenu";
import appleicon from "../assets/apple_icon.svg";
import mainlogo from "../assets/company_logo.svg";
const Checkout = () => {
  const { showCongratulationsPopup, setShowCongratulationsPopup } =
    useSharedData();
    const [Razorpay] = useRazorpay();

  const handleCheckoutClick = () => {
    let finalRazorPayValue = 500 * 100;

    var options = {
      key: "rzp_test_zt5DDs1PmkkyDy",
      amount: finalRazorPayValue, // Example: 2000 paise = INR 20
      name: "Healthspring",
      description: "Healthspring checkout",
      image: mainlogo, // COMPANY LOGO
      handler: function (response) {
        if (response.razorpay_payment_id) setShowCongratulationsPopup(true);
        // AFTER TRANSACTION IS COMPLETE YOU WILL GET THE RESPONSE HERE.
      },
      prefill: {
        name: "User Name", // pass customer name
        email: "user@email.com", // customer email
        contact: "+919123456780", //customer phone no.
      },
      notes: {
        address: "address", //customer address
      },
      theme: {
        color: "#15b8f3", // screen color
      },
    };
    console.log(options);
    var propay = new Razorpay(options);
    propay.open();
    // setShowCongratulationsPopup(true);
  };
  const [applyCoupon, setApplyCoupon] = useState(false);

  const handleCouponMenu = () => {
    setApplyCoupon((prev) => !prev);
  };
  let drawerObj = {
    display: { xs: "block", sm: "block" },
    "& .MuiDrawer-paper": { boxSizing: "border-box", width: 350 },
  };
  return (
    <>
      <Stack className={defaultStyles.max_width_stack}>
        <Bread />
        <Stack
          spacing={4}
          direction={{ xs: "column", sm: "row" }}
          className={styles["mainContainer"]}
          data-testid="checkout_container"
        >
          <Stack sx={{ width: { xs: "100%", sm: "55%" } }}>
            <Stack
              className={styles["leftContent"]}
              data-testid="left_container"
            >
              <CheckoutCard
                icon={appleicon}
                header="Weight Loss 1 Month Diet Plan"
                subheader="Don't lose sleep over losing weight. Let our experts handle it!"
                img={cardImg}
              />
              <Typography>
                <Typography
                  className={styles["descHeader"]}
                  data-testid="description_title"
                >
                  Description
                </Typography>
                <Typography
                  className={styles["descTxt"]}
                  data-testid="description_text"
                >
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                  irure dolor in reprehenderit in voluptate velit esse cillum
                  dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                  cupidatat non proident, sunt in culpa qui officia deserunt
                  mollit anim id est laborum.
                </Typography>
              </Typography>
              <Stack spacing={2}>
                <CheckoutAccordian
                  title="Comprehensive Evaluation (1)"
                  data={chkoutAccordianList}
                  icon={comprihensive}
                />
                <CheckoutAccordian
                  title="Blood Tests"
                  data={bloodtestsList}
                  icon={chemistry}
                />
              </Stack>
              <Stack>
                <Typography className={styles["descHeader"]}>
                  What's Covered
                </Typography>
                <CoverageCards />
              </Stack>
            </Stack>
            <Typography className={styles["descHeader"]}>
              Package Details
            </Typography>

            <Stack
              spacing={1}
              className={styles["packageDetails"]}
              data-testid="package_details"
            >
              <Stack className={styles["invoiceItems"]}>
                <Stack className={styles["spacerClass"]}>
                  <Typography className={styles["pkgDtls"]}>Covered</Typography>
                  <Typography className={styles["pkgQty"]}>
                    44 Parameters
                  </Typography>
                </Stack>
                <Stack className={styles["spacerClass"]}>
                  <Typography className={styles["pkgDtls"]}>
                    Facility Available at
                  </Typography>
                  <Typography className={styles["pkgQty"]}>
                    Center Only
                  </Typography>
                </Stack>
                <Stack className={styles["spacerClass"]}>
                  <Typography className={styles["pkgDtls"]}>
                    Validity
                  </Typography>
                  <Typography className={styles["pkgQty"]}>1 Month</Typography>
                </Stack>
                <Stack className={styles["spacerClass"]}>
                  <Typography className={styles["pkgDtls"]}>
                    Actual Price
                  </Typography>
                  <Typography className={styles["stricked"]}>
                    &#8377;7,999.00
                  </Typography>
                </Stack>
              </Stack>
              <Stack className={styles["spacerClass"]}>
                <Typography className={styles["pkgQtyy"]}>
                  Offer Price
                </Typography>
                <Typography className={styles["pkgTotal"]}>
                  &#8377;4,999.00
                </Typography>
              </Stack>
            </Stack>
          </Stack>
          <Stack sx={{ width: { xs: "100%", sm: "45%" } }}>
            <Stack
              className={styles["rightContent"]}
              data-testid="right_container"
            >
              <Typography variant="h2" className={styles["pmtHeader"]}>
                Payment Details
              </Typography>
            </Stack>
            <Button
              onClick={handleCouponMenu}
              className={styles.selectBtn_Flex_Start}
              startIcon={
                <IconView img={percentage} className={styles.percentageIcon} />
              }
              endIcon={<ChevronRight className={styles.svg_image_right} />}
            >
              <Typography
                align="left"
                variant="h5"
                className={`${styles.typo_Txt} ${styles.use_coupon}`}
              >
                Use Coupon
              </Typography>
            </Button>
            <Drawer
              anchor="right"
              open={applyCoupon}
              onClose={handleCouponMenu}
              sx={drawerObj}
            >
              <ApplyPopupMenu onClose={handleCouponMenu} />
            </Drawer>
            <Typography className={styles["descHeader"]}>
              Payment Info
            </Typography>
            <Stack className={styles["pmtInfo"]}>
              <Stack className={styles["finalInvoiceDtls"]}>
                <Stack className={styles["spacerClass"]}>
                  <Typography className={styles["pkgDtls"]}>
                    Total Price
                  </Typography>
                  <Typography className={styles["pkgQty"]}>
                    &#8377;1,999.00
                  </Typography>
                </Stack>
                <Stack className={styles["spacerClass"]}>
                  <Typography className={styles["pkgDtls"]}>Tax</Typography>
                  <Typography className={styles["pkgQty"]}>
                    &#8377;0.00
                  </Typography>
                </Stack>
              </Stack>
              <Stack className={styles["spacerClass"]}>
                <Typography className={styles["pkgQty"]}>
                  Grand Total
                </Typography>
                <Typography className={styles["pkgTotal"]}>
                  &#8377;1,999.00
                </Typography>
              </Stack>
            </Stack>
            <Stack
              className={styles["chkoutBtn"]}
              direction="row"
              onClick={handleCheckoutClick}
            >
              <Typography className={styles["chkout"]}>Checkout</Typography>
              <ArrowForwardIosIcon className={styles["arrow-outline"]} />
            </Stack>
            {showCongratulationsPopup && <CongratulationPopup />}
          </Stack>
        </Stack>
      </Stack>
    </>
  );
};

export default Checkout;

const IconView = ({ img, isBig = false, className = "" }) => {
  return (
    <img
      alt="Avatar"
      src={img}
      className={
        className !== ""
          ? className
          : `${isBig ? styles.iconViewBig : styles.iconView}`
      }
    />
  );
};
