import React, {useState} from "react";
import { Grid, Typography, CardContent, Stack, Button } from "@mui/material";
import  styles from "../styling/ActiveCard.module.css";
import { ActiveCards } from "../json/ActiveCards";
import {activeCard} from "../json/ActiveCards";
import { useSharedData } from "../Context/ShareContext";
import CustomDrawer from "./CustomDrawer";

export const ActiveCard = () => {
  const { setShowPackage, setPackageSummaryData, setRescheduleSuccessModal, setBookActivityModal } = useSharedData();
  const [drawerIsOpen, setDrawerIsOpen] = useState(false);
  const viewDetails = () => {
    setPackageSummaryData({
      appointmentId:"ID #19477657407",
      bookedTime:'Sat, 12 Nov 10:06 am',
      status:'Active',
      packageType:'active'
    })
    setShowPackage(true)
  }

  const nextBtnClick = () => {
    setRescheduleSuccessModal(true);
    setBookActivityModal(true);
  }
  return (
    <>
      {ActiveCards?.map((card, index) => {
        return (
          <CardContent key={index} className={styles["membpack-cardContent"]}>
            <Stack direction={{xs:"column",sm:"row"}} justifyContent="space-between" spacing={2}>
              <Stack>
                <Stack className={styles["membpack-subStack"]}>
                  {card?.icon}
                  <Stack className={styles["membpack-subStack2"]}>
                    <Typography className={styles["membpack-typoTitle"]}>
                      {card?.name}
                    </Typography>
                    <Stack direction={"row"}>
                      <Typography className={styles["membpack-typoDate"]}>
                        {card?.date}
                      </Typography>
                      <Typography className={styles["membpack-typoDot"]}> • </Typography>
                      <Typography className={styles["membpack-typoDesc"]}>
                        {card?.leftdays}
                      </Typography>
                    </Stack>
                  </Stack>
                </Stack>
              </Stack>
              <Stack >
                <Stack className={styles["membpack-subStack3"]}>
                  <Button className={styles["membpack-leftBtn"]} onClick={() => setDrawerIsOpen(true)}>{activeCard?.book}</Button>
                  <Button 
                    className={styles["membpack-rightBtn"]}
                    onClick={() => viewDetails()}
                  >
                    {activeCard?.view}
                  </Button>
                </Stack>
              </Stack>
            </Stack>
          </CardContent>
        );
      })}
      <CustomDrawer anchor={'right'} isOpen={drawerIsOpen} onCloseHandler={() => setDrawerIsOpen(false)} onNextClick={() => nextBtnClick()}/>
    </>
  );
};
