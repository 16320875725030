import blood from '../assets/images/Hemogram.png'
import pdf from '../assets/images/rxpdf.svg'
export const LabReport = [
  {
    id: 1,
    Test: "Hemogram.pdf",
    date: "Sep 20, 2023",
    image:blood
    
  },
  {
    id: 2,
    Test: "Dr Prescription.pdf",
    date: "Sep 20, 2023",
    image:pdf
  },

];
