import { Box, Stack, Typography } from "@mui/material";
import styles from "../styling/BusinessStatistics.module.css";
import { landing_dynamic } from "../json/landingDynamic";

export default function BusinessStatistics() {
  return (
    <Box data-testid="business-statistics">
      <Typography
       
        fontWeight="bold"
        align="left"
        className={styles.typo_eighty}
      >
        {landing_dynamic?.BANNER_3?.TITLE}
      </Typography>

      <Stack
        className={styles.business_stack}
        direction={{ xs: "column", sm: "row" }}
        justifyContent="space-between"
        alignItems="center"
        spacing={5}
      >
        {landing_dynamic?.BANNER_3?.BUSINESS?.map((businessItem) => (
          <Stack
            key={businessItem?.INDEX}
            className={styles.business_stack_two}
            spacing={2}
            data-testid="business-item"
            alignContent="flex-start"
          >
            <Box
              component="img"
              src={businessItem?.IMAGE}
              alt=""
              className={styles.business_icon}
            />
            <Typography
              variant="h1"
              className={styles.business_text}
              align="left"
            >
              {businessItem?.TITLE}
            </Typography>
            <Typography
              variant="h4"
              className={styles.business_text_two}
              align="left"
              
            >
              {businessItem?.CONTENT}
            </Typography>
          </Stack>
        ))}
      </Stack>
    </Box>
  );
}
