import React from "react";
import styles from "../styling/Spacer.module.css";

const Spacer = () => {

    return (
        <div className={styles.spacer} />
    );
}

export default Spacer;