import { Stack } from "@mui/material";
import React from "react";
import AboutHealthSpring from "../molecules/AboutHealthSpring";
import BusinessStatistics from "../molecules/BusinessStatistics";
import HealthSpringCertification from "../molecules/HealthSpringCertification";
import InclusiveHealthcare from "../molecules/InclusiveHealthcare";
import Introduction from "../molecules/Introduction";
import NewsMedia from "../molecules/NewsMedia";
import { WhyHealthSpring } from "../molecules/WhyHealthSpring";
import HealthyPatientTwo from "../molecules/HealthyPatientTwo";

const LandingPage = () => {
  return (
    <>
      <Stack spacing={5}>
        <Introduction />
        <AboutHealthSpring />
        <BusinessStatistics />
        <WhyHealthSpring />
        <HealthSpringCertification />
        <HealthyPatientTwo/>
        <NewsMedia />
        <InclusiveHealthcare />
      </Stack>
    </>
  );
};

export default LandingPage;
