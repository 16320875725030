import certicon1 from "../assets/ico79.png";
import certicon2 from "../assets/ico78.png";
import certicon3 from "../assets/ico77.png";
import certicon4 from "../assets/ico76.png";
import why_first from "../assets/why1.png";
import why_second from "../assets/why2.png";
import why_third from "../assets/why3.png";
import why_four from "../assets/why4.png";
import cert1 from "../assets/isoImage.png";
import cert2 from "../assets/nabllogo.jpg";
import doc from "../assets/Ladydoctor.png";
import run from "../assets/images/ico98.png";
import bowl from "../assets/images/bowl.jpg";
import mosquito from "../assets/images/mosquito.jpg";
import doctor from "../assets/images/doctortalikngtoparents.png";
import group from "../assets/images/groupdiscussion.png";


export const landing_dynamic = {
  BANNER_1: {
    TITLE: {
      TITLE_1: "Integrated Quality Care For A ",
      TITLE_2: "Healthier ",
      TITLE_3: `You. Empowering India's`,
      TITLE_4: " Families, Corporations ",
      TITLE_5: "And Communities.",
    },
  },
  BANNER_2: {
    TITLE: {
      TITLE_1: "13+ Years of Experience",
      TITLE_2: "About Healthspring",
      TITLE_3:
        "Since its genesis in 2011, Healthspring has continued to grow, evolve and adapt to changing needs, landscapes and environments. ",
      TITLE_4:
        "With a physician-led approach and a patient-centric outlook, our goal is to provide comprehensive healthcare that is accessible and effective.",
    },
  },
  BANNER_3: {
    TITLE: "Business Statistics",
    BUSINESS: [
      {
        ID: 1,
        INDEX: 1,
        IMAGE: certicon1,
        TITLE: "200+",
        CONTENT: "Corporate and Community Centers",
      },
      {
        ID: 2,
        INDEX: 2,
        IMAGE: certicon2,
        TITLE: "1K+",
        CONTENT: "Health and Wellness Programmes",
      },
      {
        ID: 3,
        INDEX: 3,
        IMAGE: certicon3,
        TITLE: "10K+",
        CONTENT: "Lives Saved",
      },
      {
        ID: 4,
        INDEX: 4,
        IMAGE: certicon4,
        TITLE: "1M+",
        CONTENT: "Satisfied Customers",
      },
    ],
  },
  BANNER_4: {
    TITLE: "Why Healthspring",
    FARDS: [
      {
        TITLE: "Operational Excellence",
        CONTENT:
          "With a key focus on a strong operational network that helps us monitor and manage our patient and client demands optimally, we are able to achieve an excellent level of functional efficiency.",
        ICON: why_first,
      },
      {
        TITLE: "Carefully Curated Partner Network",
        CONTENT:
          "Our solid network of partners in different sectors of the industry helps us strengthen our offering for our community.",
        ICON: why_second,
      },
      {
        TITLE: "Extensively trained medical personnel",
        CONTENT:
          "We are one of the largest recruiters of trained medical staff. From our specialists to our surgeons to our paramedical staff, we ensure that every single member of our team is qualified and experienced to handle a range of conditions, to help us manage our patients’ concerns effectively and efficiently.",
        ICON: why_four,
      },
      {
        TITLE: "Care Coordination",
        CONTENT:
          "With the round the clock access to our facilities and prompt consults with specialists, we ensure that every individual patient receives personalized care. Through our coordinated efforts to give you the most seamless experience, we aim to make every patient feel at ease, at every stage of the treatment process.",
        ICON: why_third,
      },
    ],
  },
  BANNER_5: {
    TITLE: {
      TITLE_1: "Healthspring Certifications",
      TITLE_2: "Great Story Means Everything",
    },
    CERTIFICATES: [
      {
        ID: 1,
        IMAGE: cert1,
        ALT: "certificate_one",
      },
      {
        ID: 2,
        IMAGE: cert2,
        ALT: "certificate_two",
      },
    ],
    IMAGE: doc,
    COMMENTS: [
      {
        ID: 1,
        DESCRIPTION: `I had severe back pain for the last 6 months. Medicines and pain
            killers helped only temporarily. Physiotherapist helped me with
            my back pain. She gave me some very useful exercises and tips to
            follow at home. Now I can travel and work better than before.
            Thanks. Highly recommended.`,
        PERSON: {
          NAME: "Amarnath Agarwal",
        },
        DETAILS: {
          PLACE: "Nerul",
          DATE: {
            MONTH: "April",
            YEAR: "2022",
          },
        },
      },
    ],
  },
  BANNER_6: {
    TITLE: {
      TITLE_1: "News and Media",
      TITLE_TOGGLE: {
        TOGGLE_1: "Blogs",
        TOGGLE_2: "News",
      },
    },
    MEDIA_CARDS: [
      {
        ID: 1,
        IMAGE: run,
        CATEGORY: "Category Name",
        DAY: "24",
        MONTH: "Dec",
        TITLE: "Fitness regime that helps you stay healthy",
        DESCRIPTION:
          "Do you pursue a fitness regime that helps you stay healthy?  Do you intend on starting one soon?...",
      },
      {
        ID: 2,
        IMAGE: bowl,
        CATEGORY: "Category Name",
        DAY: "20",
        MONTH: "Dec",
        TITLE: "Health benefits of flax seeds are a great source of fiber",
        DESCRIPTION:
          "The benefits of Flax seed are numerous and this tiny brownish seed is packed with micronutrients, vitamins...",
      },
      {
        ID: 3,
        IMAGE: mosquito,
        CATEGORY: "Category Name",
        DAY: "08",
        MONTH: "Dec",
        TITLE: "What you can do to protect you from Dengue Fever",
        DESCRIPTION:
          "Dengue fever is caused by a virus and is transmitted through the bite of an infected Aedes mosquito...",
      },
    ],
  },
  BANNER_7: {
    TITLE: "Inclusive Healthcare for All",
    INCLUSIVE_CONTENT: [
      {
        ID: 1,
        IMAGE: doctor,
        TITLE: "For Families",
        DESCRIPTION:
          "The team at Healthspring provides solutions to a diverse range of conditions to help you and your family stay healthy. With services that are tailored to your need, our packages for families facilitate more smiles and fewer illnesses.",
      },
      {
        ID: 2,
        IMAGE: group,
        TITLE: "For Corporates",
        DESCRIPTION:
          "We provide an extensive range of packages for corporate and occupational needs to help your organization create a better workplace for your employees. Get in touch with us to pick a package that suits your company’s needs.",
      },
    ],
  },
};
