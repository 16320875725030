import React from "react";
import teeth from "../assets/images/Teeth.svg";
import BookingForTeethClening from "../molecules/BookingForTeethClening";

export const TeethCleaning = () => {
  return (
    <>
        <BookingForTeethClening iconImage={teeth} headerText='Teeth Cleaning & Polishing'   />
    </>
  );
};
export default TeethCleaning;
