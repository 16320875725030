import React from "react";
import { Box, Stack, Typography } from "@mui/material";
import styles from "../styling/KickStartCareer.module.css";
import Paper from "@mui/material/Paper";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import {careerKickstart,careerKickstart_dynamic} from "../json/kickStart";

export default function KickStartCareer() {
  const handleEmailClick = (emailAddress) => {
    window.location.href = `mailto:${emailAddress}`;
  };
  return (
    <Box data-testid="KickStart">
      <Stack className={styles.headerkickstart} direction="column">
        <Stack>
          <Typography variant="h1" className={styles.Kickstart_header_text}>
            {careerKickstart?.TITLE?.TITLE_PART1}
            <span className={styles.Kickstart_header_splittext}>{careerKickstart?.TITLE?.TITLE_PART2}</span>
          </Typography>
        </Stack>

        <Stack>
          <Typography
            align="left"
            variant="h1"
            className={styles.Kickstart_header_textinner}
          >
            {careerKickstart?.TITLE?.TITLE_PART3}
          </Typography>
        </Stack>
        <Typography
         
          align="left"
          className={styles.back_text_container}
        >
          {careerKickstart_dynamic?.DESCRIPTION}
        </Typography>
        <Paper
          elevation={0}
        
          className={styles.kickstart_paper}
        >
          <Typography
            className={styles.kickstart_paper_typosinner}
            align="left"
          >
            {careerKickstart?.CONTACT_US}
          </Typography>
          <Stack
            direction="row"
            spacing={2}
            className={styles.kickstart_paper_stack}
          >
            <Stack>
            <div className={styles.circular_icon_container}>
      <MailOutlineIcon data-testid="mailicon" className={styles.circular_icon} />
    </div>
            </Stack>
            <Stack className={styles.kickstart_paper_stack_innerone}>
              <Typography
                color="text.secondary"
                align="left"
                className={styles.back_icon_container}
              >
                {careerKickstart?.EMAIL}
              </Typography>
              <div
                onClick={() => {
                  handleEmailClick(careerKickstart_dynamic?.EMAIL_MAILID);
                }}
              >
              <Typography
                align="left"
                className={styles.kick_textinner_container}
                pb={3}
              >
                {careerKickstart_dynamic?.EMAIL_MAILID}
              </Typography>
              </div>
            </Stack>
          </Stack>
        </Paper>
      </Stack>
    </Box>
  );
}
