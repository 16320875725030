import React from "react";
import { Box, Stack, Typography } from "@mui/material";
import styles from "../styling/WhyHealth.module.css";
import WhyHealthCard from "./whyHealthCard";
import { landing_dynamic } from "../json/landingDynamic";
export const WhyHealthSpring = () => {
  const renderWhyHealthCard = (index) => {
    const card = landing_dynamic?.BANNER_4?.FARDS[index];
    return (
      <WhyHealthCard
        icon={card?.ICON}
        title={card?.TITLE}
        description={card?.CONTENT}
      />
    );
  };

  const renderWhyHealthPairs = () => {
    return landing_dynamic?.BANNER_4?.FARDS?.map((_, index) => {
      if (index % 2 === 0) {
        return (
          <Stack
            direction={{ xs: "column", sm: "row" }}
            spacing={2}
            key={index}
          >
            {renderWhyHealthCard(index)}
            {index + 1 < landing_dynamic?.BANNER_4?.FARDS?.length && renderWhyHealthCard(index + 1)}
          </Stack>
        );
      }
      return null;
    });
  };

  return (
    <Box className={styles.why_call_boxes} data-testid="why-health-spring">
      <Stack className={styles.why_inner_callboxes} spacing={3}>
        <Typography variant="h1" className={styles.why_bold}>
          {landing_dynamic?.BANNER_4?.TITLE}
        </Typography>
        <Stack spacing={2} justifyContent="space-between">
          {renderWhyHealthPairs()}
        </Stack>
      </Stack>
    </Box>
  );
};
