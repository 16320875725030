import React, { useState } from "react";
import {
  AppBar,
  Box,
  Button,
  Stack,
  Typography,
  alpha,
  styled,
  Menu,
} from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useNavigate, useOutlet, Link , useLocation} from "react-router-dom";
import { useSharedData } from "../Context/ShareContext";
import SearchIcon from "@mui/icons-material/Search";
import { Footer } from "../molecules/Footer";
import media from "../assets/images/medias.png";
import useAppStyles from "../hooks/useAppStyles";
import theme from "../components/Themeify";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import styles from "../styling/LoginLayout.module.css";
import { SubHeader } from "../molecules/SubHeader";
import { SiteContent } from "../assets/content/SiteContent";
import defaultStyles from "../styling/DefaultStyles.module.css";
import cell from "../assets/images/58-call-phone.svg";
import design from "../assets/images/design.png";
import flag from "../assets/images/Flag.png";
import group from "../assets/images/Group 40767.svg";
import mainlogo from "../assets/company_logo.svg";
import userlogin from "../assets/images/userlogin.svg";

import WindowIcon from "@mui/icons-material/Window";
import SignupFooter from "../molecules/SignupFooter";

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  float: "right",
}));
const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    width: "auto",
  },
  [theme.breakpoints.up("xs")]: {
    marginRight: 0,
  },
}));

export const LoginLayout = (props) => {
  const {
    a11yProps,
    indexOfSubheader,
    setIndexOfSubheader,
    activeButtonIndex,
    setActiveButtonIndex,
  } = useSharedData();
  const navigate = useNavigate();
  const outlet = useOutlet();
  const appStyles = useAppStyles();

  const handleNavClick = (location, buttonIndex) => {
    setIndexOfSubheader(false);

    setActiveButtonIndex(buttonIndex);
    navigate(location);
  };
  const handleNavChanges = (location) => {
    setAnchorEl(null);
    navigate(location);
  };
  const openInNewTab = (url) => {
    window.open(url, "_blank", "noreferrer");
  };
  const [anchorEl, setAnchorEl] = useState(null);

  const { itemSelected } = useSharedData();
  
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event?.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLocation = () => {
    setAnchorEl(null);

    navigate("/login/everydayhealth");
    setIndexOfSubheader(0);
    setActiveButtonIndex(false);

    setTimeout(() => {
      var element = document.getElementById("box_center");

      element?.scrollIntoView({ behavior: "smooth" });
    }, 500);
  };
  const handleCorp = () => {
    setAnchorEl(null);

    setIndexOfSubheader(1);
    setActiveButtonIndex(false);

    navigate("/login/corporatehealth", { state: { scroll: true } });
    setTimeout(() => {
      var element = document.getElementById("map_center");

      element?.scrollIntoView({ behavior: "smooth" });
    }, 500);
  };

 

  const handlePhoneClick = (phoneNumber) => {
    window.location.href = `tel:${phoneNumber}`;
  };
  const location = useLocation();
  const { pathname } = location;
  return (
    <Box>
      <Stack direction="column" className={styles.login_header_top}>
        <Stack className={styles.login_header_top_main}>
          <AppBar position="static" className={styles.login_layout_appbar}>
            <Box>
              <Stack className={styles.appbar_stack}>
                <Stack
                  direction="row"
                  sx={{ gap: { xs: 0, md: 1 } }}
                  className={styles.header_buttons}
                >
                  <Button size="medium" sx={appStyles.typographyStyle2}>
                    <Stack spacing={1} direction="row" alignItems="center">
                      <LocalPhoneOutlinedIcon
                        className={styles.phone_icon}
                        sx={{ color: theme.palette.primary.main }}
                      />
                      <div
                        className={styles.mouseHover}
                        onClick={() => {
                          handlePhoneClick(SiteContent?.CONTACT_NO);
                        }}
                      >
                        <Typography variant="h5">
                          {SiteContent?.CONTACT_NO}
                        </Typography>
                      </div>
                    </Stack>
                  </Button>
                  <Stack className={styles.header_divider}>
                    <Typography variant="h3" className={styles.divider_space}>
                      |
                    </Typography>
                  </Stack>
                  <Button
                    color="primary"
                    size="medium"
                    sx={{
                      ...appStyles.typographyStyle2,
                      color: activeButtonIndex === 0 ? "#016E44" : "#0D1D0E",
                    }}
                    onClick={() => handleNavClick("aboutus", 0)}
                  >
                    <Typography variant="h5">
                      {SiteContent?.ABOUT_US}
                    </Typography>
                  </Button>
                  <Button
                    color="primary"
                    size="medium"
                    sx={{
                      ...appStyles.typographyStyle2,
                      color: activeButtonIndex === 1 ? "#016E44" : "#0D1D0E",
                    }}
                    onClick={() => handleNavClick("career", 1)}
                  >
                    <Typography variant="h5">{SiteContent?.CAREER}</Typography>
                  </Button>
                  <Button
                    color="primary"
                    size="medium"
                    sx={appStyles.typographyStyle2}
                    
                    onClick={() => openInNewTab("https://www.healthspring.in/media/category/latest-news-events/")}
                  >
                    <Typography variant="h5">
                      {SiteContent?.NEWS_AND_MEDIA}
                    </Typography>
                  </Button>
                  <Button
                    color="primary"
                    size="medium"
                    sx={{
                      ...appStyles.typographyStyle2,
                      color: activeButtonIndex === 2 ? "#016E44" : "#0D1D0E",
                    }}
                    onClick={() => {
                      handleNavClick("contactus", 2);
                    }}
                  >
                    <Typography variant="h5">{SiteContent?.CONTACT}</Typography>
                  </Button>
                  {/* <Search>
                    <SearchIconWrapper>
                      <SearchIcon className={styles.search_icon_colored} />
                    </SearchIconWrapper>
                  </Search> */}
                </Stack>
              </Stack>
            </Box>
          </AppBar>
          <SubHeader
            {...{
              a11yProps,
              indexOfSubheader,
              setIndexOfSubheader,
              setActiveButtonIndex,
            }}
          />
        </Stack>

        <Stack className={styles.layoutstackmaindivtwo}>
          <AppBar
            position="static"
            className={styles.layout_appbar}
            data-testid="main-header"
          >
            <Box>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                spacing={2}
                className={defaultStyles.max_width_stack}
              >
                <Stack className={styles.healthspringimage} direction="row">
                  <img
                    onClick={() => navigate("/login/")}
                    src={mainlogo}
                    className={styles.main_logo}
                    alt="healthfarm logo"
                  />
                </Stack>

                <Stack direction="row" className={styles.mainboxdirec}>
                  <Button
                    id="basic-button"
                    aria-controls={open ? "basic-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                    onClick={handleClick}
                  >
                    <WindowIcon className={styles.windowicons} />
                  </Button>
                  <Menu
                    className={styles.menuwindows}
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    sx={{
                      "& .MuiMenu-paper": {
                        backgroundColor: "#006F45",
                        width: "100vw",
                        height: "100vh",
                        marginTop: "-0.5rem",
                      },
                    }}
                  >
                    <Stack className={styles.loginmaindivnew}>
                      <Stack direction="row" className={styles.Loginlayoutmain}>
                        <Stack className={styles.logindivnew}>
                          <img src={userlogin} alt="" />
                        </Stack>
                        <Stack className={styles.hellologin}>
                          <Typography className={styles.logintext}>
                            {SiteContent?.HELLO_USER_LOGIN}
                          </Typography>
                        </Stack>
                      </Stack>

                      <Stack direction="row" spacing={2} mb={3} ml={6}>
                        <Stack className={styles.imagesnewlayouts}>
                          <img src={design} alt="" />
                        </Stack>
                        <Link
                          to="/login/aboutus"
                          onClick={handleClose}
                          className={styles.log_about}
                        >
                          <Stack className={styles.typonewlayouts}>
                            <Typography className={styles.homeopacitytext}>
                              {SiteContent?.ABOUT_US}
                            </Typography>
                          </Stack>
                        </Link>
                      </Stack>

                      <Stack direction="row" spacing={2} mb={3} ml={6}>
                        <Stack className={styles.imagesnewlayouts}>
                          <img src={flag} alt="" />
                        </Stack>
                        <Link
                          to="/login/career"
                          onClick={handleClose}
                          className={styles.log_about}
                        >
                          <Stack className={styles.typonewlayouts}>
                            <Typography className={styles.homeopacitytext}>
                              {SiteContent?.CAREER}
                            </Typography>
                          </Stack>
                        </Link>
                      </Stack>

                      <Stack direction="row" spacing={2} mb={3} ml={6}>
                        <Stack className={styles.imagesnewlayouts}>
                          <img src={media} alt="Flag" />
                        </Stack>
                        <Link
                          // to="/login/blogs"
                          // onClick={handleClose}
                          onClick={() => openInNewTab("https://www.healthspring.in/media/category/latest-news-events/")}
                          className={styles.log_about}
                        >
                          <Stack className={styles.typonewlayouts}>
                            <Typography className={styles.homeopacitytext}>
                              {SiteContent?.NEWS_AND_MEDIA}
                            </Typography>
                          </Stack>
                        </Link>
                      </Stack>
                      <Stack direction="row" spacing={2} mb={3} ml={6}>
                        <Stack className={styles.imagesnewlayouts}>
                          <img src={cell} alt="cell" />
                        </Stack>
                        <Link
                          to="/login/contactus"
                          onClick={handleClose}
                          className={styles.log_about}
                        >
                          <Stack className={styles.typonewlayouts}>
                            <Typography className={styles.homeopacitytext}>
                              {SiteContent?.CONTACT_US}
                            </Typography>
                          </Stack>
                        </Link>
                      </Stack>

                      <Stack direction="row" spacing={2} mb={3} ml={6}>
                        <Stack className={styles.imagesnewlayoutOther}>
                          <img src={group} alt="" />
                        </Stack>

                        <Stack className={styles.dropdownnewone}>
                          <Accordion
                            className={styles.accordiannewdropdown}
                            value={itemSelected}
                          >
                            <AccordionSummary
                              expandIcon={
                                <ExpandMoreIcon
                                  className={styles.bg_changes}
                                />
                              }
                              aria-controls="panel1a-content"
                              id="panel1a-header"
                            >
                              <Typography className={styles.homeopacitytext}>
                                {SiteContent?.OTHERS}
                              </Typography>
                            </AccordionSummary>
                            <AccordionDetails
                              className={styles.accordiandetailsicon}
                              value={itemSelected}
                            >
                              <Accordion
                                className={styles.accordiannewdropdownothers}
                                sx={{
                                  "&.muiaccordion-root:before": {
                                    border: "none",
                                    boxShadow: "none",
                                  },
                                }}
                                value={itemSelected}
                              >
                                <AccordionSummary
                                  expandIcon={
                                    <ExpandMoreIcon
                                     className={styles.bg_changes}
                                    />
                                  }
                                  aria-controls="panel1a-content"
                                  id="panel1a-header"
                                >
                                  <Typography
                                    className={styles.homeopacitytext}
                                  >
                                    {SiteContent?.SERVICES}
                                  </Typography>
                                </AccordionSummary>
                                <AccordionDetails
                                  className={styles.accordiandetailsicon}
                                  value={itemSelected}
                                >
                                  <Typography
                                    className={styles.retailopacitytext}
                                    onClick={() =>
                                      handleNavChanges("/login/everydayhealth")
                                    }
                                  >
                                    {SiteContent?.FOR_INDIVIDUALS}
                                  </Typography>
                                  <Typography
                                    className={styles.corporateopacitytext}
                                    onClick={() =>
                                      handleNavChanges("/login/corporatehealth")
                                    }
                                  >
                                    {SiteContent?.FOR_CORPORATES}
                                  </Typography>
                                </AccordionDetails>
                              </Accordion>
                              <Accordion
                                className={styles.accordiannewdropdownothers}
                                sx={{
                                  "&.muiaccordion-root:before": {
                                    border: "none",
                                    boxShadow: "none",
                                  },
                                }}
                                value={itemSelected}
                              >
                                <AccordionSummary
                                  expandIcon={
                                    <ExpandMoreIcon
                                    className={styles.bg_changes}
                                    />
                                  }
                                  aria-controls="panel1a-content"
                                  id="panel1a-header"
                                >
                                  <Typography
                                    className={styles.homeopacitytext}
                                  >
                                    {SiteContent?.LOCATIONS}
                                  </Typography>
                                </AccordionSummary>
                                <AccordionDetails
                                  className={styles.accordiandetailsicon}
                                  value={itemSelected}
                                >
                                  <Typography
                                    className={styles.retailopacitytext}
                                    onClick={
                                      handleLocation
                                    }
                                  >
                                    {SiteContent?.RETAIL}
                                  </Typography>
                                  <Typography
                                    className={styles.corporateopacitytext}
                                    onClick={ handleCorp
                                    }
                                  >
                                    {SiteContent?.CORPORATE}
                                  </Typography>
                                </AccordionDetails>
                              </Accordion>

                              <Typography
                                className={styles.blogsopacitytext}
                                onClick={() =>
                                  handleNavChanges("/login/everydayhealth")
                                }
                              >
                                {SiteContent?.EVERYDAY_HEALTH}
                              </Typography>
                              <Typography
                                className={styles.blogsopacitytext}
                                onClick={() =>
                                  handleNavChanges("/login/corporatehealth")
                                }
                              >
                                {SiteContent?.CORPORATE_HEALTH}
                              </Typography>
                              <Typography
                                className={styles.blogsopacitytext}
                                onClick={() => openInNewTab("https://www.healthspring.in/media/category/latest-news-events/")}
                              >
                                {SiteContent?.BLOGS}
                              </Typography>
                            </AccordionDetails>
                          </Accordion>
                        </Stack>
                      </Stack>
                    </Stack>
                  </Menu>
                  <Stack className={styles.boxstacknew}></Stack>
                </Stack>
              </Stack>
            </Box>
          </AppBar>
        </Stack>
        {outlet}
       {pathname === '/login/loginpage' ||  pathname === '/login/signup' ? <SignupFooter/> : <Footer />} 
      </Stack>
    </Box>
  );
};
