import React from "react";
import { Grid, Typography, CardContent, Stack, Divider } from "@mui/material";
import styles from "../styling/Notification.module.css";

export default function Notification({ data }) {
  return (
    <div>
      <CardContent>
        <Stack className={styles["notification-mainStack"]}>
          <Grid item xs={6}>
            <Stack className={styles["notification-subStack"]}>
              {data.icon}
              <Stack className={styles["notification-subStack2"]}>
                <Typography className={styles["notification-typoTitle"]}>{data?.title}</Typography>
                <Stack direction={"row"}>
                  <Typography className={styles["notification-typoDesc"]}>{data?.desc}</Typography>
                </Stack>
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={6}>
            <Stack className={styles["notification-subStack3"]}>
              <Typography className={styles["notification-typoDate"]}>{data?.days}</Typography>
            </Stack>
          </Grid>
        </Stack>
      </CardContent>
      <Divider />
    </div>
  );
}
