import { Stack } from "@mui/material";
import React from "react";
import OurApproach from "../molecules/OurApproach";
import CenterLocation from "../molecules/CenterLocation";
import OurPackages from "../molecules/OurPackages";
import EverydayServices from "../molecules/EverydayServices";
import FamilyBanner from "../molecules/FamilyBanner";
import HealthyPatient from "../molecules/HealthyPatient";
import ImgTransform from "../molecules/TransformingHealth";
import NewsMedia from "../molecules/NewsMedia";
import { useEffect } from "react";

const EverydayHealth = () => {
  useEffect(() => {
    window.scrollTo(0,0);
    return () => {
      
    };
  }, []); 
  return (
    <>
      <Stack spacing={5} >
        <FamilyBanner />
        <OurApproach />
        <EverydayServices />
        <CenterLocation />
        <OurPackages />
        <HealthyPatient />
        <ImgTransform />
        <NewsMedia />
      </Stack>
    </>
  );
};

export default EverydayHealth;
