import HomeIcon from "@mui/icons-material/Home";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import FamilyRestroomIcon from "@mui/icons-material/FamilyRestroom";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

export const LocationDropDowns = [
  {
    name: "Retail",
    icon: <HomeIcon sx={{ fontSize: "16px" }} />,
    iconBG: "#71b432",
  },
  {
    name: "Corporate",
    icon: <LocationCityIcon sx={{ fontSize: "16px" }} />,
    iconBG: "#31b79c",
  },
];
export const ServicesDropDowns = [
  {
    name: "For Individuals and Families",
    subtxt:
      "Our healthcare services prioritize personalized and comprehensive care, focusing on preventive measures, routine check-ups, and tailored treatment plans to support overall well-being.",
    icon: <FamilyRestroomIcon sx={{ fontSize: "16px" }} />,
    frwdicon: <ArrowForwardIcon sx={{ color: "#016e44" }} />,
    iconBG: "#eb7333",
  },
  {
    name: "For Corporates",
    subtxt: "We offer strategic wellness programs, occupational health services, and employee-centric healthcare solutions, fostering a healthy workforce and enhancing productivity through proactive healthcare management.",
    icon: <LocationCityIcon sx={{ fontSize: "16px" }} />,
    frwdicon: <ArrowForwardIcon sx={{ color: "#016e44" }} />,
    iconBG: "#207fd2",
  },
];
