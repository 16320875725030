import React from "react";
import Stack from "@mui/material/Stack";
import { Typography } from "@mui/material";
import AppleIcon from "@mui/icons-material/Apple";
import RowingIcon from "@mui/icons-material/Rowing";
import styles from "../styling/CoverageCards.module.css";

export const coverageCardList = [
  {
    title: "1 Physician Consultation & Review of Reports",
    icon: RowingIcon,
    iconBackColor: "#31b79c",
    bgColor: "#e1f5f1",
    id: 1,
  },

  {
    title: "2 Diet Consultation",
    icon: AppleIcon,
    iconBackColor: "#eb3d43",
    bgColor: "#f9e4e5",
    id: 2,
  },

  {
    title: "4 Diet Follow-ups (In person or on-call)",
    icon: AppleIcon,
    iconBackColor: "#eb7333",
    bgColor: "#ffece2",
    id: 3,
  },
  {
    title: "1 Physio Session",
    icon: RowingIcon,
    iconBackColor: "#71b432",
    bgColor: "#e7f3dc",
    id: 4,
  },
];

const CoverageCards = () => {
  return (
    <Stack spacing={2} data-testid="coverage_card_container">
      <Stack className={styles["coverageCards"]}>
        {coverageCardList.map((item) => (
          <CardImageView
            title={item?.title}
            desc1={item?.desc1}
            desc={item?.desc}
            Icon={item?.icon}
            iconBackColor={item?.iconBackColor}
            iconBgcolor={item?.bgColor}
            key={item?.id}
          />
        ))}
      </Stack>
    </Stack>
  );
};

const CardImageView = ({
  height = null,
  Icon,
  title = "",
  iconBackColor,
  iconBgcolor,
}) => {
  return (
    <Stack
      sx={{
        backgroundColor: iconBgcolor,
        flexDirection: "row", 
        alignItems: "flex-start",
      }}
      className={styles["cvgCard"]}
      data-testid="coverage_card"
    >
      <Stack
        className={styles["iconStack"]}
        sx={{ backgroundColor: iconBackColor }}
      >
        <Icon className={styles["pkgCardIcon"]} />
      </Stack>
      <Stack className={styles["stackContainer"]}>
        <Typography className={styles["typoCard"]}>{title}</Typography>
      </Stack>
    </Stack>
  );
};

export default CoverageCards;
