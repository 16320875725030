import Avatar from "@mui/material/Avatar";
import NotificationImg1 from "../assets/Notification1.svg";
import NotificationImg2 from "../assets/Notification2.svg";
import NotificationImg3 from "../assets/Notification3.svg";
import NotificationImg4 from "../assets/Notification4.svg";
import NotificationImg5 from "../assets/Notification5.svg";
import NotificationImg6 from "../assets/Notification6.svg";
import NotificationImg7 from "../assets/Notification7.svg";
import NotificationImg8 from "../assets/Notification8.svg";
import NotificationImg9 from "../assets/Notification9.svg";
import NotificationImg10 from "../assets/Notification10.svg";
export const MyNotifications = [
  {
    title: "Appointment Success!",
    desc: "with Dr. Sapna Jain on Sep 9, 2022, 9:00 am",
    days: "2 days ago",
    icon: (
      <Avatar sx={{ bgcolor: "#22b6d9", width: "36px", height: "36px" }}>
        <img src={NotificationImg1} alt="notification" />
      </Avatar>
    ),
  },
  {
    title: "Appointment Cancelled!",
    desc: "with doctor Dr. Raj Avasthi on Sep 9, 2022, 12:00 pm",
    days: "2 days ago",
    icon: (
      <Avatar sx={{ bgcolor: "#22b6d9", width: "36px", height: "36px" }}>
        <img src={NotificationImg2} alt="notification" />
      </Avatar>
    ),
  },
  {
    title: "Tomorrow you have appointment",
    desc: "with Dr. Rajesh Vibhore on Sep 8, 2022, 9:00 am",
    days: "2 days ago",
    icon: (
      <Avatar sx={{ bgcolor: "#22b6d9", width: "36px", height: "36px" }}>
        <img src={NotificationImg3} alt="notification" />
      </Avatar>
    ),
  },
  {
    title: "Your chest X-Ray report is ready.",
    desc: "Now you can view and download it.",
    days: "2 days ago",
    icon: (
      <Avatar sx={{ bgcolor: "#22b6d9", width: "36px", height: "36px" }}>
        <img src={NotificationImg4} alt="notification" />
      </Avatar>
    ),
  },
  {
    title: "Health gold package is activated.",
    desc: "Now you can take benefits of additional services.",
    days: "2 days ago",
    icon: (
      <Avatar sx={{ bgcolor: "#22b6d9", width: "36px", height: "36px" }}>
        <img src={NotificationImg5} alt="notification" />
      </Avatar>
    ),
  },
  {
    title: "Your health Silver package expiries Oct 12, 2022.",
    desc: "Renew now to continue services.",

    days: "2 days ago",
    icon: (
      <Avatar sx={{ bgcolor: "#22b6d9", width: "36px", height: "36px" }}>
        <img src={NotificationImg6} alt="notification" />
      </Avatar>
    ),
  },
  {
    title: "Get 20% Off on your first booking.",
    desc: "Offer valid till this week only",

    days: "2 days ago",
    icon: (
      <Avatar sx={{ bgcolor: "#22b6d9", width: "36px", height: "36px" }}>
        <img src={NotificationImg7} alt="notification" />
      </Avatar>
    ),
  },
  {
    title: "Tomorrow you have video consultation",
    desc: "with Dr. Rajesh Vibhore at 9:00 am.",

    days: "2 days ago",
    icon: (
      <Avatar sx={{ bgcolor: "#22b6d9", width: "36px", height: "36px" }}>
        <img src={NotificationImg8} alt="notification" />
      </Avatar>
    ),
  },
  {
    title: "Your e-prescription is ready",
    desc: "from Dr. Rajesh Vibhore. You can view and download it.",

    days: "2 days ago",
    icon: (
      <Avatar sx={{ bgcolor: "#22b6d9", width: "36px", height: "36px" }}>
        <img src={NotificationImg9} alt="notification" />
      </Avatar>
    ),
  },
  {
    title: "Appointment Success!",
    desc: "with Dr. Sapna Jain on Sep 9, 2022, 9:00 am",
    days: "2 days ago",
    icon: (
      <Avatar sx={{ bgcolor: "#22b6d9", width: "36px", height: "36px" }}>
        <img src={NotificationImg10} alt="notification" />
      </Avatar>
    ),
  },
  {
    title: "Appointment Cancelled!",
    desc: "with doctor Dr. Raj Avasthi on Sep 9, 2022, 12:00 pm",
    days: "2 days ago",
    icon: (
      <Avatar sx={{ bgcolor: "#22b6d9", width: "36px", height: "36px" }}>
        <img src={NotificationImg10} alt="notification" />
      </Avatar>
    ),
  },
];
