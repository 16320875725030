import React from "react";
import { Link, useLocation } from "react-router-dom";
import styles from "../styling/BreadCrumb.module.css";
import { Breadcrumbs, Typography } from "@mui/material";
import theme from "../components/Themeify";

const Bread = () => {
  const location = useLocation();
  let paths = location.pathname.split("/").filter((path) => path !== "");

  // Define the paths to be replaced with "Choose Doctor"
  const doctorPaths = ["specialists", "general_physician", "diet_and_nutrition", "mental_health"];

  // Replace specific paths with "Choose Doctor"
  paths = paths.map((path, index) =>
    doctorPaths.includes(path) ? "Choose Doctor" : path
  );

  function convertToTitleCase(str) {
    const words = str
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1));
    return words.join(" ");
  }

  const home = "Home";

  return (
    <div
      role="presentation"
      className={styles.bread_crumb}
      data-testid="breadcrumb"
    >
      <Breadcrumbs aria-label="breadcrumb">
        <Link
          className={styles.linkNoUnderline}
          color="inherit"
          to="/home"
          underline="none"
        >
          {home}
        </Link>

        {paths.map((path, index) => (
          <Link
            key={index}
            className={styles.linkNoUnderline}
            to={`/${paths.slice(0, index + 1).join("/")}`}
          >
            <Typography
              color={
                index === paths.length - 1
                  ? theme.palette.primary.light
                  : undefined
              }
            >
              {convertToTitleCase(path)}
            </Typography>
          </Link>
        ))}
      </Breadcrumbs>
    </div>
  );
};

export default Bread;
