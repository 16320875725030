import React from "react";
import { Grid, Typography, Card, CardContent, CardMedia } from "@mui/material";
import styles from "../styling/CategoryCard.module.css";
import useAppStyles from "../hooks/useAppStyles";
import { Link } from "react-router-dom";
export default function ConsultService({ category }) {
  const appStyles = useAppStyles();
  
  return (
    <>
      <Grid item xs={6} md={3} sm={6} data-testid="category_card" className={styles.categoriesmainconsult}>
        <Link to={`/consult/${category.path}`}  className={styles.linkNoUnderline}>
       
          <Card
            className={styles.category_card}
            sx={{
              backgroundColor: `${category.color}`,
            }}
          >
            <CardMedia
              component="img"
              className={styles.op_card}
              alt={category.alt}
              image={category.image}
            />
            <CardContent className={styles.card_content}>
              <Typography
                variant="h4"
                sx={{ color: appStyles.typographyStyle2 }}
                color="text.secondary"
                gutterBottom
                className={styles.card_text}
              >
                {category.name}
              </Typography>
            </CardContent>
          </Card>
        </Link>
      </Grid>
    </>
  );
}
