import pic1 from "../assets/images/vc.svg";
import pic2 from "../assets/images/homeplus.svg";
import pic3 from "../assets/images/pinkhome.svg";
import pic4 from "../assets/images/server.svg";


export const Appointment = [
  {
    name: "Appointment Booked",
    Doctor: "Dr. Sapna J.",
    desig: "Diet & Nutrition",
    money: "₹ 4,999",
    date: "Sep 9, 2022, 12:00 pm",
    icon: pic1,
  },
  {
    name: "Appointment Cancelled",
    Doctor: "Dr. Raj A.",
    desig: "Psychiatrist",
    money: "₹ 3,450",
    date: "Sep 9, 2022, 12:00 pm",
    icon: pic2,
  },
  {
    name: "Appointment Booked",
    Doctor: "Dr. Sapna J.",
    desig: "Diet & Nutrition",
    money: "₹ 3,450",
    date: "Sep 9, 2022, 12:00 pm",
    icon: pic3
  },
  {
    name: "Appointment Booked",
    Doctor: "Dr. Raj A.",
    desig: "Psychiatrist",
    money: "₹ 4,999",
    date: "Sep 9, 2022, 12:00 pm",
    icon: pic4,
  },
  {
    name: "Appointment Booked",
    Doctor: "Dr. Sapna J.",
    desig: "Diet & Nutrition",
    money: "₹ 4,999",
    date: "Sep 9, 2022, 12:00 pm",
    icon: pic1
  },
  {
    name: "Appointment Cancelled",
    Doctor: "Dr. Raj A.",
    desig: "Psychiatrist",
    money: "₹ 3,450",
    date: "Sep 9, 2022, 12:00 pm",
    icon: pic2
  },
];
