export const memberMenuConstants = {
  selectMemberText: "Select Member",
  closeIconLabel: "Close",
  locationsDummyData: [
    "Rahul Sharma",
    "Shruti Sharma",
    "Kishan Sharma",
  ],
  memberDetailsLabel: "7894561230  • 26 Yrs",
  addNewMemberLabel: "Add New Member",
  editLabel: "Edit",
};
