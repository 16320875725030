import Avatar from "@mui/material/Avatar";
import { MedicalServices } from "@mui/icons-material";
import { Box } from "@mui/system";
import apple from "../assets/images/apple.svg";
import physiotherapy from "../assets/images/physiotherapy.svg";
import styles from "../styling/HealthProfile.module.css";

export const activeCard = {
  book: "Book Activities",
  view: "View Details",
};
export const ActiveCards = [
  {
    name: "Health Gold Plus",
    date: "12 Nov, 22",
    leftdays: "48 days left",
    icon: (
      <Avatar className={styles["Health"]}>
        <MedicalServices />
      </Avatar>
    ),
  },
  {
    name: "Weight Loss 1 Month Diet Plan",
    date: "24 Oct, 22",
    leftdays: "86 days left",

    icon: (
      <Box
        component="img"
        src={apple}
        className={styles["Weight"]}
             />
    ),
  },
  {
    name: "Physiotherapy Home Visit 12",
    date: "17 Oct, 22",
    leftdays: "228 days left",
    icon: (
      <Box
        component="img"
        src={physiotherapy}
        className={styles["Physiotherapy"]}
        
      />
    ),
  },
];
