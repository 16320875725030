import React, { useState } from "react";
import { Tab, Tabs, Typography, Box, Stack } from "@mui/material";
import PackagesBlankImg from "../assets/images/blank.png";
import { BlankPage } from "./BlankPage";
import { ActiveCard } from "./ActiveCard";
import { CompletedCard } from "./CompletedCard";
import { CancelCard } from "./CancelCard";
import PackageSummary from "../pages/PackageSummary";
import styles from "../styling/AccountInformation.module.css"
import { useSharedData } from "../Context/ShareContext";
import CustomModal from "../molecules/CustomModal"
import Summary from "../pages/Summary";

function a11yProps(index) {
  return {
    id: `nav-tab-${index}`,
    "aria-controls": `nav-tabpanel-${index}`,
  };
}
export const MyPackages = () => {
  const [value, setValue] = useState(-1);
  const { showPackage, packageSummaryData, setBookActivityModal, bookActivityModal,appointmentSummaryData,showSummary } = useSharedData();
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const title="My Packages"

  return (
    <>
      {
        showPackage === false ? (
          <Box>
            <CustomModal setOpen={setBookActivityModal} open={bookActivityModal}/>
            <Typography className={styles["accinfo-title"]} variant="h4">
              {title}
            </Typography>
            <Stack direction="row" className={styles["accinfo-tabStack"]}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="nav tabs example"
              >
                <Tab
                  label={<Typography variant="h5">Active</Typography>}
                  {...a11yProps(0)}
                />
                <Tab
                  label={<Typography variant="h5">Completed</Typography>}
                  {...a11yProps(1)}
                />
                <Tab
                  label={<Typography variant="h5">Cancelled</Typography>}
                  {...a11yProps(2)}
                />
              </Tabs>
            </Stack>
            <Stack>
              {value === -1 && (
                <BlankPage
                  blankImage={PackagesBlankImg}
                  mainTxt={"No any active packages"}
                  subTxt={"You don't have an any active packages at the moment."}
                  btnTxt={"Book Package"}
                  btnClickHandler={(e) => handleChange(e, 0)}
                />
              )}
            </Stack>
            <Stack>{value === 0 && <ActiveCard />}</Stack>
            <Stack>{value === 1 && <CompletedCard />}</Stack>
            <Stack>{value === 2 && <CancelCard />}</Stack>
          </Box>
        )
        :
        (
          <>{showSummary === false ?
            <PackageSummary
            PageTitle={"Package Summary"}
            ID={packageSummaryData?.appointmentId}
            CardTitle={"Booked on"}
            CardTime={packageSummaryData?.bookedTime}
            CardStatus={packageSummaryData?.status}
            packageType={packageSummaryData?.packageType}
          />:
            <Summary
                PageTitle={"Package Summary"}
                ID={appointmentSummaryData?.appointmentId}
                CardTitle={"Booked on"}
                CardTime={appointmentSummaryData?.bookedTime}
                CardStatus={appointmentSummaryData?.status}
                appointmentType={appointmentSummaryData?.appointmentType}
    
                
              />}
              </>
        )
      }
  </>
  );
};
