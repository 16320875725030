import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import img from "../assets/images/boys.jpg";
import { Button, Box } from "@mui/material";
import styles from "../styling/CorporateCard.module.css";
import { corporate_dynamic } from "../json/corporateDynamic";
import { SiteContent } from "../assets/content/SiteContent";
export default function CorporateCard({ scrollToRef }) {

  return (
    <Box data-testid="corporate-card">
      <Card className={styles["corporate-card"]} elevation={0}>
        <div className={styles["corporate-stack"]}>
          <CardContent>
            <div className={styles["corporate-content"]}>
              <Typography align="left" className={styles["corporate-title"]}>
                {corporate_dynamic?.BANNER_1?.TITLE?.TITLE_1}
                <br />
                <span className={styles["highlight"]}>
                  {corporate_dynamic?.BANNER_1?.TITLE?.TITLE_2}
                </span>
              </Typography>
              <ul style={{padding:"0px"}} className={styles["corporate-subtitle"]}>
                {corporate_dynamic?.BANNER_1?.LISTITEMS?.map((item) => (
                  <li key={item?.key} style={{textAlign:"left"}}>{item?.content}</li>
                ))}
              </ul>
              <Typography align="left">
                <Button
                  className={styles["corporate-btn"]}
                  onClick={() => {
                    scrollToRef.current.scrollIntoView({ behavior: "smooth" });
                  }}
                >
                  {SiteContent?.SCHEDULE_DEMO}
                </Button>
              </Typography>
            </div>
          </CardContent>
        </div>
        <CardMedia
          component="img"
          alt="green iguana"
          image={img}
          className={styles["corporate-img"]}
        />
      </Card>
    </Box>
  );
}
