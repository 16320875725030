import * as React from "react";
import Typography from "@mui/material/Typography";
import { Button, Box } from "@mui/material";
import Drawer from "@mui/material/Drawer";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import styles from "../styling/CustomDrawer.module.css";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useSharedData } from "../Context/ShareContext";
import { SiteContent } from "../assets/content/SiteContent";

export default function CustomDrawer({
  anchor,
  isOpen,
  onCloseHandler,
  type = "default",
  title = "Reschedule Appointment",
  ...props
}) {
  const { setOpenModal } = useSharedData();

  const closeDrawerHandler = () => {
    onCloseHandler();
    setOpenModal(false);
  };

  const handleNextBtnClick = () => {
    props?.onNextClick && props?.onNextClick();
    onCloseHandler();
  };
  const handleCancelBtnClick = () => {
    props?.onNextCancel && props?.onNextCancel();
    onCloseHandler();
  };

  return (
    <Drawer
      anchor={anchor}
      open={isOpen}
      onClose={() => closeDrawerHandler()}
      slotProps={{
        backdrop: { style: { backdropFilter: "blur(5px)" } },
      }}
    >
      <Box padding={3} className= {styles.drawer_width}>
        <Box display={"flex"} justifyContent={"space-between"}>
          <Typography variant="subtitle2" gutterBottom className={styles.title_style}>
            {title}
          </Typography>
          <Box onClick={() => closeDrawerHandler()} className={styles.close_icon}>
            X
          </Box>
        </Box>

        {type === "default" ? (
          <>
            <Box className={styles.options_container}>
              <FormControl>
                <Typography variant="body2" gutterBottom className={styles.sub_title_style}>
                  {SiteContent?.REASON_FOR_RESCHEDULE_LABEL}
                </Typography>
                <RadioGroup aria-labelledby="demo-radio-buttons-group-label" defaultValue="other" name="radio-buttons-group">
                  {SiteContent?.RESCHEDULE_REASONS?.map((data, index) => {
                    return <FormControlLabel value={data?.value} key={index} control={<Radio />} label={<p className={styles.radio_style}>{data?.label}</p>} />;
                  })}
                </RadioGroup>
              </FormControl>
            </Box>

            <Box className={styles.bg_drawer} padding={3}>
              <Typography variant="caption" display="block" gutterBottom>
                {SiteContent?.RESCHEDULE_DRWAER_DESC}
              </Typography>
            </Box>

            <Box>
              <Button
                onClick={() => handleNextBtnClick()}
                className={styles.next_btn}
                endIcon={
                  <Box className={styles.next_btn_icon_container}>
                    <ArrowForwardIosIcon className={styles.next_btn_icon} />
                  </Box>
                }
              >
                <span className={styles.next_btn_label}>{SiteContent?.NEXT_BTN_LABEL}</span>
              </Button>
            </Box>
          </>
        ) : type === "cancel" ? (
          <>
          <Box className={styles.options_container}>
            <FormControl>
              <Typography variant="body2" gutterBottom className={styles.sub_title_style}>
              Reason for Cancel
              </Typography>
              <RadioGroup aria-labelledby="demo-radio-buttons-group-label" defaultValue="other" name="radio-buttons-group">
                {SiteContent?.CANCEL_REASONS?.map((data, index) => {
                  return <FormControlLabel value={data?.value} key={index} control={<Radio />} label={<p className={styles.radio_style}>{data?.label}</p>} />;
                })}
              </RadioGroup>
            </FormControl>
          </Box>

          <Box className={styles.bg_drawer} padding={3}>
            <Typography variant="caption" display="block" gutterBottom>
              {SiteContent?.RESCHEDULE_DRWAER_DESC}
            </Typography>
          </Box>

          <Box>
            <Button
              onClick={() => handleCancelBtnClick()}
              className={styles.next_btn}
              endIcon={
                <Box className={styles.next_btn_icon_container}>
                  <ArrowForwardIosIcon className={styles.next_btn_icon} />
                </Box>
              }
            >
              <span className={styles.next_btn_label}>Submit</span>
            </Button>
          </Box>
        </>
        ) : (
          <>
            {props?.children}
          </>
        )}
      </Box>
    </Drawer>
  );
}
