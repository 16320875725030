import { styled } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Fab from "@mui/material/Fab";
import { useNavigate } from "react-router-dom";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Cards from "../atoms/Cards";
import ExportCategoriesInputBase from "../molecules/InputLabel";
import styles from "../styling/Blogs.module.css";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import {blogs_dynamic} from "../json/BlogsDynamic";
import React, { useEffect } from "react"; 
import { useSharedData } from "../Context/ShareContext";
import { SiteContent } from "../assets/content/SiteContent";


const SearchIconWrapper = styled("div")(({ theme }) => ({
    padding: theme.spacing(1.5, 1.2),
    height: "100%",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    float: "right",
  }));
  
  const Search = styled("div")(({ theme }) => ({
    width: "100%",
  }));
  
  const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: "inherit",
    "& .MuiInputBase-input": {
      padding: theme.spacing(1, 1, 0.5),
    },
  }));



const SearchBlog = (props) => {
  return (
    <Box>
      <Search className={styles.searchBlog}>
        <StyledInputBase
          className={styles.placeholderText}
          placeholder={blogs_dynamic?.BANNER_2?.SEARCH_KEYWORD}
          inputProps={{ "aria-label": "search" }}
        />
        <SearchIconWrapper>
          <SearchIcon className={styles.searchIcon} />
        </SearchIconWrapper>
      </Search>
    </Box>
  );
};



const Blogs = () => {
  const navigate = useNavigate();
  const readmore = () => {
    navigate("/login/blogsreadmore");
  };
  const { showPopup, setShowPopup } = useSharedData();
  useEffect(() => {
    window.scrollTo(0,0);
    return () => {
      
    };
  }, []); 

  return (
    <Box data-testid="blog-banner">
      <Stack spacing={6}>
        <Stack className={styles["stack-one"]} spacing={6}>
          <Typography align="left" className={styles.blogs_typo}>
            {blogs_dynamic?.BANNER_1?.TITLE}
          </Typography>
          <Card className={styles["blog-banner-card"]}>
            <Stack className={styles["blog-banner-stack"]}>
              <CardContent>
                <Box className={styles["blog-banner-content"]}>
                  <Box className={styles.well_Box}>
                    <Typography
                      color="text.secondary"
                      align="left"
                      fontWeight="bold"
                      className={styles["blog-banner-subtitle"]}
                    >
                      {blogs_dynamic?.BANNER_1?.CARD?.CATEGORY}
                    </Typography>
                  </Box>
                  <Typography
                    align="left"
                    className={styles["blog-banner-title"]}
                    data-testid="banner-title"
                  >
                    {blogs_dynamic?.BANNER_1?.CARD?.TITLE}
                  </Typography>
                  <Typography
                    color="text.primary"
                    align="left"
                    className={styles["blog-banner-subtitle"]}
                  >
                    {blogs_dynamic?.BANNER_1?.CARD?.DESCRIPTION}
                  </Typography>

                  <Typography align="left">
                    <Button
                      className={styles["blog-banner-btn"]}
                      onClick={readmore}
                      data-testid="blog-read-more"
                    
                    >
                      {SiteContent?.READ_MORE}
                    </Button>
                  </Typography>
                </Box>
                <Fab className={styles.tab}>
                  <Stack>
                    <Typography variant="h4" fontWeight="bold">
                      {blogs_dynamic?.BANNER_1?.CARD?.DAY}
                    </Typography>
                    <Typography variant="body2" fontWeight="bold">
                      {blogs_dynamic?.BANNER_1?.CARD?.MONTH}
                    </Typography>
                  </Stack>
                </Fab>
              </CardContent>
            </Stack>
            <CardMedia
              component="img"
              alt={blogs_dynamic?.BANNER_1?.CARD?.ALT}
              image={blogs_dynamic?.BANNER_1?.CARD?.IMAGE}
              className={styles["blog-banner-img"]}
            />
          </Card>
        </Stack>
        <Stack>
          <Stack
            direction={{ xs: "column", sm: "row" }}
            spacing={2}
            justifyContent="space-between"
            className={styles["Stack-two"]}
          >
            <Stack direction="row" spacing={1}>
              <Typography  className={styles.explore_typo}>
                {blogs_dynamic?.BANNER_2?.EXPLORE_CATEGORY}
              </Typography>

              <ExportCategoriesInputBase />
            </Stack>
            <Stack>
              <SearchBlog />
            </Stack>
          </Stack>
        </Stack>

        <Cards />
        <Stack
          direction="row"
          spacing={3}
          justifyContent="center"
          className={styles.carousel_stack}
        >
          <Typography className={styles.carousel_typo}>
            {blogs_dynamic?.BANNER_2?.FIRST_PAGE}
          </Typography>
          <ChevronLeftIcon className={styles.iconColor} />
          <Typography fontWeight="bold"  >
            {blogs_dynamic?.BANNER_2?.PAGES
              .replace("%currentPage%", "1")
              .replace("%totalPages%", "49")}
              
          </Typography>
          <ChevronRightIcon className={styles.iconColor}></ChevronRightIcon>
          <Typography className={styles.carousel_typo}>
            {blogs_dynamic?.BANNER_2?.LAST_PAGE}
          </Typography>
        </Stack>
      </Stack>
      
    </Box>
  );
};

export default Blogs;
