import img1 from "../assets/morning.png";
import img2 from "../assets/afternoon.png";
import img3 from "../assets/night.png";

export const slots = [
  {
    name: "Morning",
    slots: [
      "10.00 AM",
      "10.15 AM",
      "10.30 AM",
      "10.45 AM",
      "11.00 AM",
      "10.00 AM",
      "10.15 AM",
      "10.30 AM",
      "10.45 AM",
      "11.00 AM",
    ],
    img: img1,
  },
  {
    name: "Afternoon",
    slots: [
      "10.00 AM",
      "10.15 AM",
      "10.30 AM",
      "10.45 AM",
      "11.00 AM",
      "10.00 AM",
      "10.15 AM",
      "10.30 AM",
      "10.45 AM",
      "11.00 AM",
    ],
    img: img2,
  },
  {
    name: "Evening",
    slots: [
      "10.00 AM",
      "10.15 AM",
      "10.30 AM",
      "10.45 AM",
      "11.00 AM",
      "10.00 AM",
      "10.15 AM",
      "10.30 AM",
      "10.45 AM",
      "11.00 AM",
    ],
    img: img3,
  },
];
