import theme from "../../components/Themeify";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import styles from "../../styling/ShieldBanner.module.css";
import { useNavigate } from "react-router-dom";
import React from "react";
import {
  Typography,
  Stack,
  Button,
  Box,
  Card,
  CardContent,
} from "@mui/material";
import {shield} from "../../json/shield";
import { useSharedData } from "../../Context/ShareContext";

const ShieldBanner = () => {
  const navigate = useNavigate()
  const {setValue} = useSharedData()
  const handleExplore=()=>{
    navigate('/test_and_packages/health_check_packages')
    setValue(1)
  }
  return (
    <Box>
      <Stack className={styles.container}>
        <Card className={styles.bannerCard} elevation={0}>
          <Stack className={styles.bannerStack}>
            <CardContent className={styles.cardContent}>
              <Box className={styles.bannerContent}>
                <Typography align="left" className={styles.bannerTitle}>
                  {shield?.text}
                </Typography>
                <Typography align="left" >
                  <Button
                    size="large"
                    sx={{
                      alignSelf: "self-start",
                      color: theme.palette.primary.light,
                    }}
                    className={styles.explore}
                    endIcon={<ArrowForwardIosIcon />}
                    onClick={handleExplore}
                  >
                    {shield?.explore}
                  </Button>
                </Typography>
              </Box>
            </CardContent>
          </Stack>
        </Card>
      </Stack>
    </Box>
  );
};

export default ShieldBanner;
