export const constants = {
  paymentDetails: "Payment Details",
  patientDetails: "Patient Details",
  nameLabel: "Name",
  emailLabel: "Email",
  phoneLabel: "Phone",
  genderLabel: "Gender",
  dobLabel: "DOB",
  useCouponLabel: "Use Coupon",
  paymentInfoLabel: "Payment Info",
  totalPriceLabel: "Total Price",
  taxLabel: "Tax",
  grandTotalLabel: "Grand Total",
  checkoutLabel: "Checkout",
};
