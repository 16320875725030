let TermsAndConditionContentData = {
  heading1: "Terms & Conditions",
  heading1Desc: `\nWelcome to our website  www.healthspring.in  (property of Wellspring Healthcare Pvt. Ltd.). If you continue to browse and use this website, you are agreeing to comply with and be bound by the following terms and conditions of use, which together with our privacy policy, govern Wellspring Healthcare Private Limited's relationship with you in relation to this website.
    If you disagree with any part of these terms and conditions, please do not use our website.
    The term Wellspring Private Limited or 'us' or 'we' refers to the owner of the website whose registered office is at 5th Floor, East Wing Forbes Building, Charanjit Rai Marg, Fort, Mumbai - 400001.
    The use of this website is subject to the following terms of use:`,
  heading2: "General",
  heading2Type: "array",
  heading2ArrayData: [
    " The content of the website is for your general information and use only. It is subject to change without notice",
    " Neither we nor any third parties provide any warranty or guarantee as to the accuracy, timeliness, performance, completeness or suitability of the information and materials found or offered on this website for any particular purpose. You acknowledge that such information and materials may contain inaccuracies or errors and we expressly exclude liability for any such inaccuracies or errors to the fullest extent permitted by law.",
    " This website contains material which is owned by or licensed to us. Reproduction is prohibited other than in accordance with the copyright notice, which forms part of these terms and conditions",
    " All trademarks reproduced in this website, which are not the property of, or licensed to the operator, are acknowledged on the website",
    " Unauthorized use of this website may give rise to a claim for damages and/or be a criminal offence",
    " From time to time, this website may also include links to other websites. These links are provided for your convenience to provide further information. They do not signify that we endorse the website(s). We have no responsibility for the content of the linked website(s)",
    " Your use of this website and any dispute arising out of such use of the website is subject to the laws of the Republic of India",
  ],

  heading3: "Confidentiality",
  heading3Desc: `We collect personally identifiable information (name, phone number, email etc.) from you when you request for a service. All your health records are confidential and will be accessible only to you and us.\n
Providing medical history is optional, but it is important for us to be able to help provide
accurate and timely medical care. If provided, it will be maintained with the strictest confidentiality
`,
  heading4: "Use of Demographic and Profile Data",
  heading4Desc: `We use personal information to provide the services you request. We use your personal information to provide the service requested, for billing issues, to inform you about our online and offline offers
`,
  heading8: "Provision of Aggregate Demographic Information to Third Parties",
  heading8Desc: `The company reserves the right to provide aggregate demographic information of its member base to third parties. We do not share personal information of individual users with third parties

`,
  heading5: "Cancellation",
  heading5Desc: [
    ` While we will make every effort to meet your requirements, the Company shall be under no obligation to accept part of complete cancellation. Company, any costs or expenses incurred by the Company up to the date of cancellation and all loss or damage resulting from the cancellation will be paid by the Customer`,
    ` The Company shall be entitled to cancel any contract(s) with the Customer (subject to refunding the Price previously paid by the Customer) without penalty at any time by giving written notice to the Customer`,
  ],
  heading6: "Cookies",
  heading6Desc: `Healthspring.in may use both permanent and temporary cookies. The cookies do not contain any of your personally identifiable information`,
  heading7: "Email Communications",
  heading7Desc: `We will communicate with you through the email ID you have specified. Your email address is never shared with third parties.`,
};

const TermTableData = {
  heading: "Table of Content",
  arrOfObj: [
    "General",
    "Confidentiality",
    "Use of Demographic and Profile Data",
    "Provision of Aggregate Demographic Information to Third Parties",
    "Cancellation",
    "Cookies",
    "Email Communications",
  ],
};

export { TermsAndConditionContentData, TermTableData };
