import { Stack, Typography, Box } from "@mui/material";
import React from "react";
import styles from '../styling/OutPatientCare.module.css';
import search from '../assets/teethsearch.svg';
import { useNavigate } from "react-router-dom";
export const GenericSearchList = (props) => {
  const navigate= useNavigate();

  const handleClick = (index) => {
    if (props.clickable && index === 1) {
      navigate(props?.url)
     
    }
  };
  return (
    <>
      <Stack className={styles.genericsearchlist_stack} direction={"row"} justifyContent="space-between">
        <Typography fontWeight={"bolder"}>{props.title}</Typography>
        <Box className={styles.generic_search_boxes}
          
        >
          {props.showclear ? <span>Clear All</span> : <></>}
        </Box>
      </Stack>
      {props.searchlist.map((i, index) => {
        return (
          <>
            <Stack direction={"row"}>
              <img src={search} alt='search'/>
              
              <span
                key={i}
                onClick={() => handleClick(index)}
                className={` ${props?.clickable && index === 1 ? styles.clickable : styles.marginleft_fifteen}`}                
              >
                {i}
              </span>
            </Stack>

            
            {props.searchlist.length-1 !== index ? (
              <hr className={styles.dent_hr}></hr>
            ) : (
              <></>
            )}
          </>
        );
      })}
    </>
  );
};

export default GenericSearchList;
