export const chkoutAccordianList = [
  {
    name: "Weight (twice per month)",
    id: 1,
  },
  {
    name: "Height (twice per month)",
    id: 2,
  },
  {
    name: "Body Mass Index (twice per month)",
    id: 3,
  },
  {
    name: "Waist to Hip Ratio (twice per month)",
    id: 4,
  },
  {
    name: "Blood Pressure (twice per month)",
    id: 5,
  },
  {
    name: "Random Blood Sugar (if required)",
    id: 6,
  },
  {
    name: "Body Fat Analysis (twice per month)",
    id: 7,
  },
];
export const bloodtestsList = [
  {
    name: "Calcium",
    id: 1,
  },
  {
    name: "Lipid Profile",
    id: 2,
    submenu:["Total Cholesterol", "HDL Cholesterol", "LDL Cholesterol", "VLDL Cholesterol", "Triglycerides", "TC/HDL Cholesterol ratio", "LDL/HDL ratio"]
  },
  {
    name: "Fasting & Post-Prandial Sugar",
    id: 3,
  },
  {
    name: "Thyroid Profile",
    id: 4,
    submenu:["Total Triiodothyronine (T3)", "Total Thyroxine (T4)", "Thyroid-Stimulating Hormone (TSH)"]
  },
  {
    name: "Kidney Profile",
    id: 5,
    submenu:["Blood Urea Nitrogen", "Serum Creatinine", "BUN/Creatinine ratio"]
  },
  {
    name: "Vitamin D",
    id: 6,
  },
  {
    name: "Vitamin B12",
    id: 7,
  },
];




