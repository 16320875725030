import React from "react";
import { Stack, Paper, Box } from "@mui/material";
import styles from "../styling/BlogReadMoreCard.module.css";
import Typography from "@mui/material/Typography";

import flex from "../assets/Flax_cup.png";

export const BlogReadMoreCard = () => {
  const benefits = [
    "Reduce Cancer Risk",
    "Lowers Cholesterol Levels",
    "Prevent Cardiovascular Diseases",
    "Maintains Blood Sugar",
    "Aids in Digestion",
    "Prevents Hot Flashes",
    "Reduces Inflammation",
  ];
  const flaxseed = "FLAX SEEDS BENEFITS";

  return (
    <Stack className={styles.container}>
      <Paper className={styles.cardWrapper} elevation={0}>
        <Typography className={styles.title}>{flaxseed}</Typography>
        <Paper className={styles.cardWrapper} elevation={0}>
          <Stack
            flexDirection={{ xs: "column", sm: "row" }}
            spacing={3}
            className={styles.cardWrapper}
          >
            <Box className={styles.image} component="img" src={flex} />
            <Stack className={`${styles.benefitList} ${styles.customText}`}>
              <ul className={styles.ul_dot}>
                {benefits?.map((benefit, index) => (
                  <Typography align="left" className={styles.customText} key={index}>
                    <li className={styles.listItem}>{benefit}</li>
                  </Typography>
                ))}
              </ul>
            </Stack>
          </Stack>
        </Paper>
      </Paper>
    </Stack>
  );
};
