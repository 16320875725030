import React from "react";
import {
  Grid,
  Typography,
  Avatar,
  CardContent,
  Stack,
  Box,
  Button,
} from "@mui/material";
import { MoreVert } from "@mui/icons-material";
import styles from "../styling/ActiveCard.module.css";
import { MyReport, myreports } from "../json/MyReport";
import TuneIcon from "@mui/icons-material/Tune";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import CustomDrawer from "./CustomDrawer";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import { SiteContent } from "../assets/content/SiteContent";

export const MyReports = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [filterByDrawerIsOpen, setFilterByDrawerIsOpen] = React.useState(false);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event?.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleDrawerClose = () => {
    setFilterByDrawerIsOpen(false);
  };

  const handleApplyResetClick = () => {
    // Close the drawer on clicking Apply or Reset button
    handleDrawerClose();
  };
  return (
    <Box>
      <Box className={styles.page_info}>
        <Typography className={styles["accinfo-title"]} variant="h4">
          {myreports?.report}
        </Typography>
        <Box
          className={styles.fillter_icon_container}
          onClick={() => setFilterByDrawerIsOpen(true)}
        >
          <TuneIcon className={styles.fillter_icon} />
          <span className={styles.fillter_text}>Filter By</span>
        </Box>
      </Box>

      {MyReport?.map((card, index) => {
        return (
          <CardContent key={index} className={styles["myreport-cardContent"]}>
            <Stack className={styles["myreport-mainStack"]}>
              <Grid item xs={6}>
                <Stack className={styles["myreport-subStack"]}>
                  
                  <Box
                    component="img"
                    src={card?.icon}
                    className={styles["BoxStyle"]}
                  />
                  
                  <Stack className={styles["myreport-subStack2"]}>
                    <Typography className={styles["myreport-typoTitle"]}>
                      {card?.name}
                    </Typography>
                    <Typography className={styles["myreport-typoDesc"]}>
                      {card?.desc}
                    </Typography>
                  </Stack>
                </Stack>
              </Grid>
              <Grid item xs={6}>
                <Stack className={styles["myreport-subStack3"]}>
                  <Avatar
                    className={styles["myreport-avatarRightIcon"]}
                    onClick={(e) => handleClick(e)}
                  >
                    <MoreVert className={styles.morevert} />
                  </Avatar>
                </Stack>
              </Grid>
            </Stack>
          </CardContent>
        );
      })}

      <Menu
        className={styles.menu_style}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuItem onClick={handleClose}>
          {SiteContent?.DOWNLOAD_REPORT_LABEL}
        </MenuItem>
        <MenuItem onClick={handleClose}>
          {SiteContent?.SHARE_REPORT_LABEL}
        </MenuItem>
        <MenuItem onClick={handleClose}>
          {SiteContent?.BOOKING_DETAILS_LABEL}
        </MenuItem>
      </Menu>

      <CustomDrawer
        anchor={"right"}
        isOpen={filterByDrawerIsOpen}
        onCloseHandler={() => setFilterByDrawerIsOpen(false)}
        type="custom"
        title="Fillter By"
      >
        <Box className={styles.options_container}>
          <FormControl>
            <Typography
              variant="body2"
              gutterBottom
              className={styles.sub_title_style}
            >
              {myreports?.reportType}
            </Typography>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue="other"
              name="radio-buttons-group"
            >
              <FormControlLabel
                value="Radiology"
                control={<Radio />}
                label={<p className={styles.radio_style}>Radiology</p>}
              />
              <FormControlLabel
                value="Lab Test"
                control={<Radio />}
                label={<p className={styles.radio_style}>Lab Test</p>}
              />
              <FormControlLabel
                value="Doctor Consultation"
                control={<Radio />}
                label={
                  <p className={styles.radio_style}>Doctor Consultation</p>
                }
              />
            </RadioGroup>
          </FormControl>
        </Box>

        <Box className={styles.options_container}>
          <FormControl>
            <Typography
              variant="body2"
              gutterBottom
              className={styles.sub_title_style}
            >
              {myreports?.duration}
            </Typography>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue="other"
              name="radio-buttons-group"
            >
              <FormControlLabel
                value="Last 7 days"
                control={<Radio />}
                label={<p className={styles.radio_style}>Last 7 days</p>}
              />
              <FormControlLabel
                value="Last 30 days"
                control={<Radio />}
                label={<p className={styles.radio_style}>Last 30 days</p>}
              />
              <FormControlLabel
                value="Last 3 months"
                control={<Radio />}
                label={<p className={styles.radio_style}>Last 3 months</p>}
              />
              <FormControlLabel
                value="Last 6 months"
                control={<Radio />}
                label={<p className={styles.radio_style}>Last 6 months</p>}
              />
            </RadioGroup>
          </FormControl>
        </Box>

        <Box className={styles.options_container}>
          <Box className={styles.button_container}>
            <Button
              variant="outlined"
              className={styles.reset_btn}
              onClick={handleApplyResetClick}
            >
              {myreports?.reset}
            </Button>
            <Button
              className={styles.fillter_btn}
              onClick={handleApplyResetClick}
            >
              {myreports?.apply}
            </Button>
          </Box>
        </Box>
      </CustomDrawer>
    </Box>
  );
};
