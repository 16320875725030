import pic7 from "../assets/myreports.svg";

import appointment from "../assets/appointment.svg";
import orderhistory from "../assets/orderhistory.svg";
import membership from "../assets/membership.svg";
import packages from "../assets/packages.svg";
import healthprofile from "../assets/healthprofile.svg";
import logout from "../assets/logout.svg";
import {
  NotificationsRounded,
  Person,
  SettingsRounded,
  Support,
  EventAvailable,
  EventBusy,
  WatchLater,
} from "@mui/icons-material";
import { Box } from "@mui/material";
import styles from "../styling/DropDownCard.module.css";
export const MyAccDropdownCardLeft = [
  {
    name: "My Appointments",
    icon: (
      <Box component="img" src={appointment} className={styles.appointments} />
    ),
    iconBG: "#f9e4e5",
  },
  {
    name: "My Reports",
    icon: (
      <Box
        component="img"
        src={pic7}
        className={styles["Reports"]}
      
      />
    ),
    iconBG: "#ffece2",
  },
  {
    name: "My Order History",
    icon: (
      <Box component="img" src={orderhistory} className={styles.appointments} />
    ),
    iconBG: "#e7f3dc",
  },
  {
    name: "Membership",
    icon: (
      <Box component="img" src={membership} className={styles.appointments} />
    ),
    iconBG: "#fcedca",
  },
  {
    name: "My Packages",
    icon: (
      <Box component="img" src={packages} className={styles.appointments} />
    ),
    iconBG: "#e1f5f1",
  },
];
export const MyAccDropdownCardRight = [
  {
    name: "Health Profile",
    icon: (
      <Box
        component="img"
        src={healthprofile}
        className={styles.appointments}
      />
    ),
    iconBG: "#e7f3dc",
  },
  {
    name: "Account Information",
    icon: <Person className={styles.accouninform} />,
    iconBG: "#e1f5f1",
  },
  {
    name: "Notifications",
    icon: <NotificationsRounded className={styles.notifications} />,
    iconBG: "#ffece2",
  },
  {
    name: "Settings",
    icon: <SettingsRounded className={styles.settings} />,
    iconBG: "#e1eef9",
  },
  {
    name: "Help & Support",
    icon: <Support className={styles.support} />,
    iconBG: "#fcecf5",
  },
];
export const MyAccDropdownCardRightBottom = [
  {
    name: "Logout",
    icon: <Box component="img" src={logout} className={styles.appointments} />,
    iconBG: "#f9e4e5",
  },
];
export const NotificationData = [
  {
    name: "Appointment Success! with Dr. Sapna Jain on Sep 9, 2022, 9:00 am",
    time: "8 hours ago",
    icon: <EventAvailable className={styles["available"]} />,
    iconBG: "#e7f3dc",
  },
  {
    name: "Appointment Cancelled! with Dr. Raj Avasthi on Sep 9, 2022, 12:00 pm",
    time: "12 hours ago",
    icon: <EventBusy className={styles["busy"]} />,
    iconBG: "#f9e4e5",
  },
  {
    name: "Tomorrow you have appointment with Dr. Rajesh Vibhore on Sep 8, 2022, 9:00 am",
    time: "2 days ago",
    icon: <WatchLater className={styles["Later"]} />,
    iconBG: "#e1eef9",
  },
];
