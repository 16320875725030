
import pic1 from "../assets/redlight.png";
import pic2 from "../assets/pic2.png";
import pic3 from "../assets/pic3.png"
export const helpData = [
    {
     title: "Emergency Helpline",
     subheader:"24 * 7 Toll-Free",
     background:"rgba(249, 228, 229, 1)",
     buttonText:"Call now",
     buttonBg:"rgba(235, 61, 67, 1)",
     pic:pic1
     
    },
   {
       title: "Everyday Health",
       subheader:"Questions? We're here to help",
       background:"rgba(240, 228, 252, 1)",
       buttonText:"Start now",
       buttonBg:"rgba(165, 127, 205, 1)",
       pic:pic2
       
     },

     {
       title: "Urgent Care",
       subheader:"Write your Queries to our Expert",
       background:"rgba(225, 238, 249, 1)",
       buttonText:"Start now",
       buttonBg:"rgba(80, 125, 230, 1)",
       pic:pic3
       
     }
     
  
 ];