export const Membership = [
    {
        name: "Glycosylated Haemoglobin (Hba1c): 1",
        id: 1,
      },
      {
        name:  "CBC Including ESR: 1",
        id: 2,
      },
      {
        name: "ECG: 1",
        id: 3,
      },
      {
        name: "Chest X-Ray: 1",
        id: 4,
      },
      {
        name: " Creatinine: 1",
        id: 5,
      },
      {
        name: "Total Cholesterol: 1",
        id: 6,
      },
      {
        name: "Urine Routine: 1",
        id: 7,
      },
      {
        name: "Vital Checks + RBS: 1",
        id: 8,
      },


];

export const Glycosylated = [
    {
        name: `Consultations with Family Physician`,
        val:'Unlimited',
        id: 1,
        
      },
      {
        name:  `Consultations with Dentist, Dietician & Physiotherapist`,
        val:`1 Consultation Each with All`,
        id: 2,
        
      },
      {
        name: `TeleHealth - Call your Doctor`,
        val:'Available',
        id: 3,
        
      },
  
];

export const Discount = [
    {
        name: `Specialists`,
        dis:'10%',
        id: 1,
        
      },
      {
        name:  `Radiology`,
        dis:`10%`,
        id: 2,
        
      },
      {
        name:  `Pathology`,
        dis:`10%`,
        id: 3,
        
      },
      {
        name:  `Pharmacy & Vaccinations`,
        dis:`7.5%`,
        id: 4,
        
      },
      {
        name:  `Nutrition`,
        dis:`10%`,
        id: 5,
        
      },
      {
        name:  `Physiotheraphy`,
        dis:`10%`,
        id: 6,
        
      },
      {
        name:  `Dental`,
        dis:`10%`,
        id: 7,
        
      },
      {
        name:  `24x7 Emergency Response*`,
        dis:`1st Free`,
        id: 8,
        
      }
  
];

