import React, { useState } from "react";
import {
  Stack,
  Box,
  Typography,
  Button,
  TextField,
  InputAdornment,
  Divider,
} from "@mui/material";
import {
  AccountCircleOutlined,
  CallOutlined,
  Diversity1Outlined,
  KeyboardArrowDown,
  KeyboardArrowUp,
} from "@mui/icons-material";
import { useFormik } from "formik";
import * as Yup from "yup";

import styles from "../../styling/RegistrationDetails.module.css";
import { SiteContent } from "../../assets/content/SiteContent";

const validationSchema = Yup.object().shape({
  primaryContactPerson: Yup.string().required(
    "Primary Contact Person is required"
  ),
  primaryContactRelation: Yup.string().required(
    "Primary Contact Relation is required"
  ),
  primaryContactMobile: Yup.string().required(
    "Primary Contact Mobile Number is required"
  ),
  secondaryContactPerson: Yup.string().required(
    "Secondary Contact Person is required"
  ),
  secondaryContactRelation: Yup.string().required(
    "Secondary Contact Relation is required"
  ),
  secondaryContactMobile: Yup.string().required(
    "Secondary Contact Mobile Number is required"
  ),
});

const EmergencyContactDetails = () => {
  const [showSecondary, setShowSecondary] = useState(false);

  const formik = useFormik({
    initialValues: {
      primaryContactPerson: "",
      primaryContactRelation: "",
      primaryContactMobile: "",
      secondaryContactPerson: "",
      secondaryContactRelation: "",
      secondaryContactMobile: "",
    },
    validationSchema,
    onSubmit: (values) => {},
  });

  const handleInputChange = (fieldName, value) => {
    formik.setFieldValue(fieldName, value);
  };

  const handleSecondary = () => {
    setShowSecondary((prev) => !prev);
  };

  const moveNextStep = () => {
    // can be used for linkage
  };

  return (
    <>
      <Box className={styles.regdtls_box} data-testid="emergency">
        <Typography className={styles.regdtls_main_txt} variant="h4">
          {SiteContent.EMERGENCY_CONTACT}
        </Typography>
        <form onSubmit={formik?.handleSubmit}>
          <Stack direction="column">
            <Stack item xs={6}>
              <Typography
                className={styles.regdtls_emer_maintitle}
                variant="h5"
              >
                {SiteContent.PRIMARY_CONTACT}
              </Typography>
              <Typography className={styles.regdtls_txtfldtitle} variant="h5">
                {SiteContent.CONTACT_PERSON}
              </Typography>
              <TextField
                className={styles.regdtls_txtfld}
                variant="standard"
                name="primaryContactPerson"
                value={formik?.values?.primaryContactPerson}
                onChange={(e) => {
                  const value = e?.target?.value?.replace(
                    /[^A-Za-z\s\-']/g,
                    ""
                  );
                  handleInputChange("primaryContactPerson", value);
                }}
                onBlur={formik?.handleBlur}
                error={
                  formik?.touched?.primaryContactPerson &&
                  Boolean(formik?.errors?.primaryContactPerson)
                }
                InputProps={{
                  className: styles.regdtls_txtfldinpt,
                  disableUnderline: true,
                  endAdornment: (
                    <InputAdornment data-testid="person-icon">
                      <AccountCircleOutlined
                        className={styles.regdtls_icon_color}
                      />
                    </InputAdornment>
                  ),
                }}
              />
              {formik?.touched?.primaryContactPerson &&
              formik?.errors?.primaryContactPerson ? (
                <Typography color="error" className={styles.validation}>
                  {formik?.errors?.primaryContactPerson}
                </Typography>
              ) : null}
            </Stack>
            <Stack item xs={6}>
              <Typography className={styles.regdtls_txtfldtitle} variant="h5">
                {SiteContent.RELATION}
              </Typography>
              <TextField
                className={styles.regdtls_txtfld}
                variant="standard"
                name="primaryContactRelation"
                value={formik?.values?.primaryContactRelation}
                onChange={(e) => {
                  const value = e?.target?.value?.replace(
                    /[^A-Za-z\s\-']/g,
                    ""
                  );
                  handleInputChange("primaryContactRelation", value);
                }}
                onBlur={formik?.handleBlur}
                error={
                  formik?.touched?.primaryContactRelation &&
                  Boolean(formik?.errors?.primaryContactRelation)
                }
                InputProps={{
                  className: styles.regdtls_txtfldinpt,
                  disableUnderline: true,
                  endAdornment: (
                    <InputAdornment data-testid="relation-icon">
                      <Diversity1Outlined
                        className={styles.regdtls_icon_color}
                      />
                    </InputAdornment>
                  ),
                }}
              />
              {formik?.touched?.primaryContactRelation &&
              formik?.errors?.primaryContactRelation ? (
                <Typography color="error" className={styles.validation}>
                  {formik?.errors?.primaryContactRelation}
                </Typography>
              ) : null}
            </Stack>
            <Stack item xs={6}>
              <Typography className={styles.regdtls_txtfldtitle} variant="h5">
                {SiteContent.MOBILE_NUMBER}
              </Typography>
              <TextField
                className={styles.regdtls_txtfld}
                variant="standard"
                name="primaryContactMobile"
                value={formik?.values?.primaryContactMobile}
                onChange={(e) => {
                  const value = e?.target?.value?.replace(/[^0-9]/g, "");
                  handleInputChange("primaryContactMobile", value);
                }}
                onBlur={formik?.handleBlur}
                error={
                  formik?.touched.primaryContactMobile &&
                  Boolean(formik?.errors?.primaryContactMobile)
                }
                InputProps={{
                  className: styles.regdtls_txtfldinpt,
                  disableUnderline: true,
                  endAdornment: (
                    <InputAdornment data-testid="mobile-icon">
                      <CallOutlined className={styles.regdtls_icon_color} />
                    </InputAdornment>
                  ),
                }}
              />
              {formik?.touched?.primaryContactMobile &&
              formik?.errors?.primaryContactMobile ? (
                <Typography color="error" className={styles?.validation}>
                  {formik?.errors?.primaryContactMobile}
                </Typography>
              ) : null}
            </Stack>
            <Divider className={styles.regdtls_divider} />
            <Button
              className={styles.regdtls_accobtn}
              onClick={handleSecondary}
              endIcon={
                showSecondary ? (
                  <KeyboardArrowUp className={styles.regdtls_acco_arrow} />
                ) : (
                  <KeyboardArrowDown className={styles.regdtls_acco_arrow} />
                )
              }
            >
              <Typography
                className={styles.regdtls_emer_maintitle}
                variant="h5"
              >
                {SiteContent.SECONDARY_CONTACT}
              </Typography>
            </Button>
            {showSecondary && (
              <>
                <Stack item xs={6}>
                  <Typography
                    className={styles.regdtls_txtfldtitle}
                    variant="h5"
                  >
                    {SiteContent.CONTACT_PERSON}
                  </Typography>
                  <TextField
                    className={styles.regdtls_txtfld}
                    variant="standard"
                    name="secondaryContactPerson"
                    value={formik?.values?.secondaryContactPerson}
                    onChange={(e) => {
                      const value = e?.target?.value?.replace(
                        /[^A-Za-z\s\-']/g,
                        ""
                      );
                      handleInputChange("secondaryContactPerson", value);
                    }}
                    onBlur={formik?.handleBlur}
                    error={
                      formik?.touched?.secondaryContactPerson &&
                      Boolean(formik?.errors?.secondaryContactPerson)
                    }
                    InputProps={{
                      className: styles.regdtls_txtfldinpt,
                      disableUnderline: true,
                      endAdornment: (
                        <InputAdornment data-testid="secondary-person-icon">
                          <AccountCircleOutlined
                            className={styles.regdtls_icon_color}
                          />
                        </InputAdornment>
                      ),
                    }}
                  />
                  {formik?.touched?.secondaryContactPerson &&
                  formik?.errors?.secondaryContactPerson ? (
                    <Typography color="error" className={styles.validation}>
                      {formik?.errors?.secondaryContactPerson}
                    </Typography>
                  ) : null}
                </Stack>
                <Stack item xs={6}>
                  <Typography
                    className={styles.regdtls_txtfldtitle}
                    variant="h5"
                  >
                    {SiteContent.RELATION}
                  </Typography>
                  <TextField
                    className={styles.regdtls_txtfld}
                    variant="standard"
                    name="secondaryContactRelation"
                    value={formik?.values?.secondaryContactRelation}
                    onChange={(e) => {
                      const value = e?.target?.value?.replace(
                        /[^A-Za-z\s\-']/g,
                        ""
                      );
                      handleInputChange("secondaryContactRelation", value);
                    }}
                    onBlur={formik?.handleBlur}
                    error={
                      formik?.touched?.secondaryContactRelation &&
                      Boolean(formik?.errors?.secondaryContactRelation)
                    }
                    InputProps={{
                      className: styles.regdtls_txtfldinpt,
                      disableUnderline: true,
                      endAdornment: (
                        <InputAdornment data-testid="secondary-relation-icon">
                          <Diversity1Outlined
                            className={styles.regdtls_icon_color}
                          />
                        </InputAdornment>
                      ),
                    }}
                  />
                  {formik?.touched?.secondaryContactRelation &&
                  formik?.errors?.secondaryContactRelation ? (
                    <Typography color="error" className={styles.validation}>
                      {formik?.errors?.secondaryContactRelation}
                    </Typography>
                  ) : null}
                </Stack>
                <Stack item xs={6}>
                  <Typography
                    className={styles.regdtls_txtfldtitle}
                    variant="h5"
                  >
                    {SiteContent.MOBILE_NUMBER}
                  </Typography>
                  <TextField
                    className={styles.regdtls_txtfld}
                    variant="standard"
                    name="secondaryContactMobile"
                    value={formik?.values?.secondaryContactMobile}
                    onChange={(e) => {
                      const value = e?.target?.value?.replace(/[^0-9]/g, "");
                      handleInputChange("secondaryContactMobile", value);
                    }}
                    onBlur={formik?.handleBlur}
                    error={
                      formik?.touched?.secondaryContactMobile &&
                      Boolean(formik?.errors?.secondaryContactMobile)
                    }
                    InputProps={{
                      className: styles.regdtls_txtfldinpt,
                      disableUnderline: true,
                      endAdornment: (
                        <InputAdornment data-testid="secondary-mobile-icon">
                          <CallOutlined className={styles.regdtls_icon_color} />
                        </InputAdornment>
                      ),
                    }}
                  />
                  {formik?.touched?.secondaryContactMobile &&
                  formik?.errors?.secondaryContactMobile ? (
                    <Typography color="error" className={styles.validation}>
                      {formik?.errors?.secondaryContactMobile}
                    </Typography>
                  ) : null}
                </Stack>
              </>
            )}
            <Button
              className={styles.regdtls_continue_button}
              type="submit"
              onClick={moveNextStep}
            >
              Submit
            </Button>
          </Stack>
        </form>
      </Box>
    </>
  );
};

export default EmergencyContactDetails;
