import React from "react";
import { Formik } from "formik";
import { Stack, Typography, TextField, Button } from "@mui/material";
import { StyledEngineProvider } from "@mui/material/styles";
import styles from "../styling/ContactForm.module.css";
import * as Yup from "yup";

const validationSchema = Yup.object().shape({
  name: Yup
  .string()
  .min(3, "Name is too short, must be at least 3 characters")
  .max(50, "Name is too long, must be at most 50 characters")
  .required("Name is mandatory"),
  email: Yup.string()
    .email("Invalid email address")
    .required("Email ID is required"),
    phone: Yup
    .string()
    .min(10, "Phone must be of 10 digits")
    .max(10, "Phone is too long, must be at most 10 digits")
    .required("Phone is mandatory"),
 
});

export function ContactForm() {
  const name = "Your Name";
  const message = "Message";
  const email = "Email ID";
  const phone = "Phone No.";
  const submit="Submit";
  
  return (
    // <StyledEngineProvider injectFirst>
    //   <Formik
    //     initialValues={{
    //       name: "",
    //       email: "",
    //       phone: "",
    //       message: "",
    //     }}
    //     validationSchema={validationSchema}
    //     onSubmit={(values, { setSubmitting }) => {
    //       setTimeout(() => {
    //         setSubmitting(false);
    //       }, 400);
    //     }}
    //   >
    //     {({
    //       values,
    //       errors,
    //       touched,
    //       handleChange,
    //       handleBlur,
    //       handleSubmit,
    //       isSubmitting,
    //     }) => (
    //       <form onSubmit={handleSubmit} data-testid="contact_us_form">
    //         <Stack className={styles.form_body}>
    //           <Stack className={styles.form_header}>
    //             <Typography
    //               variant="h4"
    //               id="name"
    //               className={styles.form_label}
    //               data-testid="name"
    //             >
    //               {name}
    //             </Typography>
    //             <TextField
    //               name="name"
    //               onChange={(e) => handleChange(e?.target?.name)(e?.target?.value?.replace(/[^A-Za-z\s\-']/g, ''))}
    //               onBlur={handleBlur}
    //               value={values?.name}
    //               className={styles.form_input}
    //             />
    //             {errors?.name && touched?.name && (
    //               <Typography  color="error">
    //                 {errors?.name}
    //               </Typography>
    //             )}
    //           </Stack>
    //           <Stack className={styles.form_header}>
    //             <Typography
    //               id="email"
    //               variant="h4"
    //               className={styles.form_label}
    //               data-testid="email"
    //             >
    //              {email}
    //             </Typography>
    //             <TextField
    //               type="email"
    //               name="email"
    //               onChange={handleChange}
    //               onBlur={handleBlur}
    //               value={values?.email}
    //               className={styles.form_input}
    //             />
    //             {errors.email && touched.email ? (
    //               <Typography color="error">
    //                 {errors?.email}
    //               </Typography>
    //             ):null}
    //           </Stack>
    //           <Stack className={styles.form_header}>
    //             <Typography
    //               id="phone"
    //               variant="h4"
    //               className={styles.form_label}
    //               data-testid="phone"
    //             >
    //               {phone}
    //             </Typography>
    //             <TextField
    //               name="phone"
    //               onChange={(e) => handleChange(e?.target?.name)(e?.target?.value?.replace(/\D/g, ""))}
    //               onBlur={handleBlur}
    //               value={values?.phone}
    //               className={styles.form_input}
    //             />
    //             {errors?.phone && touched?.phone ? (
    //               <Typography className={styles.yourdetailsErrorText} color="error">
    //                 {errors?.phone}
    //               </Typography>
    //             ):null}
    //           </Stack>
    //           <Stack className={styles.form_header}>
    //             <Typography
    //               id="message"
    //               variant="h4"
    //               className={styles.form_label}
    //               data-testid="message"
    //             >
    //               {message}
    //             </Typography>
    //             <TextField
    //               name="message"
    //               onChange={handleChange}
    //               onBlur={handleBlur}
    //               value={values?.message}
    //               className={styles.form_input}
    //               multiline
    //               rows={4}
    //             />
    //             {errors?.message && touched?.message ? (
    //               <Typography color="error">
    //                 {errors?.message}
    //               </Typography>
    //             ):null}
    //           </Stack>
    //           <Stack className={styles.contact_button}>
    //             <Button
    //               type="submit"
    //               className={styles.form_button}
    //               disabled={isSubmitting}
    //             >
    //               <Typography variant="h4" className={styles.form_button_text}>
    //                 {submit}
    //               </Typography>
    //             </Button>
    //           </Stack>
    //         </Stack>
    //       </form>
    //     )}
    //   </Formik>
    // </StyledEngineProvider>

    <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSfyaNFOSULzoNbBuoZHXXD3pI3mEa4x-vlx2nJInMe-7MLAdg/viewform?embedded=true" className={styles.width} height="824" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>
  );
}
