import React, { useState } from "react";
import {
  Typography,
  Toolbar,
  MenuItem,
  Stack,
  Select,
  FormControl,
  Slider,
  Box,
  Button,
} from "@mui/material";
import {filter} from "../json/filter"
import styles from "../styling/FilterMenu.module.css";
import CloseIcon from "@mui/icons-material/Close";
import AutoComplete from "../hooks/useAutoComplete";
import VideoCallIcon from "@mui/icons-material/VideoCall";
import LocalHospitalIcon from "@mui/icons-material/LocalHospital";
import RoofingIcon from "@mui/icons-material/Roofing";

export default function FilterMenu({ onClose }) {
  const [location, setLocation] = useState("");
  const [gender, setGender] = useState("");
  const [appointmentType, setAppointmentType] = useState("");
  const [value, setValue] = useState([20, 37]);

  const [price, setPrice] = useState([1000, 14000]);

  const handleLocation = (event) => {
    setLocation(event?.target?.value);
  };

  const handleRangeChange = (event, newValue) => {
    setValue(newValue);
  };

  const handlePriceChange = (event, newValue) => {
    setPrice(newValue);
  };
  function valuetext(value) {
    return `${value}`;
  }
  return (
    <>
      <Toolbar>
        <Typography
          variant="h4"
          sx={{
            fontWeight: "bold",
            marginTop: "2rem",
            marginLeft: "1rem",
          }}
        >
          {filter?.filter.by}
        </Typography>
        <CloseIcon className={styles.filterclose_icon} onClick={onClose} />
      </Toolbar>
      <Stack>
        <FormControl className={styles.form_control_container}>
          <Typography
            variant="h5"
            sx={{ marginBottom: "1rem", fontWeight: "400" }}
          >
            {filter?.loc}
          </Typography>
          <Select
            value={location}
            onChange={handleLocation}
            displayEmpty
            inputProps={{ "aria-label": "Without label" }}
            className={styles.select_dropdown}
          >
            <MenuItem value="Nerul">Nerul</MenuItem>
            <MenuItem value="Kharghar">Kharghar</MenuItem>
            <MenuItem value="Vashi">Vashi</MenuItem>
            <MenuItem value="Powai">Powai</MenuItem>
            <MenuItem value="Palava">Palava</MenuItem>
            <MenuItem value="Aundh">Aundh</MenuItem>
            <MenuItem value="Palava CBG">Palava CBG</MenuItem>
            <MenuItem value="Andheri Oshiwara">Andheri Oshiwara</MenuItem>
          </Select>
        </FormControl>
        <AutoComplete label="Specialist" />
        <AutoComplete label="Services" />
        <Stack>
          <Typography
            variant="h5"
            sx={{ marginLeft: "2.3rem", fontWeight: "400" }}
          >
           {filter?.appo.type}
          </Typography>
          <Stack direction="row" spacing={2} className={styles.stack_container}>
            <Stack
              direction="row"
              className={`${styles.stack_bg} ${
                appointmentType === "On Site" ? styles.stack_selected : ``
              }`}
              onClick={() => setAppointmentType("On Site")}
            >
              <RoofingIcon />
              {filter?.appo.onsite}
            </Stack>
            <Stack
              direction="row"
              className={`${styles.stack_bg} ${
                appointmentType === "Call" ? styles.stack_selected : ``
              }`}
              onClick={() => setAppointmentType("Call")}
            >
              <VideoCallIcon />
              {filter?.appo.call}
            </Stack>
            <Stack
              direction="row"
              className={`${styles.stack_bg} ${
                appointmentType === "In Clinic" ? styles.stack_selected : ``
              }`}
              onClick={() => setAppointmentType("In Clinic")}
            >
              <LocalHospitalIcon />
              {filter?.appo.inclinic}
            </Stack>
          </Stack>
        </Stack>
        <AutoComplete label="Specialist" />
        <AutoComplete label="Name of Doctor" />

        <Box className={styles.box_container}>
          <Typography
            variant="h5"
            sx={{ marginLeft: "2.3rem", fontWeight: "400" }}
          >
           {filter?.exp}
          </Typography>
          <Slider
            getAriaLabel={() => "Experience range"}
            value={value}
            onChange={handleRangeChange}
            valueLabelDisplay="auto"
            getAriaValueText={valuetext}
            className={styles.range_slider}
          />
        </Box>

        <Stack>
          <Typography
            variant="h5"
            sx={{ marginLeft: "2.3rem", fontWeight: "400" }}
          >
            {filter?.gender.title}
          </Typography>
          <Stack
            direction="row"
            spacing={2}
            className={styles.stack_button_container}
          >
            <Button
              variant="contained"
              className={`${styles.button_gender} ${
                gender === "Male" ? styles.gender_selected : ``
              }`}
              onClick={() => setGender("Male")}
            >
              {filter?.gender.male}
            </Button>
            <Button
              variant="contained"
              className={`${styles.button_gender} ${
                gender === "Female" ? styles.gender_selected : ``
              }`}
              onClick={() => setGender("Female")}
            >
              {filter?.gender.female}
            </Button>
          </Stack>
        </Stack>

        <AutoComplete label="Language" />

        <Box className={styles.box_container}>
          <Typography
            variant="h5"
            sx={{ marginLeft: "2.3rem", fontWeight: "400" }}
          >
            {filter?.price}
          </Typography>
          <Slider
            getAriaLabel={() => "Price"}
            value={price}
            onChange={handlePriceChange}
            valueLabelDisplay="auto"
            getAriaValueText={valuetext}
            className={styles.range_slider}
            min={1}
            step={1}
            max={15000}
          />
        </Box>

        <Stack
          direction="row"
          spacing={2}
          className={styles.stack_button_container}
        >
          <Button variant="contained" className={styles.button_reset} onClick={onClose} >
            {filter?.filter.reset}
          </Button>
          <Button variant="contained" className={styles.button_filter} onClick={onClose} >
           {filter?.filter.apply}
          </Button>
        </Stack>
      </Stack>
    </>
  );
}
