import React from "react";
import Stack from "@mui/material/Stack";
import { Typography } from "@mui/material";

import styles from "../styling/CoverageCards.module.css";
import {coverageCardList} from '../json/CoveragecardList'


const MemberCheckoutCoverage = () => {
  return (
    <Stack spacing={2} data-testid="coverage_card_container">
      <Stack className={styles["coverageCards"]}>
        {coverageCardList.map((item) => (
          <CardImageView
            title={item?.title}
            desc1={item?.desc1}
            desc={item?.desc}
            Icon={item?.icon}
           
            iconBgcolor={item?.bgColor}
            key={item?.id}
          />
        ))}
      </Stack>
    </Stack>
  );
};

const CardImageView = ({
  height = null,
  Icon,
  title = "",
  
  iconBgcolor,
}) => {
  return (
    <Stack
      sx={{
        backgroundColor: iconBgcolor,
        flexDirection: "row", 
        alignItems: "flex-start",
      }}
      className={styles["cvgCard"]}
      data-testid="coverage_card"
    >
      <Stack
        className={styles["iconStack"]}
        
      >
        
        <img src={Icon} className={styles["pkgCardIcon"]} />
      </Stack>
      <Stack className={styles["stackContainer"]}>
        <Typography className={styles["typoCard"]}>{title}</Typography>
      </Stack>
    </Stack>
  );
};

export default MemberCheckoutCoverage;
