export const careerKickstart = {
  TITLE: {
    TITLE_PART1: "Kick Start Your",
    TITLE_PART2: " Career",
    TITLE_PART3: "with Healthspring",
  },
  CONTACT_US: "Contact us directly",
  EMAIL: "Email",
};


export const careerKickstart_dynamic={
  DESCRIPTION: `Healthspring has grown leaps and bounds since the foundation in early
  2011. Within a short span of time, we have set up 26 established
  centres across Mumbai and Pune, with plans for launch of new clinics
  at more locations. Healthspring also provides Corporate Care services
  to more than a 150 organizations at a PAN India level.`,
  EMAIL_MAILID: "recruiting@healthspring.in",
}
