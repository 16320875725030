import { Stack } from '@mui/system';
import React from 'react';
import { Line } from 'react-chartjs-2';
import RoundedBox from "./RoundedBox";
import styles from "../styling/VitalsBodyParam.module.css"

const BMIChart = () => {
  const labels = ['1 Jan', '15 Jan', '28 Jan', '12 Feb', '30 Feb', '14 Mar'];
  
  const dataset1 = [19, 24, 22, 17.06, 20, 23.5];

  const datasets = [
    {
      label: '',
      data: dataset1,
      borderColor: 'black',
      backgroundColor: 'rgba(255, 255, 255, 0.5)',
    },
  ];

  const chartData = { labels, datasets };

  const options = {
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        grid: {
          display: false,
        },
      },
    },
  };

  return (
    <div>
      <Stack direction={{ xs: "column", sm: "row" }} justifyContent="space-between">
        <RoundedBox header="Your Starting" value="18.96" flexGrow={1} />
        <RoundedBox header="Your Current" value="22.06" flexGrow={1}/>
      </Stack>
      <Stack>
        <Line data={chartData} options={options} className={styles.canvas_width} />
      </Stack>
    </div>
  );
};

export default BMIChart;
