import React from "react";
import { Box, Stack, Typography } from "@mui/material";
import styles from "../styling/Ourservices.module.css";
import SecondServicesCard from "./SecondServicesCard";
import { corporate_dynamic } from "../json/corporateDynamic";
import bg from "../assets/servicebg.png"

export const Ourservices = () => {
  const renderServiceCard = (index) => {
    const service = corporate_dynamic?.BANNER_4?.FARDS[index];
    return (
      <SecondServicesCard
        icon={service?.ICON}
        title={service?.TITLE}
        description={service?.CONTENT}
      />
    );
  };
  const renderServicePairs = () => {
    return corporate_dynamic?.BANNER_4?.FARDS?.map((_, index) => {
      if (index % 2 === 0) {
        return (
          <Stack
            direction={{ xs: "column", sm: "row" }}
            spacing={2}
            key={index}
            alignItems={{xs:"center",sm:"unset"}}
            justifyContent="space-between"
          >
            {renderServiceCard(index)}
            {index + 1 < corporate_dynamic?.BANNER_4?.FARDS?.length && renderServiceCard(index + 1)}
          </Stack>
        );
      }
      return null;
    });
  };

  return (
    <Box className={styles["ourservices-boxes"]} data-testid="our-services"
    style={{
      backgroundImage: `url(${bg})`,
    }}>
      <Stack className={styles["ourservicesinner-boxes"]} spacing={3} >
        <Typography variant="h1" className={styles["every-bold"]} color="white">
          {corporate_dynamic?.BANNER_4?.TITLE}
        </Typography>
        <Stack spacing={2} justifyContent="space-between">
          {renderServicePairs()}
        </Stack>
       
      </Stack>
    </Box>
  );
};
