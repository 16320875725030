import Dentalcarepng from "../assets/images/dentalcare.svg";
import Urgentcare from "../assets/images/urgent.svg";
import Physio from "../assets/images/physio.svg";
import Vaccine from "../assets/images/vaccine.svg";
export const cards = [
  {
    index: 1,
    alt: "Dental Care",
    color: "#e1f5f1",
    image: Dentalcarepng,
    path:"/outpatient_care/dental_care",
    name: "Dental Care",
  },
  {
    index: 2,
    alt: "Urgent Care",
    color: "#f9e4e5",
    image: Urgentcare,
    path: "/outpatient_care/urgent_care",
    name: "Urgent Care",
  },
  {
    index: 3,
    alt: "Physiotherapy",
    color: "#ffece2",
    image: Physio,
    path:"",
    name: "Physiotherapy",
  },
  {
    index: 4,
    alt: "Vaccination",
    color: "#e7f3dc",
    image: Vaccine,
    path:"/outpatient_care/vaccination",
    name: "Vaccination",
  },
];
