import React, { useState } from "react";
import { CardContent, Card, Typography, Stack, Drawer } from "@mui/material";
import styles from "../styling/DoctorDetails.module.css";
import useAppStyles from "../hooks/useAppStyles";
import DirectionsIcon from "@mui/icons-material/Directions";
import LocationMenu from "../molecules/LocationMenu";
import Bread from "./Bread";
import DoctorScheduleForNew from "./DoctorScheduleForNew";
import { doc } from "../json/doctorDetails";
import defaultStyles from "../styling/DefaultStyles.module.css";
import docimg from "../assets/doc-big.png";
import badge from "../assets/doc_badge.svg";
import star from "../assets/doc_star.svg";
import onsite from "../assets/onsite.png";
import call from "../assets/images/videocall.png";
import inclinic from "../assets/hom.svg";
import { useParams } from "react-router-dom";

export default function DoctorDetails({ openCheckoutPage }) {
  const [locationMenu, setLocationMenu] = useState(false);
  const appStyles = useAppStyles();
  const { cat } = useParams();


  const openLocationMenu = (bool) => {
    setLocationMenu(bool);
  };

  const handleCloseLocationMenu = () => {
    setLocationMenu(false);
  };
  return (
    <>
      <Stack className={defaultStyles.stack_with_max_width}>
        <Stack className={defaultStyles.bread_stack}>
          <Bread />
        </Stack>
        <Stack
          direction={{ xs: "column", sm: "row" }}
          className={styles.doctordetails_page_main_stacks}
          data-testid="doctor_details_box"
        >
          <Stack spacing={2} sx={{ width: { xs: "100%", sm: "55%" } }}>
            <Card
              className={styles.left_card_layout}
              data-testid="doc_details_card"
            >
              <CardContent className={styles.card_content_styling}>
                <Stack
                  spacing={{ xs: 1, sm: 2 }}
                  direction={{ xs: "column", sm: "row" }}
                  useFlexGap
                  flexWrap="wrap"
                >
                  <img
                    src={docimg}
                    className={styles.avatar_icon_doctor_details}
                    alt="img"
                  />
                  <Stack spacing={1}>
                    <span className={styles.dummy_row} />
                    <Stack direction="row" spacing={2}>
                      <Typography
                        align="left"
                        className={styles.caption_Doctordetails}
                      >
                        {doc?.name}
                      </Typography>
                    </Stack>
                    <Stack direction="row" spacing={2}>
                      <Typography
                        align="left"
                        className={styles.subcaption_Doctordetails_txt}
                        variant="h5"
                      >
                        {doc?.occupation} <span className={styles.dot}>•</span>
                        7+ Yrs
                      </Typography>
                    </Stack>
                  </Stack>
                </Stack>
              </CardContent>
            </Card>

            <Stack
             
              direction={{ xs: "column", sm: "column",md:"row" }}
              spacing={2}
              justifyContent="space-between"
            >
              <Stack flexGrow={1}>
              <Card  className={styles.card_layouts}>
                <CardContent className={styles.card_contents_styling} >
                  <Stack
                    spacing={{ xs: 1, sm: 2 }}
                    direction={{ xs: "column", sm: "row" }}
                    useFlexGap
                    flexWrap="wrap"
                  >
                    <Stack direction="row">
                      <img
                        src={badge}
                        className={styles.icon_style_doctor_details_one}
                        alt="img"
                      />
                    </Stack>
                    <Stack direction="row" spacing={2}>
                      <Typography
                        align="left"
                        className={styles.bold}
                        color={appStyles.typographyStyle2}
                      >
                        {doc?.exp}
                      </Typography>
                    </Stack>
                  </Stack>
                </CardContent>
              </Card>
              </Stack>
              <Stack flexGrow={1} >
              <Card className={styles.card_layouts} >
                <CardContent className={styles.card_contents_styling_rating}>
                  <Stack
                    spacing={{ xs: 1, sm: 2 }}
                    direction={{ xs: "column", sm: "row" }}
                    useFlexGap
                    flexWrap="wrap"
                    className={styles.rating_chip}
                  >
                    <Stack direction="row">
                      <img
                        src={star}
                        className={styles.icon_style_doctor_details_two}
                        alt="img"
                      />
                    </Stack>
                    <Stack direction="row" spacing={2}>
                      <Typography
                        align="left"
                        className={styles.bold}
                        color={appStyles.typographyStyle2}
                      >
                        {doc?.rating}
                      </Typography>
                    </Stack>
                  </Stack>
                </CardContent>
              </Card>
              </Stack>
            </Stack>
            <Typography
              variant="h4"
              align="left"
              className={styles.bold}
              color={appStyles.typographyStyle2}
            >
              {doc?.about}
            </Typography>
            <Typography
              variant="h5"
              align="left"
              className={styles.subcaption_Doctordetails}
              data-testid="about_us_desc"
            >
              {doc?.desc}
            </Typography>
            <span className={styles.dummy_row} />
            <Typography
              variant="h4"
              align="left"
              sx={{ fontWeight: 600, color: appStyles.typographyStyle2 }}
            >
              {doc?.degree}
            </Typography>
            <Typography variant="h5" align="left" color="primary">
              {doc?.qualification}
            </Typography>

            <span className={styles.dummy_row} />
            <Typography
              variant="h4"
              align="left"
              className={styles.bold}
              color={appStyles.typographyStyle2}
            >
              {doc?.language}
            </Typography>
            <Typography
              variant="h5"
              align="left"
              sx={{
                color: "rgb(144 160 158)",
              }}
            >
              {doc?.lang}
            </Typography>

            <span className={styles.dummy_row} />
            <Typography
              variant="h4"
              align="left"
              className={styles.bold}
              color={appStyles.typographyStyle2}
            >
              {doc?.services}
            </Typography>
            <Stack direction="row" spacing={2}>
              <Typography variant="h5" className={styles.font_styling}>
                {doc?.teeth}
              </Typography>
              <Typography variant="h5" className={styles.font_styling}>
                {doc?.hydra}
              </Typography>
              <Typography variant="h5" className={styles.font_styling}>
                {doc?.root}
              </Typography>
            </Stack>

            <span className={styles.dummy_row} />

            <Typography
              variant="h4"
              align="left"
              className={styles.bold}
              color={appStyles.typographyStyle2}
            >
              {doc?.centre}
            </Typography>

            <Card className={styles.location_card_outline}>
              <CardContent className={styles.location_card}>
                <Stack spacing={3}>
                  {doc?.loc?.map((location) => (
                    <Stack direction="row" justifyContent="space-between">
                      <Stack>
                        <Typography
                          variant="h5"
                          align="left"
                          className={styles.bold}
                          color={appStyles.typographyStyle2}
                        >
                          {location}
                        </Typography>
                      </Stack>
                      <Stack direction="row">
                        <Typography
                          variant="h5"
                          align="left"
                          className={styles.bold_direction}
                          color={appStyles.typographyStyle2}
                        >
                          {doc?.direction}
                        </Typography>
                        <DirectionsIcon
                          size="small"
                          className={styles.direction_icon}
                          color="success"
                        />
                      </Stack>
                    </Stack>
                  ))}
                </Stack>
              </CardContent>
            </Card>

            <span className={styles.dummy_row} />
            <Typography
              variant="h4"
              align="left"
              className={styles.bold}
              color={appStyles.typographyStyle2}
            >
              {doc?.appotype.type}
            </Typography>
            <Stack direction="row" spacing={3}>
              <Stack direction="row" spacing={1} className={styles.small_card}>
                <img color="error" alt="" src={onsite} />
                <span className={styles.captions}>{doc?.appotype.onsite}</span>
              </Stack>

              <Stack direction="row" spacing={1} className={styles.small_card}>
                <img color="error" alt="" src={call} />
                <span className={styles.captions}> {doc?.appotype.call}</span>
              </Stack>

              <Stack direction="row" spacing={1} className={styles.small_card}>
                <img className={styles.homenew} alt="" src={inclinic} />
                <span className={styles.captions}>{doc?.appotype.clinic}</span>
              </Stack>
            </Stack>
            <hr className={styles.hr_line} />
            <span className={styles.dummy_row} />
            <Stack direction="row" className={styles.pricevalueprice}>
              <Typography
                variant="h4"
                align="left"
                className={styles.bold}
                color={appStyles.typographyStyle2}
              >
                {doc?.appotype?.price}
              </Typography>
              <span className={styles.span_width} />
              <del className={styles.striked_divv}>
                <h4 className={styles.striked_captionss}> &#8377;2499</h4>
              </del>
              <Typography
                align="left"
                sx={{
                  color: "rgb(67 148 116)",
                  fontWeight: "bold",
                }}
                variant="h4"
              >
                &#8377;{doc?.appotype?.amount}
              </Typography>
            </Stack>
          </Stack>

          <DoctorScheduleForNew
            openLocationMenu={openLocationMenu}
            openCheckoutPage={openCheckoutPage}
            url={`/consult/${cat}/doctor_details/checkout`} 
            layout="consult"         />
        </Stack>
        <Drawer
          anchor="right"
          open={locationMenu}
          onClose={() => handleCloseLocationMenu()}
          sx={{
            display: { xs: "block", sm: "block" },
            "& .MuiDrawer-paper": { boxSizing: "border-box", width: "100%" },
          }}
        >
          <LocationMenu onClose={() => handleCloseLocationMenu()} />
        </Drawer>
      </Stack>
    </>
  );
}
