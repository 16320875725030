import pic1 from "../assets/images/healthreport.svg";
import pic2 from "../assets/images/chest_xray.svg";
import pic3 from "../assets/images/liverfunction.svg";
import pic4 from "../assets/images/hemogram.svg";
import pic5 from "../assets/images/cbc.svg";
import pic6 from "../assets/images/ppbs.svg";
import pic7 from "../assets/images/TotalIge.svg";
import pic8 from "../assets/images/Hba.svg";
import pic9 from "../assets/images/AMH.svg";


export const myreports = {
  report: "My Reports",
  reportType: "Report Type",
  reset: "Reset",
  apply: "Apply Filter",
  duration: "Duration",
};

export const MyReport = [
  {
    name: "Health Report",
    desc: "Sep 08, 2022",
    icon:pic1,
  },
  {
    name: "Chest X-Ray",
    desc: "Sep 08, 2022",
    icon:pic2,
  },
  {
    name: "Liver Function Test (LFT)",
    desc: "Sep 08, 2022",
    icon:pic3,
  },
  {
    name: "Hemogram",
    desc: "Sep 10, 2022",
    icon:pic4,
  },
  {
    name: "Complete Blood Count / Hemogram (CBC)",
    desc: "Sep 08, 2022",
    icon:pic5,
  },
  {
    name: "Post Prandial Blood Sugar (PPBS)",
    desc: "Sep 08, 2022",
    icon:pic6,
  },
  {
    name: "Total IgE",
    desc: "Sep 08, 2022",
    icon:pic7,
  },
  {
    name: "HbA1c (Glycosylated Hemoglobin)",
    desc: "Sep 08, 2022",
    icon:pic8,
  },
  {
    name: "Anti-Mullerian Hormone (AMH)",
    desc: "Sep 08, 2022",
    icon:pic9,
  },
];
