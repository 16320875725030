import gyno from '../assets/images/Gyne.svg'
import Pediatrician from '../assets/images/Pediatrician.svg'
import Orthopedic from '../assets/images/Orthopedic.svg'
import Gastroenterologist from '../assets/images/Gastroenterologist.svg'
import Diabetologist from '../assets/images/diabetologist.svg'
import ENT from '../assets/images/ENT.svg'
import Radiologist from '../assets/images/Radiologist.svg'
import Psychiatrist from '../assets/images/Psychiatrist.svg'
import PsychologistCounselor from '../assets/images/Psychologist & Counselor.svg'
import Nephrologist from '../assets/images/Nephrologist.svg'
import Dermatologist from '../assets/images/Dermatologist.svg'
import SportsPhysiotherapist from '../assets/images/Sports Physiotherapist.svg'
import Neurosurgeon from '../assets/images/Neurosurgeon.svg'
import cardio from '../assets/images/Cardiologist.svg'
export const subCategories = [
    {
      name: "Gynecologist",
      icon: gyno ,
    },
    {
      name: "Pediatrician",
      icon: Pediatrician,
    },
    {
      name: "Orthopedic",
      icon: Orthopedic,
    },
    {
      name: "Cardiologist",
      icon: cardio,
    },
    {
      name: "Gastroenterologist",
      icon: Gastroenterologist,
    },
    {
      name: "Diabetologist",
      icon: Diabetologist,
    },
    {
      name: "ENT",
      icon: ENT,
    },
    {
      name: "Radiologist",
      icon: Radiologist,
    },
    {
      name: "Psychiatrist",
      icon: Psychiatrist,
    },
    {
      name: "Psychologist & Counselor",
      icon: PsychologistCounselor,
    },
    {
      name: "Nephrologist",
      icon: Nephrologist,
    },
    {
      name: "Dermatologist",
      icon: Dermatologist,
    },
    {
      name: "Sports Physiotherapist",
      icon:SportsPhysiotherapist,
    },
    {
      name: "Neurosurgeon",
      icon:Neurosurgeon,
    }
    
  ];