import React from "react";
import { InputBase, alpha, styled, Box } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import styles from "../styling/GenericSearch.module.css";
const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(1, 1),
  height: "100%",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  float: "right",
}));
const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    width: "auto",
  },
}));
const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "35ch",
    },
  },
}));

const GenericSearch = (props) => {
  return (
    <>
      <Box className={styles.generic_search_boxes}>
        <Search
          className={styles.generic_search_health}
          data-testid="search_field"
        >
          <StyledInputBase
            placeholder={
              props.placeholder !== undefined && props.placeholder !== null
                ? props.placeholder
                : "Search for lab tests"
            }
            inputProps={{ "aria-label": "search" }}
          />
          <SearchIconWrapper>
            <SearchIcon />
          </SearchIconWrapper>
        </Search>
      </Box>
    </>
  );
};

export default GenericSearch;
