import React, { useState } from "react";
import {
  Typography,
  Stack,
  Box,
  List,
  ListItem,
  ListItemText,
  Button,
} from "@mui/material";
import ServicesCard from "./OurServicesCard";
import { everydayHealth } from "../json/EverydayHealth";
import styles from "../styling/EverydayServices.module.css";
import NewGenericPopup from "./NewGenericPopup";
import { useSharedData } from "../Context/ShareContext";
import { useNavigate } from "react-router-dom";

const EverydayServices = () => {
  const [listItem, setListItem] = useState("Consultations");
  const { showPopup, setShowPopup,setActiveButtonIndex,setIndexOfSubheader } = useSharedData();
  const navigate = useNavigate()

  const selectedLocation = { NAME: "Mumbai" };

  const navigateContact = () => {
    setIndexOfSubheader(false);
    setActiveButtonIndex(2)

    
    navigate("/login/contactus", {
      state: {
        selectedLocation
      },
    });
  };
  return (
    <Box data-testid="everyday-services" id="services_scroll">
      <Stack className={styles["services-box"]} spacing={3}>
        <Typography variant="h2" className={styles["services-bold"]}>
          {everydayHealth?.SERVICES?.OUR}
        </Typography>
        <Stack direction={{ xs: "column", sm: "row" }} spacing={4}>
          <Stack>
            <List
              component="nav"
              aria-label="mailbox folders"
              className={styles.list_root_styling}
            >
              {everydayHealth?.SERVICES?.LIST?.map((item) => {
                return (
                  <ListItem
                    className={
                      listItem === item
                        ? styles.listitemactive
                        : styles.listnotactive
                    }
                    key={item}
                  >
                    <ListItemText
                      primary={
                        <Typography
                          variant="body2"
                          className={styles["services-bold"]}
                        >
                          {item}
                        </Typography>
                      }
                      onClick={() => setListItem(item)}
                    />
                  </ListItem>
                );
              })}
            </List>
          </Stack>

          {listItem && (
            <Stack spacing={3}>
              {listItem === "Consultations" && (
                <Stack spacing={2} justifyContent="space-evenly">
                  {everydayHealth?.SERVICES?.CONSULTATIONS?.map((item, index) =>
                    index % 2 === 0 ? (
                      <Stack
                        direction={{ xs: "column", sm: "column", md: "row" }}
                        spacing={2}
                        key={index}
                      >
                        <ServicesCard
                        
                          icon={item?.ICON}
                          title={item?.NAME}
                          description={item?.DESCRIPTION}
                        />
                        {index + 1 <
                          everydayHealth?.SERVICES?.CONSULTATIONS?.length && (
                          <ServicesCard
                            icon={
                              everydayHealth?.SERVICES?.CONSULTATIONS[index + 1]
                                ?.ICON
                            }
                            title={
                              everydayHealth?.SERVICES?.CONSULTATIONS[index + 1]
                                ?.NAME
                            }
                            description={
                              everydayHealth?.SERVICES?.CONSULTATIONS[index + 1]
                                ?.DESCRIPTION
                            }
                          />
                        )}
                      </Stack>
                    ) : null
                  )}
                </Stack>
              )}

              {listItem === "Out-Patient Care" && (
                <Stack spacing={2} justifyContent="space-evenly">
                  {everydayHealth?.SERVICES?.OUTPATIENT?.map((item, index) =>
                    index % 2 === 0 ? (
                      <Stack
                        direction={{ xs: "column", sm: "column", md: "row" }}
                        spacing={2}
                        key={index}
                      >
                        <ServicesCard
                          icon={item?.ICON}
                          title={item?.NAME}
                          description={item?.DESCRIPTION}
                        />
                        {index + 1 <
                          everydayHealth?.SERVICES?.CONSULTATIONS?.length && (
                          <ServicesCard
                            icon={
                              everydayHealth?.SERVICES?.OUTPATIENT[index + 1]
                                ?.ICON
                            }
                            title={
                              everydayHealth?.SERVICES?.OUTPATIENT[index + 1]
                                ?.NAME
                            }
                            description={
                              everydayHealth?.SERVICES?.OUTPATIENT[index + 1]
                                ?.DESCRIPTION
                            }
                          />
                        )}
                      </Stack>
                    ) : null
                  )}
                </Stack>
              )}

              {listItem === "Lab Tests & Packages" && (
                <Stack spacing={2} justifyContent="space-evenly">
                  {everydayHealth?.SERVICES?.LABTEST?.map((item, index) =>
                    index % 2 === 0 ? (
                      <Stack
                        direction={{ xs: "column", sm: "column", md: "row" }}
                        spacing={2}
                        key={index}
                      >
                        <ServicesCard
                          icon={item?.ICON}
                          title={item?.NAME}
                          description={item?.DESCRIPTION}
                        />
                        {index + 1 <
                          everydayHealth?.SERVICES?.LABTEST?.length && (
                          <ServicesCard
                            icon={
                              everydayHealth?.SERVICES?.LABTEST[index + 1]?.ICON
                            }
                            title={
                              everydayHealth?.SERVICES?.LABTEST[index + 1]?.NAME
                            }
                            description={
                              everydayHealth?.SERVICES?.LABTEST[index + 1]
                                ?.DESCRIPTION
                            }
                          />
                        )}
                      </Stack>
                    ) : null
                  )}
                </Stack>
              )}

              {listItem === "Emergency Medical Care" && (
                <Stack spacing={2} justifyContent="space-evenly">
                  {everydayHealth?.SERVICES?.EMERGENCY?.map((item, index) =>
                    index % 2 === 0 ? (
                      <Stack
                        direction={{ xs: "column", sm: "column", md: "row" }}
                        spacing={2}
                        key={index}
                      >
                        <ServicesCard
                          icon={item?.ICON}
                          title={item?.NAME}
                          description={item?.DESCRIPTION}
                        />
                        {index + 1 <
                          everydayHealth?.SERVICES?.EMERGENCY?.length && (
                          <ServicesCard
                            icon={
                              everydayHealth?.SERVICES?.EMERGENCY[index + 1]
                                ?.ICON
                            }
                            title={
                              everydayHealth?.SERVICES?.EMERGENCY[index + 1]
                                ?.NAME
                            }
                            description={
                              everydayHealth?.SERVICES?.EMERGENCY[index + 1]
                                ?.DESCRIPTION
                            }
                          />
                        )}
                      </Stack>
                    ) : null
                  )}
                </Stack>
              )}

              <Button
                className={styles["services-btn"]}
                onClick={navigateContact}
                // onClick={() => navigate('/login/contactus')}

              >
                 {/* {everydayHealth?.SERVICES?.ALL}  */}
                Book an Appointment
              </Button>
            </Stack>
          )}
        </Stack>
      </Stack>
      {/* {showPopup && (
        <NewGenericPopup
          SubTxt="Thank you for visiting, the new website is temporarily unavailable, we will redirect you to our existing website for now. Thank you for your patience and understanding ."
          SubBtnTxt="Cancel"
          ShowSubBtn={true}
          PlaceholderTxt={""}
        />
      )} */}
    </Box>
  );
};

export default EverydayServices;
