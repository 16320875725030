import pic1 from '../assets/images/Health Silver.svg'
import pic2 from '../assets/images/Health Gold.svg'
import pic3 from '../assets/images/Health Gold Plus.svg'
import pic4 from '../assets/images/Health Platinum.svg'
import pic5 from '../assets/images/womancare.svg'
import pic6 from '../assets/images/woman care.svg'
import pic7 from '../assets/images/Diabetic.svg'
import pic8 from '../assets/images/Diabetics.svg'
import pic9 from '../assets/images/Hypertension Plan.svg'
import pic10 from '../assets/images/DoctorVisit.svg'
import pic11 from '../assets/images/DoctorVisitone.svg'
import pic12 from '../assets/images/DoctorVisitTwo.svg'
import pic13 from '../assets/images/Dentalmember.svg'
import pic14 from '../assets/images/DentalMembers.svg'
export const MemberCards = [
  {
    name: "Health Silver",
    icon: pic1,
    validity: "1 Year",
    price: "3,499",
  },
  {
    name: "Health Gold",
    icon: pic2,
    validity: "1 Year",
    price: "5,499",
  },
  {
    name: "Health Gold Plus",
    icon: pic3,
    validity: "1 Year",
    price: "7,499",
  },
  {
    name: "Health Platinum",
    icon: pic4,
    validity: "3 Months Validity",
    price: "9,999",
  },
  {
    name: "Golden Years",
    icon: pic2,
    validity: "1 Year",
    price: "19,999",
  },
  {
    name: "Women Care (Age: 30-40)",
    icon: pic5,
    validity: "1 Year",
    price: "4,999",
  },
  {
    name: "Women Care (Age above 40)",
    icon: pic6,
    validity: "1 Year",
    price: "6,999",
  },
  {
    name: "Diabetic Protect",
    icon: pic7,
    validity: "1 Year",
    price: "5,499",
  },
  {
    name: "Diabetic Secure",
    icon: pic8,
    validity: "1 Year",
    price: "7,499",
  },
  {
    name: "Hypertension Plan",
    icon: pic9,
    validity: "1 Year",
    price: "12,499",
  },
  {
    name: "Doctor Home Visit @ 5",
    icon: pic10,
    validity: "1 Year",
    price: "3,499",
  },
  {
    name: "Doctor Home Visit @ 10",
    icon: pic11,
    validity: "1 Year",
    price: "5,499",
  },
  {
    name: "Doctor Home Visit @ 15",
    icon: pic12,
    validity: "1 Year",
    price: "7,499",
  },
  {
    name: "Dental Membership Family of 2",
    icon: pic13,
    validity: "1 Year",
    price: "3,499",
  },
  {
    name: "Dental Membership Family of 4",
    icon: pic14,
    validity: "1 Year",
    price: "5,499",
  },
];
