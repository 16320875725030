import styles from "../../styling/Herosection.module.css";
import React,{useState} from "react";
import {
  Typography,
  Stack,
  Button,
  Box,
  Card,
  CardContent,
} from "@mui/material";
import pic2 from "../../assets/Curved_underline_hero.png";
import { hero } from "../../json/HeroSectionWords";
import { useNavigate } from "react-router-dom";
import { useSharedData } from "../../Context/ShareContext";

const HeroSection = () => {
  
  const navigate = useNavigate()
 
  const handleBookAnAppointment=()=>{

    
    navigate('/consult')
    setValue(2)
  
  }
 
  const {setValue} = useSharedData(); // Use the context here

    
  return (
    <Box>
      <Stack className={styles.container}>
        <Card className={styles.bannerCard} elevation={0}>
          <Stack className={styles.bannerStack}>
            <CardContent className={styles.cardContent}>
              <Box className={styles.bannerContent}>
                <Typography align="left" className={styles.bannerTitle}>
                  {hero?.title}
                </Typography>
                <Typography align="left">
                  <Box
                    component="img"
                    src={pic2}
                    className={styles.curved_line}
                  />
                </Typography>

                <Typography align="left">
                  <Button className={styles.bannerBtn} data-testid='book_an_appointment' onClick={handleBookAnAppointment}>
                    {hero?.button }
                  </Button>
                </Typography>
              </Box>
            </CardContent>
          </Stack>
        </Card>
      </Stack>
    </Box>
  );
};

export default HeroSection;