import urgentcare from "../assets/emergency-siren.png";
import { Typography, Stack, Box } from "@mui/material";
import theme from "./Themeify";
import GenericSearch from "../molecules/GenericSearch";
import GenericSearchList from "../molecules/GenericSearchList";
import styles from "../styling/OutPatientCare.module.css";
import defaultStyles from "../styling/DefaultStyles.module.css";
import bgimage from "../assets/dental bg.png";
import Bread from "../molecules/Bread";
import { ucare } from "../json/urgent_care";
import { useNavigate } from "react-router-dom";

export const GreenBanner = (props) => {
  return (
    <Box>
      <Box>
        <Box
          className={styles.bg_img}
          style={{
            backgroundImage: `url(${bgimage})`,
          }}
        >
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography
              className={styles.typourgent_care}
              color={theme.palette.light.main}
            >
              {props.text}
            </Typography>

            <Box component="img" alt="Image" src={props.img} height="100px" />
          </Stack>
        </Box>
      </Box>
    </Box>
  );
};

export const UrgentCare = () => {
  const SearchClick = (i) => {};
  const navigate = useNavigate();
  return (
    <>
      <Stack className={defaultStyles.max_bread_14_5}>
        <Bread />
      </Stack>

      <GreenBanner text={ucare?.title} img={urgentcare} />

      <Stack className={styles.urgentcare_stack}>
        <GenericSearch placeholder={ucare?.search} />

        <Typography
        >
          <GenericSearchList
            title={ucare?.recentsearch?.title}
            searchlist={ucare?.recentsearch?.recentSearch_Urgent}
            showclear={true}
            clickable={true}
            url="/outpatient_care/urgent_care/abdomen_pain"
          />
        </Typography>

        <Typography>
          <GenericSearchList
            title={ucare?.recommendation?.title}
            SearchClick={SearchClick}
            searchlist={ucare?.recommendation?.recommendationSearch_Urgent}
          />
        </Typography>
        <Typography>
          <GenericSearchList
            title={ucare?.popular?.title}
            searchlist={ucare?.popular?.popularSearch_Urgent}
            SearchClick={SearchClick}
          />
        </Typography>
      </Stack>
    </>
  );
};
export default UrgentCare;
