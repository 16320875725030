import React from 'react';
import styles from "../styling/HealthProfile.module.css";

const RoundedBox = ({ header, value }) => {
  return (
    <div className={styles["roundedboxbox"]}>
      <div className={styles["boxcontentbox"]}>
        <div className={styles["headertext"]}>{header}</div>
        <div className={styles["valuetext"]}>{value}</div>
      </div>
    </div>
  );
};

export default RoundedBox;
