import React from "react";
import { useState } from "react";
import { Stack, Box } from "@mui/system";
import { Drawer, Grid } from "@mui/material";
import styles from "../styling/LabtestCheckOut.module.css";
import { AppointmentInclinic } from "../molecules/AppointmentInclinic";
import { AppointmentInclinicPayment } from "../molecules/AppointmentInclinicPayment";
import Bread from "../molecules/Bread";
import defaultStyles from "../styling/DefaultStyles.module.css";
import { PaymentDetailsCard } from "../molecules/UrgentCareCheckout";
import ApplyPopupMenu from "../molecules/ApplyPopupMenu";
import MemberMenu from "../molecules/MemberMenu";
import EditMemberMenu from "../molecules/EditMemberMenu";
import AddFamilyMemberFlyH from "../molecules/AddFamilyMemberFlyH";


function InclinicAppointment() {
  const [showMemberMenu, setMemberMenu] = useState(false);
  const [showMemberList, setMemberList] = useState(false);
  const [applyCoupon, setApplyCoupon] = useState(false);
  const [showAddFamilyMember, setShowAddFamilyMember] = useState(false);
  let drawerObj = {
    display: { xs: "block", sm: "block" },
    "& .MuiDrawer-paper": { boxSizing: "border-box", width: 350 },
  };


  const handleMemberMenu = () => {
    setMemberMenu((prev) => !prev);
    setMemberList(false);
    setApplyCoupon(false);
    setShowAddFamilyMember(false);
  };

  const handleMemberListMenu = () => {
    setMemberList((prev) => !prev);
    setMemberMenu(false);
    setApplyCoupon(false);
    setShowAddFamilyMember(false);
  };

  const handleCouponMenu = () => {
    setApplyCoupon((prev) => !prev);
    setMemberMenu(false);
    setMemberList(false);
    setShowAddFamilyMember(false);
  };

  const handleFamilyView = () => {
    setShowAddFamilyMember((prev) => !prev);
    setMemberMenu(false);
    setMemberList(false);
    setApplyCoupon(false);
  };
  

  return (
    <>
      <Stack className={defaultStyles.max_bread_14_5}>
        <Bread />
      </Stack>
      <Stack
        direction={{ xs: "column", sm: "column", md: "row" }}
        justifyContent="center"
        data-testid="appointment_payment_container"
        spacing={4}
        className={styles.auto_margin}
      >
        <Stack flex={11}>
          <AppointmentInclinic />
        </Stack>

        <Stack flex={10}>
          <PaymentDetailsCard
            handleCouponMenu={handleCouponMenu}
            handleMemberMenu={handleMemberMenu}
          />
        </Stack>
      </Stack>

      <Drawer
    anchor="right"
    open={showMemberMenu}
    onClose={handleMemberMenu}
    sx={drawerObj}
  >
    <MemberMenu
      onClose={handleMemberMenu}
      handleMemberListMenu={handleMemberListMenu}
      handleFamilyView={handleFamilyView}
    />
  </Drawer>

  <Drawer
    anchor="right"
    open={showMemberList}
    onClose={handleMemberListMenu}
    sx={drawerObj}
  >
    <EditMemberMenu
      handleFamilyView={handleFamilyView}
      onClose={handleMemberListMenu}
    />
  </Drawer>

  <Drawer
    anchor="right"
    open={applyCoupon}
    onClose={handleCouponMenu}
    sx={drawerObj}
  >
    <ApplyPopupMenu onClose={handleCouponMenu} />
  </Drawer>

  <Drawer
    anchor="right"
    open={showAddFamilyMember}
    onClose={handleFamilyView}
    sx={drawerObj}
  >
    <AddFamilyMemberFlyH onClose={handleFamilyView} />
  </Drawer> 
    </>
  );
}

export default InclinicAppointment;
