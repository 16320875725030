import * as React from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import styles from "../styling/InclusiveHealthcare.module.css";
import { landing_dynamic } from "../json/landingDynamic";
import LandingCards from "../atoms/LandingCards";

export default function InclusiveHealthcare() {
  return (
    <Box data-testid="inclusive-health">
      <Stack className={styles["main_inclusive"]} spacing={3}>
        <Typography variant="h2" className={styles["inclusiveheader"]}>
          {landing_dynamic?.BANNER_7?.TITLE}
        </Typography>
        <LandingCards numberOfCards={2} />
      </Stack>
    </Box>
  );
}
