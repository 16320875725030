import React, { useState } from "react";
import {
  Typography,
  Stack,
  Button,
  Avatar,
  Grid,
  Paper,
  Card,
  CardContent,
  CardHeader,
  Box,
  Divider,
  Link,
} from "@mui/material";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import TranslateOutlinedIcon from "@mui/icons-material/TranslateOutlined";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import theme from "../components/Themeify";
import { SiteContent } from "../assets/content/SiteContent";
import styles from "../styling/Summary.module.css";
import { useSharedData } from "../Context/ShareContext";
import { CheckCircle } from "@mui/icons-material";
import { PaymentInfo } from "../molecules/PaymentInfo";
import { UseCoupon } from "../molecules/UseCoupon";
import callAppointmentIcon from "../assets/callAppointmentIcon.svg";
import userIcon from "../assets/greenUser.svg";
import documentIcon from "../assets/document.svg";
import specialityIcon from "../assets/speciality.svg";
import downloadIcon from "../assets/downloadIcon.svg";
import cancelIcon from "../assets/cancelIcon.svg";
import helpIcon from "../assets/helpIcon.svg";
import callingIcon from "../assets/callingIcon.svg";
import star from "../assets/star.png";
import pic2 from "../assets/images/doctorRajAvasthi.png";
import { SidebarPopup } from "../molecules/SidebarPopup";
import CustomDrawer from "../molecules/CustomDrawer";
import cross from '../assets/crossbar.svg'
import { useNavigate } from "react-router-dom";
import { AreYouSurePopup } from "../molecules/AreYouSurePopup";

const InfoWizard = ({ text, icon, severity = "success" }) => {
  let wizard_container = styles.wizard_container;
  switch (severity) {
    case "warning":
      wizard_container = `${wizard_container} ${styles.orange_color_wizard}`;
      break;
    case "error":
      wizard_container = `${wizard_container} ${styles.red_color_wizard}`;
      break;
    default:
      wizard_container = `${wizard_container} ${styles.green_color_wizard}`;
  }
  return (
    <Box className={wizard_container}>
      <Box>
        <img src={icon} alt={"wizard_icon"} />
      </Box>
      <Box>
        <Typography className={styles.info_wizard_text}>{text}</Typography>
      </Box>
    </Box>
  );
};

const Summary = ({
  PageTitle,
  ID,
  CardTitle,
  CardTime,
  CardStatus,
  appointmentType,
}) => {

  const [drawerIsOpen, setDrawerIsOpen] = useState(false);
  const [revieOpen, setReviewOpen]= useState(false);
  const { setShowSummary, showCancel, setShowCancel,setModalValue } = useSharedData();

  const navigate = useNavigate();
  const handleClick = () => {
    navigate("/help");
  };

  const handleBack = () => {
    setShowSummary(false);
  };

  return (
    <>
      <Stack direction="row" onClick={handleBack}>
        <Button
          id="basic-button"
          startIcon={
            <Avatar className={styles.back_icon_container}>
              <KeyboardBackspaceIcon className={styles.back_icon} />
            </Avatar>
          }
          className={styles.back_btn}
        >
          <Typography variant="h5" color={theme.palette.info.grey}>
            {SiteContent.BACK}
          </Typography>
        </Button>
      </Stack>
      <Stack className={styles.summary_stack}>
        <Typography className={styles.summary_title} variant="h4">
          {PageTitle}
        </Typography>
        <Typography className={styles.summary_id} variant="h5">
          {ID}
        </Typography>
      </Stack>
      <Stack className={styles.summary_card_stack}>
        <Stack>
          <Typography className={styles.summary_card_title}>
            {CardTitle}
          </Typography>
          <Typography className={styles.summary_card_desc}>
            {CardTime}
          </Typography>
        </Stack>
        <Typography
         className={
          CardStatus === "Completed"
            ? styles.summary_card_statusnew
            : CardStatus === "Cancelled"
            ? styles.summary_card_cancelled 
            : styles.summary_card_status
        }
        >
          { CardStatus === "Cancelled" ? <img className={styles.summary_card_status_icon} src={cross} alt='cross' />:<CheckCircle className={styles.summary_card_status_icon} />}
          {CardStatus}
        </Typography>
      </Stack>

      <Grid className={styles.container_grid} container spacing={2}>
        <Grid item xs={12} sm={12} md={5} className={styles.content_left_grid}>
          <Card className={styles.appointment_details_card}>
            <CardHeader
              avatar={
                <Avatar
                  className={styles.call_appointment_avatar}
                  variant="rounded"
                  src={callAppointmentIcon}
                />
              }
              title="Call Appointment"
              className={styles.appointment_card_header}
            />
            <CardContent className={styles.appointment_card_content}>
              <Box className={styles.appointment_timing_details}>
                <Box className={styles.appointment_date_time}>
                  <Typography
                    variant="subtitle2"
                    gutterBottom
                    className={styles.appointment_date_time_text}
                  >
                    Today, 17 Nov
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    gutterBottom
                    className={styles.appointment_date_time_text}
                  >
                    At 1:30 PM
                  </Typography>
                </Box>
                <Box>
                  <Link
                    href="#"
                    underline="always"
                    className={styles.appointment_date_time_text}
                  >
                    {SiteContent.CHANGE_DATE_AND_TIME_LINK_TEXT}
                  </Link>
                </Box>
              </Box>
              <Divider />
              <Box className={styles.specialists_details}>
                <Stack className={styles.mainStack}>
                  <Grid item xs={6}>
                    <Stack className={styles.subStack}>
                      <Avatar className={styles.medical_service_avatar}>
                        <Avatar src={specialityIcon} />
                      </Avatar>
                      <Stack className={styles.subStack2}>
                        <Typography className={styles.stack_title}>
                          Specialists
                        </Typography>
                        <Typography className={styles.stack_desc}>
                          Orthopedic
                        </Typography>
                      </Stack>
                    </Stack>
                  </Grid>
                </Stack>
              </Box>
              <Divider />
              <Box className={styles.doc_details}>
                <Stack className={styles.mainStack}>
                  <Grid item xs={12}>
                    <Stack className={styles.subStack}>
                      <Avatar
                        alt="Avatar"
                        src={pic2}
                        variant="rounded"
                        className={styles.account_avatar}
                      />
                      <Stack className={styles.subStack2}>
                        <Typography className={styles.stack_title}>
                          Dr. Raj Avasthi
                        </Typography>
                        <Typography className={styles.stack_desc}>
                          Gyenocologist * 7+ yrs
                        </Typography>
                      </Stack>
                    </Stack>
                  </Grid>
                </Stack>
                <Box className={styles.doc_location_details}>
                  <Box className={styles.doc_location_container}>
                    <LocationOnOutlinedIcon className={styles.location_icon} />
                    <Typography variant="caption" display="block" gutterBottom>
                      Kharghar, +2 More
                    </Typography>
                  </Box>
                  <Box className={styles.doc_location_container}>
                    <TranslateOutlinedIcon className={styles.location_icon} />
                    <Typography variant="caption" display="block" gutterBottom>
                      English, Hindi, Gujrati
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} sm={12} md={7} textAlign={"left"}>
          {appointmentType === "completed" && (
            <Box>
              <Typography className={styles.section_title}>
                E-Prescription
              </Typography>
              <Box className={styles.appointment_details}>
                <Paper
                  className={`${styles.paper_style} ${styles.prescription_box}`}
                >
                  <Stack spacing={2} className={styles.stack_element}>
                    <Avatar
                      src={documentIcon}
                      className={styles.account_icon}
                    />
                    <Typography
                      className={`${styles.stack_title} ${styles.custom_stack_title}`}
                    >
                      E-prescription.pdf
                    </Typography>
                  </Stack>
                </Paper>
              </Box>
            </Box>
          )}

          <Box marginTop={2}>
            <Typography className={styles.section_title}>
              Appointment for
            </Typography>
            <Box className={styles.appointment_details}>
              <Paper className={styles.paper_style}>
                <Stack spacing={2} className={styles.stack_element}>
                  <Avatar src={userIcon} className={styles.account_icon} />
                  <Typography
                    className={`${styles.stack_title} ${styles.custom_stack_title}`}
                  >
                    Yourself only
                  </Typography>
                </Stack>
              </Paper>
            </Box>
          </Box>

          <UseCoupon />
          <PaymentInfo />
      {CardStatus === "Cancelled"    ?       <Box className={styles.display_none}>
          
          </Box>: <Box className={styles.text_align}>
            <Button
              variant="contained"
              className={styles.online_call_btn}
              disabled
            >
              <img src={callingIcon} alt="callingIcon" />
              &nbsp;
              {`Online Call (Start at 11:15am)`}
            </Button>
          </Box>}
        

          <Box marginTop={2}>
            <Divider />
          </Box>

          <Box marginTop={3}>
           {appointmentType==='cancelled' ? 
           <Grid item md={4} > <div onClick={handleClick}>
           <InfoWizard
             severity="warning"
             icon={helpIcon}
             text={"Need Help"}
           />
         </div></Grid>
           
           
             :  <Grid container spacing={3} >
             <Grid item md={4} textAlign={"center"}>
               <InfoWizard icon={downloadIcon} text={"Download"} />
             </Grid>

             {appointmentType === "completed" ? (
               <Grid item md={4} className={styles.text_align_three} >
                 <InfoWizard
                   severity="error"
                   icon={cancelIcon}
                   text={"Cancel Booking"}
                 />
               </Grid>
             ) : (
               <Grid item md={4} className={styles.text_align_two}>
                 <div onClick={() => setShowCancel(true)}>
                   <InfoWizard
                     severity="error"
                     icon={cancelIcon}
                     text={"Cancel Booking"}
                   />
                 </div>
               </Grid>
             )}

             <Grid item md={4} className={styles.text_align_two}>
               <div onClick={handleClick}>
                 <InfoWizard
                   severity="warning"
                   icon={helpIcon}
                   text={"Need Help"}
                 />
               </div>
             </Grid>
             {appointmentType === "completed" && (
               <Grid item md={4} className={styles.text_align_two}>
                 <div onClick={() => setReviewOpen(true)}>
                   <InfoWizard icon={star} text={"Leave a Review"} />
                 </div>
               </Grid>
             )}
           </Grid>}
           
          </Box>
        </Grid>
      </Grid>
      <SidebarPopup
        anchor={"right"}
        isOpen={revieOpen}
        onCloseHandler={() => setReviewOpen(false)}
      />
      {showCancel && <AreYouSurePopup  onCancel={()=>{setShowCancel(false); setDrawerIsOpen(true)}}/>}
      {drawerIsOpen && (
        <CustomDrawer
          anchor={"right"}
          isOpen={drawerIsOpen}
          onCloseHandler={() => setDrawerIsOpen(false)}
          onNextCancel={() => setModalValue(true)}
          title="Cancel Appointment"
          type='cancel'
          
        />
      )}
    </>
  );
};
export default Summary;
