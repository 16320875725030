import React from "react";
import { Typography, Stack, Box, Paper } from "@mui/material";
import { everydayHealth } from "../json/EverydayHealth";
import styles from "../styling/EverydayServices.module.css";

export default function OurApproach() {
  const title = "Our Approach";
  return (
    <Box data-testid="our-approach">
      <Stack className={styles["services-box"]} spacing={3}>
        <Typography variant="h2" className={styles["services-bold"]}>
          {title}
        </Typography>
        <Stack
          direction={{ xs: "column", sm: "row" }}
          spacing={{ xs: 2, sm: 3 }}
        >
          {everydayHealth?.EVERYDAY_HEALTH?.map((item) => (
            <React.Fragment key={item?.ID}>
              <Paper
                elevation={10}
                className={styles["services-paper"]}
                data-testid="service-paper"
              >
                <Stack spacing={1} p={2}>
                  <Box
                    component="img"
                    src={item?.ICON}
                    className={styles["icon-img"]}
                  />
                  <Typography
                    variant="h4"
                    className={styles["services-bold"]}
                    align="left"
                  >
                    {item?.NAME}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    align="left"
                  >
                    {item?.DESCRIPTION}
                  </Typography>
                </Stack>
              </Paper>
            </React.Fragment>
          ))}
        </Stack>
      </Stack>
    </Box>
  );
}
