import React, { useState } from "react";
import {
  Dialog,
  Paper,
  TextField,
  Stack,
  IconButton,
  Typography,
  Divider,
  DialogTitle,
  Box,
} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import sender from "../assets/chat_one.png";
import styles from "../styling/ChatApp.module.css";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import zIndex from "@mui/material/styles/zIndex";
const ChatUI = ({ open, onClose }) => {
  const [messages, setMessages] = useState([
    "Hello",
    "I need a help regarding HbA1c (Glycosylated Hemoglobin) lab test to get some information.",
  ]);
  const [inputMessage, setInputMessage] = useState("");

  const handleSendMessage = () => {
    if (inputMessage.trim() !== "") {
      setMessages([...messages, { sender: "User", text: inputMessage }]);
      setInputMessage("");
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      hideBackdrop={true}
      PaperProps={{
        style: {
          backgroundColor: "transparent",
          boxShadow: "none",
          // borderRadius: '0px 0px 25px 25px',
        },
      }}
      sx={{
        "& .MuiDialog-container": {
          alignItems: "flex-end",
          position: "fixed",
          right: "10px",
          bottom: "20px",
        },
      }}
    >
        <Box className={styles.closeicon}>
        <KeyboardArrowDownIcon className={styles.send_button} onClick={onClose} />
        </Box>
      <Paper className={styles.textalign} >
        
        <Stack direction="row" p={1} spacing={1}>
          <img src={sender} alt="sender" />
          <Stack>
            <Typography className={styles.bot_name}>Shruti Sharma</Typography>
            <Typography className={styles.health}>Health Spring</Typography>
          </Stack>
        </Stack>
        <Divider light fullWidth />
        <Stack>
          <Typography className={styles.timefirst}>
            Today, at 09:06 am
          </Typography>
        </Stack>
        <Stack direction="row" p={1} spacing={1}>
          <img src={sender} alt="sender" className={styles.img_height} />
          <Stack className={styles.bot} p={1}>
            <Typography align="left" className={styles.typo}>
              Hi Elina, I'm the Healthspring Virtual Assistant. How can I help
              you?
            </Typography>
            <Typography className={styles.timestamp_two}>09:06 am</Typography>
          </Stack>
        </Stack>

        <Stack p={2} alignItems="flex-end" spacing={1}>
          {messages.map((message, index) => (
            <div key={index}>
              <Stack direction="row" spacing={1} p={1}>
                <Stack p={1} className={styles.asker}>
                  <Typography className={styles.typo} align="left">
                    {message}
                  </Typography>
                  <Typography className={styles.timestamp} align="right">
                    09:06 am
                  </Typography>
                </Stack>

                <img src={sender} alt="sender" className={styles.img_height} />
              </Stack>
            </div>
          ))}
        </Stack>
        <Stack direction="row" p={1}>
          <TextField
            label="Type a message..."
            value={inputMessage}
            onChange={(e) => setInputMessage(e.target.value)}
            className={styles.input}
            disabled
          />

          <IconButton onClick={handleSendMessage}>
            <SendIcon className={styles.send_button} />
          </IconButton>
        </Stack>
      </Paper>
    </Dialog>
  );
};

export default ChatUI;
