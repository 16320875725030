import HealthSilver from '../assets/images/Health Silver.svg'
import HealthGold from '../assets/images/Health Gold.svg'
import HealthGoldPlus from '../assets/images/Health Gold Plus.svg'
import HealthPlatinum from '../assets/images/Health Platinum.svg'
import Weightlos from '../assets/images/Weightlos.svg'
import WeightLosses from '../assets/images/WeightLosses.svg'
import WeightGain from '../assets/images/WeightGain.svg'
import WeightGain1 from '../assets/images/WeightGain1.svg'
import physiotherapy from '../assets/images/physiotherapy.svg'
import Physiotherapynew from '../assets/images/Physiotherapynew.svg'
import PhysiotherapyHome from '../assets/images/PhysiotherapyHome.svg'
import HomeAdvance from '../assets/images/HomeAdvance.svg'
import weightloss from '../assets/images/newgroup.svg'
export const healthPkgCards = [
  {
    name: "Health Silver",
    icon: HealthSilver,
    validity: "3 Months Validity",
    price: "3,499",
  },
  {
    name: "Health Gold",
    icon: HealthGold,
    validity: "3 Months Validity",
    price: "5,499",
  },
  {
    name: "Health Gold Plus",
    icon: HealthGoldPlus,
    validity: "3 Months Validity",
    price: "7,499",
  },
  {
    name: "Health Platinum",
    icon: HealthPlatinum,
    validity: "3 Months Validity",
    price: "9,999",
  },
  {
    name: "Weight Loss 1 Month Diet Plan",
    icon: weightloss,
    validity: "3 Month Validity",
    price: "1,599",
  },
  {
    name: "Weight Loss 3 Months Diet Plan",
    icon: WeightLosses,
    validity: "3 Months Validity",
    price: "4,999",
  },
  {
    name: "Weight Loss 6 Months Diet Plan",
    icon: Weightlos,
    validity: "6 Months Validity",
    price: "6,999",
  },
  {
    name: "Weight Gain 1 Month Package",
    icon: WeightGain,
    validity: "1 Month Validity",
    price: "1,599",
  },
  {
    name: "Weight Gain 3 Month Package",
    icon: WeightGain1,
    validity: "3 Months Validity",
    price: "4,999",
  },
  {
    name: "Physiotherapy Home Visit 1",
    icon: physiotherapy,
    validity: "3 Months Validity",
    price: "1,599",
  },
  {
    name: "Physiotherapy Home Visit 6",
    icon: Physiotherapynew,
    validity: "3 Months Validity",
    price: "6,999",
  },
  {
    name: "Physiotherapy Home Visit 12",
    icon: PhysiotherapyHome,
    validity: "3 Months Validity",
    price: "6,999",
  },
  {
    name: "Home Basic",
    icon: PhysiotherapyHome,
    validity: "3 Months Validity",
    price: "3,499",
  },
  {
    name: "Home Advance",
    icon: HomeAdvance,
    validity: "3 Months Validity",
    price: "5,499",
  },
];
