import React from "react";
import { Grid, Typography, Card, CardContent, Stack, Box } from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ViewInArIcon from "../assets/microscope.svg";
import styles from "../styling/HealthCard.module.css";
import theme from "../components/Themeify";
import useAppStyles from "../hooks/useAppStyles";
import { healthPkgCards } from "../json/healthPkgCards";
import { useNavigate } from "react-router-dom";

const HealthCard = () => {
  const navigate = useNavigate();
  const appStyles = useAppStyles();

  const handleCheckoutClick = () => {
    navigate("/test_and_packages/health_check_packages/checkout");
  };

  return (
    <>
      {healthPkgCards?.map((card, index) => {
        return (
          <React.Fragment key={`health_card_${index}`}>
            <Card
              data-testid="health_card_container"
              className={styles["health-card"]}
              key={index}
            >
              <CardContent sx={{ backgroundColor: "rgb(244 246 248)" }}>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={10}>
                    <Box>
                      <Stack direction="row" columnGap={2}>
                       <img src={card.icon} className={styles.imagecard} />
                        <Typography
                          className={styles.cardname}
                          data-testid={"card_title"}
                          align="left"
                          sx={{
                            fontWeight: 600,
                            color: appStyles.typographyStyle2,
                            mt: 3,
                          }}
                          variant="h3"
                        >
                          {card?.name}
                        </Typography>
                      </Stack>
                      <Stack
                        direction="row"
                        spacing={2}
                        mt={2}
                        justifyContent="flex-start"
                        className={styles["small-cards"]}
                        data-testid={"validity_text"}
                      >
                        <img src={ViewInArIcon} alt=""/>
                        <span className={styles.captions}>{card?.validity}</span>
                      </Stack>
                    </Box>
                  </Grid>

                  <Grid item xs={2}>
                    <Stack alignItems="end" spacing={2} className={styles["icons-stack"]}>
                      <ArrowForwardIosIcon
                        size="medium"
                        className={styles["icons-ouline"]}
                        onClick={handleCheckoutClick}
                      />
                      <Typography
                      className={styles["typo-ouline"]}
                        variant="h4"
                        data-testid="price_text"
                      >
                        &#8377;{card?.price}
                      </Typography>
                    </Stack>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </React.Fragment>
        );
      })}
    </>
  );
};

export default HealthCard;
