import React from "react";
import reactiveprotein from "../assets/reactiveprotein.png";

export const ReactiveProtein = () => {
  return (
    <>
      <img src={reactiveprotein} alt="reactiveprotein"></img>
    </>
  );
};
export default ReactiveProtein;
