import React, { useEffect } from "react";
import { Stack, Box } from "@mui/material";
import { ContactForm } from "../molecules/ContactForm";
import styles from "../styling/ContactUs.module.css";
import ContactCenterLocations from "../molecules/ContactCenterLocations";
import GetInTouch from "../molecules/GetInTouch";
import { StyledEngineProvider } from "@mui/material/styles";
import { useLocation } from "react-router-dom";
export const ContactUs = () => {

  const location = useLocation();
  const selectedLocation = location?.state && location?.state?.selectedLocation;
  useEffect(() => {
      if(selectedLocation){
        window.scrollTo(0, 1000);
      }
      else{
        window.scrollTo(0,0)
      }
    
    
   
  }, [selectedLocation]);
  return (
    <StyledEngineProvider injectFirst>
      <Stack direction="column" className={styles.contact_us}>
        <Stack direction={{ xs: "column", sm: "row" }} alignContent="left">
          <Box className={styles.left_container} data-testid="get_in_touch">
            <GetInTouch />
          </Box>

          <Box className={styles.right_container} data-testid="contact_us_form_container">
            <ContactForm />
          </Box>
        </Stack>

        <ContactCenterLocations selectedLocations={selectedLocation} />
      </Stack>
    </StyledEngineProvider>
  );
};
