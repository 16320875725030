import React from "react";
import { Grid, Typography, Card, CardContent, CardMedia } from "@mui/material";
import styles from "../styling/CategoryCard.module.css";
import useAppStyles from "../hooks/useAppStyles";
import { Link } from "react-router-dom";

export default function TestPackageHealthCheck({ category }) {
  const appStyles = useAppStyles();
  return (
    <>
      <Grid item xs={6} md={3} sm={6} data-testid="test_package_health_check">
        <Link
          to={category.path}
          className={styles.linkNoUnderline}
          data-testid="test_package_health_link"
        >
          <Card
            className={styles.category_card}
            sx={{
              backgroundColor: `${category.color}`,
            }}
          >
            <CardMedia
              component="img"
              className={styles.op_card}
              alt={category.alt}
              image={category.image}
              data-testid="test_package_image"
            />
            <CardContent className={styles.card_content}>
              <Typography
                variant="h4"
                sx={{ color: appStyles.typographyStyle2 }}
                color="text.secondary"
                gutterBottom
                className={styles.card_text}
                data-testid="test_package_title"
              >
                {category.name}
              </Typography>
            </CardContent>
          </Card>
        </Link>
      </Grid>
    </>
  );
}
