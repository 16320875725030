import React from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import ArrowOutwardOutlinedIcon from "@mui/icons-material/ArrowOutwardOutlined";
import { Fab } from "@mui/material";
import styles from "../styling/Cards.module.css";
import { useNavigate } from "react-router-dom";
import {blogs_dynamic} from "../json/BlogsDynamic";
import { SiteContent } from "../assets/content/SiteContent";

export default function Cards({ numberOfCards }) {
  const slicedCards =  blogs_dynamic?.BANNER_2?.MEDIA_CARDS?.slice(0, numberOfCards);
   const navigate = useNavigate()
  const renderCard = (card, index) => (
    <Card key={card?.ID} className={styles["photo-card"]} elevation={1}>
      <Box className={styles["card-media-container"]}>
        <CardMedia
          className={styles["blog-card-pic"]}
          component="img"
          image={card?.IMAGE}
          alt="card_img"
        />
      </Box>
      <Stack className={styles["blog-category-stack"]}>
        <Typography className={styles["blog-stack-text"]}>
          {card?.CATEGORY}
        </Typography>
      </Stack>
      <CardContent className={styles["content-photo-card"]}>
        <Fab className={styles["fab"]}>
          <Stack>
            <Typography variant="h4" fontWeight="bold">
              {card?.DAY}
            </Typography>
            <Typography variant="body2" fontWeight="bold">
              {card?.MONTH}
            </Typography>
          </Stack>
        </Fab>
        <Stack spacing={1}>
          <Typography align="left" className={styles["blog-title"]}>
            {card?.TITLE}
          </Typography>
          <Typography
            className={styles.desc}
            color="text.secondary"
            align="left"
          >
            {card?.DESCRIPTION}
          </Typography>
        </Stack>
      </CardContent>
      <CardActions>
        <Button size="small" onClick={()=>navigate('/login/blogsreadmore')}>
          <Typography textTransform="none" fontWeight="bold">
            {SiteContent.READ_MORE}
          </Typography>
        </Button>
        <ArrowOutwardOutlinedIcon className={styles["blogsnewArrows"]} />
      </CardActions>
    </Card>
  );

  const renderCardStack = () => {
    return slicedCards?.map((card, index) => (
      index % 3 === 0 && (
        <Stack
          key={card?.ID}
          direction={{ xs: "column", sm: "row" }}
          alignItems="center"
          spacing={2}
          mb={2}
          justifyContent="space-between"
        >
          <React.Fragment key={card?.ID}>
            {renderCard(card, index)}
            {slicedCards[index + 1] && renderCard(slicedCards[index + 1], index + 1)}
            {slicedCards[index + 2] && renderCard(slicedCards[index + 2], index + 2)}
          </React.Fragment>
        </Stack>
      )
    ));
  };

  return (
    <Box data-testid="blog-card">
      <Stack className={styles["blog-cards-stack"]}>
        <Stack direction="column" spacing={8}>
          {renderCardStack()}
        </Stack>
      </Stack>
      
    </Box>
  );
}
