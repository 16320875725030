import React from "react";
import { Avatar, Stack, Typography } from "@mui/material";
import styles from "../styling/LabtestCheckOut.module.css";
import Divider from "@mui/material/Divider";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import appoint from "../assets/images/ico10.png";
import { RadioButton } from "../atoms/RadioButton";
import {payment} from "../json/payment"
export const AppointmentInclinicPayment = () => {
  return (
    <>
      <Stack align="left" className={styles.lab_test_appointments_main_ones}>
        <Stack>
          <Typography
            variant="h2"
            className={styles.lab_test_appointments_typo_one}
            mb={2}
          >
            {payment?.pay}
          </Typography>
        </Stack>
        <Divider mb={2} />
        <Stack>
          <Typography
            variant="h3"
            className={styles.lab_test_appointments_typo_one}
            mt={2}
            mb={2}
          >
            {payment?.appo}
          </Typography>
        </Stack>
        <Stack className={styles.inclinis_tab_stack}>
          <Stack direction="row" spacing={2} justifyContent="space-between">
            <Stack direction="row" spacing={2} alignItems="center">
              <Avatar clasName={styles.image_ico10_new} src={appoint} alt="" />
              <Typography
                className={styles.inclinic_appointment_right_typo}
                fontWeight="bold"
                variant="h4"
                align="left"
              >
                {payment?.you}
              </Typography>
            </Stack>

            <Stack>
              <RadioButton />
            </Stack>
          </Stack>
          <Divider variant="middle"  />
          <Stack direction="row" spacing={2} justifyContent="space-between" >
            <Stack direction="row" spacing={2} alignItems="center">
              <Avatar clasName={styles.image_ico10_new} src={appoint} alt=""  />
              <Typography
                fontWeight="bold"
                variant="h4"
                align="left"
                className={styles.inclinic_appointment_right_typo}
              >
                {payment?.you}
              </Typography>
            </Stack>

            <Stack>
              <RadioButton />
            </Stack>
          </Stack>
        </Stack>

        <Divider className={styles.appointment_clinic_divider_inner}  />
        <Stack className={styles.lab_test_pmtDetails_stack}>
          <Stack className={styles.lab_test_spacerClass_stack}>
            <Typography className={styles.lab_test_pkgQty_stack}>
              {payment?.coupon}
            </Typography>
            <ArrowForwardIosIcon />
          </Stack>
        </Stack>
        <Typography className={styles.lab_test_descHeader_stack}>
          {payment?.payInfo}
        </Typography>
        <Stack className={styles.lab_test_pmtinfo_stack}>
          <Stack className={styles.lab_test_finalInvoiceDtls_stack}>
            <Stack className={styles.lab_test_spacerClass_stack}>
              <Typography className={styles.lab_test_pkgDtls_stack}>
                {payment?.total}
              </Typography>
              <Typography className={styles.lab_test_pkgQty_stack}>
                &#8377;{payment?.price}
              </Typography>
            </Stack>
            <Stack className={styles.lab_test_spacerClass_stack}>
              <Typography className={styles.lab_test_pkgDtls_stack}>
                {payment?.tax}
              </Typography>
              <Typography className={styles.lab_test_pkgQty_stack}>
                &#8377;{payment?.quatity}
              </Typography>
            </Stack>
          </Stack>
          <Stack className={styles.lab_test_spacerClass_stack}>
            <Typography className={styles.lab_test_pkgQty_stack}>
              {payment?.grand}
            </Typography>
            <Typography className={styles.lab_test_pkgTotal_stack}>
              &#8377;{payment?.totalprice}
            </Typography>
          </Stack>
        </Stack>
        <Stack className={styles.lab_test_chkkbutton_stack} direction="row">
          <Typography className={styles.lab_test_chkoutt_stack}>
            {payment?.checkout}
          </Typography>
          <ArrowForwardIosIcon className={styles.lab_test_arowoutlined_stack} />
        </Stack>
      </Stack>
    </>
  );
};
