import xray from "../assets/images/xrayhealthpackage.png";
import microscope from "../assets/images/microscopehealthpackage.png";
import crown from "../assets/images/crownhealthpackage.png";
import doctor from "../assets/images/doctorhealthpackage.png";

export const pkgcategories = [
  {
    name: "Health Check Packages",
    color: "#e1f5f1",
    icon: "#31b79c",
    path: "/test_and_packages/health_check_packages",
    checkoutpath: "/test_and_packages/health_check_packages/checkout",
    bannertxt: "Health Check Packages",
    image: doctor
  },
  {
    name: "Memberships",
    color: "#f9e4e5",
    icon: "#eb3d43",
    path: "/test_and_packages/membership",
    checkoutpath: "/test_and_packages/membership/checkout",
    bannertxt: "Let's Buy a Membership",
    image: crown
  },
  {
    name: "Lab Tests",
    color: "#ffece2",
    icon: "#eb7333",
    path: "/test_and_packages/lab_tests",
    checkoutpath: "/test_and_packages/lab_tests/checkout",
    bannertxt: "Laboratory Tests",
    image: microscope
  },
  {
    name: "X-rays and Scans",
    color: "#b3e5fc",
    icon: "#2196f3",
    path:"/test_and_packages/xray",
    checkoutpath: "/test_and_packages/xray/checkout",
    bannertxt: "X-rays and Scans",
    image: xray

  },
];
