export const filter = {
  filter: {
    reset: "Reset",
    by: "Filter By",
    apply: " Apply Filter",
  },
  loc: "Centre Location",
  appo: {
    type: " Appointment Type",
    onsite: "On Site",
    call: "Call",
    inclinic: "In Clinic",
  },
  exp: `Experience Level (Year)`,
  gender: {
    title: "Gender",
    male: "Male",
    female: "Female",
  },
  price: "Price",
};
