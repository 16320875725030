import { Link } from "react-router-dom";
import { AppBar, Tab, Tabs, Typography, styled, Stack } from "@mui/material";
import { MyAccountSection } from "./MyAccountSection";
import styles from "../styling/TabSection.module.css";

function LinkTab(props) {
  return <Tab component={Link} to={props.pathname} {...props} />;
}

const useStyles = styled((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function TabSection({ a11yProps, value, setValue }) {
  const classes = useStyles();
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <div className={classes.root}>
      <AppBar position="static" className={styles.appbar_subheader_navbar}>
        <Stack direction="row" className={styles.appbar_stack}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="nav tabs example"
          >
            <LinkTab
              label={<Typography variant="h5">Home</Typography>}
              pathname="/home"
              {...a11yProps(0)}
            />
            <LinkTab
              label={<Typography variant="h5">Test & Packages</Typography>}
              pathname="/test_and_packages"
              {...a11yProps(1)}
            />
            <LinkTab
              label={<Typography variant="h5">Consult</Typography>}
              pathname="/consult"
              {...a11yProps(2)}
            />
            <LinkTab
              label={<Typography variant="h5">Out-Patient Care</Typography>}
              pathname="/outpatient_care"
              {...a11yProps(3)}
            />
            <LinkTab
              label={<Typography variant="h5">Contact Us</Typography>}
              pathname="/contactus"
              {...a11yProps(4)}
            />
          </Tabs>
          <Stack direction="row">
            <MyAccountSection setValue={setValue} />
          </Stack>
        </Stack>
      </AppBar>
    </div>
  );
}
