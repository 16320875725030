import React from "react";
import { Paper, Typography, Stack, Box } from "@mui/material";
import styles from "../styling/WhyCard.module.css";

const WhyHealthCard = ({ icon, title, description }) => {
  return (
    <Paper elevation={1} className={styles.why_paper} >
      <Stack p={3} className={styles.why_width} direction="row" spacing={2} alignContent="center" >
        <Stack  alignItems="center" >
          <Box component="img" src={icon} className={styles.why_icon_img} />
          
        </Stack>
        <Stack>
        <Typography  align="left" className={styles.why_bold}>
            {title}
          </Typography>
        

        <Typography
          
          align="left"
          className={styles.why_service_typo}
        >
          {description}
        </Typography>
        </Stack>
      </Stack>

    </Paper>
  );
};

export default WhyHealthCard;
