import React from "react";
import {
  Grid,
  Typography,
  Box,
  CardContent,
  Stack,
  Divider,
} from "@mui/material";
import styles from "../styling/ActiveCard.module.css";
import { Appointment } from "../json/Apponintment";

export const MyOrderHistory = () => {
  const title="My Order History"
  return (
    <Box>
      <Typography className={styles["accinfo-title"]} variant="h4">
        {title}
      </Typography>
      {Appointment?.map((card, index) => (
        <div key={index}>
          <CardContent>
            <Stack direction="row" justifyContent="space-between">
                <Stack className={styles["myorder-subStack"]}>
                  
                <Box
                    component="img"
                    src={card?.icon}
                    className={styles["BoxStyle"]}
                  
                  />
                  <Stack className={styles["myorder-subStack2"]}>
                    <Typography className={styles["myorder-typoTitle"]}>
                      {card?.name}
                    </Typography>
                    <Stack direction={{xs:"row",sm:"row"}}>
                      <Typography className={styles["myorder-typoDesc"]}>
                        {card?.Doctor}
                      </Typography>
                      <Typography className={styles["myorder-typoDot"]}> • </Typography>
                      <Typography className={styles["myorder-typoDesc"]}>
                        {card?.desig}
                      </Typography>
                    </Stack>
                  </Stack>
                </Stack>
                <Stack className={styles["myorder-subStack3"]}>
                  <Typography
                    className={
                      card?.name.includes("Booked")
                        ? styles["myorder-typoMoneyBooked"]
                        : styles["myorder-typoMoneyCancelled"]
                    }
                  >
                    {card?.money}
                  </Typography>
                  <Typography className={styles["myorder-typoDate"]}>
                    {card?.date}
                  </Typography>
                </Stack>
            </Stack>
          </CardContent>
          {index !== Appointment?.length - 1 && <Divider variant="middle" />}
        </div>
      ))}
    </Box>
  );
};
