import React from "react";
import {
  Card,
  CardMedia,
  Stack,
  Typography,
  Divider,
  Box,
  useMediaQuery,
} from "@mui/material";
import pic from "../assets/images/newimgone.png"
import LinkedInIcon from "../assets/images/linkedin.png";
import styles from "../styling/team.module.css";
import NewGenericPopup from "./NewGenericPopup";
import { useSharedData } from "../Context/ShareContext";
import { linkedinProfiles } from "../json/linkedinProfiles";




const TeamCard = ({ image, name, designation, description, setdirection }) => {
  const isScreenSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const { showPopup, setShowPopup } = useSharedData();
  const openLinkedInProfile = () => {
    const linkedinUrl = linkedinProfiles[name];
    if (linkedinUrl) {
      window.open(linkedinUrl, "_blank");
    }
  };
  return (
    <Box data-testid="team-card">
      <Card elevation={0}>
        <Stack
          data-testid="team-card-stack"
          direction={isScreenSmall ? "column" : setdirection}
          alignItems="center"
          justifyContent="space-between"
        >
          <CardMedia
            component="img"
            image={image}
            alt="Image"
            className={styles["team-img"]}
          />
          <Stack flexWrap="wrap" spacing={1} className={styles.testimonials}>
            <Typography variant="h2" fontWeight="bold" align="left">
              {name}
            </Typography>
            <Stack direction="row" spacing={1}>
              <Typography
                color="text.secondary"
                className={styles["team-card-designation"]}
              >
                {designation} &bull;
              </Typography>

              <img src={LinkedInIcon} alt='' className={styles["Linkedin-icon"]} 
               onClick={openLinkedInProfile}
                sx={{cursor:'pointer'}}/>
            </Stack>
            <Box
              data-testid="coma-icon"
              component="img"
              src={pic}
              className={styles["team-coma-icon"]}
            ></Box>
            <Typography
              color="text.secondary"
              align="left"
              className={styles["team-desc"]}
            >
              {description}
            </Typography>
          </Stack>
        </Stack>
      </Card>
      <Divider variant="middle" className={styles["my-custom-divider"]} />
      {/* {showPopup && (
        <NewGenericPopup
          SubTxt="Thank you for visiting, the new website is temporarily unavailable, we will redirect you to our existing website for now. Thank you for your patience and understanding ." 
          SubBtnTxt="Cancel"
          ShowSubBtn={true}
          PlaceholderTxt={""}
        />
      )} */}
    </Box>
  );
};

export default TeamCard;
