import React from "react";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import theme from "../components/Themeify";
import styles from "../styling/CheckoutCard.module.css";


const CheckoutCard = (props) => {
  return (
    <Card className={styles["checkoutCard"]}>
      <CardHeader
        avatar={<CustomAvatar icon={props.icon}/>}
        title={<Header txt={props.header} />}
        subheader={<SubHeader txt={props.subheader} />}
        className={styles["chkoutCardHeader"]}
      />
      <CardMedia
        component="img"
        height="auto"
        image={props.img}
        alt="Card Image"
      />
    </Card>
  );
};

const Header = (props) => {
  return (
    <Typography
      variant="h2"
      sx={{
        color: theme.palette.info.contrastText,
        fontWeight: 550,
        padding: "1rem",
        width: "60%",
      }}
    >
      {props.txt}
    </Typography>
  );
};

const SubHeader = (props) => {
  return (
    <Typography
      variant="h4"
      sx={{
        color: theme.palette.info.contrastText,
        padding: "1rem",
        width: "61%",
      }}
    >
      {props.txt}
    </Typography>
  );
};

const CustomAvatar = (props) => {
  return (
    <Avatar
      sx={{
        height: "80px",
        width: "80px",
        marginBottom: "4rem",
      }}
      aria-label="icon"
    >
      <img src={props.icon} alt="appleicon" />
    </Avatar>
  );
};

export default CheckoutCard;
