import { Stack } from "@mui/system";
import React from "react";
import { Typography } from "@mui/material";
import styles from "../styling/AppointmentChcekout.module.css";
import teethcircle from "../assets/teethicon_two.png";
import Divider from "@mui/material/Divider";
import { Card, CardContent } from "@mui/material";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import inclinic from "../assets/inclinic.png";
import location from "../assets/location.png";
import { useNavigate } from "react-router-dom";

export const AppointmentInclinic = () => {
  const date = "Today, 17 Nov 2022";
  const time = "At 1:30 PM";
  const navigate = useNavigate();
  const navigateToUrl = () => {
    navigate("/outpatient_care/dental_care/teeth_cleaning_polishing");
  };
  return (
    <Card
      className={`${styles.doc_card} ${styles.card_view} `}
      key={1}
      data-testid="appointment_card"
      elevation={0}
    >
      <CardContent>
        <Stack
          className={`${styles.header} ${styles.grid_view}`}
          direction="row"
          alignItems="center"
        >
          <IconView img={inclinic} isBig={true} />
          <span className={`${styles.on_site_text}`}>InClinic Appointment</span>
        </Stack>

        <Stack className={`${styles.date_time_parent}`} direction="row">
          <Stack className={styles.date}>
            <Stack direction="row" spacing={{ xs: 0.5, sm: 1 }}>
              <CalendarMonthIcon   className={styles.icon_fix}/>
              <Typography className={`${styles.day_txt}`} align="left">
                {date}
              </Typography>
            </Stack>
            <span
              className={`${styles.green_color}`}
              onClick={() => navigateToUrl()}
            >
              Change date & Time
            </span>
          </Stack>
          <Stack direction="row" spacing={{ xs: 0.5, sm: 1 }}>
            <AccessTimeIcon className={styles.icon_fix} />
            <Typography className={`${styles.day_txt}`} align="left">
              {time}
            </Typography>
          </Stack>
        </Stack>

        <Stack spacing={2} className={styles.doc_stack}>
          <Stack direction="row" spacing={2}>
            <Stack>
              <img
                src={teethcircle}
                className={styles.avatar_icon_small}
                alt="img"
              />
            </Stack>

            <Stack spacing={0} alignSelf="center">
              <Stack direction="column">
                <Typography align="left" fontWeight="bold">
                  Dental Care
                </Typography>
              </Stack>
              <Stack direction="row" spacing={2}>
                <Typography align="left" className={styles.desc_txt}>
                  Teeth Cleaning & Polishing
                </Typography>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
        <Divider className={styles.divider_margin} />
        <Stack spacing={2} className={styles.doc_stack}>
          <Stack direction="row" spacing={2}>
            <Stack>
              <img src={location} alt="img" />
            </Stack>

            <Stack spacing={0} alignSelf="center">
              <Stack direction="row" spacing={1.5}>
                <Typography align="left" fontWeight="bold">
                  Nerul
                </Typography>
              </Stack>
              <Stack direction="row" spacing={2}>
                <Typography align="left" className={styles.desc_txt_dental}>
                  Shop No.19-21, Gr. Floor, Meredien Apartment, Sector 6 Nerul
                  Rd, Navi Mumbai 400706
                </Typography>
              </Stack>
              <Typography className={styles.get_direction}>
                Get Directions
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );
};

const IconView = ({ img, isBig = false, className = "" }) => {
  return (
    <img
      alt="Avatar"
      src={img}
      className={
        className !== ""
          ? className
          : `${isBig ? styles.iconViewBig : styles.iconView}`
      }
    />
  );
};
