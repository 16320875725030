import React from "react";
import {
  Box,
  Stack,
  Typography,
  IconButton,
  SvgIcon,
  Divider,
} from "@mui/material";
import {
  Facebook,
  LocationOn,
  Phone,
  Email,
} from "@mui/icons-material";
import mainlogo from "../assets/company_logo.svg";
import theme from "../components/Themeify";
import YouTubeIcon from "@mui/icons-material/YouTube";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { SiteContent } from "../assets/content/SiteContent";
import { useNavigate,Link } from "react-router-dom";

import styles from "../../src/styling/footer.module.css";
import NewGenericPopup from "./NewGenericPopup";
import { useSharedData } from "../Context/ShareContext";

export const Footer = () => {
  const { showPopup, setShowPopup, setIndexOfSubheader, setActiveButtonIndex } =
    useSharedData();

  const navigate = useNavigate();
  const handleServices_two = () => {
    navigate("/login/everydayhealth");
    setIndexOfSubheader(0);
    setActiveButtonIndex(false);

    setTimeout(() => {
      var element = document.getElementById("services_scroll");

      element?.scrollIntoView({ behavior: "smooth" });
    }, 500);
  };
  const handleLocation = () => {
    navigate("/login/everydayhealth");
    setIndexOfSubheader(0);
    setActiveButtonIndex(false);

    setTimeout(() => {
      var element = document.getElementById("box_center");

      element?.scrollIntoView({ behavior: "smooth" });
    }, 500);
  };
  const handleNavigate = (path, index) => {
    setIndexOfSubheader(index);
    setActiveButtonIndex(false);
    navigate(path);
  };
  const openInNewTab = (url) => {
    window.open(url, "_blank", "noreferrer");
  };
  const handleNavigateUpper = (path, index) => {
    setActiveButtonIndex(index);
    setIndexOfSubheader(false);
    navigate(path);
  };
  const handlePrivacy=()=>{
    setActiveButtonIndex(10);
    setIndexOfSubheader(false);
    navigate("/login/policy")


  }
  const handleTerms=()=>{
    setActiveButtonIndex(10);
    setIndexOfSubheader(false);
    navigate("/login/terms")


  }
  const handleServices = () => {
    navigate("/login/corporatehealth");

    setTimeout(() => {
      let element = document.getElementById("map_center");

      element?.scrollIntoView({ behavior: "smooth" });
    }, 500);
  };
  const handlePhoneClick = (phoneNumber) => {
    window.location.href = `tel:${phoneNumber}`;
  };

  const handleEmailClick = (emailAddress) => {
    window.location.href = `mailto:${emailAddress}`;
  };
  return (
    <Box className={styles["footer-box"]}>
      <Stack
        direction={{ xs: "column", sm: "column", md: "row" }}
        justifyContent="space-between"
        spacing={2}
        pt={12}
        pb={7}
        className={styles["footer-stack"]}
      >
        <Stack>
          <Stack direction="row" mb={2} className={styles.img_stack_main}>
            <Stack className={styles.responsive_logo}>
              <img
                src={mainlogo}
                className={styles.main_logo}
                alt="healthfarm logo"
              />
            </Stack>
          </Stack>
          <Stack direction="row" spacing={1} alignItems="flex-start">
            <IconButton>
              <LocationOn sx={{ color: theme.palette.primary.dark }} />
            </IconButton>
            <Stack alignSelf="flex-end">
              <Typography className={styles["text-typo2-bold"]} pt={1}>
                {SiteContent.HEAD_OFFICE}
              </Typography>
              <Typography
                className={styles["text-typo2-lean"]}
                color={theme.palette.text.secondary}
              >
                {SiteContent.ADDRESS_LINE_1} <br />
                {SiteContent.ADDRESS_LINE_2}
              </Typography>
            </Stack>
          </Stack>
          <Stack direction="row" spacing={1}>
            <IconButton>
              <Phone sx={{ color: theme.palette.primary.dark }} />
            </IconButton>
            <div
              className={styles.mouseHover}
              onClick={() => {
                handlePhoneClick(SiteContent.FOOTER_CONTACT_NO);
              }}
            >
              <Typography
                className={styles["text-typo2"]}
                color={theme.palette.text.secondary}
                alignSelf="center"
              >
                {SiteContent.FOOTER_CONTACT_NO}
              </Typography>
            </div>
          </Stack>
          <Stack direction="row" spacing={1}>
            <IconButton>
              <Email sx={{ color: theme.palette.primary.dark }} />
            </IconButton>
            <div
              className={styles.mouseHover}
              onClick={() => {
                handleEmailClick(SiteContent.FOOTER_EMAIL);
              }}
            >
              <Typography
                className={styles["text-typo2"]}
                color={theme.palette.text.secondary}
                alignSelf="center"
              >
                {SiteContent.FOOTER_EMAIL}
              </Typography>
            </div>
          </Stack>
        </Stack>

        <Stack direction="row" className={styles.space}>
          <Stack spacing={1.5} className={styles.space}>
            <Typography className={styles["text-typo1"]} mb={2}>
              {SiteContent.COMPANY}
            </Typography>

            <Typography
              className={styles["text-typo2"]}
              color={theme.palette.text.secondary}
              onClick={handleServices_two}
            >
              {SiteContent.SERVICES}
            </Typography>

            <Typography
              className={styles["text-typo2"]}
              color={theme.palette.text.secondary}
              onClick={handleLocation}
            >
              {SiteContent.LOCATION}
            </Typography>
            <Typography
              className={styles["text-typo2"]}
              color={theme.palette.text.secondary}
              onClick={() => handleNavigate("/login/everydayhealth", 0)}
            >
              {SiteContent?.EVERYDAY_HEALTH}
            </Typography>
            <Typography
              className={styles["text-typo2"]}
              color={theme.palette.text.secondary}
              onClick={() => handleNavigate("/login/corporatehealth", 1)}
            >
              {SiteContent?.CORPORATE_HEALTH}
            </Typography>
          </Stack>
          <Stack spacing={1.5} className={styles.newspace}>
            <Typography
              className={styles["text-typo2"]}
              color={theme.palette.text.secondary}
              onClick={() => handleNavigateUpper("/login/aboutus", 0)}
            >
              {SiteContent?.ABOUT_US}
            </Typography>
            <Typography
              className={styles["text-typo2"]}
              color={theme.palette.text.secondary}
              onClick={() => handleNavigateUpper("/login/career", 1)}
            >
              {SiteContent?.CAREERS}
            </Typography>

            <Typography
              className={styles["text-typo2"]}
              color={theme.palette.text.secondary}
              onClick={() =>
                openInNewTab(
                  "https://www.healthspring.in/media/category/latest-news-events/"
                )
              }
            >
              {SiteContent?.NEWS_AND_MEDIA}
            </Typography>

            <Typography
              className={styles["text-typo2"]}
              color={theme.palette.text.secondary}
              onClick={() => handleNavigateUpper("/login/contactus", 2)}
            >
              {SiteContent?.CONTACT}
            </Typography>

            <Typography
              className={styles["text-typo2"]}
              color={theme.palette.text.secondary}
              // onClick={() => handleNavigate("/login/blogs",2)}
              onClick={() => openInNewTab("https://www.healthspring.in/media/category/latest-news-events/")}
            >
              {SiteContent.BLOGS}
            </Typography>
          </Stack>
        </Stack>
        <Stack>
          <Stack className={styles.space}>
            <Typography className={styles["text-typo1"]} mb={2.5}>
              {SiteContent.FOLLOW_US}
            </Typography>
          </Stack>
          <Stack direction="row" spacing={0} justifyContent="space-around">
            <Link to='https://urldefense.com/v3/__https://www.facebook.com/HealthspringIndia__;!!Nyu6ZXf5!u2oskUm8zRXvNrAiFmxpYk05q5lc2ZSJsrqgAU7eo2vZFROfAV1F-fzEDAV1t7DUsvcPeFcmworGKBLJs7U_jPvbdHpNsw$'>
            <IconButton>
              <Facebook
                sx={{ color: theme.palette.primary.dark }}
                className={styles["follow-icons"]}
              />
            </IconButton>
            </Link>
            <Link to='https://urldefense.com/v3/__https://twitter.com/Healthspring___;!!Nyu6ZXf5!u2oskUm8zRXvNrAiFmxpYk05q5lc2ZSJsrqgAU7eo2vZFROfAV1F-fzEDAV1t7DUsvcPeFcmworGKBLJs7U_jPvmUVD9Mg$'>
            <IconButton>
              <TwitterLogo
                sx={{ color: theme.palette.primary.dark }}
                className={styles["follow-icons"]}
              />
            </IconButton>
            </Link>
            {/* <IconButton>
              <YouTubeIcon
                sx={{ color: theme.palette.primary.dark }}
                className={styles["follow-icons"]}
              />
            </IconButton> */}
            <Link to='https://urldefense.com/v3/__https://www.instagram.com/thehealthspring/__;!!Nyu6ZXf5!u2oskUm8zRXvNrAiFmxpYk05q5lc2ZSJsrqgAU7eo2vZFROfAV1F-fzEDAV1t7DUsvcPeFcmworGKBLJs7U_jPvzP0YnNg$'>
            <IconButton>
              <InstagramIcon
                sx={{ color: theme.palette.primary.dark }}
                className={styles["follow-icons"]}
              />
            </IconButton>
            </Link>
            <Link to='https://www.linkedin.com/company/3543986/admin/feed/posts/'>
            <IconButton>

              <LinkedInIcon
                sx={{ color: theme.palette.primary.dark }}
                className={styles["follow-icons"]}
              />
            </IconButton>
            </Link>
          </Stack>
        </Stack>
      </Stack>
      <Divider sx={{ my: 4 }} />
      <Stack
        direction="row"
        justifyContent="space-between"
        className={styles["divider-stack"]}
      >
        <Stack>
          <Typography
            className={styles.footer_last_stack}
            variant="body2"
            color={theme.palette.text.secondary}
          >
          {SiteContent?.COPYRIGHT}
          </Typography>
        </Stack>

        <Stack direction="row" spacing={3} className={styles.newstyle}>
          <Typography
            onClick={handlePrivacy}
            className={styles["text-typo2"]}
            color={theme.palette.text.secondary}
          >
            {SiteContent?.PRIVACY_POLICY}
          </Typography>
          <Typography
            onClick={handleTerms}
            className={styles["text-typo2"]}
            color={theme.palette.text.secondary}
          >
            {SiteContent.TERMS_AND_CONDITIONS}
          </Typography>
          <Typography
            className={styles["text-typo2"]}
            color={theme.palette.text.secondary}
            onClick={() => handleServices()}
          >
            {SiteContent?.SITEMAP}
          </Typography>
        </Stack>
      </Stack>
      {/* {showPopup && (
        <NewGenericPopup
          SubTxt="Thank you for visiting, the new website is temporarily unavailable, we will redirect you to our existing website for now. Thank you for your patience and understanding ."
          SubBtnTxt="Cancel"
          ShowSubBtn={true}
          PlaceholderTxt={""}
        />
      )} */}
    </Box>
  );
};
const TwitterLogo = (props) => {
  return (
    <SvgIcon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 512 512"
        fill="#016E44"
        style={{ filter: "none" }}
      >
        <path d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z" />
      </svg>
    </SvgIcon>
  );
};
