export const payment = {
  pay: "Payment Details",
  appo: "Appointment Is For",
  you: "Yourself Only",
  coupon:"Use Coupon",
  payInfo:"Payment Info",
  total:"Total Price",
  price:"1,999.00",
  tax:"Tax",
  quantity:"0.00",
  grand:"Grand Total",
  totalprice:"1,999.00",
  checkout:"Checkout"
};
