import React, { useState } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { Button, Box, Dialog, DialogContent, DialogActions, Stack } from "@mui/material";
import img from "../assets/video_img.png";
import styles from "../styling/AboutHealthSpring.module.css";
import { useNavigate } from "react-router-dom";
import RipplePlayIcon from "./RipplePlay";
import { SiteContent } from "../assets/content/SiteContent";
import { useSharedData } from "../Context/ShareContext";
import { landing_dynamic } from "../json/landingDynamic";
import vid from "../assets/videos/Healthspring_video.mp4";
import ClearIcon from '@mui/icons-material/Clear';

export default function AboutHealthSpring() {
  const { showPopup, setShowPopup, setIndexOfSubheader, setActiveButtonIndex } = useSharedData();
  const navigate = useNavigate();
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);

  const handleNavigateUpper = (path, index) => {
    setActiveButtonIndex(index);
    setIndexOfSubheader(false);
    navigate(path);
  };

  const handlePlayVideo = () => {
    setIsVideoPlaying(true);
  };

  const handleCloseVideo = () => {
    setIsVideoPlaying(false);
  };

  return (
    <Box data-testid="about-health-spring">
      <Card className={styles["About-spring-card"]} elevation={0}>
        <CardContent>
          <Box className={styles["About-spring-content"]}>
            <Typography className={styles["About-spring-thirteen"]} align="left">
              {landing_dynamic?.BANNER_2?.TITLE?.TITLE_1}
            </Typography>
            <Typography align="left" className={styles["About-spring-title"]}>
              {landing_dynamic?.BANNER_2?.TITLE?.TITLE_2}
            </Typography>
            <Typography color="text.primary" align="left" className={styles["About-spring-subtitle"]}>
              {landing_dynamic?.BANNER_2?.TITLE?.TITLE_3} <br /> <br /> {landing_dynamic?.BANNER_2?.TITLE?.TITLE_4}
            </Typography>
            <Typography align="left">
              <Button className={styles["About-spring-btn"]} onClick={() => handleNavigateUpper("/login/aboutus", 0)}>
                {SiteContent.KNOW_MORE}
              </Button>
            </Typography>
          </Box>
        </CardContent>
        <CardMedia
          component="img"
          alt="About Healthspring"
          image={img}
          className={styles["About-spring-img"]}
        />
        <Box component="div" className={styles.rippleBox} onClick={handlePlayVideo}>
          <RipplePlayIcon className={styles.rippleBox}></RipplePlayIcon>
        </Box>

        <Dialog open={isVideoPlaying} onClose={handleCloseVideo}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              maxWidth: "800px",
              maxHeight:"500px" 
            },
          },
        }} >
          <DialogContent >
            <Stack>
              <ClearIcon   onClick={handleCloseVideo} className={styles.closeicon}/>

            <video controls autoPlay={false} className={styles.video_responsive} >
              <source src={vid} type="video/mp4"  controls/>
            </video> 
            </Stack>
          </DialogContent>
         
        </Dialog>
      </Card>
    </Box>
  );
}
