import React from "react";
import {  Typography, Stack } from "@mui/material";
import styles from "../styling/VitalsBodyParam.module.css";
import { bodyparameter1, bodyparameter2 } from "../json/HealthProfile";

const renderVitalsStack = (bodyParameters) => {
  return bodyParameters.map((item, index) => (
    <Stack bgcolor={item?.bgColor} key={index} className={styles["vitals-subStack"]}>
    <Stack   direction="row">
      {item?.icon}
      <Stack className={styles["vitals-subStack2"]} flex={1}>
        <Typography className={styles["vitals-typoTitle"]}>{item?.title}</Typography>
        <Stack flexDirection={"row"}>
          <Typography className={styles["vitals-typoDesc"]}>{item?.desc} </Typography>
          <Typography className={styles["vitals-typoDesc1"]}>{item?.desc1}</Typography>
        </Stack>
       
      </Stack>
      {item?.normal &&(
          <Typography className={styles.normal} >
            {item.normal}
          </Typography>

        )}
    </Stack>  
    <Stack >
    {item?.charts}
    </Stack>
    </Stack>
  ));
};

export const BodyParameters = () => {
  return (
    <Stack direction={{xs:"column",sm:"row"}} spacing={2}  mt={2}>
      <Stack direction="column" flexGrow={1} spacing={2}>{renderVitalsStack(bodyparameter1)}</Stack>
      <Stack direction="column" flexGrow={1}spacing={2}>{renderVitalsStack(bodyparameter2)}</Stack>
    </Stack>
  );
};

export default BodyParameters;
