import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  AppBar,
  Tab,
  Tabs,
  Typography,
  Stack,
  Button,
  Box,
  Toolbar,
  IconButton,
  Menu,
} from "@mui/material";
import { makeStyles } from "@material-ui/core";
import mainlogo from "../assets/company_logo.svg";
import mobilelogo from "../assets/images/mainlogo.png";
import theme from "../components/Themeify";
import SubHeaderDropDowns from "./SubHeaderDropDowns";
import styles from "../styling/SubheaderTabs.module.css";
import { SiteContent } from "../assets/content/SiteContent";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import MenuItem from "@mui/material/MenuItem";

const pages = [
  { name: "EveryDay Health", id: 1 },
  { name: "Corporate Health", id: 2 },
 
];

function LinkTab(props) {
  return <Tab component={Link} to={props.pathname} {...props} />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiPaper-root": {
      borderRadius: "20px",
    },
  },
}));

const SubHeaderTabs = ({
  a11yProps,
  indexOfSubheader,
  setIndexOfSubheader,
  setActiveButtonIndex,
}) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [anchorElNav, setAnchorElNav] = useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event?.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleMenuClick = (menuId) => {
    if (menuId === 1) {
      navigate("/login/everydayhealth");
    }
    if (menuId === 2) {
      navigate("/login/corporatehealth");
    }
   
    setAnchorElNav(null);
  };

  const handleChange = (event, newValue) => {
    setIndexOfSubheader(newValue);
    setActiveButtonIndex(null);
  };
  function handleLanding() {
    navigate("/login/");
    setActiveButtonIndex(null);
    setIndexOfSubheader(false);
  }
  const openInNewTab = (url) => {
    window.open(url, "_blank", "noreferrer");
  };
  // const handleLogin = () => {
  //   navigate("/login/loginpage");
  // };

  const handleLogin = () => {
    // navigate("/login/loginpage");

    window.open("https://www.healthspring.in/login/", "_blank");  };

  return (
    <AppBar position="static" className={styles.appbar_subheader}>
      <Container maxWidth="xl">
        <Toolbar disableGutters className={styles.subheader_toolbar}>
          <Stack
            direction="row"
            className={styles.logo_landing}
            onClick={handleLanding}
            sx={{ display: { xs: "none", md: "flex" } }}
          >
            <img
              src={mainlogo}
              className={styles.main_logo}
              alt="healthfarm logo"
            />
          </Stack>

          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
              className={styles.icon_button}
            >
              <MenuIcon />
            </IconButton>
            <Stack>
              <Stack className={styles.responsive_logo}>
                <img
                  src={mainlogo}
                  className={styles.main_logo}
                  alt="healthfarm logo"
                  onClick={handleLanding}
                />
              </Stack>
              <Stack className={styles.small_screen_logo}>
                <img
                  src={mobilelogo}
                  className={styles.mobile_logo}
                  alt="healthfarm mobile logo"
                  onClick={handleLanding}
                />
              </Stack>
            </Stack>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
              className={classes.root}
            >
              {pages?.map((page) => (
                <MenuItem
                  key={page?.id}
                  onClick={() => {
                    handleMenuClick(page?.id);
                  }}
                >
                  <Typography variant="h5" color={theme.palette.info.main}>
                    {page?.name}
                  </Typography>{" "}
                </MenuItem>
              ))}
            </Menu>
          </Box>
          <Stack
            direction="row"
            className={styles.logo_landing}
            sx={{ display: { xs: "flex", md: "none" } }}
          >
            <SubHeaderDropDowns setIndexOfSubheader={setIndexOfSubheader} />
          </Stack>
          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "none", md: "flex" },
              justifyContent: "flex-end",
            }}
          >
            <Stack direction="row">
              <SubHeaderDropDowns setIndexOfSubheader={setIndexOfSubheader} />
              <Tabs
                value={indexOfSubheader}
                onChange={handleChange}
                aria-label="nav tabs example"
              >
                <LinkTab
                  label={
                    <Typography variant="h5">
                      {SiteContent.EVERYDAY_HEALTH}
                    </Typography>
                  }
                  pathname="/login/everydayhealth"
                  {...a11yProps(0)}
                />
                <LinkTab
                  label={
                    <Typography variant="h5">
                      {SiteContent.CORPORATE_HEALTH}
                    </Typography>
                  }
                  pathname="/login/corporatehealth"
                  {...a11yProps(1)}
                />
                {/* <LinkTab
                  label={
                    <Typography variant="h5">{SiteContent.BLOGS}</Typography>
                  }
                  pathname="/login/blogs"
                  onClick={() => openInNewTab("https://www.healthspring.in/media/category/latest-news-events/")}
                  {...a11yProps(2)}
                /> */}
                <Typography className={styles.typosnew} variant="h5" 
                 onClick={() => openInNewTab("https://www.healthspring.in/media/category/latest-news-events/")}
                 >{SiteContent.BLOGS}</Typography>
              </Tabs>
            </Stack>
          </Box>

          <Box className={styles.custom_box}>
            <Stack direction="row">
              <Stack className={styles.divider_spacing_class}>
                <Typography variant="h3" className={styles.divider_class} ml={1.5}>
                  |
                </Typography>
              </Stack>
              <Button className={styles.login_btn} onClick={handleLogin}>
                <AccountCircleOutlinedIcon className={styles.account_icon} />
                <Typography variant="h5" className={styles.login_btn_txt}>
                  {SiteContent.HELLO_LOGIN}
                </Typography>
              </Button>
            </Stack>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default SubHeaderTabs;
