import SearchIcon from "@mui/icons-material/Search";
export const RecentSearch = [
{
    icon:<SearchIcon sx={{color:'#6e8280'}}/>,
    val:'X-Ray Ankle Joint AP View'
},
{
    icon:<SearchIcon sx={{color:'#6e8280'}}/>,
    val:'X-Ray Cervical Spine Lateral'
},
{
    icon:<SearchIcon sx={{color:'#6e8280'}} />,
    val:'X-Ray Chest PA View'
},
]
export const Recommendation =[
    {
        icon:<SearchIcon sx={{color:'#6e8280'}}/>,
        val:'X-Ray IVP'
    },
    {
        icon:<SearchIcon sx={{color:'#6e8280'}}/>,
        val:'X-Ray KUB'
    },
    {
        icon:<SearchIcon sx={{color:'#6e8280'}} />,
        val:'X-Ray Micturating Cystourethirogram'
    }
]
export const Popular =[
    {
        icon:<SearchIcon sx={{color:'#6e8280'}}/>,
        val:'X-Ray MCU'
    },
    {
        icon:<SearchIcon sx={{color:'#6e8280'}}/>,
        val:'X-Ray OPG'
    },
    {
        icon:<SearchIcon sx={{color:'#6e8280'}} />,
        val:'X-Ray RGU'
    }
]

export const recent={
    title:"Recent Search",
    clear:"Clear All",
    recommendation:"Recommendation",
    popular:"Popular",
    
}