export const ucare = {
  title: "Urgent Care",
  search: "Search for urgent care",
  recentsearch:{ title: "Recent Search",recentSearch_Urgent: ["Fever", "Abdomen Pain", "Rash"]},
  recommendation:{title:"Recommendation", recommendationSearch_Urgent :["Diarrhea", "Rash", "Abdomen Pain"]},
  popular:{title: "Popular",popularSearch_Urgent : ["Fever", "Diarrhea", "Rash"]}
};

export const vaccination = {
  title: "Let's Find Vaccination",
  search: "Search for urgent care",
  recentsearch:{ title: "Recent Search",recentSearch_Vaccine: ["Blood glucose test", "C-reactive protein (CRP) blood test", "Iron studies blood test"]},
  recommendation:{title:"Recommendation", recommendationSearch_Vaccine :["Blood glucose test", "C-reactive protein (CRP) blood test", "Iron studies blood test"]},
  popular:{title: "Popular",popularSearch_Vaccine : ["Physiotherapy Doctor", "Lab test appointment", "Lab test appointment"]}
};


export const ur = {
  payment: "Payment Details",
  appo: "Appointment is For",
  member: "Member Details",
  select:"Select Member",
  coupon:"Use Coupon",
  pay: "Payment Info",
  check:"Checkout",
  date:"Today, 17 Nov 2022",
  urgent:"Urgent Care",
  abdomen:"Abdomen Pain",
  
};
