import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";

import { Stack, Box } from "@mui/material";
import styles from "../styling/UsBanner.module.css";
import { aboutUs_Dynamic } from "../json/aboutus";

export default function UsBanner() {
  return (
    <Box data-testid="us-banner">
      <Card className={styles["one-card"]} elevation={0}>
        <Stack className={styles["one-stack"]}>
          <CardContent sx={{ p: 0, "&:last-child": { pb: 0 } }}>
            <Stack className={styles.content}>
              <Typography align="left" className={styles["one-title"]}>
                <span className={styles["highbold"]}>
                  {aboutUs_Dynamic?.BANNER_1?.HEADER?.LINE1}
                  <br /> {aboutUs_Dynamic?.BANNER_1?.HEADER?.LINE2}
                </span>
                {aboutUs_Dynamic?.BANNER_1?.HEADER?.LINE3}<br />{aboutUs_Dynamic?.BANNER_1?.HEADER?.LINE4}
              </Typography>
              <Typography align="left" className={styles["one-subtitle"]}>
                {aboutUs_Dynamic?.BANNER_1?.DESCRIPTION}
              </Typography>
            </Stack>
          </CardContent>
        </Stack>
        <CardMedia
          component="img"
          alt="us_image"
          image={aboutUs_Dynamic?.BANNER_1?.IMAGE}
          className={styles["one-img"]}
        />
      </Card>
    </Box>
  );
}
