import React from "react";
import DocCard from "./DocCard";
import { Stack, Typography, Box } from "@mui/material";
import useAppStyles from "../../hooks/useAppStyles";
import { SiteContent } from "../../assets/content/SiteContent";
import styles from "../../styling/RecentReport.module.css";
import { everydayHealth } from "../../json/EverydayHealth";

export default function DocConsultation() {
  const appStyles = useAppStyles();
  return (
    <Box>
      <Stack spacing={3} className={styles.recent_stack}>
        <Stack direction="row" alignContent="center">
          <Typography
            variant="h1"
            align="left"
            className={styles.bold}
            color={appStyles.typographyStyle2}
          >
            {SiteContent.DOCTORS_CONSULTATION}
          </Typography>
        </Stack>
        <Stack
          direction={{ xs: "column", sm: "row" }}
          spacing={1}
          justifyContent="space-between"
          alignItems="center"
        >
          {everydayHealth?.SERVICES?.DOCTORCONSULTATIONS?.map((consult) => (
            <DocCard 
              key={consult?.ID}
              text={consult?.NAME}
              icon={consult?.ICON}
            />
          ))}
        </Stack>
      </Stack>
    </Box>
  );
}
