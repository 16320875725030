import React, {useEffect} from "react";
import { Box, Typography, Stack } from "@mui/material";
import styles from "../styling/OurTeam.module.css";
import TeamCard from "../molecules/TeamCard";
import { aboutUs_Dynamic } from "../json/aboutus";

const OurTeam = () => {

  useEffect(() => {
    window.scrollTo(0,0);
    return () => {
      
    };
  }, []); 
  return (
    <Stack spacing={5}>
      <Box
        className={styles["main-team-container"]}
        data-testid="main-container"
      >
        <Stack>
          <Typography
            className={styles["main-team-title"]}
            variant="h1"
            fontWeight="bold"
            data-testid="main-title"
          >
            Our Team
          </Typography>
        </Stack>
      </Box>

      <Box>
        <Stack
          className={styles["about-team-stack"]}
          data-testid="about-team-stack"
        >
          {aboutUs_Dynamic?.BANNER_3?.TEAMARRAY?.map((item, index) => {
            const descriptionLines = item?.DESCRIPTION.split("\n");
            return (
              <TeamCard
                key={index}
                image={item?.IMAGE}
                name={item?.NAME}
                designation={item?.DESIGNATION}
                description={descriptionLines.map((line, index) => (
                  <p key={index}>{line}</p>
                ))}
                setdirection={item?.SETDIRECTION}
              />
            );
          })}
        </Stack>
      </Box>
    </Stack>
  );
};

export default OurTeam;
