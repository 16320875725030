import React from "react";
import { Typography, Box, Avatar } from "@mui/material";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import  styles from "../styling/BlankPage.module.css";

export const BlankPage = ({ blankImage, mainTxt, subTxt, btnTxt, btnClickHandler }) => {
  const onClickHandler = () => {
    if(btnClickHandler){
      btnClickHandler()
    }
  }
  return (
    <Box className={styles["blankPage-blankBox"]}>
      <Avatar alt="Avatar" src={blankImage} className="blankPage-blankImage" />
      <Typography className={styles["blankPage-blankMainTxt"]}>{mainTxt}</Typography>
      <Typography className={styles["blankPage-blankSubTxt"]}>{subTxt}</Typography>
      {btnTxt && (
        <Typography className={styles["blankPage-blankBtnTxt"]} onClick={onClickHandler}>
          {btnTxt}
          <KeyboardArrowRightIcon />
        </Typography>
      )}
    </Box>
  );
};
