import { Box } from "@mui/material";
import React from "react";
import SubHeaderTabs from "../atoms/SubHeaderTabs";

export const SubHeader = ({
  a11yProps,
  indexOfSubheader,
  setIndexOfSubheader,
  setActiveButtonIndex,
}) => {
  return (
    <Box>
      <SubHeaderTabs
        {...{
          a11yProps,
          indexOfSubheader,
          setIndexOfSubheader,
          setActiveButtonIndex,
        }}
      />
    </Box>
  );
};
