import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Stack } from "@mui/system";
import styles from "../styling/WorkspringHealth.module.css"; 
import pathone from "../assets/images/Path1.png";
import pathtwo from "../assets/images/Path2.png";
import { corporate_dynamic } from "../json/corporateDynamic";


export const WorkSpringHealth = () => {
  return (
    <Box data-testid="workspringhealth">
      <Box className={styles["workhealthspring-box"]}>
        <Stack className={styles["workhealthspring-stack"]}>
          <Typography
            className={styles["workhealthspring-typoone"]}
            variant="h2"
          >
            {corporate_dynamic?.BANNER_6?.TITLE}
          </Typography>
        </Stack>
        <Stack className={styles["workhealthspringthird-stack"]}>
          <img src={pathone} alt=" One" />
        </Stack>
        <Stack className={styles["workhealthspringsec-stack"]}>
          <Typography className={styles["workhealthspring-typo"]} variant="h3">
           {corporate_dynamic?.BANNER_6?.DESCRIPTION}
          </Typography>
        </Stack>
        <Stack className={styles["workhealthspringfour-stack"]}>
          <img className={styles["workhealthiconimage"]} src={pathtwo} alt=" Two" />
        </Stack>
      </Box>
    </Box>
  );
};
