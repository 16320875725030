// HelpCard.js
import React, { useState } from "react";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import { Button, Stack } from "@mui/material";
import AddCommentIcon from "@mui/icons-material/AddComment";
import AddIcCallIcon from "@mui/icons-material/AddIcCall";
import styles from "../styling/HelpCard.module.css";
import ChatApp from "./ChatApp";

const HelpCard = (props) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  let buttonEndIcon = null;

  if (props.buttonText === "Start now") {
    buttonEndIcon = <AddCommentIcon className={styles["icon-bg"]} />;
  } else if (props.buttonText === "Call now") {
    buttonEndIcon = <AddIcCallIcon className={styles["icon-bg"]} />;
  }

  const handleButtonClick = () => {
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  return (
    <Card
      elevation={5}
      sx={{ backgroundColor: props.background }}
      className={styles["h-card"]}
    >
      <Stack
        direction={{ xs: "column", sm: "column", md: "row" }}
        justifyContent="space-between"
        alignItems="center"
        spacing={1}
        p={3}
      >
        <Stack direction="row" spacing={3}>
          <Stack>
            <img src={props.pic} className={styles["imgbg"]} alt="Loading" />
          </Stack>
          <Stack alignSelf="center">
            <Typography variant="h3" fontWeight={600} align="left">
              {props.title}
              <Typography variant="body1" align="left" color="#686868">
                {props.subheader}
              </Typography>
            </Typography>
          </Stack>
        </Stack>

        <Stack>
          <Button
            variant="contained"
            endIcon={buttonEndIcon}
            sx={{
              backgroundColor: props.buttonBg,
              "&.MuiButton-root:hover": { backgroundColor: props.buttonBg },
            }}
            className={styles["help-btn"]}
            onClick={props.buttonText === "Start now" ? handleButtonClick : null}          >
            <Typography
              variant="h5"
              color="white"
              className={styles["help-typo"]}
            >
              {props.buttonText}
            </Typography>
          </Button>
        </Stack>
      </Stack>

      <ChatApp
        open={dialogOpen}
        onClose={handleCloseDialog}
        buttonText={props.buttonText}
      />
    </Card>
  );
};

export default HelpCard;
