import React from "react";
import { Typography, Box } from "@mui/material";
import NotificationImg from "../assets/images/notification_icon.png";
import { BlankPage } from "./BlankPage";
import styles from "../styling/AccountInformation.module.css";
import Notification from "./Notification";
import { MyNotifications } from "../json/Notifications";
import { useSharedData } from "../Context/ShareContext";

export const Notifications = () => {
  const { showNotifications, setShowNotifications } = useSharedData();
  const title="Notification";
  return (
    <Box>
      <Typography className={styles["accinfo-title"]} variant="h4">
        {title}
      </Typography>
      {
        showNotifications ? <>
          {MyNotifications?.map((data, index) => (
            <Notification data={data} key={index} />
           ))}
        </>
        :
        <BlankPage btnClickHandler={()=>setShowNotifications(true)} blankImage={NotificationImg} mainTxt={"No Notifications"} subTxt={"You don't have any notifications at the moment. Adjust your preference to turn on notification"} btnTxt={"Adjust Settings"} />
      }
      
    </Box>
  );
};
