import styles from "../../styling/LabBanner.module.css";
import React from "react";
import {
  Typography,
  Stack,
  Button,
  Box,
  Card,
  CardContent,
} from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useNavigate } from "react-router-dom";
import { useSharedData } from "../../Context/ShareContext";
const LabBanner = () => {
  const test="Laboratory Tests"
  const testDetails="Lipid, Thyroid, Blood, Cardiac, Urine..."
  const book="Book Test"
  const navigate = useNavigate()
  const {setValue} = useSharedData();
  const handleBookTest=()=>{
    
    navigate('/test_and_packages/lab_tests')
    setValue(1)
  }
  return (
    <Box data-testid="lab_banner_wrapper">
      <Stack className={styles.container}>
        <Card className={styles.bannerCard} elevation={0}>
          <CardContent className={styles.cardContent}>
            <Box className={styles.bannerContent}>
              <Typography
                align="left"
                className={styles.bannerTitle}
                data-testid="lab_banner_title"
              >
                {test}
              </Typography>

              <Typography
                align="left"
                className={styles.bannerSubtitle}
                data-testid="lab_banner_desc"
              >
                {testDetails}
              </Typography>

              <Typography align="left">
                <Button className={styles.bannerBtn }  endIcon={<ArrowForwardIosIcon />} onClick={handleBookTest}>{book}
                 
                </Button>
              </Typography>
            </Box>
          </CardContent>
        </Card>
      </Stack>
    </Box>
  );
};

export default LabBanner;
