import React, { useState } from "react";
import styles from "../../styling/ReportCard.module.css";
import {
  Card,
  CardHeader,
  Stack,
  Menu,
  MenuItem,
} from "@mui/material";
import { MoreVert } from "@mui/icons-material";
import useAppStyles from "../../hooks/useAppStyles";
import { SiteContent } from "../../assets/content/SiteContent";
import { useSharedData } from "../../Context/ShareContext";
import { useNavigate } from "react-router-dom";
export default function ReportCard(props) {
  const [anchorEl, setAnchorEl] = useState(null);
  const {setitemSelectedPassed,setValue} = useSharedData();
  const handleClick = (event) => {
    setAnchorEl(event?.currentTarget);
  };
const navigate = useNavigate()
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleNavigateReport = (selected) => {
    setValue(null);
    navigate("/myaccount");
    setitemSelectedPassed(selected)
  };


  const appStyles = useAppStyles();

  return (
    <Stack className={styles["main-stack"]} data-testid="report_card_wrapper">
      <Card elevation={3} className={styles["main-card"]}>
        <CardHeader
          avatar={<img src={props.image} alt='blood' className={styles.avatarblood} />}
          titleTypographyProps={{
            variant: "h4",
            fontWeight: "550",
            align: "left",
          }}
          title={props.name}
          subheaderTypographyProps={{
            variant: "body2",
            align: "left",
            color: appStyles.typographyStyle2,
          }}
          subheader={props.date}
          action={
            <>
              <MoreVert className={styles["three-dot"]} onClick={handleClick} />

              <Menu
                id="menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
              >
                <MenuItem onClick={handleClose}>{SiteContent?.DOWNLOAD_REPORT_LABEL}</MenuItem>
                <MenuItem onClick={handleClose}>{SiteContent?.SHARE_REPORT_LABEL}</MenuItem>
                <MenuItem onClick={() => handleNavigateReport("My Reports")}>{SiteContent?.BOOKING_DETAILS_LABEL}</MenuItem>
              </Menu>
            </>
          }
        />
      </Card>
    </Stack>
  );
}
