import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { Healthform } from "../atoms/Healthform";
import { Stack, Box } from "@mui/material";
import styles from "../styling/HealthSolution.module.css";
import email from "../assets/images/Groupmsg.png";
import { corporate_dynamic } from "../json/corporateDynamic";
export default function HealthSolution({ scrollToRef }) {
  const handleEmailClick = (emailAddress) => {
    window.location.href = `mailto:${emailAddress}`;
  };
  return (
    <Box data-testid="health-solution" ref={scrollToRef}>
      <Stack className={styles.solutionMainStack}>
        <Card className={styles.solutionCard} elevation={0}>
          <Stack className={styles.solutionStack}>
            <CardContent>
              <Box className={styles.solutionContent}>
                <Typography align="left" className={styles.solutionTitle}>
                  {corporate_dynamic?.BANNER_10?.TITLE}
                </Typography>
                <Healthform></Healthform>
              </Box>
            </CardContent>
          </Stack>
          <CardMedia
            component="img"
            alt="healthsolution"
            image={corporate_dynamic?.BANNER_10?.IMAGE}
            className={styles.solutionImg}
          />

          <Box className={styles.email}>
            <Stack direction="row" spacing={1}>
              <Stack className={styles.emailStack}>
                <Box
                  component="img"
                  src={email}
                  alt=""
                  className={styles.icon}
                />
              </Stack>
              <Stack>
                <Typography className={styles.emailt} align="left">
                  {corporate_dynamic?.BANNER_10?.DESCRIPTION}
                  <br />
                  <div className={styles.mouseHover}
                    onClick={() => {
                      handleEmailClick(corporate_dynamic?.BANNER_10?.WEBSITE);
                    }}
                  >
                    <span className={styles.emailBold}>
                      {corporate_dynamic?.BANNER_10?.WEBSITE}
                    </span>
                  </div>
                </Typography>
              </Stack>
            </Stack>
          </Box>
        </Card>
      </Stack>
    </Box>
  );
}
