import React, { useEffect } from "react";

export default function MeetingEvent({
  payload = {
    sdkKey: "tCbsxgw7Q3u_0ob3TYtDQ",
    meetingNumber: 86974976236,
    passWord: "1N8WXF",
    role: 0,
    userName: "jay",
    userEmail: "",
    registrantToken: "",
    zakToken: "",
    leaveUrl: "http://localhost:3000",
    sdkSecret: "l3aLwyftBN7Dz5JRv7iHuXngHlhz3p6U",
  },
}) {
  async function getSignature() {
    const { ZoomMtg } = await import("@zoomus/websdk");
    ZoomMtg.setZoomJSLib("https://source.zoom.us/2.14.0/lib", "/av");

    ZoomMtg.preLoadWasm();
    ZoomMtg.prepareWebSDK();
    ZoomMtg.generateSDKSignature({
      meetingNumber: payload.meetingNumber,
      role: payload.role,
      sdkKey: payload.sdkKey,
      sdkSecret: payload.sdkSecret,
      success: async function (signature) {
        startMeeting(signature.result);
      },
    });
  }

  async function startMeeting(signature) {
    const { ZoomMtg } = await import("@zoomus/websdk");
    document.getElementById("zmmtg-root").style.display = "block";

    ZoomMtg.init({
      leaveUrl: payload.leaveUrl,
      disablePreview: true,
      success: (success) => {
        ZoomMtg.join({
          signature: signature,
          sdkKey: payload.sdkKey,
          meetingNumber: payload.meetingNumber,
          passWord: payload.passWord,
          userName: payload.userName,
          userEmail: payload.userEmail,
          tk: payload.registrantToken,
          zak: payload.zakToken,
          success: (success) => {
            console.log(success);
          },
          error: (error) => {
            console.log(error);
          },
        });
      },
      error: (error) => {
        console.log(error);
      },
    });
  }

  useEffect(() => {
    getSignature();
    return () => {};
  }, []); 

  return <></>;
}
