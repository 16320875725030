import {  Drawer, Stack} from "@mui/material";
import defaultStyles from "../styling/DefaultStyles.module.css";
import injection from "../assets/injections (2).svg";
import Bread from "../molecules/Bread";
import CreactiveDoctorSchedule from "../molecules/CreactiveDoctorSchedule";
import { InternalBanner } from "../molecules/BookingForTeethClening";
import LocationMenu from "../molecules/LocationMenu";
import { useState } from "react";
import DoctorScheduleForNew from "../molecules/DoctorScheduleForNew";

export default function CReactiveProtien() {
  const headerText="C-reactive protein (CRP) blood test";

  const [locationMenu, setLocationMenu] = useState(false);

  const openLocationMenu = (bool) => {
    setLocationMenu(bool);
  };

  const handleCloseLocationMenu = () => {
    setLocationMenu(false);
  };
  return (
    <>
     <Stack className={defaultStyles.max_bread_14_5}>
        <Bread />
      </Stack>
      <div >
        <InternalBanner text={headerText} img={injection} />

        <Stack alignItems="center" justifyContent="center" mt={2}>
          
        <DoctorScheduleForNew
            url={`/outpatient_care/vaccination/c_Reactive_protien_crp_blood_test/checkout`}
            layout="outpatient"
          />
                  </Stack>
        <Drawer
          anchor="right"
          open={locationMenu}
          onClose={() => handleCloseLocationMenu()}
          sx={{
            display: { xs: "block", sm: "block" },
            "& .MuiDrawer-paper": { boxSizing: "border-box", width: "350px" },
          }}
        >
          <LocationMenu onClose={() => handleCloseLocationMenu()} />
        </Drawer>
      </div>
      </>
  );
}
