import React, { useState } from "react";
import {
  Typography,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  List,
  Divider,
  Radio,
  Toolbar,
} from "@mui/material";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import styles from "../styling/LocationMenu.module.css";
import CloseIcon from "@mui/icons-material/Close";
export default function LocationMenu({ onClose }) {
  const [selectedValue, setSelectedValue] = useState("Nerul");
  const handleChangeLocation = (location) => {
    setSelectedValue(location);
  };
  // const locationsDummyData = ["Nerul", "Kharghar", "Vashi", "Powai"];
  const locationsDummyData = [
    {
      name: "Nerul",
      address:
        "Shop No.19-21, Gr. Floor, Meredien Apartment, Sector 6 Nerul Rd, Navi Mumbai 400706",
    },
    {
      name: "Kharghar",
      address:
        "Gayatri Sankul CHS Ltd., Rekhi Sai Daffodil CHS Ltd, Shop No. 3 & 4, Daffodil, Plot No. 17, 18 & 19 Rekhi Sai Daffodil, Ramsheth Thakur School Rd, Sector-19, Kharghar. Navi Mumbai 410210",
    },
    {
      name: "Vashi",
      address:
        "Arti CHS Ltd, Plot no 29 Sector 2, Vashi, next to RBL Bank, Navi Mumbai 400703",
    },
    {
      name: "Powai",
      address:
        "Shop No.19-21, Gr. Floor, Meredien Apartment, Sector 6 Nerul Rd, Navi Mumbai 400706",
    },
    {
      name: "Palava",
      address:
        "Gayatri Sankul CHS Ltd., Rekhi Sai Daffodil CHS Ltd, Shop No. 3 & 4, Daffodil, Plot No. 17, 18 & 19 Rekhi Sai Daffodil, Ramsheth Thakur School Rd, Sector-19, Kharghar. Navi Mumbai 410210",
    },
    {
      name: "Nerul",
      address:
        "Arti CHS Ltd, Plot no 29 Sector 2, Vashi, next to RBL Bank, Navi Mumbai 400703",
    },
    {
      name: "Aundh",
      address:
        "Shop No.19-21, Gr. Floor, Meredien Apartment, Sector 6 Nerul Rd, Navi Mumbai 400706",
    },
    {
      name: "Palava CBG",
      address:
        "Arti CHS Ltd, Plot no 29 Sector 2, Vashi, next to RBL Bank, Navi Mumbai 400703",
    },
    {
      name: "Andheri Oshiwara",
      address:
        "Shop No.19-21, Gr. Floor, Meredien Apartment, Sector 6 Nerul Rd, Navi Mumbai 400706",
    },
  ];
  const select = "Select Location";
  return (
    <>
      <Toolbar>
        <Typography variant="h4" className={styles.caption}>
          {select}
        </Typography>
        <CloseIcon className={styles.close_icon} onClick={onClose} />
      </Toolbar>

      <List>
        {locationsDummyData?.map((text) => (
          <>
            <ListItem key={text}>
              <ListItemButton>
                <ListItemIcon className={styles.list_Item_Icon}>
                  <LocationOnOutlinedIcon />
                </ListItemIcon>
                <ListItemText
                  primary={text?.name}
                  secondary={text?.address}
                  sx={{ fontWeight: "bold" }}
                />
                <Radio
                  checked={selectedValue === text?.name}
                  onChange={() => handleChangeLocation(text?.name)}
                  value={selectedValue}
                  className={styles.radio_button}
                  sx={{ marginTop: "-4rem" }}
                />
              </ListItemButton>
            </ListItem>
            <Divider className={styles.divider} />
          </>
        ))}
      </List>
    </>
  );
}
