import React from "react";
import { Card, Stack, Typography } from "@mui/material";
import { helpData } from "../json/helplist";
import HelpCard from "../molecules/HelpCard";
import pic from "../assets/helpman.png";
import styles from "../styling/Help.module.css"; 
import defaultStyles from "../styling/DefaultStyles.module.css";
import Bread from "../molecules/Bread";


const Help = () => {
  return (
    <Stack className={defaultStyles.max_width_stack}>
      <Bread/>

      <Stack spacing={3} className={styles["stack-help"]}>
        <Card elevation={5} className={styles["help-card"]}>
          <Stack
            direction="row"
            justifyContent="space-around"
            className={styles["card-help"]}
          >
            <Stack>
              <Typography
                variant="h1"
                align="center"
                p={5}
                className={styles["help-main"]}
              >
                Get in Touch, Ready to help
              </Typography>
            </Stack>
            <Stack alignSelf="center">
              <img src={pic} alt="Loading" />
            </Stack>
          </Stack>
        </Card>
        <Stack spacing={2} justifyContent="space-between">
          {helpData?.map((data) => (
            <HelpCard
              title={data?.title}
              subheader={data?.subheader}
              background={data?.background}
              buttonText={data?.buttonText}
              buttonBg={data?.buttonBg}
              imgBg={data?.imgBg}
              pic={data?.pic}
            />
          ))}
        </Stack>
      </Stack>
      </Stack>
  );
};

export default Help;
