import React from "react";
import { Stack, Typography } from "@mui/material";
import styles from "../styling/TermsAndPolicyMolecules.module.css";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
const HeadingTxt = ({ title = "", style = "" }) => {
  return (
    <Typography className={`${styles.heading_Txt} ${style}`} align="left">
      {title}
    </Typography>
  );
};

const SubHeadingDescTxtWithUrl = ({
  descVal = "",
  className = "",
  green = true,
}) => {
  let Url = "";
  let urlIncluded = false;
  if (descVal.includes("url-")) {
    urlIncluded = true;
    Url = descVal.split("url-");
  } else {
    Url = descVal;
  }
  const handleEmailClick = (emailAddress) => {
    window.location.href = `mailto:${emailAddress}`;
  };
  const isEmail = (str) => {
    const emailRegex = /\S+@\S+\.\S+/;
    return emailRegex.test(str);
  };
  const isHttps = (str) => {
    return str.includes("https://");
  };
  const DataGreenUrlsCheck = ({ item, index }) => {
    if (index % 2 !== 1) {
      return <span>{item}</span>;
    } else {
      const trimmedItem = item.trim();
      return (
        <span
          className={green ? styles.green_Text : styles.black_Text}
          onClick={() => {
            if (isEmail(trimmedItem)) {
              handleEmailClick(trimmedItem);
            } 
            else if (isHttps(trimmedItem)) {
              window.open(
                trimmedItem,
                '_blank' 
              );
            }
            else {
            }
          }}
          style={{ cursor: "pointer" }}
        >
          {trimmedItem}
        </span>
      );
    }
  };


  return (
    
    <Stack className={className}>
      {urlIncluded ? (
        <Typography className={styles.greenUrlCheckTxt}>
          {Url.map((item, index) => (
            <DataGreenUrlsCheck item={item} index={index} />
          ))}
        </Typography>
      ) : (
        <Typography className={styles.greenUrlCheckTxt}>{Url}</Typography>
      )}
    </Stack>
  );
};

const SubHeadingTxt = ({ subTitle = "", className = "" }) => {
  return (
    <Typography align="left" className={`${styles.subTitle_Txt} ${className}`}>
      {subTitle}
    </Typography>
  );
};

const DotLinesWithTxt = ({ text = "" }) => {
  return (
    <Stack className={styles.dotView}>
      <FiberManualRecordIcon className={styles.green_Dot} />
      <Typography align="left">{text}</Typography>
    </Stack>
  );
};

const TableTop = ({ Title = "" }) => {
  return (
    <Typography align="left" className={styles.blackTopTableContentTxt}>
      {Title}
    </Typography>
  );
};

const TableTopDesc = ({ Title = "", handleNavigate }) => {
  return (
    <Typography
      align="left"
      className={styles.greenTopTableContentTxt}
      onClick={() => handleNavigate(Title)}
    >
      {Title}
    </Typography>
  );
};
export {
  HeadingTxt,
  SubHeadingDescTxtWithUrl,
  SubHeadingTxt,
  DotLinesWithTxt,
  TableTop,
  TableTopDesc,
};
