import * as React from "react";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Typography } from "@mui/material";
import styles from "../styling/InputLabel.module.css";
import { categories } from "../json/category";

export default function SelectLabels() {
  const [age, setAge] = React.useState("");

  const handleChange = (event) => {
    setAge(event?.target?.value);
  };

  return (
    <div className={styles.divfirst} data-testid="export"> 
      <FormControl>
        <Select
          value={age}
          onChange={handleChange}
          displayEmpty
          inputProps={{ "aria-label": "Without label" }}
          className={styles.selectForm}
        >
          <MenuItem value="">
            <Typography>{categories?.title}</Typography>
          </MenuItem>
          <MenuItem value={10}>{categories?.one}</MenuItem>
          <MenuItem value={20}>{categories?.two}</MenuItem>
          <MenuItem value={30}>{categories?.three}</MenuItem>
        </Select>
      </FormControl>
    </div>
  );
}
