import React from "react";
import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  Button,
  Stack,
  Box,
} from "@mui/material";
import img from "../assets/ico75.png";
import { useNavigate } from "react-router-dom";
import { landing_dynamic } from "../json/landingDynamic";
import styles from "../styling/introductionBanner.module.css";
import { SiteContent } from "../assets/content/SiteContent";
import { useSharedData } from "../Context/ShareContext";
export default function Introduction() {
  const navigate = useNavigate();
  const { setIndexOfSubheader, setActiveButtonIndex } = useSharedData();


  function handleOnClickLogin() {
    navigate("/login/loginpage");
  }
  const openInNewTab = (url) => {
    window.open(url, "_blank", "noreferrer");
  };
  const selectedLocation = { NAME: "Mumbai" };

  const navigateContact = () => {
    setIndexOfSubheader(false);
    setActiveButtonIndex(2)

    navigate("/login/contactus", {
      state: {
        selectedLocation
      },
    });
  };
  return (
    <Box data-testid="introduction">
      <Card className={styles["new-family-card"]} elevation={0}>
        <Stack className={styles["new-family-stack"]}>
          <CardContent sx={{ p: 0, "&:last-child": { pb: 0 } }}>
            <Box className={styles["new-family-content"]}>
              <Typography
                align="left"
                className={styles["new-family-title"]}
                data-testid="landing_title"
              >
                {landing_dynamic?.BANNER_1?.TITLE?.TITLE_1}
                <span className={styles["new-highlight"]}>
                  {landing_dynamic?.BANNER_1?.TITLE?.TITLE_2}
                </span>
                {landing_dynamic?.BANNER_1?.TITLE?.TITLE_3}
                <span className={styles["new-highlight"]}>
                  {landing_dynamic?.BANNER_1?.TITLE?.TITLE_4}
                </span>{" "}
                {landing_dynamic?.BANNER_1?.TITLE?.TITLE_5}
              </Typography>
              <Typography
                color="text.primary"
                align="left"
                className={styles["new-family-subtitle"]}
              >
                {SiteContent?.LANDING_INTRO}
              </Typography>

              <Stack direction="row" spacing={2}>
                <Typography align="left">
                  <Button
                    className={styles["new-family-btn"]}
                    // onClick={() => openInNewTab("https://www.healthspring.in/book-an-appointment")}
                    onClick={navigateContact}
                  >
                    {SiteContent?.BOOK_AN_APPOINTMENT}
                  </Button>
                </Typography>
                <Typography align="left">
                  <Button
                    className={styles["new-family-btn_two"]}
                    variant="outlined"
                    // onClick={handleOnClickLogin}
                    onClick={() =>
                      openInNewTab(
                        "https://secure.healthspring.in/patientportal/"
                      )
                    }
                  >
                    {SiteContent?.HEALTH_PORTAL_LOGIN}
                  </Button>
                </Typography>
              </Stack>
            </Box>
          </CardContent>
        </Stack>
        <CardMedia
          component="img"
          alt="intro_img"
          image={img}
          className={styles["new-family-img"]}
        />
      </Card>
    </Box>
  );
}
