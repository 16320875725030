import pic1 from "../assets/Vision.png";
import pic2 from "../assets/Mission.png";
import pic3 from "../assets/Values.png";
import img from "../assets/checkup.png";
import img1 from "../assets/TeamImages/teampic1.png";
import img2 from "../assets/TeamImages/teampic2.png";
import img3 from "../assets/TeamImages/teampic3.png";
import img4 from "../assets/TeamImages/teampic4.png";
import img5 from "../assets/TeamImages/new_img_team.png";
import img6 from "../assets/TeamImages/teampic6.png";
import img7 from "../assets/TeamImages/teampic7.png";
import img8 from "../assets/TeamImages/teampic8.png";

export const aboutUs_Dynamic = {
  BANNER_1: {
    IMAGE: img,
    HEADER: {
      LINE1: "Impactful, ",
      LINE2: "Timely ",
      LINE3: "Healthcare. ",
      LINE4: " Everyday.",
    },
    DESCRIPTION:
      "Everyday at Healthspring, we aspire to be the most trusted custodians to quality healthcare. Our focus on patient-centric care while upholding the highest level of ethics and transparency has made us one of the leading names in healthcare in India.",
  },
  BANNER_2: {
    TITLE: "Our Purposes & Values",
    PURPOSES: [
      {
        NAME: "Vision",
        ICON: pic1,
        DESCRIPTION: `Our vision is to create the most trusted and dependable healthcare brand in India, through excellent care and customer service while achieving utmost transparency and upholding the highest ethical standards.`,
      },
      {
        NAME: "Mission",
        ICON: pic2,
        DESCRIPTION: `Healthspring’s mission is to constantly provide the best holistic services for every patient, so that we can collectively contribute towards enhancing the overall health of our community. We strive to be the leaders in innovation, accessibility and future-forward healthcare.`,
      },
      {
        NAME: "Values",
        ICON: pic3,
        DESCRIPTION: `Our core philosophy relies on a data based, patient-centric approach to quality healthcare for our communities. By relying on the 4 Ps of Medicine- Prediction, Prevention, Participation and Personalisation, we aim to constantly innovate.`,
      },
    ],
  },
  BANNER_3: {
    TEAMARRAY: [
      {
        IMAGE: img1,
        NAME: "Professor Gautam Sen",
        DESIGNATION: "Chairman and Founder",
        DESCRIPTION:
          "Professor Gautam Sen is the Chairman and Co-founder of Healthspring, which he set up with his son, Kaushik with an aim to bring high-quality primary care to India, and to transform Indian healthcare delivery. With more than three-and-half decades in practicing General Surgery and Surgical Oncology, Dr Sen has held a number of distinguished roles in the past, including a recently completed year-long term at the 6-member board of governors for the Medical Council of India.",
        SETDIRECTION: "row",
      },
      {
        IMAGE: img2,
        NAME: "Kaushik Sen",
        DESIGNATION: "CEO and Co-founder",
        DESCRIPTION:
          "Kaushik is the CEO of Healthspring, the leading provider of Primary and Preventive Care, Occupational and Corporate Healthcare in India. Healthspring provides high-quality, standards-based, and affordable primary care through more than 250 physical locations across India, as well as through a pan-India network of healthcare delivery.\n Prior to founding Healthspring, Kaushik worked with management consulting firm Bain & Co. in Boston, Singapore and Delhi for 10 years, primarily in the areas of Healthcare, Consumer and Private Equity.",
        SETDIRECTION: "row-reverse",
      },
      {
        IMAGE: img3,
        NAME: "Dr. Kushilab Bose",
        DESIGNATION: "Chief of Medical Operations",
        DESCRIPTION:
          "As Chief of Medical Operations at Healthspring, Capt. Kushilab Bose is directly responsible for delivering quality care across the many Healthspring centres. He also provides strategic direction to the patient-centric approach at Healthspring.\n Capt. Bose leads from the front, with 20+ of operational experience with Indian Navy, where he rendered seven years of his sea service on board all classes of warships.",
        SETDIRECTION: "row",
      },
      {
        IMAGE: img4,
        NAME: "Rajeev Desai",
        DESIGNATION: "CFO",
        DESCRIPTION:
          "Mr. Rajeev Desai, CFO at Healthspring, comes with 20+ years of experience across areas like corporate accounting, financial services, ERP implementation and cost control. With a diverse experience across the industry, Mr. Desai specializes in in managing Financial Control & Planning functions for companies. He has worked with the primary healthcare sector and has also been associated with Reliance Infrastructure Ltd. for 19 years. At Healthspring, he formulates initiatives & undertakes strategic planning to achieve corporate goals.",
        SETDIRECTION: "row-reverse",
      },
      {
        IMAGE: img5,
        NAME: "Sukalyan Roy",
        DESIGNATION: "COO, Healthspring",
        DESCRIPTION:
          "Sukalyan Roy, COO at Healthspring, with more than 25 years of experience, excels in undertaking new projects and monitoring till the implementation and managing end-to-end sales/ marketing solutions, along with process layout designing, interacting with stakeholders, and customer relationship management. His in-depth knowledge enables him to understand fundamental business needs and drivers from an owner’s perspective, and leverage this knowledge to engineer business solutions for customers. \n His past assignments includes stints with Domino's Pizza, Pantaloons, Pidilite, Future Group and Reliance Retail.",
        SETDIRECTION: "row",
      },
      {
        IMAGE: img6,
        NAME: "Dr. Ankit Khambhati",
        DESIGNATION: "Strategic Initiatives Function",
        DESCRIPTION:
          "Ankit currently leads the Strategic Initiatives function at Healthspring and also manages the marketing and communication activities for the company. Ankit specializes in Digital Health Solutions, Intelligent Innovation, Strategic Planning, Product Design, Health Marketing & Communications, Healthcare Marketing, Team Building and Healthcare Tech. He is highly skilled in scaling business operations and his experience with startups adds to his knowledge base.",
        SETDIRECTION: "row-reverse",
      },

      {
        IMAGE: img7,
        NAME: "Saransh Meshram",
        DESIGNATION: "Retail Head",
        DESCRIPTION:
          "He possesses 17+ years of rich experience in Retail Business across hospitality & healthcare industries. He holds a degree in Hospitality Administration and a masters in Operations Management from Symbiosis International University, Pune.\n Prior to joining Healthspring, Saransh worked extensively across the healthcare and F&B space. With 11+ years of intensive experience in healthcare, Saransh is a name to be reckoned with in the industry.",
        SETDIRECTION: "row",
      },
      {
        IMAGE: img8,
        NAME: "Anuratti Kathariya",
        DESIGNATION: "Head Human Resources",
        DESCRIPTION:
          "Building on 16+ years of experience across industries, geographies and various areas of HR, Anuratti’s approach is to drive tangible business results through focused people initiatives. MBA in HR, her strength lies in understanding the ground realities of business including its cultural nuances and translating them into practical, cost effective and impactful initiatives. A leader with deep understanding of people behaviors , her vision is to make a difference in the way HR is implemented and viewed by employees and the industry as a whole. She joined Healthspring with an aim to make HR a business function than just another conventional support function.",
        SETDIRECTION: "row-reverse",
      },
    ],
  },
  BANNER_4: {
    TITLE: "Healthspring's Milestone",
    SUBTITLE:
      "From redefining healthcare to a nationwide presence, our evolution has been marked by expanding services and pioneering innovation. Whether enhancing industrial health or establishing an accredited pathology lab, our commitment shines through, especially during challenging times like the pandemic, where we led groundbreaking approaches for corporate clients. Welcome to Healthspring – where every milestone is a step towards a healthier tomorrow.",
    MILESTONE_TITLE: "Milestones",
    MILESTONES: [
      {
        YEAR: "2011",
        HEADER: "The Genesis",
        SUBTEXT: `Launched a network of clinics in Mumbai providing high quality primary and preventive care`,
      },
      {
        YEAR: "2012",
        HEADER: "Focused Health Initiatives",
        SUBTEXT: `Launched the first structured Annual Health Programs for Corporates and Schools`,
      },
      {
        YEAR: "2014",
        HEADER: "Expansion and Diversification",
        SUBTEXT: `Clinics launched in Pune, Bangalore and the National Capital Region`,
      },
      {
        YEAR: "2015",
        HEADER: "Accredited Excellence and Innovation",
        SUBTEXT: `NABL Accredited Lab in Mumbai First Occupational Health Centre\nLaunched Emergency Medical Response system`,
      },
      {
        YEAR: "2016",
        HEADER: "Scaling Heights",
        SUBTEXT: `Total 30 clinics serving 300,000 active customers\n1500+ lives saved by the EMR system\n150+ Corporate clients across 30 cities`,
      },
      {
        YEAR: "2019",
        HEADER: "Nationwide Footprint",
        SUBTEXT: `Pan India operations - 150+ Primary & Occupational Health Centres.\n800,000 Active Users.\n5500+ lives saved by the EMR system`,
      },
      {
        YEAR: "2020-21",
        HEADER: "Agile Pandemic Management",
        SUBTEXT: `Launched Pan-India COVID-19 Response Quarantine Services, Vaccination & Telemedicine Support for retail and corporate communities.`,
      },
      {
        YEAR: "2023",
        HEADER: "Elevated Healthcare Landscape",
        SUBTEXT: `250+ Primary & Occupational Health Centres\n1 million Active users\nProfitable for 2 successive financial years`,
      },
    ],
  },
};
