import React from "react";
import Box from "@mui/material/Box";
import { Typography, Stack } from "@mui/material";
import styles from "../styling/Award.module.css";

import { corporate_dynamic } from "../json/corporateDynamic";

export const Award = () => {
  return (
    <>
      <Box className={styles.awardRoot} data-testid="award">
        <Typography
          className={styles.awardTypos}
          variant="h4"
          component="div"
          mb={7}
        >
          {corporate_dynamic?.BANNER_9?.TITLE}
        </Typography>
        <Stack
          direction={{ xs: "column", sm: "row" }}
          spacing={2}
          alignItems="center"
          justifyContent="space-between"
          flexWrap="wrap"
          useFlexGap
          mb={7}
          className={styles.newBoxes}
        >
          {corporate_dynamic?.BANNER_9?.AWARDS_LOGO?.map((item) => (
            <Box
              component="img"
              key={item.ID}
              src={item.IMAGE}
              alt={item.ALT}
              className={styles.awardboxicons}
            />
          ))}
        </Stack>
      </Box>
    </>
  );
};
