import React from "react";
import { Paper, Typography, Stack,Box } from "@mui/material";
import MedicalServicesIcon from "@mui/icons-material/MedicalServices";
import styles from "../styling/EverydayHealth.module.css";
import theme from "../components/Themeify";
import ArrowOutwardOutlinedIcon from "@mui/icons-material/ArrowOutwardOutlined";

export default function OurPackagesCard(props) {
  return (
    <Paper className={styles.packageCard} elevation={3} data-testid="package-card">
      <Stack spacing={2} p={2} className={styles.packages_width}>
        <Stack direction="row" spacing={3}>
          {/* <MedicalServicesIcon
            className={styles.packageHelpIcon}
            sx={{ background: props.icon }}
          /> */}
          <Box component="img" src={props?.icon}  className={styles.icon_size}/>
          <Typography variant="h3" className={styles.everyBold}>
            {props.title}
          </Typography>
        </Stack>
        <Stack>
          <Typography align="left" color="text.secondary" sx={{minHeight:"100px"}}>
            {props.description}
          </Typography>
        </Stack>
        <Stack direction="row" justifyContent="space-between">
          <Typography
            color={theme.palette.primary.main}
            className={styles.everyBold}
          >
           ₹{props.price} /-
          </Typography>
          <ArrowOutwardOutlinedIcon
            className={styles.packageArrow}
            sx={{ color: theme.palette.primary.dark }}
          />
        </Stack>
      </Stack>
    </Paper>
  );
}
