import React from "react";
import dent from "../assets/tooth-care.png";
import { useNavigate } from "react-router-dom";
import toothicon from "../assets/toothicon_xd.png";
import { dentList } from "../json/dentlist";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import styles from "../styling/OutPatientCare.module.css";
import { Typography, Stack } from "@mui/material";
import Bread from "../molecules/Bread";
import defaultStyles from "../styling/DefaultStyles.module.css";
import { GreenBanner } from "./Urgentcare";
export const DentalCare = () => {
  const Handleclick = (name, index) => {
    if (index === 0) {
      navigate("/outpatient_care/dental_care/teeth_cleaning_polishing");
    }
  };
  const dental = "Dental Care";
  const navigate = useNavigate();
  return (
    <>
      <Stack className={defaultStyles.max_bread_14_5}>
        <Bread />
      </Stack>

      <GreenBanner text={dental} img={dent} />

      <div className={styles.dentalcard_content_div}>
        <Stack direction={"column"}>
          {dentList?.map((i, index) => {
            return (
              <div
                onClick={() => {
                  Handleclick(i?.name, index);
                }}
                key={i?.index}
              >
                <Stack direction={"row"}>
                  <img
                    src={toothicon}
                    alt="dental Care"
                    className={styles.teethimg}
                  />
                  <button className={styles.dentalcard_content_div_button}>
                    <Stack direction="row" justifyContent="space-between">
                      <Typography
                        className={styles.new_typo}
                        align="left"
                      >
                        {i?.name}
                      </Typography>

                      <ArrowForwardIosIcon className={styles.unset} />
                    </Stack>
                  </button>
                </Stack>
                <hr className={styles.dent_hr} />
              </div>
            );
          })}
        </Stack>
      </div>
    </>
  );
};

export default DentalCare;
