import React, { useState } from "react";
import {
  Stack,
  Box,
  Typography,
  Button,
  TextField,
  Avatar,
} from "@mui/material";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { useNavigate } from "react-router-dom";
import styles from "../styling/VerifyAbha.module.css";
import theme from "../components/Themeify";
import AbhaId from "../assets/images/abha_id.jpg";
import OtpPage from "../molecules/OtpPage";
import { SiteContent } from "../assets/content/SiteContent";
import { useFormik } from "formik";
import * as Yup from "yup";

const VerifyAbha = () => {
  let canGetOtpTime = 60;
  const validationSchema = Yup.object().shape({
    abhaAddress: Yup.string().required("ABHA Address is required"),
    abhaId: Yup.string().required("ABHA ID is required"),
  });
  const navigate = useNavigate();
  const [isOtpRequested, setIsOtpRequested] = useState(false);
  const [otpRemainingTime, setOtpRemainingTime] = useState(60);

  const handleBack = () => {
    navigate("/login/signup");
  };

  const handleVerify = () => {
    setIsOtpRequested(true);
    changeOtpTime();
  };

  const changeOtpTime = () => {
    var intervalId = setInterval(function () {
      canGetOtpTime -= 1;
      setOtpRemainingTime(canGetOtpTime);

      if (canGetOtpTime <= 0) {
        canGetOtpTime = 10;
        clearInterval(intervalId);
      }
    }, 1000);
  };

  const formik = useFormik({
    initialValues: {
      abhaAddress: "",
      abhaId: "",
    },
    validationSchema,
    onSubmit: (values) => {
      
      handleVerify()
      
    },
  });

  return (
    <>
      <Stack direction="row" onClick={handleBack}>
        <Button
          id="basic-button"
          startIcon={
            <Avatar className={styles.back_icon_container}>
              <KeyboardBackspaceIcon className={styles.back_icon} />
            </Avatar>
          }
        >
          <Typography variant="h5" color={theme.palette.info.grey}>
            {SiteContent.BACK}
          </Typography>
        </Button>
      </Stack>
      {!isOtpRequested && (
        <Box className={styles.abha_box}>
          <Avatar
            src={AbhaId}
            className={styles.abha_logo}
            alt="Abha Id Logo"
          />
          <Typography className={styles.abha_main_txt} variant="h4">
            {SiteContent.ABHA_DETAILS}
          </Typography>
          <Stack direction="column">
            <Stack item xs={6}>
              <Typography className={styles.abha_txtfldtitle} variant="h5">
                {SiteContent.ABHA_ADDRESS}
              </Typography>
              <TextField
                className={styles.abha_txtfld}
                variant="standard"
                name="abhaAddress"
                value={formik?.values?.abhaAddress}
                onChange={formik?.handleChange}
                onBlur={formik?.handleBlur}
                error={
                  formik?.touched?.abhaAddress &&
                  Boolean(formik?.errors?.abhaAddress)
                }
                InputProps={{
                  className: styles.abha_txtfldinpt,
                  disableUnderline: true,
                }}
              />
              {formik?.touched?.abhaAddress &&
                formik?.errors?.abhaAddress ? (
                  <Typography className={styles.errorText} color="error">
                    {formik?.errors?.abhaAddress}
                  </Typography>
                ):null}
            </Stack>
            <Stack item xs={6}>
              <Typography className={styles.abha_txtfldtitle} variant="h5">
                {SiteContent.ABHA_ID}
              </Typography>
              <TextField
                className={styles.abha_txtfld}
                variant="standard"
                name="abhaId"
                value={formik?.values?.abhaId}
                onChange={formik?.handleChange}
                onBlur={formik?.handleBlur}
                error={
                  formik?.touched?.abhaId &&
                  Boolean(formik?.errors?.abhaId)
                }
                InputProps={{
                  className: styles.abha_txtfldinpt,
                  disableUnderline: true,
                }}
              />
              {formik?.touched?.abhaId &&
                formik?.errors?.abhaId ? (
                  <Typography className={styles.errorText} color="error">
                    {formik?.errors?.abhaId}
                  </Typography>
                ):null}
            </Stack>
          </Stack>
          <Button className={styles.abha_verify_button} onClick={handleVerify}>
            Verify ABHA
          </Button>
        </Box>
      )}
      {isOtpRequested && (
        <OtpPage
          PhoneNo=""
          OptRemainingTime={otpRemainingTime}
          PageName="VerifyAbha"
        />
      )}
    </>
  );
};

export default VerifyAbha;