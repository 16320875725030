import React from "react";
import { Typography, Stack, Box, Paper } from "@mui/material";
import styles from "../styling/OurPurpose.module.css";
import {aboutUs_Dynamic}from "../json/aboutus";
export default function OurPurpose() {

  return (
    <div data-testid="Our-purpose">
    <Box > 
      <Stack className={styles["purpose-box"]} spacing={3}>
        <Typography variant="h1" className={styles["purpose-bold"]}>
          {aboutUs_Dynamic?.BANNER_2?.TITLE}
        </Typography>
        <Stack direction={{ xs: "column", sm: "row" }} spacing={3} >
          {aboutUs_Dynamic?.BANNER_2?.PURPOSES?.map((item,index) => {
            return (
              <Paper key={index} elevation={10} className={styles["purpose-paper"]} sx={{width:'100%'}}>
                <Stack spacing={1} p={2}>
                  <Stack spacing={2} direction="row">
                    <Box
                      component="img"
                      src={item?.ICON}
                      className={styles["icon-img"]}
                    />
                    <Typography
                      variant="h3"
                      className={styles["purpose-bold"]}
                      align="left"
                      pt={1}
                    >
                      {item?.NAME}
                    </Typography>
                  </Stack>
                  <Typography
                    fontSize="16px"
                    color="textSecondary"
                    align="left"
                    pl={1}
                  >
                    {item?.DESCRIPTION}
                  </Typography>
                </Stack>
              </Paper>
            );
          })}
        </Stack>
      </Stack>
    </Box>
    </div>
  );
}
