import React, { useState } from "react";
import { Typography, Stack, Button } from "@mui/material";
import styles from "../styling/AddFamilyMember.module.css";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import CallOutlinedIcon from "@mui/icons-material/CallOutlined";
import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";
import CakeOutlinedIcon from "@mui/icons-material/CakeOutlined";
import PersonSearchOutlinedIcon from "@mui/icons-material/PersonSearchOutlined";

export default function AddFamilyMemberFlyH({ onClose }) {
  const genderArray = ["Male", "Female", "Other"];
  const member={
    edit:"Add New Member",
    submit:"Submit",

  }
  const [genderIndex, setGenderIndex] = useState(0);
  return (
    <>
      <Stack direction={"row"}>
        <ArrowBackIcon className={styles.back_icon} onClick={onClose} />
        <Typography variant="h4" className={styles.selectName}>
          {member.edit}
        </Typography>
      </Stack>
      <CustomTextInput
        heading="Name"
        placeholder="Elina Johnson"
        Icon={
          <AccountCircleOutlinedIcon
            fontSize="15px"
            className={styles.right_icon}
            onClick={onClose}
          />
        }
      />
      <CustomTextInput
        heading="Email"
        placeholder="elinajohnson@gmail.com"
        Icon={
          <EmailOutlinedIcon
            fontSize="15px"
            className={styles.right_icon}
            onClick={onClose}
          />
        }
      />
      <CustomTextInput
        heading=""
        placeholder="7894561230"
        Icon={
          <CallOutlinedIcon
            fontSize="15px"
            className={styles.right_icon}
            onClick={onClose}
          />
        }
      />
      <Stack className={styles.genderMAinView}>
        <Typography className={styles.headingTxt}>{"Gender"}</Typography>
        <div className={styles.genderView}>
          {genderArray?.map((item, index) => (
            <Typography
              onClick={() => setGenderIndex(index)}
              className={
                index === genderIndex
                  ? styles.genderBtnOnn
                  : styles.genderBtnOff
              }
            >
              {item}
            </Typography>
          ))}
        </div>
      </Stack>

      <CustomTextInput
        heading="Date of Birth"
        placeholder="26/09/1996"
        Icon={
          <CakeOutlinedIcon
            fontSize="15px"
            className={styles.right_icon}
            onClick={onClose}
          />
        }
      />
      <CustomTextInput
        heading="Relation"
        placeholder="Mother"
        Icon={
          <PersonSearchOutlinedIcon
            fontSize="15px"
            className={styles.right_icon}
            onClick={onClose}
          />
        }
      />
      <Button className={styles.submit_btn}>{member.submit}</Button>
    </>
  );
}

const CustomTextInput = ({ heading = "Name", Icon, placeholder = "" }) => {
  return (
    <Stack className={styles.containerStyleInput}>
      <Typography className={styles.headingTxt}>{heading}</Typography>
      <Stack
        className={styles.inputContainerImage}
        direction={"row"}
        alignItems={"center"}
      >
        {heading === "" && (
          <Typography className={styles.mobileTxt}>{"+91 "}</Typography>
        )}
        {heading === "" && <ExpandMoreOutlinedIcon fontSize="12px" />}

        <input
          placeholder={placeholder}
          className={styles.textInputContainer}
        />
        {Icon}
      </Stack>
    </Stack>
  );
};
