import React from "react";
import { Stack, Box, Typography } from "@mui/material";

import styles from "../styling/Certification.module.css";
import { landing_dynamic } from "../json/landingDynamic";
export default function HealthSpringCertification() {
  return (
    <Box data-testid="certificate">
      <Stack spacing={3}>
        <Typography align="center"  className={styles.typoHeader} >
          {landing_dynamic?.BANNER_5?.TITLE?.TITLE_1}
        </Typography>
      </Stack>
      <Stack direction="row" 
      spacing={{sm:3,md:10}}
      justifyContent="center">
        <Box
          component="img"
          className={styles.boxicons}
          src={landing_dynamic?.BANNER_5?.CERTIFICATES[0]?.IMAGE}
          alt={landing_dynamic?.BANNER_5?.CERTIFICATES[0]?.ALT}
        />
        <Box
          component="img"
          className={styles.boxicons}
          src={landing_dynamic?.BANNER_5?.CERTIFICATES[1]?.IMAGE}
          alt={landing_dynamic?.BANNER_5?.CERTIFICATES[1]?.ALT}
        />
      </Stack>
     
    </Box>
  );
}
