import Avatar from "@mui/material/Avatar";
import { MedicalServices } from "@mui/icons-material";
export const Cancelled = [
  {
    name: "Health Gold Plus",
    date: "02 Nov, 22",
    icon: (
      <Avatar sx={{ bgcolor: "#f57d04", width: "40px", height: "40px" }}>
        <MedicalServices />
      </Avatar>
    ),
    type: "Cancelled",
  },
];
