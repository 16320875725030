import React from "react";
import { Grid, Typography, Card, CardContent, Stack, Box } from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ViewInArIcon from "@mui/icons-material/ViewInAr";
import styles from "../styling/HealthCard.module.css";
import useAppStyles from "../hooks/useAppStyles";
import {  MemberCards } from "../json/MemberCards";
import { useNavigate } from "react-router-dom";

const MembershipCard = () => {
  const navigate = useNavigate();
  const appStyles = useAppStyles();

  const handleCheckoutClick = () => {
    navigate("/test_and_packages/membership/checkout");
  };

  return (
    <>
      {MemberCards?.map((card, index) => {
        return (
          <>
            <Card
              className={styles["health-card"]}
              key={index}
              data-testid="membership_card"
            >
              <CardContent className={styles.cardcontent} >
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={10}>
                    <Box>
                      <Stack direction="row" columnGap={2}>
                      <img src={card.icon} className={styles.imagecard} />
                        <Typography
                        className={styles.cardname}
                          align="left"
                          variant="h3"
                        >
                          {card?.name}
                        </Typography>
                      </Stack>
                      <Stack
                        direction="row"
                        spacing={2}
                        mt={2}
                        justifyContent="flex-start"
                        className={styles["small-cards"]}
                      >
                        <ViewInArIcon color="primary" />
                        <span className={styles.captions}>{card?.validity}</span>
                      </Stack>
                    </Box>
                  </Grid>

                  <Grid item xs={2}>
                    <Stack alignItems="end" spacing={2} className={styles["icons-stack"]}>
                      <ArrowForwardIosIcon
                        size="medium"
                        className={styles["icons-ouline"]}
                        onClick={handleCheckoutClick}
                      />
                      <Typography  className={styles["typo-ouline"]}
                        variant="h4"
                      >
                        &#8377;{card?.price}
                      </Typography>
                    </Stack>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </>
        );
      })}
    </>
  );
};

export default MembershipCard;
