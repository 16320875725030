import { Box, Divider, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import React, {useEffect} from "react";
import { RecentSearch ,Recommendation, Popular} from "../json/RecentSerach";
import styles from "../styling/HealthCardSearch.module.css";
import defaultStyles from "../styling/DefaultStyles.module.css";
import Bread from "./Bread";
import GenericHeaderCard from "./GenericHeaderCard";
import GenericSearch from "./GenericSearch";
import labexp from "../assets/lab-experiment.png";
import { Link } from "react-router-dom";
const TestPackageLabDetails = () => {
  useEffect(() => {
    window.scrollTo(0,0);
    return () => {
      
    };
  }, []); 
  return (
    <>
      <Stack className={defaultStyles.stack_with_max_width}>
        <Stack className={defaultStyles.bread_stack}>
          <Bread />
        </Stack>
        <Box
          className={styles.lab_test_new_box_main}
          data-testid="TestPackageLabDetailsContainer"
        >
          <Stack spacing={4}>
            <GenericHeaderCard
              headingtext={"Laboratory Tests"}
              banner={labexp}
            />
            <GenericSearch placeholder="Search for lab tests" />
          </Stack>
          <Stack>
            <Stack className={styles.main_stack_health_card_search}>
              <Stack>
                <Typography className={styles.recent_serch_healthcard}>
                  Recent Search
                </Typography>
              </Stack>
              <Stack className={styles.clearall_stack_healthcard}>
                <Typography className={styles.clear_allll_healthcard}>
                  Clear All
                </Typography>
              </Stack>
            </Stack>

            <Stack direction="column" spacing={2}>
              {RecentSearch?.map((data, i) => {
                return (
                  <>
                    <Stack direction="row" spacing={2} key={i}>
                      {data?.icon}
                      <Link
                        to="/test_and_packages/lab_tests/details"
                        style={{ textDecoration: "none" }}
                      >
                        <Typography
                          varient="h4"
                          className={styles.health_search_typo}
                        >
                          {data?.val}
                        </Typography>
                      </Link>
                    </Stack>
                    <Stack className={styles.health_search_divider}>
                      <Divider variant="middle" />
                    </Stack>
                  </>
                );
              })}
            </Stack>
            <Stack className={styles.recomendations_allll_healthcard}>
              <Typography className={styles.recent_serch_healthcard}>
                Recommendation
              </Typography>
            </Stack>
            <Stack direction="column" spacing={2}>
              {Recommendation.map((data, i) => {
                return (
                  <>
                    <Stack direction="row" spacing={2} key={i}>
                      {data?.icon}
                      <Typography className={styles.health_search_typo}>
                        {data?.val}
                      </Typography>
                    </Stack>
                    <Stack className={styles.health_search_divider}>
                      <Divider variant="middle" />
                    </Stack>
                  </>
                );
              })}
            </Stack>
            <Stack className={styles.recomendations_allll_healthcard}>
              <Typography className={styles.recent_serch_healthcard}>
                Popular
              </Typography>
            </Stack>
            <Stack direction="column" spacing={2}>
              {Popular?.map((data, i) => {
                return (
                  <>
                    <Stack direction="row" spacing={2} key={i}>
                      {data?.icon}
                      <Typography className={styles.health_search_typo}>
                        {data?.val}
                      </Typography>
                    </Stack>
                    <Stack className={styles.health_search_divider}>
                      <Divider variant="middle" />
                    </Stack>
                  </>
                );
              })}
            </Stack>
          </Stack>
        </Box>
      </Stack>
    </>
  );
};

export default TestPackageLabDetails;
