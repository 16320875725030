import React from "react";
import { Box, Stack, Button, Typography } from "@mui/material";
import { everydayHealth } from "../json/EverydayHealth";
import OurPackagesCard from "./OurPackagesCard";
import styles from "../styling/EverydayHealth.module.css";
import NewGenericPopup from "./NewGenericPopup";
import { useSharedData } from "../Context/ShareContext";
import { useNavigate } from "react-router-dom";

export default function OurPackages() {
  const { showPopup, setShowPopup, setIndexOfSubheader, setActiveButtonIndex } =
    useSharedData();

  const navigate = useNavigate();
  const renderPackageCard = (index) => {
    const pack = everydayHealth?.PACKAGES[index];
    return (
      <OurPackagesCard
        icon={pack?.ICON}
        title={pack?.NAME}
        description={pack?.DESCRIPTION}
        price={pack?.PRICE}
      />
    );
  };

  const renderPackagePairs = () => {
    return everydayHealth?.PACKAGES?.map((_, index) => {
      if (index % 2 === 0) {
        return (
          <Stack
            direction={{ xs: "column", sm: "row" }}
            spacing={2}
            key={index}
          >
            {renderPackageCard(index)}
            {index + 1 < everydayHealth?.PACKAGES?.length &&
              renderPackageCard(index + 1)}
          </Stack>
        );
      }
      return null;
    });
  };
  const handleNavigateUpper = (path, index) => {
    setActiveButtonIndex(index);
    setIndexOfSubheader(false);
    navigate(path);
  };

  return (
    <Box data-testid="our-packages">
      <Stack className={styles.packageStack} spacing={3}>
        <Typography variant="h2" className={styles.everyBold}>
          Our Packages
        </Typography>
        <Stack spacing={2} justifyContent="space-between">
          {renderPackagePairs()}
        </Stack>
        <Box>
          <Button onClick={() => handleNavigateUpper("/login/contactus", 2)}>
            <Typography className={styles.packageBtn}>
              Contact Us For More Information
            </Typography>
          </Button>
        </Box>
      </Stack>
      {/* {showPopup && (
        <NewGenericPopup
          SubTxt="Thank you for visiting, the new website is temporarily unavailable, we will redirect you to our existing website for now. Thank you for your patience and understanding ."
          SubBtnTxt="Cancel"
          ShowSubBtn={true}
          PlaceholderTxt={""}
        />
      )} */}
    </Box>
  );
}
