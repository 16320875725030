import { Box, Grid, Stack, Typography } from "@mui/material";
import React from "react";
import {
  DotLinesWithTxt,
  HeadingTxt,
  SubHeadingDescTxtWithUrl,
  SubHeadingTxt,
  TableTop,
  TableTopDesc,
} from "./TermsAndPolicyMolecules";
import styles from "../styling/TermsAndPolicyMolecules.module.css";
import {
  PrivacyPolicyData,
  privacyTableContent,
} from "../assets/content/PrivacyPolicy";
import { useEffect } from "react";

const PrivacyPolicy = () => {
  const handleNavigate = (item) => {
    console.log(item);
    const element = document.getElementById(`${item}`);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Box className={styles.box_styling} data-testid="policy_container">
      <Grid container  className={styles.maingridtersm} >
        
        <Grid item xs={11} sm={8}>
          <HeadingTxt title={PrivacyPolicyData.heading1} />
          <SubHeadingDescTxtWithUrl descVal={PrivacyPolicyData.heading1Desc} />
          <div id={PrivacyPolicyData.heading2}>
            <SubHeadingTxt subTitle={PrivacyPolicyData.heading2} />
          </div>
          <Stack className={styles.padding_left20}>
            <SubHeadingTxt
              className={styles.padding_Top10}
              subTitle={PrivacyPolicyData.subHeading1}
            />
            <SubHeadingDescTxtWithUrl
              descVal={PrivacyPolicyData.heading1SubHeading1desc}
            />
            <SubHeadingTxt
              className={styles.padding_Top20}
              subTitle={PrivacyPolicyData.heading1SubHeading2}
            />
            <SubHeadingDescTxtWithUrl
              className={styles.padding_Bottom20}
              descVal={PrivacyPolicyData.heading1SubHeading2desc}
            />

            {PrivacyPolicyData.heading1SubHeading2Arr.map((item) => (
              <SubHeadingDescTxtWithUrl
                className={styles.padding_Bottom20}
                descVal={item}
              />
            ))}
          </Stack>
          <div id={PrivacyPolicyData.heading3}>
            <SubHeadingTxt subTitle={PrivacyPolicyData.heading3} />
          </div>

          <Stack className={styles.padding_left20}>
            <SubHeadingTxt
              className={styles.padding_Top10}
              subTitle={PrivacyPolicyData.heading3subHeading1}
            />

            <Stack className={styles.padding_left20}>
              <SubHeadingDescTxtWithUrl
                className={styles.padding_Bottom20}
                descVal={PrivacyPolicyData.heading3subHeading2}
              />
              <SubHeadingDescTxtWithUrl
                className={styles.padding_Bottom20}
                descVal={PrivacyPolicyData.heading3subHeading2Desc}
              />

              {PrivacyPolicyData.heading3subHeadingDescArray.map((item) => (
                <DotLinesWithTxt text={item} />
              ))}

              <SubHeadingDescTxtWithUrl
                className={styles.padding_Bottom20}
                descVal={PrivacyPolicyData.heading3subHeading3Desc}
              />
            </Stack>
            <SubHeadingTxt subTitle={PrivacyPolicyData.heading4} />
            <SubHeadingDescTxtWithUrl
              className={styles.padding_Bottom20}
              descVal={PrivacyPolicyData.heading4Desc}
            />

            <Stack className={styles.padding_left20}>
              {PrivacyPolicyData.heading3subHeading2DescArray.map((item) => (
                <Stack>
                  <SubHeadingDescTxtWithUrl
                    className={styles.padding_Bottom20}
                    descVal={item?.name}
                  />

                  <SubHeadingDescTxtWithUrl
                    className={styles.padding_Bottom20}
                    descVal={item?.desc}
                  />
                  {item?.subArray && (
                    <Stack className={styles.padding_left20}>
                      {item?.subArray.map((item) => (
                        <Stack>
                          <SubHeadingDescTxtWithUrl
                            className={styles.padding_Bottom20}
                            descVal={item?.name}
                            green={false}
                          />

                          <SubHeadingDescTxtWithUrl
                            className={styles.padding_Bottom20}
                            descVal={item?.desc}
                            green={false}
                          />
                        </Stack>
                      ))}
                    </Stack>
                  )}
                </Stack>
              ))}
            </Stack>

            {PrivacyPolicyData.heading3DescArray.map((item) => (
              <SubHeadingDescTxtWithUrl
                className={styles.padding_Bottom20}
                descVal={item}
                green={false}
              />
            ))}
            <SubHeadingTxt
              subTitle={PrivacyPolicyData.heading5}
              className={styles.padding_Top5}
            />
            <SubHeadingTxt
              subTitle={PrivacyPolicyData.heading6}
              className={styles.padding_Top5}
            />

            <SubHeadingDescTxtWithUrl
              className={styles.padding_Bottom20}
              descVal={PrivacyPolicyData.heading5Desc}
            />

            <SubHeadingTxt
              subTitle={PrivacyPolicyData.heading7}
              className={styles.padding_Top5}
            />

            <SubHeadingDescTxtWithUrl
              className={styles.padding_Bottom20}
              descVal={PrivacyPolicyData.heading7Desc}
            />

            <SubHeadingTxt
              subTitle={PrivacyPolicyData.heading7}
              className={styles.padding_Top5}
            />

            {PrivacyPolicyData.heading8ArrayData.map((item) => (
              <Stack className={styles.padding_left20}>
                <SubHeadingDescTxtWithUrl
                  className={styles.padding_Bottom20}
                  descVal={item?.name}
                />
                <SubHeadingDescTxtWithUrl
                  className={styles.padding_Bottom20}
                  descVal={item?.desc}
                />
                <Stack className={styles.padding_left20}>
                  {item?.dotArray?.map((item) => (
                    <DotLinesWithTxt text={item} />
                  ))}
                </Stack>
              </Stack>
            ))}
          </Stack>
          <div id={PrivacyPolicyData.heading9}>
            <SubHeadingTxt
              subTitle={PrivacyPolicyData.heading9}
              className={styles.padding_Top5}
            />
          </div>

          <SubHeadingDescTxtWithUrl
            className={styles.padding_Bottom20}
            descVal={PrivacyPolicyData.heading9Desc}
          />
          <div id={PrivacyPolicyData.heading10}>
            <SubHeadingTxt
              subTitle={PrivacyPolicyData.heading10}
              className={styles.padding_Top5}
            />
          </div>

          <SubHeadingDescTxtWithUrl
            className={styles.padding_Bottom20}
            descVal={PrivacyPolicyData.heading10Desc}
          />
          <div id={PrivacyPolicyData.heading11}>
            <SubHeadingTxt
              subTitle={PrivacyPolicyData.heading11}
              className={styles.padding_Top5}
            />
          </div>

          <Stack className={styles.padding_left20}>
            <SubHeadingTxt
              subTitle={PrivacyPolicyData.heading12}
              className={styles.padding_Top5}
            />
            <SubHeadingDescTxtWithUrl
              className={styles.padding_Bottom20}
              descVal={PrivacyPolicyData.heading11Desc}
            />
          </Stack>
          <div id={PrivacyPolicyData.heading13}>
            <SubHeadingTxt
              subTitle={PrivacyPolicyData.heading13}
              className={styles.padding_Top5}
            />
          </div>

          <Stack className={styles.padding_left20}>
            <SubHeadingTxt
              subTitle={PrivacyPolicyData.heading14}
              className={styles.padding_Top5}
            />
            <SubHeadingDescTxtWithUrl
              className={styles.padding_Bottom20}
              descVal={PrivacyPolicyData.heading13Desc}
            />
          </Stack>
        </Grid>
        <Grid item xs={0} sm={1}></Grid>

        
        <Grid item xs={12} sm={3}>
          <Stack className={styles.paperStyle}>
            <TableTop Title={privacyTableContent.heading} />

            {privacyTableContent.arrOfObj.map((item) => (
              <TableTopDesc Title={item} handleNavigate={handleNavigate} />
            ))}
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
};

export default PrivacyPolicy;
