import React,{useEffect} from "react";
import { Stack} from "@mui/material";
import CarouselCards from "../molecules/HomePagemolecules/CarouselCard";
import DocConsultation from "../molecules/HomePagemolecules/DocConsultation";
import RecentReport from "../molecules/HomePagemolecules/RecentReport";
import HeroSection from "../molecules/HomePagemolecules/HeroSection";
import LabBanner from "../molecules/HomePagemolecules/LabBanner";
import ShieldBanner from "../molecules/HomePagemolecules/ShieldBanner";



export const Home = () => {
  useEffect(() => {
    window.scrollTo(0,0);
    return () => {
      
    };
  }, []); 
  return (
      <Stack spacing={8} >
          <HeroSection />
          <CarouselCards />
          <RecentReport />
          <DocConsultation />
          <LabBanner />
          <ShieldBanner />
               
      </Stack>    
  )
}
