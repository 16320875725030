import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import img from "../assets/family.jpeg";
import { Button, Stack, Box } from "@mui/material";
import styles from "../styling/familyBanner.module.css";
import { everydayHealth } from "../json/EverydayHealth";
import { useSharedData } from "../Context/ShareContext";
import NewGenericPopup from "./NewGenericPopup";
import { useNavigate } from "react-router-dom";

export default function FamilyBanner() {
  const openInNewTab = (url) => {
    window.open(url, "_blank", "noreferrer");
  };

  const { showPopup, setShowPopup,setIndexOfSubheader, setActiveButtonIndex } = useSharedData();

  const navigate= useNavigate();

  const selectedLocation = { NAME: "Mumbai" };

  const navigateContact = () => {
    setIndexOfSubheader(false);
    setActiveButtonIndex(2);

    
    navigate("/login/contactus", {
      state: {
        selectedLocation
      },
    });
  };
  return (
    <Box data-testid="family-banner">
      <Card className={styles.familyCard} elevation={0}>
        <Stack className={styles.familyStack}>
          <CardContent sx={{ p: 0, "&:last-child": { pb: 0 } }}>
            <Box className={styles.familyContent}>
              <Typography
                align="left"
                className={styles.familyTitle}
                data-testid="check_heading"
              >
                {everydayHealth?.FAMILY?.CHOOSE}{" "}
                <span className={styles.highlight}>
                  {everydayHealth?.FAMILY?.FAM}
                </span>
              </Typography>
              <Typography
                color="text.primary"
                align="left"
                className={styles.familySubtitle}
              >
                {everydayHealth?.FAMILY?.DESC}
              </Typography>

              <Typography align="left">
                <Button
                  className={styles.familyBtn}
                  // onClick={() =>
                  //   openInNewTab(
                  //     "https://www.healthspring.in/book-an-appointment"
                  //   )
                  // }
                  onClick={navigateContact}
                >
                  {everydayHealth?.FAMILY?.BOOK}
                </Button>
              </Typography>
            </Box>
          </CardContent>
        </Stack>
        <CardMedia
          component="img"
          alt="family_img"
          image={img}
          className={styles.familyImg}
        />
      </Card>
      {/* {showPopup && (
        <NewGenericPopup
          SubTxt="Thank you for visiting, the new website is temporarily unavailable, we will redirect you to our existing website for now. Thank you for your patience and understanding ."
          SubBtnTxt="Cancel"
          ShowSubBtn={true}
          PlaceholderTxt={""}
        />
      )} */}
    </Box>
  );
}
