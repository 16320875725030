import React, { useState } from 'react';
import { Stack, IconButton, Typography, Box } from '@mui/material';
import AppointmentCards from './AppointmentCards';
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import { Appointments, Appointment } from '../../json/Appoinment';
import useAppStyles from '../../hooks/useAppStyles';
import styles from "../../styling/CarouselCard.module.css"; 
import { SiteContent } from '../../assets/content/SiteContent';

export default function CarouselCards() {
  const [startIndex, setStartIndex] = useState(0);
  const appStyles = useAppStyles();
  const handleNext = () => {
    const nextIndex = startIndex + 2;
    if (nextIndex < Appointment?.length) {
      setStartIndex(nextIndex);
    }
  };

  const handlePrevious = () => {
    const previousIndex = startIndex - 2;
    if (previousIndex >= 0) {
      setStartIndex(previousIndex);
    }
  };

  return (
    <Box>
      <Stack spacing={3} className={styles["carousel-stack"]}>
        <Stack direction="row" alignContent="center">
          <Typography variant="h1"  align="left"  flexGrow={1} color={appStyles.typographyStyle2} className={styles.bold}>
            {SiteContent.UPCOMING_APPOINTMENTS}
          </Typography>
          <IconButton disabled={startIndex === 0} onClick={handlePrevious}>
            <ArrowBackOutlinedIcon className={`${styles["arrow-icon"]} ${startIndex === 0 ? '' : styles["arrow-icon-activated"]}`} />
          </IconButton>
          <IconButton disabled={startIndex + 2 >= Appointments?.length} onClick={handleNext}>
            <ArrowForwardOutlinedIcon className={`${styles["arrow-icon"]} ${startIndex + 2 >= Appointment?.length ? '' : styles["arrow-icon-activated"]}`} />
          </IconButton>
        </Stack>

        <Stack direction={{ xs: "column", sm: "row", md: "row" }} spacing={2} justifyContent="space-between" >
          {Appointment?.slice(startIndex, startIndex + 2).map(appointment => (
            <AppointmentCards
              key={appointment?.id}
              name={appointment?.name}
              designation={appointment?.designation}
              date={appointment?.date}
              time={appointment?.time}
              image={appointment?.avatars}
              avatar={appointment?.image}
            />
          ))}
        </Stack>
      </Stack>
    </Box>
  );
}
