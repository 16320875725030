import { Box, Drawer, Stack, Typography } from "@mui/material";
import CheckoutAccordian from "./CheckoutAccordian";
import React from "react";
import styles from "../styling/TestPackageBooking.module.css";
import { testPackageBookingTexts } from "../json/TestPackageBookingTexts";
import DoctorScheduleForTest from "./DoctorScheduleForTest";
import LocationMenu from "./LocationMenu";
import { useState } from "react";
import DoctorScheduleForNew from "./DoctorScheduleForNew";

export default function TestPackageBooking({
  iconImage,
  header,
  title,
  data,
  description,
  extraDescription,
}) {
  const [locationMenu, setLocationMenu] = useState(false);

  const openLocationMenu = (bool) => {
    setLocationMenu(bool);
  };

  const handleCloseLocationMenu = () => {
    setLocationMenu(false);
  };
  return (
    <>
      <Stack
        direction={{ xs: "column", sm: "row" }}
        className={styles.labdetails_page_main_stack}
        data-testid="doctor_details_box"
      >
        <Stack spacing={2} sx={{ width: { xs: "100%", sm: "55%" } }}>
          <Stack spacing={4} direction="row" className={styles.mainContainer}>
            <Stack>
              <Stack className={styles.leftContent}>
                <Box className={styles.initialImage}>
                  <Stack
                    direction="row"
                    className={styles.workhealthspring_stack}
                  >
                    <img
                      src={iconImage}
                      alt="Icon"
                      className={styles.iconImage}
                    />
                    <Typography
                      className={styles.workhealthspring_typoone}
                      variant="h4"
                    >
                      {header}
                    </Typography>
                  </Stack>
                </Box>

                <Stack spacing={2}>
                  <CheckoutAccordian title={title} data={data} />
                </Stack>
                <Typography>
                  <Typography className={styles.descHeader}>
                    {testPackageBookingTexts?.descriptionHeader}
                  </Typography>
                  <Typography className={styles.descTxt}>
                    {description}
                    <br />
                    <br />
                    {extraDescription}
                  </Typography>
                </Typography>
              </Stack>
              <Typography className={styles.descHeader}>
                {testPackageBookingTexts?.testRequirementsHeader}
              </Typography>

              <Stack spacing={1} className={styles.packageDetails}>
                <Stack className={styles.invoiceItems}>
                  <Stack className={styles.spacerClass}>
                    <Typography className={styles.pkgDtls}>
                      {testPackageBookingTexts?.sampleTypeLabel}
                    </Typography>
                    <Typography className={styles.pkgQty}>Blood</Typography>
                  </Stack>
                  <Stack className={styles.spacerClass}>
                    <Typography className={styles.pkgDtls}>
                      {testPackageBookingTexts?.fastingLabel}
                    </Typography>
                    <Typography className={styles.pkgQty}>
                      {testPackageBookingTexts?.fastingLabel}
                    </Typography>
                  </Stack>
                  <Stack className={styles.spacerClass}>
                    <Typography className={styles.pkgDtls}>
                      {testPackageBookingTexts?.tubeTypeLabel}
                    </Typography>
                    <Typography className={styles.pkgQty}>Plain</Typography>
                  </Stack>
                </Stack>
              </Stack>

              <Stack className={styles.spacernewClass}>
                <Typography className={styles.pkgDtLs}>
                  {testPackageBookingTexts?.priceLabel}
                </Typography>
                <Typography className={styles.pkgqty}>
                  {testPackageBookingTexts?.currencySymbol}499
                </Typography>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
        <Stack className={styles.calender_stack}>
          <DoctorScheduleForNew layout="test"/>
        </Stack>
      </Stack>
      <Drawer
        anchor="right"
        open={locationMenu}
        onClose={() => handleCloseLocationMenu()}
        sx={{
          display: { xs: "block", sm: "block" },
          "& .MuiDrawer-paper": { boxSizing: "border-box", width: "350px" },
        }}
      >
        <LocationMenu onClose={() => handleCloseLocationMenu()} />
      </Drawer>
    </>
  );
}
