import React from "react";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { corporate_dynamic } from "../json/corporateDynamic";
import styles from "../styling/WorkplaceCard.module.css";

export const WorkplaceCard = () => {
  const cardsPerRow = 3;

  const rows = [];
  for (let i = 0; i <  corporate_dynamic?.BANNER_2?.CARD_DATA?.length; i += cardsPerRow) {
    const rowCards =  corporate_dynamic?.BANNER_2?.CARD_DATA?.slice(i, i + cardsPerRow);
    rows.push(
      <Stack
        key={`row_${i}`}
        direction={{ xs: "column", sm: "column", md: "row" }}
        justifyContent="space-between"
        alignItems="center"
         spacing={7}
      >
        {rowCards.map((card) => (
          <Card
            className={styles["workplacecard-card"]}
            key={card?.ID}
            elevation={20}
            data-testid="workplacecard"
          >
            <CardMedia
              className={styles["card-medias"]}
              component="img"
              image={card?.ICON}
              data-testid="card-media"
            />
            <CardContent className={styles["content"]}>
              <Typography
                className={styles["workplacetyposss-typography_new"]}
                variant="h5"
                data-testid="card-title"
              >
                {card?.TITLE}
              </Typography>
            </CardContent>
          </Card>
        ))}
      </Stack>
    );
  }

  return (
    <>
      <Box data-testid="workplace">
        <Stack mb={8}>
          <Typography
            className={styles["workplacetypos-typography"]}
            variant="h4"
          >
            {corporate_dynamic?.BANNER_2?.TITLE?.TITLE_1}
          </Typography>
          <Typography
            className={styles["workplacetyposs-typography"]}
            variant="h4"
          >
           {corporate_dynamic?.BANNER_2?.TITLE?.TITLE_2}
          </Typography>
        </Stack>
        <Box className={styles["workplacecard-box"]}>
          <Stack direction="column" spacing={10}>
            {rows}
          </Stack>
        </Box>
      </Box>
    </>
  );
};
