export const doc = {
  desc: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.`,
  name: "Dr. Raj Avasthi",
  occupation: "Physician",
  exp: "7+ Years Experience",
  rating: "4 Star Ratings",
  about: "About Me",
  degree: "Education and Certifications",
  qualification: "MBBS, MD, DNB",
  language: "Languages",
  lang: "English, Hindi, Gujrati",
  services: "Services",
  teeth: "Teeth Cleaning",
  hydra: "Hydra Facial",
  root: "Root Canal",
  centre: "Centre Location",
  loc: ["Nerul, Navi Mumbai", "Vashi, Navi Mumbai", "Kharghar, Navi Mumbai"],
  direction: "Get Directions",
  appotype: {
    type: "Appointment Type",
    onsite: "On Site",
    call: "Call",
    clinic: "Inclinic",
    price: "Price/Session",
    amount: "1999",
  },
};
