import Avatar from "@mui/material/Avatar";
import {  Home } from "@mui/icons-material";
import { Box } from "@mui/material";
import pic1 from "../assets/images/dumble.svg";
import styles from "../styling/DropDownCard.module.css";

export const Completed = [
  {
    name: "Weight Gain 1 Month Package",
    date: "02 Nov, 22",
    icon: (
      <Box
        component="img"
        src={pic1}
        className={styles.weight}
       
      />
    ),
  },
  {
    name: "Home Advance",
    date: "21 Oct, 22",
    icon: (
      <Avatar sx={{ bgcolor: "#f5aa04", width: "40px", height: "40px" }}>
        <Home />
      </Avatar>
    ),
  },
];
