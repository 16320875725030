import React, { useState,useEffect } from "react";
import {
  Typography,
  Stack,
  Box,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import { LocationArr_dynamic } from "../json/contactUs";

import ContactCard from "./ContactCard";
import styles from "../styling/ContactCenterLocations.module.css";

const ContactCenterLocations = ({selectedLocations}) => {
  const locationList = Object.keys(LocationArr_dynamic);
  const [locationItem, setLocationItem] = useState(selectedLocations?.NAME || locationList[0]);

  const selectedLocation = LocationArr_dynamic[locationItem];
 
  const handleLocationClick = (item) => {
    setLocationItem(item);
    
  };
 

  return (
    <Box>
      <Stack>
        <Typography variant="h1" className={styles.center_location}>
          Center Locations
        </Typography>
        <Stack direction={{ xs: "column", sm: "column", md: "row" }} spacing={4}>
          <Stack>
            <List
              component="nav"
              aria-label="mailbox folders"
              className={styles.contact_location_list}
            >
              {locationList?.map((item) => (
                <ListItem
                 
                  className={locationItem === item ? styles.listitemactive : styles.listnotactive}
                  key={item}
                  onClick={() => handleLocationClick(item)}
                >
                  <ListItemText
                    primary={
                      <Typography
                        variant="body2"
                        className={styles.center_location_item}
                      >
                        {item}
                      </Typography>
                    }
                  />
                </ListItem>
              ))}
            </List>
          </Stack>

          {locationItem && (
            <Stack spacing={3} className={styles.contact_center_location}>
              <Stack spacing={{ xs: 4, sm: 2 }} justifyContent="space-evenly">
                {selectedLocation?.map((item, index) => {
                  if (index % 2 === 0) {
                    return (
                      <Stack
                        direction={{ xs: "column", sm: "row" }}
                        spacing={{ xs: 4, sm: 2 }}
                        key={index}
                      >
                        <ContactCard
                          name={item?.name}
                          address1={item?.address1}
                          address2={item?.address2}
                          address3={item?.address3}
                          address4={item?.address4}
                          phone={item?.phone}
                        />
                        {index + 1 < selectedLocation.length && (
                          <ContactCard
                            name={selectedLocation[index + 1].name}
                            address1={selectedLocation[index + 1].address1}
                            address2={selectedLocation[index + 1].address2}
                            address3={selectedLocation[index + 1].address3}
                            address4={selectedLocation[index + 1].address4}
                            phone={selectedLocation[index + 1].phone}
                          />
                        )}
                      </Stack>
                    );
                  }
                  return null;
                })}
              </Stack>
            </Stack>
          )}
        </Stack>
      </Stack>
    </Box>
  );
};

export default ContactCenterLocations;
