import React, { useState } from "react";
import { Tab, Tabs, Typography, Stack, Box, Avatar, Grid } from "@mui/material";
import capture from "../assets/images/Capture.png";
import  styles from "../styling/AccountInformation.module.css";
import { healthprofile,title } from "../json/HealthProfile";
import { Vitals } from "./Vitals";
import { BodyParameters } from "./BodyParameters";

function a11yProps(index) {
  return {
    id: `nav-tab-${index}`,
    "aria-controls": `nav-tabpanel-${index}`,
  };
}

export const HealthProfile = () => {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <Box>
      <Stack mb={3}>
        <Typography className={styles["accinfo-title"]} variant="h4">
          {title?.header}
        </Typography>
        <Box className={styles["accinfo-boxCard"]}>
          <Stack direction="row" p={2} alignItems="center">
            <Avatar
              alt="Avatar"
              src={capture}
              className={styles["accinfo-avatarImage"]}
            />
            <Stack p={2}>
              <Typography className={styles["accinfo-name"]} variant="h3">
                {title?.person.name}
              </Typography>
              <Typography className={styles["accinfo-email"]} variant="h5">
                {title?.person.age}
              </Typography>
            </Stack>
          </Stack>
        </Box>
      </Stack>

      <Typography className= {styles["healthprofile-mainTitle"]} variant="h4">
        Personal Details
      </Typography>
      <Stack className={styles["healthprofile-mainBox"]}>
  {healthprofile?.map((item, index) => (
    <React.Fragment key={index}>
      {index % 2 === 0 && (
        <Stack direction={{xs:"column",sm:"row"}} justifyContent="space-between">
          <Stack className={styles["healthprofile-mainStack"]} flex={1}>
            {healthprofile[index]?.icon}
            <Stack className={styles["healthprofile-subStack"]}>
              <Typography className={styles["healthprofile-typoTitle"]}>
                {healthprofile[index]?.title}
              </Typography>
              <Typography className={styles["healthprofile-typoSubTitle"]}>
                {healthprofile[index]?.desc}
              </Typography>
            </Stack>
          </Stack>
          {index + 1 < healthprofile.length && (
            <Stack className={styles["healthprofile-mainStack"]}flex={1}>
              {healthprofile[index + 1]?.icon}
              <Stack className={styles["healthprofile-subStack"]}>
                <Typography className={styles["healthprofile-typoTitle"]}>
                  {healthprofile[index + 1]?.title}
                </Typography>
                <Typography className={styles["healthprofile-typoSubTitle"]}>
                  {healthprofile[index + 1]?.desc}
                </Typography>
              </Stack>
            </Stack>
          )}
        </Stack>
      )}
    </React.Fragment>
  ))}
</Stack>


      <Stack direction="row" className={styles["accinfo-tabStack"]}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="nav tabs example"
          
        >
          <Tab
            label={<Typography variant="h5">{title?.vitals}</Typography>}
            {...a11yProps(0)}
          />
          <Tab
            label={<Typography variant="h5">{title?.body}</Typography>}
            {...a11yProps(1)}
          />
        </Tabs>
      </Stack>
      <Stack>{value === 0 && <Vitals />}</Stack>
      <Stack >{value === 1 && <BodyParameters />}</Stack>
    </Box>
  );
};
