import React from "react";
import {
  Stack,
  Box,
  Avatar,
  Typography,
  Button,
  TextField,
  InputAdornment,
  Divider,
} from "@mui/material";
import styles from "../../styling/RegistrationDetails.module.css";
import Profile from "../../assets/images/profile.png";
import { SiteContent } from "../../assets/content/SiteContent";
import {
  AccountCircleOutlined,
  CakeOutlined,
  CallOutlined,
  EmailOutlined,
  PersonOutlineOutlined,
  TransgenderOutlined,
} from "@mui/icons-material";
import { useSharedData } from "../../Context/ShareContext";
import { useFormik } from "formik";
import * as yup from "yup";

const ProfileDetails = () => {
  const { currentSteps, setCurrentSteps } = useSharedData();

  const moveNextStep = () => {
    setCurrentSteps(currentSteps + 1);
  };

  const validationSchema = yup.object({
    email: yup.string().email("Enter a valid email").required("Email is mandatory"),
    name: yup
      .string()
      .matches(/^[A-Za-z\s\-']{1,50}$/, "Name is not valid")
      .min(5, "Name is too short, must be at least 5 characters")
      .max(50, "Name is too long, must be at most 50 characters")
      .required("Name is mandatory"),
    mobile: yup
      .string()
      .matches(
        /^[0-9\s\-']+$/,
        "Only numeric characters, spaces, hyphens, and apostrophes are allowed"
      )
      .min(10, "Mobile number must be of 10 digits")
      .max(10, "Mobile number is too long, must be at most 10 digits")
      .required("Mobile number is mandatory"),
    age: yup.string().matches(/^\d+$/, "Invalid Age").required("Field is mandatory"),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      mobile: "",
      gender: "",
      dob: "",
      age: "",
    },
    validationSchema: validationSchema,
    onSubmit: () => {
      moveNextStep();
    },
  });

  return (
    <form onSubmit={formik?.handleSubmit}>
      <Box className={styles.regdtls_box} data-testid="profile">
        <Typography className={styles.regdtls_main_txt} variant="h4">
          {SiteContent.FILL_YOUR_PROFILE}
        </Typography>
        <Avatar src={Profile} className={styles.regdtls_logo} alt="Profile Logo" />
        <Stack direction="column">
          <Stack item xs={6}>
            <Typography className={styles.regdtls_txtfldtitle} variant="h5">
              {SiteContent.NAME}
            </Typography>
            <TextField
              className={styles.regdtls_txtfld}
              variant="standard"
              name="name"
              onChange={(e) =>
                formik?.handleChange(e?.target?.name)(
                  e?.target?.value.replace(/[^A-Za-z\s\-']/g, "")
                )
              }
              onBlur={formik?.handleBlur}
              value={formik?.values?.name}
              error={formik?.touched?.name && Boolean(formik?.errors?.name)}
              InputProps={{
                className: styles.regdtls_txtfldinpt,
                disableUnderline: true,
                endAdornment: (
                  <InputAdornment data-testid="name-icon">
                    <AccountCircleOutlined
                      className={styles.regdtls_icon_color}
                    />
                  </InputAdornment>
                ),
              }}
            />
            {formik?.touched?.name && formik?.errors?.name ? (
              <Typography color="error" className={styles.validation}>
                {formik?.errors?.name}
              </Typography>
            ) : null}
          </Stack>
          <Stack item xs={6}>
            <Typography className={styles.regdtls_txtfldtitle} variant="h5">
              {SiteContent.EMAIL}
            </Typography>
            <TextField
              className={styles.regdtls_txtfld}
              variant="standard"
              onChange={formik?.handleChange}
              onBlur={formik?.handleBlur}
              name="email"
              value={formik?.values?.email}
              error={formik?.touched?.email && Boolean(formik?.errors?.email)}
              InputProps={{
                className: styles.regdtls_txtfldinpt,
                disableUnderline: true,
                endAdornment: (
                  <InputAdornment data-testid="email-icon">
                    <EmailOutlined className={styles.regdtls_icon_color} />
                  </InputAdornment>
                ),
              }}
            />
            {formik?.touched?.email && formik?.errors?.email ? (
              <Typography color="error" className={styles.validation}>
                {formik?.errors?.email}
              </Typography>
            ) : null}
          </Stack>
          <Stack item xs={6}>
            <Typography className={styles.regdtls_txtfldtitle} variant="h5">
              {SiteContent.MOBILE_NUMBER}
            </Typography>
            <TextField
              className={styles.regdtls_txtfld}
              name="mobile"
              onChange={(e) =>
                formik?.handleChange(e?.target?.name)(
                  e?.target?.value?.replace(/[^0-9]/g, "")
                )
              }
              variant="standard"
              onBlur={formik?.handleBlur}
              value={formik?.values?.mobile}
              error={formik?.touched?.mobile && Boolean(formik?.errors?.mobile)}
              InputProps={{
                className: styles.regdtls_txtfldinpt,
                disableUnderline: true,
                endAdornment: (
                  <InputAdornment data-testid="mobile-icon">
                    <CallOutlined className={styles.regdtls_icon_color} />
                  </InputAdornment>
                ),
              }}
            />
            {formik?.touched?.mobile && formik?.errors?.mobile ? (
              <Typography color="error" className={styles.validation}>
                {formik?.errors?.mobile}
              </Typography>
            ) : null}
          </Stack>
          <Stack item xs={6}>
            <Typography className={styles.regdtls_txtfldtitle} variant="h5">
              {SiteContent.GENDER}
            </Typography>
            <TextField
              className={styles.regdtls_txtfld}
              variant="standard"
              name="gender"
              onChange={formik?.handleChange}
              onBlur={formik?.handleBlur}
              value={formik?.values?.gender}
              error={formik?.touched?.gender && Boolean(formik?.errors?.gender)}
              InputProps={{
                className: styles.regdtls_txtfldinpt,
                disableUnderline: true,
                endAdornment: (
                  <InputAdornment data-testid="gender-icon">
                    <TransgenderOutlined
                      className={styles.regdtls_icon_color}
                    />
                  </InputAdornment>
                ),
              }}
            />
          </Stack>
          <Stack item xs={6}>
            <Typography className={styles.regdtls_txtfldtitle} variant="h5">
              {SiteContent.DOB}
            </Typography>
            <TextField
              className={styles.regdtls_txtfld}
              variant="standard"
              name="dob"
              onChange={formik?.handleChange}
              onBlur={formik?.handleBlur}
              value={formik?.values?.dob}
              error={formik?.touched?.dob && Boolean(formik?.errors?.dob)}
              InputProps={{
                className: styles.regdtls_txtfldinpt,
                disableUnderline: true,
                endAdornment: (
                  <InputAdornment data-testid="dob-icon">
                    <CakeOutlined className={styles.regdtls_icon_color} />
                  </InputAdornment>
                ),
              }}
            />
          </Stack>
          <Divider className={styles.regdtls_divider}>OR</Divider>
          <Stack item xs={6}>
            <Typography className={styles.regdtls_txtfldtitle} variant="h5">
              {SiteContent.AGE}
            </Typography>
            <TextField
              className={styles.regdtls_txtfld}
              variant="standard"
              name="age"
              onChange={(e) =>
                formik?.handleChange(e?.target?.name)(
                  e?.target?.value?.replace(/[^0-9]/g, "")
                )
              }
              onBlur={formik?.handleBlur}
              value={formik?.values?.age}
              error={formik?.touched?.age && Boolean(formik?.errors?.age)}
              InputProps={{
                className: styles.regdtls_txtfldinpt,
                disableUnderline: true,
                endAdornment: (
                  <InputAdornment data-testid="age-icon">
                    <PersonOutlineOutlined
                      className={styles.regdtls_icon_color}
                    />
                  </InputAdornment>
                ),
              }}
            />
            {formik?.touched?.age && formik?.errors?.age ? (
              <Typography color="error" className={styles.validation}>
                {formik?.errors?.age}
              </Typography>
            ) : null}
          </Stack>
        </Stack>
      </Box>

      <Button className={styles.regdtls_continue_button} type="submit">
        {SiteContent.continue}
      </Button>
    </form>
  );
};

export default ProfileDetails;
