import React, { useState } from "react";
import { Box, Stack, Typography, Button, Drawer } from "@mui/material";
import AccessTimeFilledIcon from "@mui/icons-material/AccessTimeFilled";
import styles from "../styling/CategoryCard.module.css";

import { slots } from "../json/slots";
import LightModeIcon from "@mui/icons-material/LightMode";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import MyLocationIcon from "@mui/icons-material/MyLocation";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import doctorstyles from "../styling/DoctorSchedule.module.css";
import { Link, useParams } from "react-router-dom";
import LocationMenu from "./LocationMenu";
export default function Tab5DoctorSchedule({
  tab,
  Btn,
  url,
  book,
}) {
  const [locationMenu, setLocationMenu] = useState(false);
  const [selectedSlotIndex, setSelectedSlotIndex] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [Upper, setUpper] = useState(null);


  

  const openLocationMenu = (bool) => {
    setLocationMenu(bool);
  };

  const handleDrawerToggle = () => {
      openLocationMenu(true);
  };

  const handleCloseLocationMenu = () => {
    setLocationMenu(false);
  };

  return (
    <div>
      
      {tab === "tab3" && (
        <Stack
          spacing={2}
          direction="row"
          className={doctorstyles.location_dropdown_schedule}
          onClick={handleDrawerToggle}
          
        >
          <Stack direction="row" spacing={2} flexGrow={1} >
          <MyLocationIcon color="success" />
          <Typography>Navi Mumbai</Typography>
          </Stack>

          <KeyboardArrowDownIcon />
        </Stack>
      )}

      <Stack direction="row" className={doctorstyles.available_layout}>
        <Typography variant="h5">Available Sessions</Typography>
        <span className={doctorstyles.dummy_div} />
        <Stack direction="row" className={doctorstyles.duration_layout}>
          <AccessTimeFilledIcon
            className={doctorstyles.timer_icon}
            size="small"
            color="primary"
          />

          <Typography variant="h5" color="primary">
            15 min duration
          </Typography>
        </Stack>
      </Stack>
      <Box className={doctorstyles.box_scroll}>
        {slots?.map((item, i) => (
          <Stack spacing={2} justifyContent="space-between" mt={2}>
            <Stack direction="row" spacing={2}  alignItems="center">
              <img
              src={item.img}
                className={doctorstyles.icons_slot}
              />
              <Typography variant="h5" align="left" color="primary">
                {item?.name} 
              </Typography>
            </Stack>
            <Stack
              direction="row"
              spacing={{ xs: 1, sm: 2 }}
              useFlexGap
              flexWrap="wrap"
              justifyContent={{xs:"space-between",sm:"unset"}}
              pr={{xs:0.5,sm:"unset"}}
             
            >
              {item?.slots?.map((ele, index) => (
                <Typography
                  key={index}
                  onClick={() => {setSelectedSlotIndex(index) ; setUpper(i) ;setSelectedItem(ele)}}
                  className={`${doctorstyles.border} ${
                    index === selectedSlotIndex && i ===Upper
                      ? doctorstyles.selected_slot
                      : ``
                  }`}
                >
                  {ele}
                </Typography>
              ))}
            </Stack>
          </Stack>
        ))}
      </Box>
      <Link
        to={url}
        className={styles.linkNoUnderline}
      >
        <Button
          className={doctorstyles.proceed_btn}
          endIcon={
            <Stack className={doctorstyles.backView}>
              <KeyboardArrowRightIcon
                className={doctorstyles.svg_image_white}
              />
            </Stack>
          }
        >
          {book === "test" ? ("Book Test") : ("Proceed To Book")}
        </Button>
      </Link>
      <Drawer
        anchor="right"
        open={locationMenu}
        onClose={() => handleCloseLocationMenu()}
        sx={{
          display: { xs: "block", sm: "block" },
          "& .MuiDrawer-paper": { boxSizing: "border-box", width: 350 },
        }}
      >
        <LocationMenu onClose={() => handleCloseLocationMenu()} />
      </Drawer>
    </div>
  );
}
