import React from "react";
import { Box, Typography, Stack } from "@mui/material";
import useAppStyles from "../hooks/useAppStyles";
import { SiteContent } from "../assets/content/SiteContent";
import styles from "../styling/Consult.module.css";
import TestPackageHealthCheck from "../molecules/TestPackageHealthCheck";
import Bread from "../molecules/Bread";
import { pkgcategories } from "../json/pkgCategories";
import defaultStyles from "../styling/DefaultStyles.module.css";

export const TestAndPackages = () => {
  const appStyles = useAppStyles();
  return (
    <>
      <Stack className={defaultStyles.max_width_stack}>
        <Stack>
          <Bread />
          <Box className={styles.box_styling}>
            <Stack className={styles.grid_styling}>
              <Stack>
                <Typography
                  variant="h1"
                  sx={{ color: appStyles.typographyStyle2 }}
                  className={styles.page_heading_text}
                >
                  {SiteContent.LETS_FIND}
                  <br />
                  {SiteContent.TEST_AND_PACKAGES}
                </Typography>
              </Stack>
              <Stack
                direction="row"
                flexWrap="wrap"
                spacing={5}
                useFlexGap
                className={styles.cards_stack}
              >
                {pkgcategories?.map((item, index) => {
                  return (
                    <React.Fragment key={index}>
                      <TestPackageHealthCheck key={index} category={item} />
                    </React.Fragment>
                  );
                })}
              </Stack>
            </Stack>
          </Box>
        </Stack>
      </Stack>
    </>
  );
};
