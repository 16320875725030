import pic1 from "../assets/approach1.png";
import pic2 from "../assets/approach2.png";
import pic3 from "../assets/approach3.png";
import pic4 from "../assets/approach4.png";
import pic5 from "../assets/ico12.png";
import pic6 from "../assets/Specialists.png";
import pic7 from "../assets/Diet.png";
import pic8 from "../assets/mentalwell.png";
import pic9 from "../assets/urgent.png";
import pic10 from "../assets/dental.png";
import pic11 from "../assets/physio.png";
import pic12 from "../assets/vaccination.png";
import pic13 from "../assets/healthcheck.png";
import pic14 from "../assets/laboratory.png";
import pic15 from "../assets/xrays.png";
import pic16 from "../assets/emergency.png";

import Mumbai from "../assets/Mumbai.png";
import NaviMumbai from "../assets/NaviMumbai.png";
import Pune from "../assets/Pune.png";
import Thane from "../assets/Thane.png";

import pack1 from "../assets/pack1.png";
import pack2 from "../assets/pack2.png";
import pack3 from "../assets/pack3.png";
import pack4 from "../assets/pack4.png";
import doctor from "../assets/doctorconsult.svg";
import diet from "../assets/diet.svg";
import special from "../assets/Specialists.png";
import mental from "../assets/mental.svg";
export const everydayHealth = {
  FAMILY: {
    CHOOSE: "Choose Healthspring For Your",
    FAM: "Family",
    DESC: `Proactively ensure that your family’s health is taken care of. Join the Healthspring community today for a healthier, happier future for your loved ones.`,
    BOOK: "Book an Appointment",
  },
  EVERYDAY_HEALTH: [
    {
      ID: 1,
      NAME: "Predictive",
      ICON: pic1,
      DESCRIPTION:
        "Harnessing cutting-edge technologies, we anticipate health risks before they emerge, empowering you with proactive insights for a healthier future.",
    },
    {
      ID: 2,
      NAME: "Preventive",
      ICON: pic2,
      DESCRIPTION:
        "Our approach to wellness goes beyond treating illnesses. We focus on preventing health issues, providing you with the tools and guidance to lead a life of vitality.",
    },
    {
      ID: 3,
      NAME: "Participative",
      ICON: pic3,
      DESCRIPTION:
        "Your health, your way. Experience a healthcare revolution tailored to your unique needs.",
    },
    {
      ID: 4,
      NAME: "Personalised",
      ICON: pic4,
      DESCRIPTION:
        "Encouraging active involvement and collaboration between healthcare providers and patients in the decision-making process.",
    },
  ],
  SERVICES: {
    LIST: [
      "Consultations",
      "Out-Patient Care",
      "Lab Tests & Packages",
      "Emergency Medical Care",
    ],
    OUR: "Our Services",
    ALL: "More Services",
    DOCTORCONSULTATIONS: [
      {
        ID: 1,
        NAME: "General Physician",
        ICON: doctor,
      },
      {
        ID: 2,
        NAME: "Specialists",
        ICON: special,
      },
      {
        ID: 3,
        NAME: "Diet & Nutrition",
        ICON: diet,
      },
      {
        ID: 4,
        NAME: "Mental Health",
        ICON: mental,
      },
    ],
    CONSULTATIONS: [
      {
        ID: 1,
        NAME: "General Physician",
        ICON: pic5,
        DESCRIPTION:
          "Every single consult at Healthspring is of immense importance and we have a team of qualified, experienced, expert physicians to assist you with a wide range of general queries.",
      },
      {
        ID: 2,
        NAME: "Specialists",
        ICON: pic6,
        DESCRIPTION:
          "For your specific concerns, our team of specialists works closely with you to identify your condition and address it in the best way possible.",
      },
      {
        ID: 3,
        NAME: "Diet & Nutrition",
        ICON: pic7,
        DESCRIPTION:
          "We believe in holistic healthcare and our team of certified nutritionists guides you towards maintaining a healthy, nutritive diet.",
      },
      {
        ID: 4,
        NAME: "Mental Wellness",
        ICON: pic8,
        DESCRIPTION:
          "Mental healthcare is an extremely significant aspect of the overall health and wellness framework, and our team of trained mental health professionals aims to help tackle a range of conditions.",
      },
    ],
    OUTPATIENT: [
      {
        NAME: "Urgent Care",
        ICON: pic9,
        DESCRIPTION:
          "We provide 24x7 Medical Emergency Response cover to all our registered patients. With a helpline that is functional round the clock, we make emergency care hassle-free for you.",
      },
      {
        NAME: "Dental care",
        ICON: pic10,
        DESCRIPTION:
          "We have a dedicated team of qualified dentists and specialist orthodontists who effectively manage all your dental care concerns.",
      },

      {
        NAME: "Physiotherapy",
        ICON: pic11,
        DESCRIPTION:
          "Our in-house team of physiotherapists helps you with all your needs, ranging from pain management to rehabilitation.",
      },
      {
        NAME: "Vaccinations",
        ICON: pic12,
        DESCRIPTION:
          "It can be quite stressful to manage your entire family’s vaccination schedules, but we help you keep a track of all your vaccinations and record your history so that you don’t have to worry about a thing!",
      },
    ],
    LABTEST: [
      {
        NAME: "Health Check Packages",
        ICON: pic13,
        DESCRIPTION:
          "We have a range of packages for a variety of needs to ensure that healthcare is accessible and affordable for every patient and their family. Our packages help monitor your family’s health by streamlining the entire healthcare process to make check-ups and tests convenient.",
      },
      {
        NAME: "Laboratory Tests",
        ICON: pic14,
        DESCRIPTION:
          "We administer and manage a range of lab tests for our patients. With our accurate reporting and quick delivery, all your test results are taken care of.",
      },
      {
        NAME: "X-rays & Scans",
        ICON: pic15,
        DESCRIPTION:
          "We understand that getting X-rays and Scans can be hectic and chaotic for many, which is why we have an in-house team that handles that for you, so that all your healthcare needs are taken care of under one roof.",
      },
    ],
    EMERGENCY: [
      {
        NAME: "Emergency Medical Care",
        ICON: pic16,
        DESCRIPTION:
          "We provide round the clock emergency medical care to efficiently manage all your family’s emergencies. With our prompt response management systems and on-call assistance, we ensure that all your concerns are addressed",
      },
    ],
  },
  LOCATION: {
    TITLE: "Center Location",
    LIST: [
      {
        NAME: "Mumbai",
        ICON: Mumbai,
      },
      {
        NAME: "Navi Mumbai",
        ICON: NaviMumbai,
      },
      {
        NAME: "Pune",
        ICON: Pune,
      },
      {
        NAME: "Thane",
        ICON: Thane,
      },
    ],
  },
  PACKAGES: [
    {
      NAME: "Health Silver",
      ICON: pack1,
      DESCRIPTION:
        "Diagnostic tests are the first line of defence against any disease in a preventive situation. Therefore it becomes extremely crucial to get tested early & regularly to detect early-onset conditions which can later pose serious health challenges.",
      PRICE: "2999",
    },
    {
      NAME: "Health Gold",
      ICON: pack2,
      DESCRIPTION:
        "Your Health check is more than a battery of tests. It should be right for your body and your wallet. Introducing a comprehensive Health Gold Health check that helps you monitor and upkeep your health parameters. We will assist you in finding your health...",
      PRICE: "4999",
    },
    {
      NAME: "Health Gold Plus",
      ICON: pack3,
      DESCRIPTION:
        "It is important to get frequent screening tests as you get older, especially beyond the age of 35. These tests can help you spot potential health problems and diagnose your illness before any symptoms appear. Receive expert health advice to take...",
      PRICE: "6999",
    },
    {
      NAME: "Health Platinum",
      ICON: pack4,
      DESCRIPTION:
        "Let the Health Platinum health check be the beginning of a healthy you. Designed to include the screening of all health parameters you need, nothing you don't. These tests can help you spot potential health problems and diagnose your illness before any...",
      PRICE: "8999",
    },
  ],
  STORIES: {
    TITLE: "Healthy Patient Stories",
    DESCRIPTION: [
      {
        ID: 1,

        content: `I am Akhilesh Borkar. I received an injury in the gym while working out. When we visited Healthspring Aundh to meet Dr Pankaj, the doctor explained very well about the injury and treatment to be taken. He also told me the cause of the pain, the precautions to take, and the period required to heal the injury. Because of the physiotherapy treatment which he provided, my back healed in less than the expected time. He also recommended the exercises and stretches to do and which to avoid even after physiotherapy ended. I would definitely recommend Dr Pankaj for physiotherapy treatment.`,
        NAME: "Akhilesh Borkar",
        loc: "Aundh",
        month: "Oct",
        year: "2023",
      },

      {
        ID: 2,

        content: `It’s a pleasure to express our gratitude to Dr. Amey Jadhav for his exceptional care and expertise in managing my father’s health concerns. Consulting with Dr. Jadhav at Healthspring Clinic in Parel has been a transformative experience for us. His extensive knowledge on various health matters such as blood pressure management, bone health, and the intricacies of vitamin supplementation is truly remarkable. Dr. Jadhav’s detailed analysis of lab blood report outcomes is commendable, and his recommendations have been crucial in enhancing my father’s well-being. What stands out the most, however, is his extraordinary patience. He listens with great attention, addresses every concern with compassion, and ensures that we are fully informed and comfortable with the care plan. His dedication to his patients is evident in every interaction, and it is a blessing to have a physician who not only excels in his field but also brings a personal touch to his practice. For anyone in search of a healthcare professional who combines vast medical knowledge with a caring heart, Dr. Jadhav is the one you can trust implicitly. His service is not just medical care; it’s a beacon of hope and health. We cannot recommend him enough!`,
        NAME: "Shrenik Vora",
        loc: "Parel",
        month: "Nov",
        year: "2023",
      },

      {
        ID: 3,

        content: `It was our first visit to Healthspring, Vashi for an annual medical check-up & it was by far the best experience we had compared to the other medical centres we have been to in the past. The staff is very polite & they explain things with full responsibility. The place is very hygienic & equipment is well maintained. The best experience was getting a detailed consultation from Dr Hasmeet as she walked us through each section of the report & guided us on how to overcome the deficiencies spotted. This was the very first time we had an experience like this. No other medical centre provides such consultations where they tell you about your report analysis. I highly recommend visiting this place for a comfortable experience.`,
         NAME:"Kumudini Gaonkar",
         month: "Dec",
         loc:"Vashi",
         year: "2023",
      },
      {
        ID: 4,
        content:
          "I came here for a dentist appointment because I had trouble with the molar tooth where the root canal treatment was done earlier at another clinic. Doctor Mrunalini helped me understand the problem and later went on with a surgical extraction. The whole procedure was painless and within 2 weeks time stitches were removed and the particular area was healed properly. Thank you Healthspring for taking care of my dental hygiene.",
         NAME:"Rahul Singh",
         month: "Nov",
         loc:"Palava",
         year: "2023",
        },
      {
        ID: 5,
        content:"This place is a one-stop for all your medical needs. The staff is very friendly, accommodating and very helpful. I have been going here for over 4 years now. They have yearly membership schemes and various packages with great discounts. My family's blood tests are always done from here and they have nurses who come for home tests too. We had the nurse Swati come home for my wife's blood test and it was a smooth experience. The needles used were so fine that there was no pain and the hand didn't feel heavy at all. I would recommend this place to all.",
        NAME:"Vivek Ujgaonkar",
        month: "Dec",
        loc:"Khar",
        year: "2023",
      }
    ],
    NAME: "Amarnath Agarwal",
    READ: "Read All Stories",
  },
  NEWSTEXTS: {
    TITLE: "News and Media",
    NEWS_MEDIA_TITLE: "News and Media",
    BLOGS_BTN_LABEL: "Blogs",
    MEDIA_BTN_LABEL: "News",
    READ_MORE_BTN: "Read More",
  },
};
