
import SearchIcon from "@mui/icons-material/Search";
import styles from '../styling/SearchIcon.module.css'
export const RecentSearch = [
{
    icon:<SearchIcon className={styles.searchIcon}/>,
    val:'Blood glucose test'
},
{
    icon:<SearchIcon className={styles.searchIcon}/>,
    val:'C-reactive protein (CRP) blood test'
},
{
    icon:<SearchIcon className={styles.searchIcon} />,
    val:'Liver Function Test (LFT)'
},
]
export const Recommendation = [
    {
        icon:<SearchIcon className={styles.searchIcon}/>,
        val:'Blood glucose test'
    },
    {
        icon:<SearchIcon className={styles.searchIcon}/>,
        val:'C-reactive protein (CRP) blood test'
    },
    {
        icon:<SearchIcon className={styles.searchIcon} />,
        val:'Iron studies blood test'
    },
    ]
    export const Popular = [
        {
            icon:<SearchIcon className={styles.searchIcon}/>,
            val:'TSH'
        },
        {
            icon:<SearchIcon className={styles.searchIcon}/>,
            val:'Thyroid Panel'
        },
        {
            icon:<SearchIcon className={styles.searchIcon} />,
            val:'Blood Urea Nitrogen'
        },
        ]

export const recent={
    title:"Recent Search",
    clear:"Clear All",
    recommendation:"Recommendation",
    popular:"Popular",
    
}