import React from "react";
import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";
import PlayCircleFilledRoundedIcon from "@mui/icons-material/PlayCircleFilledRounded";
import { keyframes } from "@mui/system";
import styles from "../styling/RipplePlay.module.css";

const rippleAnimation = keyframes`
  from {
    opacity: 1;
    transform: scale3d(0.75, 0.75, 1);
  }
  to {
    opacity: 0;
    transform: scale3d(2, 2, 1);
  }
`;

const StepContainer = styled(Box)(({ theme }) => ({
  position: "relative",
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  height: `40px`,
  width: `40px`,
  border: ` ${theme.palette.primary.main}`,
  borderRadius: "50%",
  background: theme.palette.common.green,
  boxShadow: `0 0 20px 0 ${theme.palette.grey[500]}`,
   cursor: "pointer",
  "&:after": {
    content: '""',
    opacity: 0,
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    height: "100%",
    width: "100%",
    background: theme.palette.primary.main,
    borderRadius: "50%",
    animation: `${rippleAnimation} 2s 0s infinite cubic-bezier(0.65, 0, 0.34, 1)`,
    zIndex: -1,
  },
}));

const RipplePlayIcon = () => {
  return (
    <StepContainer data-testid="ripple-play-icon">
      <PlayCircleFilledRoundedIcon className={styles.play} />
    </StepContainer>
  );
};

export default RipplePlayIcon;
