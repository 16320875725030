import Avatar from "@mui/material/Avatar";
import pic1 from "../assets/images/pressure.svg";
import pic2 from "../assets/images/pulse.svg";
import pic3 from "../assets/images/oxygen.svg";
import pic4 from "../assets/images/sugar.svg";
import pic5 from "../assets/images/temperature.svg";

import pic6 from "../assets/images/height.svg";
import pic7 from "../assets/images/visceral.svg";
import pic8 from "../assets/images/subcutaneous.svg";
import pic9 from "../assets/images/hip.svg";
import pic10 from "../assets/images/weight.svg";
import pic11 from "../assets/images/waist.svg";
import pic12 from "../assets/images/bmi.svg";
import pic13 from "../assets/images/protein.svg";
import pic14 from "../assets/images/chest.svg";
import pic15 from "../assets/images/bmr.svg";
import pic16 from "../assets/images/metabolic.svg";
import pic17 from "../assets/images/fat.svg";
import pic18 from "../assets/images/fit-body.svg";
import pic19 from "../assets/images/skeletal.svg";
import pic20 from "../assets/images/bodywater.svg";
import pic21 from "../assets/images/bone.svg";
import pic22 from "../assets/images/ratio.svg";

import styles from "../styling/HealthProfile.module.css";




import {
  Link,
  Business,
  FmdGood,
  Badge,
  WaterDrop,
  Coronavirus,
} from "@mui/icons-material";
import Chart from "../atoms/Chart";
import { Box } from "@mui/system";
import BMIChart from "../atoms/BMIChart";
import WaistHipChart from "../atoms/WaistHipChart";




export const healthprofile = [
  {
    title: "Address",
    desc: "122, 2nd Main Rd, Gandhinagar, Bangalore, Karnataka 560009",
    icon: (
      <Avatar className={styles["Address"]}>
        <FmdGood />
      </Avatar>
    ),
  },
  {
    title: "Organization",
    desc: "Organization Name Goes Here",
    icon: (
      <Avatar className={styles["Organization"]}>
        <Business />
      </Avatar>
    ),
  },
  {
    title: "Marital Status",
    desc: "Single",
    icon: (
      <Avatar className={styles["Marital"]}>
        <Link />
      </Avatar>
    ),
  },
  {
    title: "Employee Code",
    desc: "#6637",
    icon: (
      <Avatar className={styles["Employee"]}>
        <Badge />
      </Avatar>
    ),
  },
  {
    title: "Blood Group",
    desc: "A+",
    icon: (
      <Avatar className={styles["Blood"]}>
        <WaterDrop />
      </Avatar>
    ),
  },
  {
    title: "Allergies",
    desc: "foods such as peanuts, milk and eggs",
    icon: (
      <Avatar className={styles["Allergies"]}>
        <Coronavirus />
      </Avatar>
    ),
  },
];
const label = [
  { month: "1 January", data: 19 },
  { month: "15 January", data: 24 },
  { month: "28 January", data: 22 },
  { month: "12 February", data: 17.06 },
  { month: "30 February", data: 20 },
  { month: "14 March", data: 23.5 },
];
const labels = label.map((item) => item?.month);
export const data = {
  labels,
  datasets: [
    {
      fill: true,
      label: "Dataset 1",
      data: label.map((item) => item?.data),
      borderColor: "rgb(53, 162, 235)",
      backgroundColor: "rgba(53, 162, 235, 0.5)",
    },
  ],
};

const label2 = [
  { month: "1 January", data: 19 },
  { month: "15 January", data: 24 },
  { month: "28 January", data: 22 },
  { month: "12 February", data: 17.06 },
  { month: "30 February", data: 20 },
  { month: "14 March", data: 23.5 },
];
const labels2 = label2.map((item) => item?.month);
export const data2 = {
  labels2,
  datasets: [
    {
      fill: true,
      label: "Dataset 2",
      data: label2.map((item) => item?.data),
      borderColor: "rgb(53, 162, 235)",
      backgroundColor: "rgba(53, 162, 235, 0.5)",
    },
  ],
};

export const vitals = [
  {
    title: "Blood Pressure",
    desc: "140/100",
    desc1: "mm Hg",
    icon:(
      <Box
        component="img"
        src={pic1}
        className={styles["BoxStyle"]}
      />
    ),
    bgColor: "#fcecf5",
  },
  {
    title: "Pulse",
    desc: "120",
    desc1: "BPM",
    icon:(
      <Box
        component="img"
        src={pic2}
        className={styles["BoxStyle"]}
      />
    ),
    bgColor: "#ffece2",
  },

  {
    title: "Oxygen (O2)",
    desc: "95",
    desc1: "%",
    icon:(
      <Box
        component="img"
        src={pic3}
        className={styles["BoxStyle"]}
      />
    ),
    bgColor: "#e7f3dc",
  },
  {
    title: "Blood Sugar",
    desc: "140",
    desc1: "mg/dL",
    icon:(
      <Box
        component="img"
        src={pic4}
        className={styles["BoxStyle"]}
      />
    ),
    bgColor: "#f9e4e5",
  },
  {
    title: "Temparature",
    desc: "36.1",
    desc1: "c",
    icon:(
      <Box
        component="img"
        src={pic5}
        className={styles["BoxStyle"]}
      />
    ),
    bgColor: "#e1f5f1",
  },
];

export const title = {
  header: "Health Profile",
  person: {
    name: "Elina Jhonson",
    age: "32 years, Female",
  },
  body:"Body Parameters",
  vitals:"Vitals"
};

export const bodyparameter1 = [
  {
    title: "Height",
    desc: "5'3",
    desc1: "Ft/In",
    icon:(
      <Box
        component="img"
        src={pic6}
        className={styles["BoxStyle"]}
      />
    ),
    bgColor: "#fcecf5",
  },
  {
    title: "Waist",
    desc: "34",
    desc1: "in",
    icon:(
      <Box
        component="img"
        src={pic11}
        className={styles["BoxStyle"]}
      />
    ),
    bgColor: "#ffece2",
  },{
    title: "Required BMI",
    desc: "18.5-24.9",
    desc1: "",
    icon:(
      <Box
        component="img"
        src={pic12}
        className={styles["BoxStyle"]}
      />
    ),
    charts: <BMIChart  />,

    bgColor: "#e1eef9",
    normal:"Normal Weight"
  },
  {
    title: "Skeletal Muscle",
    desc: "35",
    desc1: "%",
    icon:(
      <Box
        component="img"
        src={pic19}
        className={styles["BoxStyle"]}
      />
    ),
    bgColor: "#E1F5F1",
  },
  {
    title: "Body Water",
    desc: "62",
    desc1: "%",
    icon:(
      <Box
        component="img"
        src={pic20}
        className={styles["BoxStyle"]}
      />
    ),
    bgColor: "#E1EEF9",
  },
  {
    title: "Bone Mass",
    desc: "2.65",
    desc1: "Kg",
    icon:(
      <Box
        component="img"
        src={pic21}
        className={styles["BoxStyle"]}
      />
    ),
    bgColor: "#FCECF5",
  },
  {
    title: "Protein",
    desc: "17",
    desc1: "%",
    icon:(
      <Box
        component="img"
        src={pic13}
        className={styles["BoxStyle"]}
      />
    ),
    bgColor: "#FFECE2",
  },
  {
    title: "Chest",
    desc: "34",
    desc1: "inches",
    icon:(
      <Box
        component="img"
        src={pic14}
        className={styles["BoxStyle"]}
      />
    ),
    bgColor: "#F9E4E5",
  },
];

export const bodyparameter2 = [
  
  {
    title: "Weight",
    desc: "70",
    desc1: "Kgs",
    icon:(
      <Box
        component="img"
        src={pic10}
        className={styles["BoxStyle"]}
      />
    ),
    bgColor: "#ffece2",
  },
  {
    title: "Hip",
    desc: "82",
    desc1: "cm",
    icon:(
      <Box
        component="img"
        src={pic9}
        className={styles["BoxStyle"]}
      />
    ),
    bgColor: "#ffece2",
  },
  {
    title: "Body Fat",
    desc: "16",
    desc1: "%",
    icon:(
      <Box
        component="img"
        src={pic17}
        className={styles["BoxStyle"]}
      />
    ),
    bgColor: "#e1eef9",
  },
  {
    title: "Fat-Free Body Weight",
    desc: "62",
    desc1: "Kg",
    icon:(
      <Box
        component="img"
        src={pic18}
        className={styles["BoxStyle"]}
      />
    ),
    bgColor: "#FCECF5",
  },
  {
    title: "Visceral Fat",
    desc: "10",
    desc1: "%",
    icon:(
      <Box
        component="img"
        src={pic7}
        className={styles["BoxStyle"]}
      />
    ),
    bgColor: "#ffece2",
  },
  {
    title: "Subcutaneous Fat",
    desc: "85",
    desc1: "%",
    icon:(
      <Box
        component="img"
        src={pic8}
        className={styles["BoxStyle"]}
      />
    ),
    bgColor: "#E7F3DC",
  },
  {
    title: "Muscle Mass",
    desc: "75",
    desc1: "%",
    icon:(
      <Box
        component="img"
        src={pic9}
        className={styles["BoxStyle"]}
      />
    ),
    bgColor: "#F9E4E5",
  },
  {
    title: "Waist-Hip Ratio",
    desc: "0.85",
    desc1: "",
    icon:(
      <Box
        component="img"
        src={pic22}
        className={styles["BoxStyle"]}
      />
    ),
    charts: <WaistHipChart/>,
    normal:"Moderate",

    bgColor: "#E1F5F1",
  },
  {
    title: "Metabolic Age",
    desc: "34",
    desc1: "Year",
    icon:(
      <Box
        component="img"
        src={pic16}
        className={styles["BoxStyle"]}
      />
    ),
    bgColor: "#E7F3DC",
  },
  {
    title: "BMR",
    desc: "7,100",
    desc1: "KJ",
    icon:(
      <Box
        component="img"
        src={pic15}
        className={styles["BoxStyle"]}
      />
    ),
    bgColor: "#e1f5f1",
  },
];

