import React, { useState } from "react";
import { Box, Grid, Stack, Typography, Switch, Avatar } from "@mui/material";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import {SettingsConstants} from "../json/SettingsConstants";
import styles from "../styling/Settings.module.css";

export const Settings = () => {
  const [notiEnable, setNotiEnable] = useState(false);

  return (
    <Box>
      <Typography className={styles["accinfo-title"]} variant="h4">
        {SettingsConstants?.pageTitle}
      </Typography>
      <Box className={styles["settings-mainBox"]}>
        <Grid item xs={6}>
          <Stack spacing={2}>
            <Typography className={styles["settings-typoLeft"]}>
              {SettingsConstants?.notifications}
            </Typography>

            <Typography className={styles["settings-typoLeft"]}>
              {SettingsConstants?.privacyPolicy}
            </Typography>

            <Typography className={styles["settings-typoLeft"]}>
              {SettingsConstants?.termsAndConditions}
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={6}>
          <Stack spacing={2} alignItems="flex-end">
            <Switch
              className={styles["settings-switchRight"]}
              size="md"
              variant="solid"
              checked={notiEnable}
              onChange={(event) => setNotiEnable(event?.target?.checked)}
            />

            <Avatar className={styles["settings-typoRight"]}>
              <KeyboardArrowRightIcon className={styles.keycolor} />
            </Avatar>

            <Avatar className={styles["settings-typoRight"]}>
              <KeyboardArrowRightIcon className={styles.keycolor} />
            </Avatar>
          </Stack>
        </Grid>
      </Box>
    </Box>
  );
};
