export const SiteContent = {
  // HEADER & SUBHEADER SECTION TEXT \\
  CONTACT_NO: "+91 86528 86529",
  ABOUT_US: "About Us",
  CAREER: "Career",
  CAREERS: "Careers",
  NEWS_AND_MEDIA: "News & Media",
  CONTACT: "Contact",
  HEALTHSPRING: "Healthspring",
  FAMILY_HEALTH_EXPERTS: "FAMILY HEALTH EXPERTS",
  EVERYDAY_HEALTH: "Everyday Health",
  CORPORATE_HEALTH: "Corporate Health",
  BLOGS: "Blogs",
  HELLO_LOGIN: "Hello, Login",
  OTHERS:'Others',
  SERVICES:"Services",
  OUR_TEAM: "Our Team",
  // FOOTER SECTION TEXT \\
  HEAD_OFFICE: "Head Office",
  ADDRESS_LINE_1: "5th Floor, East Wing, Forbes Building,",
  ADDRESS_LINE_2: "Charanjit Rai Marg, Fort, Mumbai, 400001",
  FOOTER_CONTACT_NO: "+91 22 6130 3434",
  FOOTER_EMAIL: "info@healthspring.in",
  COMPANY: "Company",
  TEST_AND_PACKAGES: "Test & Packages",
  LOCATION:"Locations",
  CONSULT: "Consult",
  OUT_PATIENT_CARE: "Out-Patient Care",
  CONTACT_US: "Contact Us",
  HELP: "Help",
  DOWNLOAD_APP: "Download App",
  FOLLOW_US: "Follow Us",
  PRIVACY_POLICY: "Privacy Policy",
  TERMS_AND_CONDITIONS: "Terms & Conditions",
  SITEMAP: "SiteMap",
  COPYRIGHT: "© Copyright 2023 Healthspring. All rights reserved.",
  // LOGIN SECTION TEXT \\
  BACK: "Back",
  WELCOME_BACK: "Welcome Back!",
  MEET_OUR_TEAM: "Meet Our Team",
  ENTER_PHONE_NUMBER_TO_LOGIN: "Enter phone number to login",
  LOGIN: "Login",
  DONT_HAVE_AN_ACCOUNT: "Don't have an account?",
  SIGN_UP: "Sign Up",
  // SIGNUP SECTION TEXT \\
  CREATE_NEW_ACCOUNT: "Create New Account",
  ENTER_PHONE_NUMBER_TO_CONTINUE: "Enter phone number to continue",
  ALREADY_HAVE_AN_ACCOUNT: "Already have an account?",
  BY_SIGNING_UP_YOU_ACCEPT_OUR: "By signing up, you accept our",
  // OTP SECTION TEXT \\
  ENTER_YOUR_OTP: "Enter Your OTP",
  WE_SENT_IT_TO_THE_NUMBER: "We sent it to the number +",
  VERIFY_OTP: "Verify OTP",
  RESEND_CODE_IN: "Resend Code in",
  // ABHA SECTION TEXT \\
  ABHA_DETAILS: "ABHA Details",
  ABHA_ADDRESS: "ABHA Address",
  ABHA_ID: "ABHA ID",
  // PROFILE DETAILS SECTION TEXT \\
  FILL_YOUR_PROFILE: "Fill Your Profile",
  NAME: "Name",
  EMAIL: "Email",
  MOBILE_NUMBER: "Mobile Number",
  MOBILE_NUMBER_REAL: "7894561230",
  GENDER: "Gender",
  DOB: "Date Of Birth",
  AGE: "Age",
  // ADDRESS DETAILS SECTION TEXT \\
  ADDRESS_DETAILS: "Address Details",
  COUNTRY: "Country",
  PINCODE: "Pincode",
  STATE: "State",
  CITY: "City",
  ADDRESS_LINE_ONE: "Address Line 1",
  ADDRESS_LINE_TWO: "Address Line 2",
  ADDRESS_LINE_THREE: "Address Line 3 (Optional)",
  LANDMARK: "Landmark (Optional)",
  // EMERGENCY DETAILS SECTION TEXT \\
  EMERGENCY_CONTACT: "Emergency Contact",
  PRIMARY_CONTACT: "Primary Contact",
  CONTACT_PERSON: "Contact Person",
  RELATION: "Relation",
  SECONDARY_CONTACT: "Secondary Contact",
  // HOME \\
  KNOW_MORE: "Know More",
  know:"Know More",
  UPCOMING_APPOINTMENTS: "Upcoming Appointments",
  RECENT_REPORTS: "Recent Reports",
  DOCTORS_CONSULTATION: "Doctor Consultation",
  // TEST AND PACKAGES\\
  LETS_FIND: "Let's Find",
  // CONSULT \\
  OUR_SPECIALIST_DOCTOR: "Our Specialist Doctors",
  // OUT PATIENT CARE \\
  OUT_PATIENT_CARE_SERVICES: "Out-Patient Care Services",
  //CONTACT US \\
  GET_IN_TOUCH: "Get In Touch",
 //About HealthSpring //
 about:"About Healthspring",
 
 
 LANDING_INTRO:"We specialise in accessible, proactive healthcare solutions for all. From large corporates to families and individuals, Healthspring caters to everyone who dreams of a healthier, stronger life.",
//usBanner Intro//
Us_intro:"Every day at Healthspring, we aspire to be the most trusted custodians of quality healthcare. Our focus on patient-centric care while upholding the highest level of ethics and transparency has made us one of the leading names in healthcare in India",
 PAYMENT_INFO_TITLE: 'Payment Info',
 TOTAL_PRICE_LABEL: 'Total Price',
 COUPON_LABEL:'Coupon',
 TAX_LABEL:'Tax',
 GRAND_TOTAL_LABEL:'Grand Total',
 CHANGE_DATE_AND_TIME_LINK_TEXT: 'Change Date and Time',
 continue:"Continue",
 submit:"Submit",
 SCHEDULE_DEMO:"Schedule a Demo",
 DOWNLOAD_REPORT_LABEL: 'Download Report',
 SHARE_REPORT_LABEL: 'Share Report',
 BOOKING_DETAILS_LABEL: 'Booking Details',
 REASON_FOR_RESCHEDULE_LABEL: "Reason for Reschedule",
 RESCHEDULE_DRWAER_DESC:"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
 RESCHEDULE_REASONS: [
  {
    label:"I'm having a schedule clash",
    value:1
  },
  {
    label:"I'm not available on schedule",
    value:2
  },
  {
    label:"I have a activity that can't be left behind",
    value:3
  },
  {
    label:"Other",
    value:4
  }
 ],
 CANCEL_REASONS: [
  {
    label:"I want to change to another doctor",
    value:1
  },
  {
    label:"I want to change package",
    value:2
  },
  {
    label:"I have recovered from the disease",
    value:3
  },
  {
    label:"I have found a suitable medicine",
    value:4
  },
  {
    label:"Other",
    value:5
  }
 ],
 NEXT_BTN_LABEL:'Next',
 EDIT_BTN_LABEL:'Edit',

 READ_MORE:"Read More",
READ_ALL_STORIES:"Read All Stories",
//  Membership Summary

BOOK_ACTIVITY:"Book Activity",
BOOK_ACTIVITIES:"Book Activities",

HEALTH_PORTAL_LOGIN:"Health Portal Login",
BOOK_AN_APPOINTMENT:"Book an Appointment",
// LoginLayoutjs file
HELLO_USER_LOGIN: ' Hello User, Log in',
FOR_INDIVIDUALS:'For Individuals & Family',
FOR_CORPORATES:' For Corporates',
LOCATIONS:"Locations",
RETAIL:"Retail",
CORPORATE:"Corporate",
};

