import React from "react";
import { Stack, Box, Typography, Button, TextField } from "@mui/material";
import styles from "../../styling/RegistrationDetails.module.css";
import { SiteContent } from "../../assets/content/SiteContent";
import { useSharedData } from "../../Context/ShareContext";
import * as yup from "yup";
import { useFormik } from "formik";

const AddressDetails = () => {
  const { currentSteps, setCurrentSteps } = useSharedData();

  const validationSchema = yup.object({
    country: yup.string().required("Country is mandatory"),
    pincode: yup
      .string()
      .matches(/^\d+$/, "Invalid Pin Cide")
      .required("PIN code is mandatory"),
    state: yup.string().required("State is mandatory"),
    city: yup.string().required("City is mandatory"),
    addressLine1: yup.string().required("Address Line 1 is mandatory"),
  });

  const formik = useFormik({
    initialValues: {
      country: "",
      pincode: "",
      state: "",
      city: "",
      addressLine1: "",
      addressLine2: "",
      addressLine3: "",
      landmark: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      moveNextStep();
    },
  });

  const moveNextStep = () => {
    setCurrentSteps(currentSteps + 1);
  };

  return (
    <Box className={styles.regdtls_box} data-testid="address">
      <Typography className={styles.regdtls_main_txt} variant="h4">
        {SiteContent.ADDRESS_DETAILS}
      </Typography>
      <Stack direction="column">
        <Stack item xs={6}>
          <Typography className={styles.regdtls_txtfldtitle} variant="h5">
            {SiteContent.COUNTRY}
          </Typography>
          <TextField
            className={styles.regdtls_txtfld}
            variant="standard"
            name="country"
            onChange={(e) =>
              formik?.handleChange(e?.target?.name)(
                e?.target?.value?.replace(/[^A-Za-z\s\-']/g, "")
              )
            }
            onBlur={formik?.handleBlur}
            value={formik?.values?.country}
            error={formik?.touched?.country && Boolean(formik?.errors?.country)}
            InputProps={{
              className: styles.regdtls_txtfldinpt,
              disableUnderline: true,
            }}
          />
          {formik?.touched?.country && formik?.errors?.country ? (
            <Typography color="error" className={styles.validation}>
              {formik?.errors?.country}
            </Typography>
          ) : null}
        </Stack>
        <Stack item xs={6}>
          <Typography className={styles.regdtls_txtfldtitle} variant="h5">
            {SiteContent.PINCODE}
          </Typography>
          <TextField
            className={styles.regdtls_txtfld}
            variant="standard"
            name="pincode"
            onChange={(e) =>
              formik?.handleChange(e?.target?.name)(
                e?.target?.value?.replace(/[^0-9]/g, "")
              )
            }
            onBlur={formik?.handleBlur}
            value={formik?.values?.pincode}
            error={formik?.touched?.pincode && Boolean(formik?.errors?.pincode)}
            InputProps={{
              className: styles.regdtls_txtfldinpt,
              disableUnderline: true,
            }}
          />
          {formik?.touched?.pincode && formik?.errors?.pincode ? (
            <Typography color="error" className={styles.validation}>
              {formik?.errors?.pincode}
            </Typography>
          ) : null}
        </Stack>
        <Stack item xs={6}>
          <Typography className={styles.regdtls_txtfldtitle} variant="h5">
            {SiteContent.STATE}
          </Typography>
          <TextField
            className={styles.regdtls_txtfld}
            variant="standard"
            name="state"
            onChange={(e) =>
              formik?.handleChange(e?.target?.name)(
                e?.target?.value?.replace(/[^A-Za-z\s\-']/g, "")
              )
            }
            onBlur={formik?.handleBlur}
            value={formik?.values?.state}
            error={formik?.touched?.state && Boolean(formik?.errors?.state)}
            InputProps={{
              className: styles.regdtls_txtfldinpt,
              disableUnderline: true,
            }}
          />
          {formik?.touched?.state && formik?.errors?.state ? (
            <Typography color="error" className={styles.validation}>
              {formik?.errors?.state}
            </Typography>
          ) : null}
        </Stack>
        <Stack item xs={6}>
          <Typography className={styles.regdtls_txtfldtitle} variant="h5">
            {SiteContent.CITY}
          </Typography>
          <TextField
            className={styles.regdtls_txtfld}
            variant="standard"
            name="city"
            onChange={(e) =>
              formik?.handleChange(e?.target?.name)(
                e?.target?.value?.replace(/[^A-Za-z\s\-']/g, "")
              )
            }
            onBlur={formik?.handleBlur}
            value={formik?.values?.city}
            error={formik?.touched?.city && Boolean(formik?.errors?.city)}
            InputProps={{
              className: styles.regdtls_txtfldinpt,
              disableUnderline: true,
            }}
          />
          {formik?.touched?.city && formik?.errors?.city ? (
            <Typography color="error" className={styles.validation}>
              {formik?.errors?.city}
            </Typography>
          ) : null}
        </Stack>
        <Stack item xs={6}>
          <Typography className={styles.regdtls_txtfldtitle} variant="h5">
            {SiteContent.ADDRESS_LINE_ONE}
          </Typography>
          <TextField
            className={styles.regdtls_txtfld}
            variant="standard"
            name="addressLine1"
            onChange={formik?.handleChange}
            onBlur={formik?.handleBlur}
            value={formik?.values?.addressLine1}
            error={
              formik?.touched?.addressLine1 &&
              Boolean(formik?.errors?.addressLine1)
            }
            InputProps={{
              className: styles.regdtls_txtfldinpt,
              disableUnderline: true,
            }}
          />
          {formik?.touched?.addressLine1 && formik?.errors?.addressLine1 ? (
            <Typography color="error" className={styles.validation}>
              {formik?.errors?.addressLine1}
            </Typography>
          ) : null}
        </Stack>
        <Stack item xs={6}>
          <Typography className={styles.regdtls_txtfldtitle} variant="h5">
            {SiteContent.ADDRESS_LINE_TWO}
          </Typography>
          <TextField
            className={styles.regdtls_txtfld}
            variant="standard"
            InputProps={{
              className: styles.regdtls_txtfldinpt,
              disableUnderline: true,
            }}
          />
        </Stack>
        <Stack item xs={6}>
          <Typography className={styles.regdtls_txtfldtitle} variant="h5">
            {SiteContent.ADDRESS_LINE_THREE}
          </Typography>
          <TextField
            className={styles.regdtls_txtfld}
            variant="standard"
            InputProps={{
              className: styles.regdtls_txtfldinpt,
              disableUnderline: true,
            }}
          />
        </Stack>
        <Stack item xs={6}>
          <Typography className={styles.regdtls_txtfldtitle} variant="h5">
            {SiteContent.LANDMARK}
          </Typography>
          <TextField
            className={styles.regdtls_txtfld}
            variant="standard"
            InputProps={{
              className: styles.regdtls_txtfldinpt,
              disableUnderline: true,
            }}
          />
        </Stack>
      </Stack>
      <Button
        className={styles.regdtls_continue_button}
        onClick={formik?.handleSubmit}
      >
        {SiteContent.continue}
      </Button>
    </Box>
  );
};
export default AddressDetails;
