import React, { useState } from "react";
import {
  Stack,
  Box,
  Typography,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Avatar,
  TextField,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import styles from "../styling/GenericPopup.module.css";
import { useSharedData } from "../Context/ShareContext";

const GenericPopup = ({
  Icon,
  MainTxt,
  SubTxt,
  MainBtnTxt,
  SubBtnTxt,
  ShowSubBtn,
  PageName,
  PlaceholderTxt,
}) => {
  const navigate = useNavigate();
  const {
    setCurrentSteps,
    showPopup,
    setShowPopup,
    setShowSignPopup,
    showSignPopup,
    showCongratulationsPopup, 
        setShowCongratulationsPopup,
    showSuccessPopup,
    setShowSuccessPopup,
    setValue,
    itemSelected, setitemSelected, value
  } = useSharedData();
  const [anchorEl, setAnchorEl] = useState(null);


  const handleClose = (event) => {
    setShowCongratulationsPopup(false);
    setShowPopup(false);
    setShowSignPopup(false)
    if (PageName === "Congratulations") {
      setitemSelected(event?.target?.getAttribute("value"));
      setAnchorEl(null);
      navigate("/myaccount");
    }
  };

  const handleNavigate = () => {
    setShowCongratulationsPopup(false);
    setShowPopup(false);
    setShowSignPopup(false);

    if (PageName === "Signup") {
      navigate("/login/verifyabha");
    } else if (PageName === "RegDetails") {
      setShowSuccessPopup(true);
    } else if (PageName === "RegSuccess") {
      setCurrentSteps(1);
      setShowSuccessPopup(false);
      setValue(0);
      navigate("/home");
    } else if (PageName === "AccountPackageDetails") {
      navigate("/myaccount");
    } else {
      navigate("/login/basicdetails");
    }
  };

  return (
    <Box>
      <Dialog
        PaperProps={{ sx: { borderRadius: "20px" } }}
        open={showCongratulationsPopup || showPopup || showSuccessPopup ||showSignPopup}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          id="alert-dialog-title"
          className={styles.gen_dialog_title}
        >
          <Avatar src={Icon} className={styles.gen_logo} alt="Abha Id Logo" />
          <Typography className={styles.gen_main_txt} variant="h4">
            {MainTxt}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Typography className={styles.gen_sub_txt} variant="h5">
              {SubTxt}
            </Typography>
          </DialogContentText>
        </DialogContent>
        {PlaceholderTxt && (
          <TextField
            className={styles.gen_txtfld}
            variant="standard"
            placeholder={PlaceholderTxt}
            InputProps={{
              className: styles.gen_txtfldinpt,
              disableUnderline: true,
            }}
          />
        )}
         <Stack className={styles.gen_btn_stack}>
          <Button
            className={styles.gen_yes_button}
            onClick={
              MainBtnTxt === "View Package Details"
                ? handleClose
                : handleNavigate
            }
          >
            {MainBtnTxt}
          </Button>
          {ShowSubBtn && (
            <Button
              className={styles.gen_no_button}
              onClick={
                SubBtnTxt === "Back to Home" ? handleNavigate : handleClose
              }
            >
              {SubBtnTxt}
            </Button>
          )}
        </Stack>
      </Dialog>
    </Box>
  );
};
export default GenericPopup;
