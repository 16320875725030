import * as React from "react";
import { Route, Routes } from "react-router-dom";
import { Home } from "../pages/Home";
import { Layout } from "../templates/Layout";
import { TestAndPackages } from "../pages/TestAndPackages";
import { Consult } from "../pages/Consult";
import { OutPatientCare } from "../pages/OutPatientCare";
import { ContactUs } from "../pages/ContactUs";
import ProtectedRoutes from "./ProtectedRoutes";
import { LoginLayout } from "../templates/LoginLayout";
import EverydayHealth from "../pages/EverydayHealth";
import CorporateHealth from "../pages/CorporateHealth";
import { AboutUs } from "../pages/AboutUs";
import { Career } from "../pages/Career";
import Blogs from "../pages/Blogs";
import LoginPage from "../pages/LoginPage";
import Help from "../pages/Help";
import MeetingEvent from "../hooks/meetingEvent";
import { MyAccount } from "../pages/MyAccount";
import Checkout from "../pages/Checkout";
import LandingPage from "../pages/LandingPage";
import OurTeam from "../pages/OurTeam";
import SignupPage from "../pages/SignupPage";
import { BlogsReadmore } from "../pages/BlogsReadmore";
import MilestoneTimeline from "../molecules/MilestoneTimeline";
import VerifyAbha from "../pages/VerifyAbha";
import TermsAndCondition from "../molecules/TermsAndCondition";
import PrivacyPolicy from "../molecules/PrivacyPolicy";
import { LabtestCheckout } from "../pages/LabtestCheckout";
import { XrayScan } from "../pages/XrayScan";
import InclinicAppointment from "../pages/InclinicAppointment";
import { OnsiteAppointment } from "../pages/OnsiteAppointment";
import BasicDetails from "../pages/BasicDetails";
import TestPackageMembershipDetails from "../molecules/TestPackageMembershipDetails";
import TestPackageHealthCheckDetails from "../molecules/TestPackageHealthCheckDetails";
import TestPackageLabDetails from "../molecules/TestPackageLabDetails";
import TestPackageXrayDetails from "../molecules/TestPackageXrayDetails";
import MeetingReview from "../molecules/MeetingReview";
import { SidebarPopup } from "../molecules/SidebarPopup";
import DentalCare from "../components/Dentalcare";
import UrgentCare from "../components/Urgentcare";
import Vaccination from "../components/Vaccination";
import { XrayScanpage } from "../components/XrayScanpage";
import DoctorSelection from "../molecules/DoctorSelection";
import DoctorDetails from "../molecules/DoctorDetails";
import AppointmentPayment from "../molecules/AppointmentPayment";
import TeethCleaning from "../components/TeethCleaning";
import { LiverFunctionTest } from "../molecules/LiverFunctionTest";
import CReactiveProtien from "../components/CReactiveProtien";
import AbdomenPain from "../molecules/AbdomenPain";
import UrgentCareCheckout from "../molecules/UrgentCareCheckout";
import HomeAppointment from "../molecules/HomeAppointment";
import MemberCheckOut from "../pages/MemberCheckOut";

export default function RouteFlow() {
  return (
    <Routes>
      <Route element={<ProtectedRoutes />}>
        <Route path="/" element={<Layout />}>
          <Route path="home" element={<Home />} />
          <Route path="consult" element={<Consult />} />
          <Route path="consult/:cat" element={<DoctorSelection />} />
          <Route
            path="consult/:cat/doctor_details"
            element={<DoctorDetails />}
          />
          <Route
            path="consult/:cat/doctor_details/checkout"
            element={<AppointmentPayment />}
          />
          <Route path="test_and_packages" element={<TestAndPackages />} />
          <Route
            path="test_and_packages/health_check_packages"
            element={<TestPackageHealthCheckDetails />}
          />
          <Route
            path="test_and_packages/health_check_packages/checkout"
            element={<Checkout />}
          />
          <Route
            path="test_and_packages/membership"
            element={<TestPackageMembershipDetails />}
          />
          <Route
            path="test_and_packages/membership/checkout"
            element={<MemberCheckOut/>}
          />
          <Route
            path="test_and_packages/lab_tests"
            element={<TestPackageLabDetails />}
          />
          <Route
            path="test_and_packages/xray"
            element={<TestPackageXrayDetails />}
          />
          <Route path="outpatient_care" element={<OutPatientCare />} />
          <Route path="outpatient_care/dental_care" element={<DentalCare />} />
          <Route
            path="outpatient_care/dental_care/teeth_cleaning_polishing"
            element={<TeethCleaning />}
          />
          <Route path="outpatient_care/urgent_care" element={<UrgentCare />} />
          <Route
            path="outpatient_care/urgent_care/abdomen_pain"
            element={<AbdomenPain />}
          />

          <Route
            path="outpatient_care/urgent_care/abdomen_pain/checkout"
            element={<UrgentCareCheckout />}
          />
          <Route
            path="outpatient_care/vaccination/c_Reactive_protien_crp_blood_test"
            element={<CReactiveProtien />}
          />
          <Route
            path="outpatient_care/vaccination/c_Reactive_protien_crp_blood_test/checkout"
            element={<HomeAppointment />}
          />
          <Route
            path="outpatient_care/physiotherapy"
            element={<Vaccination />}
          />
          <Route path="outpatient_care/vaccination" element={<Vaccination />} />
          <Route path="help" element={<Help />} />
          <Route path="contactus" element={<ContactUs />} />
          <Route path="myaccount" element={<MyAccount />} />
          <Route path="checkout" element={<Checkout />} />
          <Route path="onsite" element={<OnsiteAppointment />} />
          <Route path="meeting-review" element={<MeetingReview />} />
          <Route path="failed" element={<SidebarPopup />} />
          <Route path="meeting" element={<MeetingEvent />} />
          {/* <Route path="terms" element={<TermsAndCondition />} />
          <Route path="policy" element={<PrivacyPolicy />} /> */}
          <Route path="doctor" element={<LabtestCheckout />} />
          <Route
            path="test_and_packages/xray/booking"
            element={<XrayScanpage />}
          />
          <Route
            path="test_and_packages/xray/booking/checkout"
            element={<XrayScan />}
          />
          <Route
            path="outpatient_care/dental_care/teeth_cleaning_polishing/checkout"
            element={<InclinicAppointment />}
          />
          <Route
            path="test_and_packages/lab_tests/details"
            element={<LiverFunctionTest />}
          />
          <Route
            path="test_and_packages/lab_tests/details/checkout"
            element={<LabtestCheckout />}
          />
        </Route>
      </Route>
      <Route path="login" element={<LoginLayout />}>
        <Route index element={<LandingPage />} />
        <Route path="landingpage" element={<LandingPage />} />
        <Route path="everydayhealth" element={<EverydayHealth />} />
        <Route path="corporatehealth" element={<CorporateHealth />} />
        <Route path="contactus" element={<ContactUs />} />
        <Route path="aboutus" element={<AboutUs />} />
        <Route path="career" element={<Career />} />
        <Route path="blogs" element={<Blogs />} />
        <Route path="loginpage" element={<LoginPage />} />
        <Route path="Ourteam" element={<OurTeam />} />
        <Route path="signup" element={<SignupPage />} />
        <Route path="blogsreadmore" element={<BlogsReadmore />} />
        <Route path="milestonetimeline" element={<MilestoneTimeline />} />
        <Route path="verifyabha" element={<VerifyAbha />} />
        <Route path="basicdetails" element={<BasicDetails />} />
        <Route path="terms" element={<TermsAndCondition />} />
        <Route path="policy" element={<PrivacyPolicy />} />
      </Route>
    </Routes>
  );
}
