import React, { useState } from "react";
import {
  Typography,
  Stack,
  Button,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Avatar,
  Box,
} from "@mui/material";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useNavigate } from "react-router-dom";
import theme from "../components/Themeify";
import loginPgIcon from "../assets/images/loginpg-icon.png";
import styles from "../styling/LogSignOtpPage.module.css";
import OtpPage from "../molecules/OtpPage";
import { SiteContent } from "../assets/content/SiteContent";

const SignupPage = () => {
  let canGetOtpTime = 60;
  const navigate = useNavigate();
  const [isOtpReqested, setIsOtpReqested] = useState(false);
  const [optRemainingTime, setOptRemainingTime] = useState(60);
  const [phoneno, setPhoneno] = useState("");

  const handleLogin = () => {
    navigate("/login/loginpage");
  };

  const handleSignup = () => {
    setIsOtpReqested((prev) => !prev);
    changeOtpTime();
  };

  const changeOtpTime = () => {
    var intervalId = setInterval(function () {
      canGetOtpTime -= 1;
      setOptRemainingTime(canGetOtpTime);

      if (canGetOtpTime <= 0) {
        canGetOtpTime = 60;
        clearInterval(intervalId);
      }
    }, 1000);
  };

  return (
    <>
      <Stack direction="row" onClick={handleLogin}>
        <Button
          id="basic-button"
          startIcon={
            <Avatar className={styles.back_icon_container}>
              <KeyboardBackspaceIcon className={styles.back_icon} />
            </Avatar>
          }
        >
          <Typography variant="h5" color={theme.palette.info.grey}>
            {SiteContent.BACK}
          </Typography>
        </Button>
      </Stack>
      {!isOtpReqested && (
        <Stack className={styles.main_stack}>
          <Box className={styles.main_box}>
            <Stack className={styles.avatar_icon}>
              <Avatar
                src={loginPgIcon}
                className={styles.top_icon}
                alt="Signup Page Logo"
              />
            </Stack>
            <Typography variant="h3" className={styles.main_txt}>
              {SiteContent.CREATE_NEW_ACCOUNT}
            </Typography>
            <Typography className={styles.sub_txt}>
              {SiteContent.ENTER_PHONE_NUMBER_TO_CONTINUE}
            </Typography>
            <PhoneInput
              country={"in"}
              placeholder="00000-00000"
              value={phoneno}
              onChange={(phoneno) => setPhoneno(phoneno)}
            />
            <FormGroup className={styles.checkbox_container}>
              <FormControlLabel
                control={<Checkbox />}
                label={
                  <Typography className={styles.checkboxtxt}>
                    Alerts messages by <b>SMS/WhatsApp</b>
                  </Typography>
                }
                className={styles.checkbox}
              />
            </FormGroup>
            <Button className={styles.main_btn} onClick={handleSignup}>
              {SiteContent.SIGN_UP}
            </Button>
            <Stack className={styles.btm_stack}>
              <Typography className={styles.btm_txt}>
                {SiteContent.ALREADY_HAVE_AN_ACCOUNT}
              </Typography>
              <Typography
                className={styles.btm_logsigntxt}
                onClick={handleLogin}
                data-testid="login"
              >
                {SiteContent.LOGIN}
              </Typography>
            </Stack>

            <Stack className={styles.disclaimer_stack}>
              <Typography className={styles.disclaimer_maintxt}>
                {SiteContent.BY_SIGNING_UP_YOU_ACCEPT_OUR}
              </Typography>
              <Stack className={styles.disclaimer_2ndstack}>
                <Typography className={styles.disclaimer_underlinedtxt}>
                  {SiteContent.TERMS_AND_CONDITIONS}
                </Typography>
                <Typography className={styles.disclaimer_nonunderlinedtxt}>
                  and
                </Typography>
                <Typography className={styles.disclaimer_underlinedtxt}>
                  {SiteContent.PRIVACY_POLICY}
                </Typography>
              </Stack>
            </Stack>
          </Box>
        </Stack>
      )}
      {isOtpReqested && (
        <OtpPage PhoneNo={phoneno} OptRemainingTime={optRemainingTime} PageName="Signup" />
      )}
    </>
  );
};
export default SignupPage;
