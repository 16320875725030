import { Stack, Box } from "@mui/system";
import React from "react";
import { Typography } from "@mui/material";
import styles from "../styling/LabtestAppointment.module.css";
import lab from "../assets/images/lab.png";
import Divider from "@mui/material/Divider";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import ScheduleOutlinedIcon from "@mui/icons-material/ScheduleOutlined";
import {onsiteAppointmentLeftTexts} from "../json/OnsiteAppointmentLeftTexts";

export const OnsiteAppointmentLeft = (props) => {
  return (
    <>
      <Box className={styles.onsiteappoint_labtest_box_main}>
        <Stack
          className={styles.labtest_stack_mainone}
          direction="row"
          spacing={0.5}
        >
          <Stack className={styles.labtest_stack_maintwo}>
            <img className={styles.ico_21_img} src={lab} alt="" />
          </Stack>
          <Stack className={styles.onsiteappoint_stack_oness}>
            <Typography
              className={styles.onsiteappoint_typo_oness}
              align="left"
              variant="h4"
              data-testid="appointment_title_text"
            >
              {onsiteAppointmentLeftTexts?.appointmentTitleText}
            </Typography>
          </Stack>
        </Stack>
        <Divider />
        <Stack
          className={styles.onsite_stacke_innerOne}
          spacing={8}
          direction="row"
        >
          <Stack>
            <Stack
              className={styles.onsite_stacke_innerTwo}
              spacing={2}
              direction="row"
            >
              <Stack>
                <CalendarMonthOutlinedIcon />
              </Stack>
              <Stack>
                <Typography
                  align="left"
                  variant="h4"
                  className={styles.today_17_nov_date}
                >
                  {onsiteAppointmentLeftTexts?.todayDateText}
                </Typography>
              </Stack>
            </Stack>
            <Stack className={styles.onsite_stacke_innerThree}>
              <Typography
                align="left"
                variant="h5"
                className={styles.onsite_stacke_innerThree_typoss}
              >
                {onsiteAppointmentLeftTexts?.changeDateAndTimeText}
              </Typography>
            </Stack>
          </Stack>

          <Stack spacing={2} direction="row">
            <Stack>
              <ScheduleOutlinedIcon />
            </Stack>
            <Stack>
              <Typography variant="h4" className={styles.today_17_nov_date}>
                {onsiteAppointmentLeftTexts?.appointmentTimeText}
              </Typography>
            </Stack>
          </Stack>
        </Stack>
        <Divider />
        <Stack
          direction="row"
          spacing={1}
          className={styles.onsite_stacke_innerThree_innerone}
        >
          {" "}
          <Stack>
            <img src={props.imagetwo} alt="" width="51px" />
          </Stack>
          <Stack>
            <Typography
              align="left"
              className={styles.onsiteappoint_typo_oness}
            >
              {props.textOne}
            </Typography>
            <Typography align="left" mb={1}>
              {props.textTwo}
            </Typography>
          </Stack>
        </Stack>
      </Box>
    </>
  );
};
