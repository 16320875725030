import React from "react";
import {
  Typography,
  ListItem,
  List,
  Divider,
  Toolbar,
  Stack,
} from "@mui/material";
import styles from "../styling/MemberMenu.module.css";
import CloseIcon from "@mui/icons-material/Close";
import { locationsDummyData, coupon } from "../json/LocationsDummyData";

export default function ApplyPopupMenu({
  onClose,
  selectedCoupon,
  setSelectedCoupon,
}) {
  return (
    <>
      <Toolbar>
        <Typography varinat="h4" className={styles.selectName}>
          {coupon.apply}
        </Typography>
        <CloseIcon className={styles.close_icon} onClick={onClose} />
      </Toolbar>
      <CustomTextInput setSelectedCoupon={setSelectedCoupon}/>
      <Typography className={styles.moreOffer}>More Offer</Typography>
      <List>
        {locationsDummyData?.map((text) => (
          <>
            <ListItem
              className={styles.list_View}
              key={text?.id}
              onClick={() => {
                setSelectedCoupon(text);
                onClose();
              }}
            >
              <Stack className={styles.stack_container}>
                <Typography className={styles.couponCodeText}>
                  {text?.name}
                </Typography>

                <Typography className={styles.couponCodeApplyText}>
                  {text?.button}
                </Typography>
              </Stack>

              <Stack>
                <Typography className={styles.couponTxt}>
                  {text?.off}
                </Typography>
                <Typography className={styles.couponDescTxt}>
                  {text?.text}
                </Typography>
              </Stack>
            </ListItem>
            <Divider className={styles.divider_line} />
          </>
        ))}
      </List>
    </>
  );
}

const CustomTextInput = ({setSelectedCoupon}) => {
  return (
    <Stack
      className={styles.inputContainer}
      direction={"row"}
      alignItems={"center"}
    >
      <input
        placeholder="Apply Coupon"
        className={styles.textInputContainer}
        onChange={() => setSelectedCoupon(null)}
      />

      <p className={styles.ApplyBtn}>Apply</p>
    </Stack>
  );
};
