import React,{useState,useEffect} from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import Button from "@mui/material/Button";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import CallOutlinedIcon from "@mui/icons-material/CallOutlined";
import ladydoctor from "../assets/images/lady_doc.png";
import TextField from "@mui/material/TextField";
import Divider from "@mui/material/Divider";
import { Typography } from "@mui/material";
import styles from "../styling/SidebarPopup.module.css";
import StarRating from "../atoms/Star";
import {sidebarPopupTexts} from "../json/SidebarPopupTexts"; 
import { useSharedData } from "../Context/ShareContext";
import Drawer from "@mui/material/Drawer";
import calling from '../assets/caling.svg'
export const SidebarPopup = ({ anchor, isOpen, onCloseHandler}) => {
  const [starCount, setStarCount] = useState(0);
  const {setOpenModal , setShowSummary} = useSharedData();
  const [state, setState] = useState({
    right: isOpen,
  });

  const handleBack = () => {
    
    setShowSummary(false);
   
  };


  
  useEffect(() => {
    setState({ right: isOpen });
  }, [isOpen]);

  const closeDrawerHandler = () => {
    onCloseHandler();
    setOpenModal(false)
  };
  


  return (
    
      
      <Drawer anchor={anchor} open={isOpen} onClose={() => closeDrawerHandler()}>

<Box
     
     
    >
      <Stack className={styles.sidebar_main_stack_pop_up} direction="row">
        <Stack className={styles.sidebar_main_innerfirststack_pop_up}>
          <Typography className={styles.sidebar_main_innerfirsttypo_pop_up}>
            {sidebarPopupTexts?.writeReviewTitle} 
          </Typography>
        </Stack>
        <Stack className={styles.sidebar_main_innersecondstack_pop_up}>
          <CloseOutlinedIcon className={styles.cross_slidepopupnew} onClick={() => closeDrawerHandler()}/>
        </Stack>
      </Stack>
      <Stack className={styles.sidebar_main_innerthirdstack_pop_up}>
        <img className={styles.sidebar_image_pop_up} src={ladydoctor} alt="" />
      </Stack>
      <Stack className={styles.sidebar_main_innerfourstack_pop_up}>
        <Typography className={styles.sidebar_main_innerfourstacktypos_pop_up}>
          {sidebarPopupTexts?.howWasYourExperience}
        </Typography>
      </Stack>
      <Stack className={styles.sidebar_main_innerthirdstack_pop_up}>
        <StarRating setValue={setStarCount}  />
      </Stack>
      <Stack
        className={styles.sidebar_main_innerfourthstack_pop_up}
        direction="row"
        spacing={2}
      >
        <Stack>
          <Typography className={styles.sidebar_main_innerfourthtypos_pop_up}>
            {sidebarPopupTexts?.shareYourExperience}
          </Typography>
          <Typography className={styles.sidebar_main_innerfifthtypos_pop_up}>
            {sidebarPopupTexts?.letUsCallYou}
          </Typography>
        </Stack>
        <Stack>
          <img src={calling} alt='calling'/>
        </Stack>
      </Stack>
      <Stack className={styles.sidebar_main_innerfifthstack_pop_up}>
        <Divider>{sidebarPopupTexts?.or}</Divider>
      </Stack>

      <TextField
        variant="outlined"
        className={styles.sidebar_main_innereightstack_pop_up}
        placeholder="Write your suggestion..."
        sx={{
          border: "none",
          "& fieldset": { border: "none" },
        }}
      />
      <Stack
        className={styles.sidebar_main_innersixthstack_pop_up}
        direction="row"
        spacing={1}
      >
        <Stack className={styles.sidebar_main_innerseventhstack_pop_up}>
          <Button className={styles.cancel_sidebar_button} onClick={handleBack}>
            {sidebarPopupTexts?.cancelButtonLabel}
          </Button>
        </Stack>

        <Stack className={styles.sidebar_main_innerseventhstack_pop_up}>
          <Button className={styles.backs_sidebar_button}>
            {sidebarPopupTexts?.submitButtonLabel}
          </Button>
        </Stack>
      </Stack>
    </Box>
      </Drawer>
    
  );
};
