import { Box } from "@mui/material";
import React from "react";
import TabSection from "../atoms/TabSection";

export const Navbar = ({a11yProps,value,setValue}) => {
  return (
    <Box>
      <TabSection {...{a11yProps,value, setValue}} />
    </Box>
  );
};
