import React, { useState } from "react";
import {
  Typography,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  List,
  Divider,
  Radio,
  Toolbar,
  Stack,
  Button,
} from "@mui/material";
import styles from "../styling/MemberMenu.module.css";
import CloseIcon from "@mui/icons-material/Close";
import Person from "@mui/icons-material/Person";
import Add from "@mui/icons-material/Add";
import Create from "@mui/icons-material/Create";

import {memberMenuConstants} from "../json/memberMenuConstants";

export default function MemberMenu({
  onClose,
  handleMemberListMenu,
  handleFamilyView,
}) {
  const [selectedValue, setSelectedValue] = useState("Nerul");
  const handleChangeLocation = (location) => {
    setSelectedValue(location);
  };

  return (
    <>
      <Toolbar>
        <Typography variant="h4" className={styles.selectName}>
          {memberMenuConstants?.selectMemberText}
        </Typography>
        <CloseIcon className={styles.close_icon} onClick={onClose} />
      </Toolbar>

      <List>
        {memberMenuConstants?.locationsDummyData?.map((text, index) => (
          <>
            <ListItem className={styles.list_View_height} key={text}>
              <ListItemButton>
                <ListItemIcon>
                  <Stack className={styles.personView}>
                    <Person className={styles.personIcon} />
                  </Stack>
                </ListItemIcon>
                <ListItemText
                  primary={text}
                  secondary={memberMenuConstants?.memberDetailsLabel}
                  className={styles.bold_text}
                />
                <Radio
                  checked={selectedValue === text}
                  onChange={() => handleChangeLocation(text)}
                  value={selectedValue}
                  className={styles.radio_button}
                />
              </ListItemButton>
            </ListItem>
            <Divider className={styles.divider_line_edit} />
          </>
        ))}
      </List>
      <Stack direction={"row"} className={styles.buttonContainer}>
        <Button onClick={handleFamilyView} className={styles.addMember_btn}>
          <Add fontSize="14px" className={styles.icon_style} />

          <Typography variant="h5" className={styles.addNewMemberTxt}>
            {memberMenuConstants?.addNewMemberLabel}
          </Typography>
        </Button>

        <Button onClick={handleMemberListMenu} className={styles.addEdit_btn}>
          <Create fontSize="12px" className={styles.icon_style} />
          <Typography variant="h5" className={styles.addNewMemberTxt}>
            {memberMenuConstants?.editLabel}
          </Typography>
        </Button>
      </Stack>
    </>
  );
}
