import React from "react";
import { Formik } from "formik";
import { Stack, Typography, TextField, Button, Box } from "@mui/material";
import { Dropdownformcomp } from "./Dropdownformcomp";
import styles from "../styling/Healthform.module.css";
import * as Yup from "yup";

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  phone: Yup.string().required("Phone is required"),
  message: Yup.string().required("Message is required"),
});

export function Healthform() {
  // return (
  //   <Formik
  //     initialValues={{
  //       name: "",
  //       email: "",
  //       phone: "",
  //       message: "",
  //     }}
  //     validationSchema={validationSchema}
  //     onSubmit={(values, { setSubmitting }) => {
  //       setTimeout(() => {
  //         setSubmitting(false);
  //       }, 400);
  //     }}
  //   >
  //     {({
  //       values,
  //       errors,
  //       touched,
  //       handleChange,
  //       handleBlur,
  //       handleSubmit,
  //       isSubmitting,
  //     }) => (
  //       <form onSubmit={handleSubmit}>
  //         <Stack padding={5} alignItems="flex-start">
  //           <Stack className={styles["Healthform-header"]}>
  //             <TextField
  //               className={styles["Healthform-input"]}
  //               placeholder="Name"
  //               name="name"
  //               value={values?.name}
  //               onChange={(e) => handleChange(e?.target?.name)(e?.target?.value?.replace(/[^A-Za-z\s\-']/g, ''))}
  //               onBlur={handleBlur}
  //               error={touched?.name && Boolean(errors?.name)}
  //             />
  //             {touched?.name && errors?.name ? (
  //               <Typography
  //                 className={styles["yourdetails-errorText"]}
  //                 color="error"
  //               >
  //                 {errors?.name}
  //               </Typography>
  //             ) : null}
  //           </Stack>
  //           <Stack className={styles["Healthform-header"]}>
  //             <TextField
  //               type="email"
  //               className={styles["Healthform-input"]}
  //               placeholder="Email"
  //               name="email"
  //               value={values?.email}
  //               onChange={handleChange}
  //               onBlur={handleBlur}
  //               error={touched?.email && Boolean(errors?.email)}
  //             />
  //             {touched?.email && errors?.email ? (
  //               <Typography
  //                 className={styles["yourdetails-errorText"]}
  //                 color="error"
  //               >
  //                 {errors?.email}
  //               </Typography>
  //             ) : null}
  //           </Stack>
  //           <Stack className={styles["Healthform-header"]}>
  //             <TextField
  //               className={styles["Healthform-input"]}
  //               placeholder="Company Name"
  //               name="companyName"
  //               value={values?.companyName}
  //               onChange={handleChange}
  //               onBlur={handleBlur}
  //             />
  //           </Stack>
  //           <Stack className={styles["Healthform-header"]}>
  //             <TextField
  //               className={styles["Healthform-input"]}
  //               placeholder="Phone"
  //               name="phone"
  //               value={values?.phone}
  //               onChange={(e) => handleChange(e?.target?.name)(e?.target?.value?.replace(/\D/g, ""))}

  //               onBlur={handleBlur}
  //               error={touched?.phone && Boolean(errors?.phone)}
  //             />
  //             {touched?.phone && errors?.phone ? (
  //               <Typography
  //                 className={styles["yourdetails-errorText"]}
  //                 color="error"
  //               >
  //                 {errors?.phone}
  //               </Typography>
  //             ) : null}
  //           </Stack>
  //           <Stack className={styles["Healthform-header"]}>
  //             <Dropdownformcomp />
  //           </Stack>
  //           <Stack className={styles["form-button"]}>
  //             <Button
  //               className={styles.form_btn}
  //               type="submit"
  //               disabled={isSubmitting}
  //             >
  //               <Typography
  //                 variant="h4"
  //                 sx={{ padding: [1] }}
  //                 fontWeight="bold"
  //               >
  //                 Submit
  //               </Typography>
  //             </Button>
  //           </Stack>
  //         </Stack>
  //       </form>
  //     )}
  //   </Formik>
  // );
  return(
    <Box mb={7}>

  <iframe src="https://docs.google.com/forms/d/e/1FAIpQLScbsqZ0hqCuCSR2bTDtFDxchujo7gdpbUUXejxYXvtstxJVgQ/viewform?embedded=true" className={styles.width} height="600" frameborder="0" marginheight="0" marginwidth="0" style={{overflow:"hidden"}}>Loading…</iframe>
  </Box> 
)
}
