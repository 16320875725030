import { Button, Box, Typography, Stack } from "@mui/material";
import { useNavigate } from "react-router-dom";
import styles from "../styling/Milestone.module.css";
import { SiteContent } from "../assets/content/SiteContent";
import { aboutUs_Dynamic } from "../json/aboutus";


export const Milestone = () => {
  const navigate = useNavigate();
  

  function handleOnTimeline() {
    navigate("/login/milestonetimeline");
  }
  

  return (
    <Box>
      <Box className={styles.road_box}>
        <Box className={styles.road_container}>
          <Stack spacing={3}>
            <Typography variant="h2" fontWeight="bold" align="left" p={5} pb={0} className={styles.padding_title}>
              {aboutUs_Dynamic?.BANNER_4?.TITLE}
            </Typography>
            <Typography align="left" className={styles.milestone_subtitle} >
              {aboutUs_Dynamic?.BANNER_4?.SUBTITLE}
            </Typography>
            <Box className={styles.pos_fix}>
              <Button
                variant="contained"
                size="small"
                className={styles.road_btn}
                onClick={handleOnTimeline}
                sx={{ marginBottom: 2 }}
              >
                <Typography variant="h4" fontWeight="bold">
                  {SiteContent?.KNOW_MORE}
                </Typography>
              </Button>
            </Box>
          </Stack>
        </Box>
      </Box>
    </Box>
  );
};
