
import Videocalls from  '../assets/videocall.png'
import persons from '../assets/images/user2.png'
import home from '../assets/images/home.png'
import redhome from '../assets/images/home red.png'
export const Appointments = [
  {
    id: 1,
    name: "Dr Jenny Wilson",
    designation: "Psychiatrist",
    date: "Mon Sep 9, 2023",
    time: "9:00-9:30 am",
  },
  {
    id: 2,
    name: "Dr Senny Wilson",
    designation: "Psychiatrist",
    date: "Mon Sep 8, 2023",
    time: "9:00-9:30 am",
  },
  {
    id: 3,
    name: "Dr Penny Wilson",
    designation: "Psychiatrist",
    date: "Mon Sep 9, 2023",
    time: "9:00-9:30 am",
  },
  {
    id: 4,
    name: "Dr Kenny Wilson",
    designation: "Psychiatrist",
    date: "Mon Sep 9, 2023",
    time: "9:00-9:30 am",
  },
  {
    id: 5,
    name: "Dr Penny Wilson",
    designation: "Psychiatrist",
    date: "Mon Sep 9, 2023",
    time: "9:00-9:30 am",
  },
  {
    id: 6,
    name: "Dr Oenny Wilson",
    designation: "Psychiatrist",
    date: "Mon Sep 9, 2023",
    time: "9:00-9:30 am",
  },
  {
    id: 7,
    name: "Dr Lenny Wilson",
    designation: "Psychiatrist",
    date: "Mon Sep 9, 2023",
    time: "9:00-9:30 am",
  },
  {
    id: 8,
    name: "Dr Chenny Wilson",
    designation: "Psychiatrist",
    date: "Mon Sep 9, 2023",
    time: "9:00-9:30 am",
  },
];

 
 
export const Appointment  =  [
  {
    id: 1,
    name: "Dr Jenny Wilson",
    designation: "Psychiatrist",
    date: "Mon Sep 9, 2023",
    time: "9:00-9:30 am",
    avatars:Videocalls,
    image: persons
  },
  {
    id: 2,
    name: "Dr Jenny Wilson",
    designation: "Psychiatrist • Andheri, S.V.Road",
    date: "Mon Sep 8, 2023",
    time: "9:00-9:30 am",
    avatars:redhome,
    image:home
  },
  {
    id: 3,
    name: "Dr Jenny Wilson",
    designation: "Psychiatrist • Andheri, S.V.Road",
    date: "Mon Sep 8, 2023",
    time: "9:00-9:30 am",
    avatars:redhome,
    image:home
  },
  {
    id: 4,
    name: "Dr Jenny Wilson",
    designation: "Psychiatrist • Andheri, S.V.Road",
    date: "Mon Sep 8, 2023",
    time: "9:00-9:30 am",
    avatars:redhome,
    image:home
  },
  {
    id: 5,
    name: "Dr Jenny Wilson",
    designation: "Psychiatrist • Andheri, S.V.Road",
    date: "Mon Sep 8, 2023",
    time: "9:00-9:30 am",
    avatars:redhome,
    image:home
  },
  {
    id: 6,
    name: "Dr Jenny Wilson",
    designation: "Psychiatrist • Andheri, S.V.Road",
    date: "Mon Sep 8, 2023",
    time: "9:00-9:30 am",
    avatars:redhome,
    image:home
  }
]