import React from "react";
import {
  Stack,
  Step,
  Stepper,
  StepConnector,
  stepConnectorClasses,
  styled,
  Box,
  Avatar,
  Typography,
  Button,
} from "@mui/material";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { useNavigate } from "react-router-dom";
import styles from "../styling/BasicDetails.module.css";
import theme from "../components/Themeify";
import { SiteContent } from "../assets/content/SiteContent";
import { useSharedData } from "../Context/ShareContext";
import ProfileDetails from "../molecules/RegistrationDetails/ProfileDetails";
import AddressDetails from "../molecules/RegistrationDetails/AddressDetails";
import EmergencyContactDetails from "../molecules/RegistrationDetails/EmergencyContactDetails";

const steps = ["", "", "", ""];

const QontoConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#b2c943",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#b2c943",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor:
      theme.palette.mode === "dark" ? theme.palette.grey[800] : "#e6e8e6",
    borderTopWidth: 3,
    borderRadius: 1,
  },
}));

const BasicDetails = () => {
  const navigate = useNavigate();
  const { currentSteps, setCurrentSteps } = useSharedData();

  const handleBack = () => {
    if(currentSteps === 3) {
      setCurrentSteps(currentSteps - 1);
    } else if (currentSteps === 2) {
      setCurrentSteps(currentSteps - 1);
    } else {
    navigate("/login/verifyabha");
    }
  };

  return (
    <Box >
      <Stack direction="row" onClick={handleBack} className={styles.newLay}>
        <Button
          id="basic-button"
          startIcon={
            <Avatar className={styles.back_icon_container}>
              <KeyboardBackspaceIcon className={styles.back_icon} />
            </Avatar>
          }
        >
          <Typography variant="h5" color={theme.palette.info.grey}>
            {SiteContent.BACK}
          </Typography>
        </Button>
        
      </Stack>
      <Stack className={styles.stepper_stack}>
          <Stepper
            alternativeLabel
            activeStep={currentSteps}
            connector={<QontoConnector />}
          >
            {steps?.map((label) => (
              <Step key={label}></Step>
            ))}
          </Stepper>
        </Stack>
      {currentSteps === 1 ? (
        <ProfileDetails />
      ) : currentSteps === 2 ? (
        <AddressDetails />
      ) : (
        <EmergencyContactDetails />
      )}
    </Box>
  );
};
export default BasicDetails;
