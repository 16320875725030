import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import styles from "../styling/YourEmergencyDetails.module.css";
import {
  Grid,
  Typography,
  Stack,
  Box,
  InputAdornment,
  Button,
  Paper,
  Select,
  MenuItem,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import TextField from "@mui/material/TextField";
import { BackButton } from "./BackButton";
import { styled } from "@mui/material/styles";

import {
  AccountCircleOutlined,
  CakeOutlined,
  CallOutlined,
  EmailOutlined,
  PersonOutlineOutlined,
  TransgenderOutlined,
} from "@mui/icons-material";

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .min(5, "Name is too short, must be at least 5 characters")
    .max(50, "Name is too long, must be at most 50 characters")
    .required("Name is mandatory"),
  email: Yup.string()
    .email("Enter a valid email")
    .required("Email is mandatory"),
  mobileNumber: Yup.string()
    .min(10, "Mobile number must be of 10 digits")
    .max(10, "Mobile number is too long, must be at most 10 digits")
    .required("Mobile number is mandatory"),
  gender: Yup.string().required("Gender is required"),
  dateOfBirth: Yup.string().required("Date of Birth is required"),
  age: Yup.string()
    .matches(/^\d+$/, "Invalid Age")
    .required("Field is mandatory"),
  country: Yup.string().required("Country is required"),
  pincode: Yup.string().required("Pincode is required"),
  state: Yup.string().required("State is required"),
  city: Yup.string().required("City is required"),
  addressLine1: Yup.string().required("Address Line 1 is required"),
  addressLine2: Yup.string(),
});

const Item = styled(Paper)(({ theme, active }) => ({
  backgroundColor: active ? "#B2C943" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
  width: "30%",
  borderRadius: "14px",
  border: "1px solid",
  borderColor: active ? "#B2C943" : "#D1D7D6",
  boxShadow: "none",
  cursor: "pointer",
}));
const countries = [
  { code: "+1", label: "+1" },
  { code: "+91", label: "+91" },
  // Add more countries as needed
];

export const YourDetails = () => {
  const [editMode, setEditMode] = useState(false);
  const [selectedGender, setSelectedGender] = useState("");
  const [selectedCountryCode, setSelectedCountryCode] = useState(
    countries[1].code
  ); // Set the default country code

  const editClickhandler = () => {
    setEditMode(true);
  };
  const handleBack = () => {
    setEditMode(false);
  };

  const formik = useFormik({
    initialValues: {
      name: "Elina Jhonson",
      email: "elinajhonson@email.com",
      mobileNumber: "7894561230",
      gender: "Female",
      dateOfBirth: "26/09/1996",
      age: "26",
      country: "India",
      pincode: "380005",
      state: "Maharashtra",
      city: "Navi Mumbai",
      addressLine1: "Block No. C9, Sardar Apartment,",
      addressLine2: "Ranip Chowk, Near Shivji Temple",
    },
    validationSchema,
    onSubmit: (values) => {},
  });

  return (
    <Box>
      {!editMode && (
        <Box className={styles.page_info}>
          <Typography className={styles["yourdetails-mainTitle"]} variant="h4">
            Personal Info
          </Typography>
          <Button
            severity="success"
            onClick={() => editClickhandler()}
            startIcon={<EditIcon />}
            className={styles.edit_btn}
          >
            Edit
          </Button>
        </Box>
      )}

      {editMode && <BackButton onClickHandler={() => handleBack()} />}

      {editMode && (
        <Box className={styles.page_info_edit}>
          <Typography className={styles["yourdetails-mainTitle"]} variant="h4">
            Edit Personal Info
          </Typography>
        </Box>
      )}
      <Grid container mb={6} className={styles.inputFieldGrid}>
        <Stack direction="column" className={styles.detailsFieldWrapper}>
          <Stack direction={{ xs: "column", md: "row" }} spacing={4}>
            <Stack item xs={6}>
              <Typography
                className={styles["yourdetails-textfieldTitle"]}
                variant="h5"
              >
                Name
              </Typography>
              <TextField
                disabled={!editMode}
                className={styles["yourdetails-textfield"]}
                variant="standard"
                InputProps={{
                  className: styles["yourdetails-textfieldInput"],
                  disableUnderline: true,
                  endAdornment: (
                    <InputAdornment>
                      <AccountCircleOutlined className={styles.three} />
                    </InputAdornment>
                  ),
                }}
                name="name"
                value={formik?.values?.name}
                onChange={(e) =>
                  formik.handleChange(e?.target?.name)(
                    e?.target?.value?.replace(/[^A-Za-z\s\-']/g, "")
                  )
                }
                onBlur={formik?.handleBlur}
                error={formik?.touched?.name && Boolean(formik?.errors?.name)}
              />
              {formik?.touched?.name && formik?.errors?.name ? (
                <Typography
                  className={styles["yourdetails-errorText"]}
                  color="error"
                >
                  {formik?.errors?.name}
                </Typography>
              ) : null}
            </Stack>
            <Stack item xs={6} className={styles.inputFieldContainer}>
              <Typography
                className={styles["yourdetails-textfieldTitle"]}
                variant="h5"
              >
                Email
              </Typography>

              <TextField
                disabled={!editMode}
                className={styles["yourdetails-textfield"]}
                variant="standard"
                InputProps={{
                  className: styles["yourdetails-textfieldInput"],
                  disableUnderline: true,
                  endAdornment: (
                    <InputAdornment>
                      <EmailOutlined className={styles.three} />
                    </InputAdornment>
                  ),
                }}
                name="email"
                value={formik?.values?.email}
                onChange={formik?.handleChange}
                onBlur={formik?.handleBlur}
                error={formik?.touched?.email && Boolean(formik?.errors?.email)}
              />
              {formik?.touched?.email && formik?.errors?.email ? (
                <Typography
                  className={styles["yourdetails-errorText"]}
                  color="error"
                >
                  {formik?.errors?.email}
                </Typography>
              ) : null}
            </Stack>
          </Stack>
          <Stack direction={{ xs: "column", md: "row" }} spacing={4}>
            <Stack item xs={6}>
              <Typography
                className={styles["yourdetails-textfieldTitle"]}
                variant="h5"
              >
                Mobile Number
              </Typography>

              <Stack direction="row" alignItems="center">
                <TextField
                  disabled={true}
                  className={styles["yourdetails-textfield"]}
                  variant="standard"
                  InputProps={{
                    className: styles["yourdetails-textfieldInput"],
                    disableUnderline: true,
                    startAdornment: (
                      <InputAdornment position="start">
                        <Select
                         sx={{ boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: 0 } }}
                          disabled={true}
                          value={selectedCountryCode}
                          onChange={(e) =>
                            setSelectedCountryCode(e.target.value)
                          }
                          className={styles["yourdetails-countryCodeSelect"]}
                        >
                          {countries.map((country) => (
                            <MenuItem key={country.code} value={country.code}>
                              {country.label}
                            </MenuItem>
                          ))}
                        </Select>
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <CallOutlined className={styles.three} />
                      </InputAdornment>
                    ),
                  }}
                  name="mobileNumber"
                  value={formik?.values?.mobileNumber}
                  onChange={(e) => {
                    const value = e?.target?.value?.replace(/\D/g, "");
                    formik?.handleChange(e?.target?.name)(value);
                  }}
                  onBlur={formik?.handleBlur}
                  error={
                    formik?.touched?.mobileNumber &&
                    Boolean(formik?.errors?.mobileNumber)
                  }
                />
              </Stack>

              {formik?.touched?.mobileNumber && formik?.errors?.mobileNumber ? (
                <Typography
                  className={styles["yourdetails-errorText"]}
                  color="error"
                >
                  {formik?.errors?.mobileNumber}
                </Typography>
              ) : null}
            </Stack>
            <Stack item xs={6} className={styles.inputFieldContainer}>
              <Typography
                className={styles["yourdetails-textfieldTitle"]}
                variant="h5"
              >
                Gender
              </Typography>
              {editMode ? (
                <Stack direction="row" spacing={2} className={styles.genderWrapper}>
                  <Item
                    onClick={() => setSelectedGender("male")}
                    active={selectedGender === "male"}
                  >
                    Male
                  </Item>
                  <Item
                    onClick={() => setSelectedGender("female")}
                    active={selectedGender === "female"}
                  >
                    Female
                  </Item>
                  <Item
                    onClick={() => setSelectedGender("other")}
                    active={selectedGender === "other"}
                  >
                    Other
                  </Item>
                </Stack>
              ) : (
                <TextField
                  disabled={!editMode}
                  className={styles["yourdetails-textfield"]}
                  variant="standard"
                  InputProps={{
                    className: styles["yourdetails-textfieldInput"],
                    disableUnderline: true,
                    endAdornment: (
                      <InputAdornment>
                        <TransgenderOutlined className={styles.three} />
                      </InputAdornment>
                    ),
                  }}
                  name="gender"
                  value={formik?.values?.gender}
                  onChange={(e) =>
                    formik?.handleChange(e?.target?.name)(
                      e?.target?.value?.replace(/\D/g, "")
                    )
                  }
                  onBlur={formik?.handleBlur}
                  error={
                    formik?.touched?.gender && Boolean(formik?.errors?.gender)
                  }
                />
              )}
              {formik?.touched?.gender && formik?.errors?.gender ? (
                <Typography
                  className={styles["yourdetails-errorText"]}
                  color="error"
                >
                  {formik?.errors?.gender}
                </Typography>
              ) : null}
            </Stack>
          </Stack>
          <Stack direction={{ xs: "column", md: "row" }} spacing={2}>
            <Stack item xs={6}>
              <Typography
                className={styles["yourdetails-textfieldTitle"]}
                variant="h5"
              >
                Date Of Birth
              </Typography>
              <TextField
                disabled={!editMode}
                className={styles["yourdetails-textfieldDobAge"]}
                variant="standard"
                InputProps={{
                  className: styles["yourdetails-textfieldInput"],
                  disableUnderline: true,
                  endAdornment: (
                    <InputAdornment>
                      <CakeOutlined className={styles.three} />
                    </InputAdornment>
                  ),
                }}
                name="dateOfBirth"
                value={formik?.values?.dateOfBirth}
                onChange={formik?.handleChange}
                onBlur={formik?.handleBlur}
                error={
                  formik?.touched?.dateOfBirth &&
                  Boolean(formik?.errors?.dateOfBirth)
                }
              />
              {formik?.touched?.dateOfBirth && formik?.errors?.dateOfBirth ? (
                <Typography
                  className={styles["yourdetails-errorText"]}
                  color="error"
                >
                  {formik?.errors?.dateOfBirth}
                </Typography>
              ) : null}
            </Stack>
            <Stack item xs={6} className={styles.inputFieldContainer}>
              <Typography
                className={styles["yourdetails-textfieldTitle"]}
                variant="h5"
              >
                Age
              </Typography>
              <TextField
                disabled={!editMode}
                className={styles["yourdetails-textfieldDobAge"]}
                variant="standard"
                InputProps={{
                  className: styles["yourdetails-textfieldInput"],
                  disableUnderline: true,
                  endAdornment: (
                    <InputAdornment>
                      <PersonOutlineOutlined className={styles.three} />
                    </InputAdornment>
                  ),
                }}
                name="age"
                value={formik?.values?.age}
                onChange={(e) =>
                  formik.handleChange(e?.target?.name)(
                    e?.target?.value?.replace(/\D/g, "")
                  )
                }
                onBlur={formik?.handleBlur}
                error={formik?.touched?.age && Boolean(formik?.errors?.age)}
              />
              {formik?.touched?.age && formik?.errors?.age ? (
                <Typography
                  className={styles["yourdetails-errorText"]}
                  color="error"
                >
                  {formik?.errors?.age}
                </Typography>
              ) : null}
            </Stack>
          </Stack>
        </Stack>
      </Grid>
      {editMode && (
        <Typography className={styles["yourdetails-mainTitle"]} variant="h4">
          Edit Address Info
        </Typography>
      )}
      {!editMode && (
        <Typography className={styles["yourdetails-mainTitle"]} variant="h4">
          Address Info
        </Typography>
      )}
      <Grid container mb={3} className={styles.addressInfoGrid}>
        <Stack direction={{ xs: "column", md: "row" }} spacing={4}>
          <Stack item xs={6}>
            <Typography
              className={styles["yourdetails-textfieldTitle"]}
              variant="h5"
            >
              Country
            </Typography>
            <TextField
              disabled={!editMode}
              className={styles["yourdetails-textfield"]}
              variant="standard"
              InputProps={{
                className: styles["yourdetails-textfieldInput"],
                disableUnderline: true,
              }}
              name="country"
              value={formik?.values?.country}
              onChange={(e) =>
                formik.handleChange(e?.target?.name)(
                  e?.target?.value?.replace(/[^A-Za-z\s\-']/g, "")
                )
              }
              onBlur={formik?.handleBlur}
              error={
                formik?.touched?.country && Boolean(formik?.errors?.country)
              }
            />
            {formik?.touched?.country && formik?.errors?.country ? (
              <Typography
                className={styles["yourdetails-errorText"]}
                color="error"
              >
                {formik?.errors?.country}
              </Typography>
            ) : null}
          </Stack>
          <Stack item xs={6} className={styles.inputFieldContainer}>
            <Typography
              className={styles["yourdetails-textfieldTitle"]}
              variant="h5"
            >
              Pincode
            </Typography>
            <TextField
              disabled={!editMode}
              className={styles["yourdetails-textfield"]}
              variant="standard"
              InputProps={{
                className: styles["yourdetails-textfieldInput"],
                disableUnderline: true,
              }}
              name="pincode"
              value={formik?.values?.pincode}
              onChange={(e) => {
                const value = e?.target?.value?.replace(/\D/g, "");
                formik?.handleChange(e?.target?.name)(value);
              }}
              onBlur={formik.handleBlur}
              error={
                formik?.touched?.pincode && Boolean(formik?.errors?.pincode)
              }
            />
            {formik?.touched?.pincode && formik?.errors?.pincode ? (
              <Typography
                className={styles["yourdetails-errorText"]}
                color="error"
              >
                {formik?.errors?.pincode}
              </Typography>
            ) : null}
          </Stack>
        </Stack>
        <Stack direction={{ xs: "column", md: "row" }} spacing={4}>
          <Stack item xs={6}>
            <Typography
              className={styles["yourdetails-textfieldTitle"]}
              variant="h5"
            >
              State
            </Typography>
            <TextField
              disabled={!editMode}
              className={styles["yourdetails-textfield"]}
              variant="standard"
              InputProps={{
                className: styles["yourdetails-textfieldInput"],
                disableUnderline: true,
              }}
              name="state"
              value={formik?.values?.state}
              onChange={(e) =>
                formik?.handleChange(e?.target?.name)(
                  e?.target?.value?.replace(/[^A-Za-z\s\-']/g, "")
                )
              }
              onBlur={formik?.handleBlur}
              error={formik?.touched?.state && Boolean(formik?.errors?.state)}
            />
            {formik?.touched?.state && formik?.errors?.state ? (
              <Typography
                className={styles["yourdetails-errorText"]}
                color="error"
              >
                {formik?.errors?.state}
              </Typography>
            ) : null}
          </Stack>
          <Stack item xs={6} className={styles.inputFieldContainer}>
            <Typography
              className={styles["yourdetails-textfieldTitle"]}
              variant="h5"
            >
              City
            </Typography>
            <TextField
              disabled={!editMode}
              className={styles["yourdetails-textfield"]}
              variant="standard"
              InputProps={{
                className: styles["yourdetails-textfieldInput"],
                disableUnderline: true,
              }}
              name="city"
              value={formik?.values?.city}
              onChange={(e) => {
                const value = e?.target?.value?.replace(/[^A-Za-z\s\-']/g, "");
                formik.handleChange(e?.target?.name)(value);
              }}
              onBlur={formik?.handleBlur}
              error={formik?.touched?.city && Boolean(formik?.errors?.city)}
            />
            {formik?.touched?.city && formik?.errors?.city ? (
              <Typography
                className={styles["yourdetails-errorText"]}
                color="error"
              >
                {formik?.errors?.city}
              </Typography>
            ) : null}
          </Stack>
        </Stack>
        <Stack direction={{ xs: "column", md: "row" }} spacing={4}>
          <Stack item xs={6}>
            <Typography
              className={styles["yourdetails-textfieldTitle"]}
              variant="h5"
            >
              Address Line 1
            </Typography>
            <TextField
              disabled={!editMode}
              className={styles["yourdetails-textfield"]}
              variant="standard"
              name="addressLine1"
              value={formik?.values?.addressLine1}
              onChange={formik?.handleChange}
              onBlur={formik?.handleBlur}
              error={
                formik?.touched?.addressLine1 &&
                Boolean(formik?.errors?.addressLine1)
              }
              InputProps={{
                className: styles["yourdetails-textfieldInput"],
                disableUnderline: true,
              }}
            />
            {formik?.touched?.addressLine1 && formik?.errors?.addressLine1 ? (
              <Typography
                className={styles["yourdetails-errorText"]}
                color="error"
              >
                {formik?.errors?.addressLine1}
              </Typography>
            ) : null}
          </Stack>
          <Stack item xs={6} className={styles.inputFieldContainer}>
            <Typography
              className={styles["yourdetails-textfieldTitle"]}
              variant="h5"
            >
              Address Line 2
            </Typography>
            <TextField
              disabled={!editMode}
              className={styles["yourdetails-textfield"]}
              variant="standard"
              name="addressLine2"
              value={formik?.values?.addressLine2}
              onChange={formik?.handleChange}
              onBlur={formik?.handleBlur}
              error={
                formik?.touched?.addressLine2 &&
                Boolean(formik?.errors?.addressLine2)
              }
              InputProps={{
                className: styles["yourdetails-textfieldInput"],
                disableUnderline: true,
              }}
            />
            {formik.touched.addressLine2 && formik.errors.addressLine2 ? (
              <Typography
                className={styles["yourdetails-errorText"]}
                color="error"
              >
                {formik?.errors?.addressLine2}
              </Typography>
            ) : null}
          </Stack>
        </Stack>
        {editMode && (
          <Stack direction="row" spacing={4}>
            <Stack item xs={6}>
              <Button
                onClickHandler={() => handleBack()}
                className={styles.custom_btn}
              >
                Save
              </Button>
            </Stack>
          </Stack>
        )}
      </Grid>
    </Box>
  );
};
