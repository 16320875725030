import React, { useState } from "react";
import { Tab, Tabs, Typography, Box, Stack } from "@mui/material";
import AppointmentBlankImg from "../assets/images/no_appointment.png";
import { BlankPage } from "./BlankPage";
import { UpcomingApponitment } from "./UpcomingApponitment";
import { CompletedAppointment } from "./CompletedAppointment";
import { CancelledAppointment } from "./CancelledAppointment";
import { useSharedData } from "../Context/ShareContext";
import Summary from "../pages/Summary";
import CustomModal from "../molecules/CustomModal"
import styles from "../styling/AccountInformation.module.css"

function a11yProps(index) {
  return {
    id: `nav-tab-${index}`,
    "aria-controls": `nav-tabpanel-${index}`,
  };
}
export const Myappointment = () => {
  const [value, setValue] = useState(-1);
  const { showSummary, appointmentSummaryData, appointmentRescheduleModal, setAppointmentRescheduleModal,modalValue,setModalValue } = useSharedData();
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const title="My Appointments";
  const upcoming="Upcoming";
  const completed="Completed";
  const cancelled="Cancelled";

  return (
    <Box >
     
  <CustomModal setOpen={setAppointmentRescheduleModal} open={appointmentRescheduleModal}   type="success"/>
  <CustomModal setOpen={setModalValue} open={modalValue} type="custom"/>

 


      {
       showSummary === false ? (
        <>
            <Typography className={styles["accinfo-title"]} variant="h4">
              {title}
            </Typography>
            <Stack direction="row" className={styles["accinfo-tabStack"]}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="nav tabs example"
              >
                <Tab
                  label={<Typography variant="h5">{upcoming}</Typography>}
                  {...a11yProps(0)}
                />
                <Tab
                  label={<Typography variant="h5">{completed}</Typography>}
                  {...a11yProps(1)}
                />
                <Tab
                  label={<Typography variant="h5">{cancelled}</Typography>}
                  {...a11yProps(2)}
                />
              </Tabs>
            </Stack>
            <Stack>
              {value === -1 && (
                <BlankPage
                  blankImage={AppointmentBlankImg}
                  mainTxt={"No Appointments, Yet"}
                  subTxt={"You don't have an appointment scheduled at the moment."}
                />
              )}
            </Stack>
            <Stack>{value === 0 && <UpcomingApponitment />}</Stack>
            <Stack>{value === 1 && <CompletedAppointment />}</Stack>
            <Stack>{value === 2 && <CancelledAppointment />}</Stack>
        </>
       ) : (
          <Summary
            PageTitle={"Appointment Summary"}
            ID={appointmentSummaryData?.appointmentId}
            CardTitle={"Booked on"}
            CardTime={appointmentSummaryData?.bookedTime}
            CardStatus={appointmentSummaryData?.status}
            appointmentType={appointmentSummaryData?.appointmentType}
            
          />
        )
      }
     
    </Box>
  );
};
