import { Box, Stack } from "@mui/material";
import React,{useEffect} from "react";
import GenericHeaderCard from "./GenericHeaderCard";
import GenericSearch from "./GenericSearch";
import HealthCard from "./HealthCard";
import styles from "../styling/HealthCardSearch.module.css";
import defaultStyles from "../styling/DefaultStyles.module.css";
import Bread from "./Bread";
import banner from "../assets/medical-shield.png";

export default function TestPackageHealthCheckDetails() {
  useEffect(() => {
    window.scrollTo(0,0);
    return () => {
      
    };
  }, []); 
  return (
    <>
      <Stack className={defaultStyles.stack_with_max_width}>
        <Stack className={defaultStyles.bread_stack}>
          <Bread />
        </Stack>
        <Box
          className={styles.lab_test_new_box_main}
          data-testid="test_package_health_details_container"
        >
          <Stack spacing={4}>
            <GenericHeaderCard
              headingtext={"Health Check Packages"}
              banner={banner}
            />
            <GenericSearch placeholder="Search for health package" />
            <HealthCard />
          </Stack>
        </Box>
      </Stack>
    </>
  );
}
