import doctor from "../assets/doc-big.png";
import onsite from "../assets/onsite.png";
import call from "../assets/images/videocall.png";
import home from "../assets/hom.svg";
import doctors from "../assets/doctors.svg";
import ladydoctor from "../assets/ladydoctors.png";

export const DoctorCard = [
  {
    img: doctor,
    name: "Dr. Raj Avasthi",
    domain: "Gynecologist",
    years: "7+ Yrs",
    place: "Kharghar, +2 More",
    lang: "English, Hindi, Gujarati",
    price: "₹1,999",
    prices: "₹2,499",
    onsite: "On Site",
    call: "Call",
    InClinic: "Inclinic",
    avatarone: onsite,
    video: call,
    home: home,
  },
  {
    img: doctors,
    name: "Dr. Rajesh Vibhore",
    domain: "Gynecologist",
    years: "4+ Yrs",
    place: "Vashi, +2 More",
    lang: "English, Hindi, Gujarati",
    price: "₹1,499",
    prices: "₹1,999",
    onsite: "On Site",
    call: "",
    InClinic: "Inclinic",
    avatarone: onsite,
    video: "",
    home: home,
  },
  {
    img: ladydoctor,
    name: "Dr. Vishakha Jain",
    domain: "Gynecologist",
    years: "7+ Yrs",
    place: "Kharghar, +2 More",
    lang: "English, Hindi",
    price: "₹999",
    prices: "₹1,499",
    onsite: "",
    call: "Call",
    InClinic: "Inclinic",
    avatarone: "",
    video: call,
    home: home,
  },
  {
    img: doctor,
    name: "Dr. Raj Avasthi",
    domain: "Gynecologist",
    years: "7+ Yrs",
    place: "Kharghar, +2 More",
    lang: "English, Hindi, Gujarati",
    price: "₹1,999",
    prices: "₹2,499",
    onsite: "On Site",
    call: "Call",
    InClinic: "Inclinic",
    avatarone: onsite,
    video: call,
    home: home,
  },
  {
    img: doctors,
    name: "Dr. Rajesh Vibhore",
    domain: "Gynecologist",
    years: "4+ Yrs",
    place: "Vashi, +2 More",
    lang: "English, Hindi, Gujarati",
    price: "₹1,499",
    prices: "₹1,999",
    onsite: "On Site",
    call: "",
    InClinic: "Inclinic",
    avatarone: onsite,
    video: "",
    home: home,
  },
];
