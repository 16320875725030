import React from "react";
import TestPackageBooking from "./TestPackageBooking";
import iconImage from "../assets/images/micro.svg";
import { Stack } from "@mui/system";
import Bread from "./Bread";
import defaultStyles from "../styling/DefaultStyles.module.css";
import { accordionData } from "../json/AccoedianData";
export const LiverFunctionTest = () => {
  let header = "Liver Function Test(LFT)";
  let title = "Includes 11 tests";

  let description =
    "Liver Function Test consists of various blood tests that may help diagnose and monitor liver-related diseases. Some tests help determine how well the liver is functioning. The LFT test measures the level of liver enzymes and proteins in your blood. Liver disorders may present symptoms like abdominal pain, weight loss, nausea, diarrhoea, lack of energy, jaundice, fever or fatigue, dark-coloured urine or light-coloured stools.";
  let extraDescription =
    "Doctors often recommend this test for monitoring liver function during ongoing treatment of other conditions as well. People who are overweight, eat unhealthy food, face digestion-related problems frequently, consume alcohol, and suffer from autoimmune diseases are at a higher risk of liver injury. Individuals can take this test for a routine evaluation of their liver function or as advised by a doctor.";


  return (
    <>
      <Stack className={defaultStyles.stack_with_max_width}>
        <Stack className={defaultStyles.bread_stack}>
          <Bread />
        </Stack>
        <TestPackageBooking
          iconImage={iconImage}
          header={header}
          title={title}
          data={accordionData}
          description={
            <>
              {description}
              <span className={defaultStyles.break_after_stools}>
                {" "}
              </span>
            </>
          }
          extraDescription={extraDescription}
          
        />
      </Stack>
    </>
  );
};
