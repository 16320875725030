import React from "react";
import {
  Card,
  Stack,
  CardContent,
  Box,
  Typography,
  Button,
} from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import styles from "../styling/SidebarPopup.module.css";
import {meetingReviewConstants} from "../json/meetingReviewConstants";

const MeetingReview = () => {
  return (
    <Stack data-testid="meeting_review_container">
      <Card className={styles.meeting_review_card_main}>
        <CardContent>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            mb={2}
          >
            <CheckCircleOutlineIcon color="success" fontSize="large" />
          </Box>
          <Typography variant="h5" component="h2" align="center" gutterBottom>
            {meetingReviewConstants?.congratulationsText}
          </Typography>
          <Typography variant="body2" component="p" align="center" gutterBottom>
            {meetingReviewConstants?.taskCompleteText}
          </Typography>
          <Box display="flex" justifyContent="space-between" mt={3} mx={-1}>
            <Button variant="contained" color="primary">
              {meetingReviewConstants?.leaveReviewButton}
            </Button>
            <Button variant="contained" color="primary">
              {meetingReviewConstants?.backToHomeButton}
            </Button>
          </Box>
        </CardContent>
      </Card>
    </Stack>
  );
};

export default MeetingReview;
