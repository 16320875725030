import React, { useState } from "react";
import { Typography, Stack, Button, Avatar, Box } from "@mui/material";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useNavigate } from "react-router-dom";
import theme from "../components/Themeify";
import loginPgIcon from "../assets/images/loginpg-icon.png";
import ByeEmoji from "../assets/images/bye_emoji.png";
import styles from "../styling/LogSignOtpPage.module.css";
import OtpPage from "../molecules/OtpPage";
import { SiteContent } from "../assets/content/SiteContent";
import { useFormik } from "formik";
import * as Yup from "yup";
import loginStyle from "../styling/LoginPage.module.css";

const validationSchema = Yup.object({
  phoneno: Yup.string().required("Phone number is required"),
});

const LoginPage = () => {
  let canGetOtpTime = 60;
  const navigate = useNavigate();
  const [isOtpRequested, setIsOtpRequested] = useState(false);
  const [optRemainingTime, setOptRemainingTime] = useState(60);

  const formik = useFormik({
    initialValues: {
      phoneno: "",
    },
    validationSchema,
    onSubmit: () => {
      setIsOtpRequested(true);
      changeOtpTime();
    },
  });

  const handleBack = () => {
    navigate("/login/");
  };

  const handleSignUp = () => {
    navigate("/login/signup");
  };

  const changeOtpTime = () => {
    var intervalId = setInterval(function () {
      canGetOtpTime -= 1;
      setOptRemainingTime(canGetOtpTime);

      if (canGetOtpTime <= 0) {
        canGetOtpTime = 60;
        clearInterval(intervalId);
      }
    }, 1000);
  };

  return (
    <>
      <Stack direction="row" className={loginStyle.back_stack}>
        <Stack className={loginStyle.back_btn}>
          <Button
            id="basic-button"
            startIcon={
              <Avatar className={styles.back_icon_container}>
                <KeyboardBackspaceIcon className={styles.back_icon} />
              </Avatar>
            }
            onClick={handleBack}
          >
            <Typography variant="h5" color={theme.palette.info.grey}>
              {SiteContent.BACK}
            </Typography>
          </Button>
        </Stack>
      </Stack>
      {!isOtpRequested && (
        <Stack className={styles.main_stack}>
          <Box className={styles.main_box}>
            <Stack className={styles.avatar_icon}>
              <Avatar
                src={loginPgIcon}
                className={styles.top_icon}
                alt="Login Page Logo"
              />
            </Stack>
            <Stack className={styles.mid_stack}>
              <Typography variant="h3" className={styles.main_txt}>
                {SiteContent.WELCOME_BACK}
              </Typography>
              <Avatar
                src={ByeEmoji}
                className={styles.bye_icon}
                alt="Bye Emoji Logo"
              />
            </Stack>
            <Typography className={styles.sub_txt}>
              {SiteContent.ENTER_PHONE_NUMBER_TO_LOGIN}
            </Typography>
            <PhoneInput
              country={"in"}
              value={formik?.values?.phoneno}
              onChange={(phoneno) => formik?.setFieldValue("phoneno", phoneno)}
              onBlur={formik?.handleBlur("phoneno")}
              error={
                formik?.touched?.phoneno && Boolean(formik?.errors?.phoneno)
              }
            />
            {formik?.touched?.phoneno && formik?.errors?.phoneno ? (
              <Typography variant="body2" color="error">
                {formik?.errors?.phoneno}
              </Typography>
            ) : null}
            <Button className={styles.main_btn} onClick={formik?.handleSubmit}>
              {SiteContent.LOGIN}
            </Button>
            <Stack className={styles.btm_stack}>
              <Typography className={styles.btm_txt}>
                {SiteContent.DONT_HAVE_AN_ACCOUNT}{" "}
              </Typography>
              <Typography
                className={styles.btm_logsigntxt}
                onClick={handleSignUp}
              >
                {SiteContent.SIGN_UP}
              </Typography>
            </Stack>
          </Box>
        </Stack>
      )}
      {isOtpRequested && (
        <OtpPage
          PhoneNo={formik?.values?.phoneno}
          OptRemainingTime={optRemainingTime}
          PageName="Login"
        />
      )}
    </>
  );
};
export default LoginPage;
