import React from "react";
import { Stack } from "@mui/system";
import LabtestAppointment from "../molecules/LabtestAppointment";
import styles from "../styling/LabtestCheckOut.module.css";
import ico22 from "../assets/xyz.svg";
import { LabtestCheckoutPayment } from "../molecules/LabtestCheckoutPayment";
import Bread from "../molecules/Bread";
import defaultStyles from "../styling/DefaultStyles.module.css";

export const XrayScan = () => {
  return (
    <>
        <Stack className={defaultStyles.max_bread_14_5}>
        <Bread />
      </Stack>
      <Stack
        className={styles.xrayscan_main_stack_inner_main}
        spacing={4}
        direction={{
          xs: "column",
          sm: "column",
          md: "row",
          lg: "row",
        }}
        mt={5}
      >
        <Stack className={styles.xrayscan_main_stack_inner}>
          <LabtestAppointment
            img={ico22}
            textone="Xray and Scan Appointments"
            textTwo="Xray Ankle Joint Ap View"
          />
        </Stack>
        <Stack className={styles.paymentch}>
          <LabtestCheckoutPayment />
        </Stack>
      </Stack>
    </>
  );
};
