export const membershipConstants = {
  pageTitle: "My Membership",
  activeTabLabel: "Active",
  completedTabLabel: "Completed",
  cancelledTabLabel: "Cancelled",
  blankPageMainText: "No any active membership",
  blankPageSubText: "You don't have any active membership at the moment.",
  blankPageButtonText: "Book Membership",
  modalTitle: "Membership Summary",
  cardTitleLabel: "Booked on",
  cardTimeLabel: "Card Time",
  cardStatusLabel: "Card Status",
};
