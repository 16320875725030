import React,{useState} from "react";
import { Typography, Stack, Button, Avatar, Box } from "@mui/material";
import { MuiOtpInput } from "mui-one-time-password-input";
import { useNavigate } from "react-router-dom";
import loginPgIcon from "../assets/images/loginpg-icon.png";
import styles from "../styling/LogSignOtpPage.module.css";
import GenericPopup from "./GenericPopup";
import { useSharedData } from "../Context/ShareContext";
import AbhaId from "../assets/images/abha_id.jpg";
import SuccessRight from "../assets/images/success_modal.png";
import { useFormik } from "formik";
import * as Yup from "yup";
import { otpPageTexts } from "../json/OtpPageTexts";

const OtpPage = ({ PhoneNo, OptRemainingTime, PageName }) => {
  const navigate = useNavigate();
  const [showError, setShowError] = useState(false);
  const { showPopup, setShowPopup,setShowSignPopup,showSignPopup } = useSharedData();
 // State for controlling the popup

  const validationSchema = Yup.object().shape({
    otp: Yup.string()
      .required("OTP is required")
      .matches(/^\d{4}$/, "OTP must be a 4-digit number"),
  });

  const formik = useFormik({
    initialValues: {
      otp: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      if (values.otp !== "") {
        setShowError(false);
        if (PageName === "Login") {
          localStorage.setItem("authenticated", true);
          navigate("/home");
        } else {
          handlePopupOpening();
        }
      } else {
        setShowError(true);
      }
    },
  });

  const handlePopupOpening = () => {
    setShowSignPopup(true);

  };


  return (
    <Box data-testid="otp">
      <Stack className={styles.main_stack}>
        <form onSubmit={formik?.handleSubmit}>
          <Box className={styles.main_box}>
            <Stack className={styles.avatar_icon}>
              <Avatar
                src={loginPgIcon}
                className={styles.top_icon}
                alt="Otp Page Logo"
              />
            </Stack>
            <Typography variant="h3" className={styles.main_txt}>
              {otpPageTexts?.enterYourOtpText}
            </Typography>
            <Typography className={styles.sub_txt}>
              {otpPageTexts?.weSentItToTheNumberText} {PhoneNo}
            </Typography>
            <MuiOtpInput
              name="otp"
              value={formik?.values?.otp}
              onChange={(newValue) => {
                formik?.setFieldValue("otp", newValue);
                formik?.handleChange("otp")(newValue);
              }}
              className={styles.otp_section}
            />
            {formik?.errors?.otp && formik?.touched?.otp ? (
              <Typography className={styles.err_txt}>
                {formik?.errors?.otp}
              </Typography>
            ) : null}
            <Button
              type="submit"
              className={styles.main_btn}
              // disabled={!formik.values.otp || formik.errors.otp}
           
            >
              {otpPageTexts?.verifyOtpText}
            </Button>
            <Stack className={styles.btm_stack}>
              <Typography className={styles.btm_txt}>
                {otpPageTexts?.resendCodeInText}
              </Typography>
              <Typography className={styles.btm_logsigntxt}>
                00:{OptRemainingTime}
              </Typography>
            </Stack>
          </Box>
        </form>
      </Stack>
      {showSignPopup && (
        PageName === "Signup" ? (
          <GenericPopup
          Icon={AbhaId}
          MainTxt={otpPageTexts?.doYouHaveAbhaIdText}
          SubTxt={otpPageTexts?.abhaIdMainText}
          MainBtnTxt={otpPageTexts?.yesText}
          SubBtnTxt={otpPageTexts?.noText}
          ShowSubBtn={true}
          PageName={PageName}
          PlaceholderTxt={""}
        />
      ) : (
        <GenericPopup
          Icon={SuccessRight}
          MainTxt={otpPageTexts?.verifiedSuccessfullyText}
          SubTxt={otpPageTexts?.abhaAddressAndIdVerifiedText}
          MainBtnTxt={otpPageTexts?.continueText}
          SubBtnTxt={""}
          ShowSubBtn={false}
          PageName={PageName}
          PlaceholderTxt={""}
        />
        )
      )}
    </Box>
  );
};

export default OtpPage;
