import AppleIcon from "../assets/apples (2).svg";
import Dental from "../assets/dental.svg"
import RowingIcon from "../assets/physio.svg";
import Doctor from '../assets/men.svg'

export const coverageCardList = [
    {
      title: "Unlimited Family Physician",
      icon: Doctor,
      bgColor: "#e1f5f1",
      id: 1,
    },
  
    {
      title: "1 Dental Care",
      icon: Dental,
      bgColor: "#E1EEF9",
      id: 2,
    },
  
    {
      title: "1 Dietician",
      icon: AppleIcon,
      bgColor: "#FFECE2",
      id: 3,
    },
    {
      title: "1 Physiotherapist",
      icon: RowingIcon,
      bgColor: "#E7F3DC",
      id: 4,
    },
  ];