import { green, grey, lightGreen } from "@mui/material/colors";
import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 360,
      sm: 600,
      md: 900,
      lg: 1024,
      xl: 1850,
    },
  },
  palette: {
    primary: {
      main: "#016e44",
      light: lightGreen[500],
      dark: green[900],
    },
    secondary: {
      main: "#509B7E",
      light: "rgba(178, 201, 67, 1)",
    },
    error: {
      main: "#d93538",
    },
    info: {
      main: "#000000",
      light: "rgb(72, 72, 72)",
      grey: "#3d4a48",
    },
    light: {
      main: "#ffffff",
    },
    title: {
      main: grey[500],
    },
  },
  typography: {
    fontFamily: "Inter, sans-serif", 
    h1: {
      fontSize: "36px",
      textTransform: "none",
    },
    h2: {
      fontSize: "30px",
      textTransform: "none",
    },
    h3: {
      fontSize: "24px",
      textTransform: "none",
    },
    h4: {
      fontSize: "18px",
      textTransform: "none",
    },
    h5: {
      fontSize: "12px",
      textTransform: "none",
      fontWeight: "bold",
    },
    h6: {
      fontSize: "6px",
      textTransform: "none",
    },
    subtitle1: {
      fontSize: "10px",
      textTransform: "none",
    },
  },
});

export default theme;
