import icon43 from "../assets/images/ico43.png";
import icon44 from "../assets/images/ico44.png";
import icon45 from "../assets/images/ico45.png";
import icon46 from "../assets/images/ico46.png";
import icon47 from "../assets/images/ico47.png";
import icon48 from "../assets/images/ico48.png";
import icon49 from "../assets/images/ico49.png";
import icon50 from "../assets/images/ico50.png";
import icon51 from "../assets/images/ico51.png";
import icon52 from "../assets/images/ico52.png";
import icon53 from "../assets/images/ico53.png";
import icon54 from "../assets/images/ico54.png";
import icon55 from "../assets/images/ico55.png";
import icon56 from "../assets/images/ico56.png";
import map from "../assets/images/map.png";
import img_one from "../assets/loc_one.png";
import img_two from "../assets/loc_two.png";
import img_three from "../assets/loc_three.png";
import img_four from "../assets/loc_four.png";
import icon60 from "../assets/images/ico60.png";
import icon61 from "../assets/images/ico61.png";
import icon62 from "../assets/images/ico62.png";
import icon63 from "../assets/images/ico63.png";
import icon64 from "../assets/images/ico64.png";
import img1 from "../assets/images/Hca.png";
import img2 from "../assets/images/HealthCare.png";
import img3 from "../assets/images/HealthAward.png";
import img4 from "../assets/images/Somali.png";
import img5 from "../assets/images/GlobalHealth.png";
import health_image from "../assets/images/ico70.png";
import serve1 from "../assets/serve1.png";
import serve2 from "../assets/serve2.png";
import serve3 from "../assets/serve3.png";
import serve4 from "../assets/serve4.png";




export const corporate_dynamic = {
  BANNER_1: {
    TITLE: {
      TITLE_1: "Boost Your ",
      TITLE_2: "Team's Health",
    },
    LISTITEMS: [
      { key: 1, content: "Prioritize health and wellness at your workplace" },
      {
        key: 2,
        content: "Provide customized healthcare plans to all your employees",
      },
      {
        key: 3,
        content: "Identify At-risk employees and monitor their well-being",
      },
      { key: 4, content: "Get detailed insights about the overall health of your organization" },
    ],
  },
  BANNER_2: {
    TITLE: {
      TITLE_1: "Need for health solutions at",
      TITLE_2: "Workplace",
    },
    CARD_DATA: [
      { id: 1, TITLE: "Improving the health of your workforce", ICON: icon43 },
      { id: 2, TITLE: "Improving your organizational atmosphere", ICON: icon44 },
      {
        id: 3,
        TITLE: "Improving your team’s productivity at work",
        ICON: icon45,
      },
      { id: 4, TITLE: "Reducing your human capital costs", ICON: icon46 },
      { id: 5, TITLE: "Reducing employee absenteeism", ICON: icon47 },
      {
        id: 6,
        TITLE: "Reducing medical emergencies at the workplace",
        ICON: icon48,
      },
    ],
  },
  BANNER_3: {
    TITLE: {
      TITLE_1: "Why",
      TITLE_2: "Choose us?",
    },
    FARD_DATA: [
      {
        ID: 1,
        TITLE: "On-site Inspection",
        CONTENT:
          "Our packages for corporate include site inspections that help monitor your employees’ health on a regular basis.",
        ICON: icon49,
      },
      {
        ID: 2,
        TITLE: "Detailed Analytics",
        CONTENT:
          "With our in-depth analytics, we help you collect insights about your team’s health and also work on improving it.",
        ICON: icon50,
      },
      {
        ID: 3,
        TITLE: "Electronic Medical records",
        CONTENT:
          "We give you complete access to your team’s medical records in an easy, convenient and hassle-free way.",
        ICON: icon51,
      },
      {
        ID: 4,
        TITLE: "Occupational Health Services",
        CONTENT:
          "We provide end-to-end management of your team’s health in factories through our services that meet all statutory compliances.",
        ICON: icon52,
      },
      {
        ID: 5,
        TITLE: "Telehealth Solutions",
        CONTENT:
          "With our telehealth services, we ensure that your team can access our services in an effortless manner.",
        ICON: icon53,
      },
    ],
  },
  BANNER_4: {
    TITLE: "Our Services",
    FARDS: [
      {
        TITLE: "Occupational Healthcare",
        CONTENT:
          "In accordance with the Factories Act, we have designed robust healthcare solutions that cater to the needs of diverse large organizations. We take complete charge of your organization’s complete health.",
        ICON: serve1,
      },
      {
        TITLE: "Corporate Health Solutions",
        CONTENT:
          "From customized healthcare to extensive analytics and insights, we help maintain your team’s health and boost your company’s productivity in the long run.",
        ICON: serve2,
      },
      {
        TITLE: "On-site Medical Rooms",
        CONTENT:
          "With our on-site services, we ensure that your team’s health is prioritized. Our medical rooms facilitate quick treatments, faster diagnosis and better treatment for your team.",
        ICON: serve3,
      },
      {
        TITLE: "Care-Coordination",
        CONTENT:
          "Discover seamless care coordination at our healthcare center, where our dedicated team ensures the integration of comprehensive medical services in occupational health, promoting well-being and fostering a healthy workplace environment.",
        ICON: serve4,
      },
    ],
  },
  BANNER_5: {
    HEADER: "Case Studies",
    CASE_STUDIES: [
      {
        TITLE: "Clinical Problem Solving",
        DESCRIPTION: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.`,
      },
    ],
  },
  BANNER_6: {
    TITLE: "Work Place Health Stories",
    DESCRIPTION: ` I am delighted with the extremely prompt and accurate services
    related to Covid testing and blood profiling provided for my son and
    our entire family on 10th May 2021.The coordination, promptness of
    response and timely delivery of reports was extremely helpful. We
    are very satisfied with the services provided by you and your team.`,
  },
  BANNER_7:{
    TITLE:{
      TITLE_1: "Our Onsite",
      TITLE_2:" Healthcare Services",
      TITLE_3:" Impact a Million Corporate Employees across India",
    },
    MAP_IMAGE: map,
    LEGENDS:[
      {
        ID:1,
        IMAGE:img_one,
        LABEL: "Headquarters"
      },
      {
        ID:2,
        IMAGE:img_two,
        LABEL: "OCC. Health Services",
      },
      {
        ID:3,
        IMAGE:img_three,
        LABEL: "Corporate Health",
      },
      {
        ID:4,
        IMAGE:img_four,
        LABEL: "Services",
      },
    ]
  },
  BANNER_8:{
    TITLE:"Marquee Clients",
    CLIENTS_LOGO:[
      {
        ID:1,
        ALT: "marque_one",
        IMAGE: icon60,
      },
      {
        ID:2,
        ALT: "marque_one",
        IMAGE: icon61,
      },
      {
        ID:3,
        ALT: "marque_one",
        IMAGE: icon62,
      },
      {
        ID:4,
        ALT: "marque_one",
        IMAGE: icon63,
      },
      {
        ID:5,
        ALT: "marque_one",
        IMAGE: icon64,
      },
    ]
  },
  BANNER_9:{
    TITLE:"Awards",
    AWARDS_LOGO:[
      {
        ID: 1,
        IMAGE: img1,
        ALT: "AWARD IMAGE 1",
      },
      {
        ID: 2,
        IMAGE: img2,
        ALT: "AWARD IMAGE 1",
      },
      {
        ID: 3,
        IMAGE: img3,
        ALT: "AWARD IMAGE 1",
      },
      {
        ID: 4,
        IMAGE: img4,
        ALT: "AWARD IMAGE 1",
      },
      {
        ID: 5,
        IMAGE: img5,
        ALT: "AWARD IMAGE 1",
      },
      
    ]
  },
  BANNER_10:{
    TITLE: "Design Your Workplace Health Solution",
    DESCRIPTION: "For additional information, you can reach out to us at ",
    WEBSITE: "corporatewellness@healthspring.in",
    IMAGE: health_image
  }

};
