import { red } from "@mui/material/colors";

const useAppStyles = () => {
  return {
    invalid: {
      color: red[500],
    },
    typographyStyle1: {
      display: { xs: "none", sm: "block" },
      color: "#ff1493",
    },
    typographyStyle2: {
      color: "black",
      textTransform: "none",
    },
    selected: {
      color: "#B2C943",
    },
    cardSubCaptions:{
      color:"rgb(60 73 61)"
    }
    
  };
};

export default useAppStyles;
