import React, { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { Box, CardActions, IconButton, Stack } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Ladydoc from "../assets/Ladydoctor.png";
import pic from "../assets/quotes.png";
import styles from "../styling/HealthPatient.module.css";
import theme from "../components/Themeify";
import { everydayHealth } from "../json/EverydayHealth";
import { Slider } from "../atoms/Slider";
import { landing_dynamic } from "../json/landingDynamic";

export default function HealthyPatient(props) {
  const content = everydayHealth?.STORIES?.DESCRIPTION || [];

  const {
    currentIndex,
    expandedItem,
    transitionClass,
    handleNextClick,
    handleBackClick,
    setExpandedItem,
  } = Slider(content);

  return (
    <Box data-testid="healthy-patient">
      <Card className={styles.healthyCard} elevation={0}>
        <Stack className={styles.healthyStack}>
          <Box className={styles.healthyContent}>
            <CardContent>
              <Typography align="left" className={styles.healthyTitle}>
                {props.landing
                  ? landing_dynamic?.BANNER_5?.TITLE?.TITLE_2
                  : everydayHealth?.STORIES?.TITLE}{" "}
                <br />
                <Box
                  component="img"
                  src={pic}
                  className={styles.quotesImg}
                  mt={1}
                ></Box>
              </Typography>
              <Box className={styles.Slider + " " + transitionClass}>
                <Box className={styles.slic_width}>
                  {content.map(
                    (item, index) =>
                      index === currentIndex && (
                        <div key={index}>
                          <Typography
                            color="text.primary"
                            align="left"
                            className={styles.healthySubtitle}
                          >
                            {expandedItem === index ? (
                              <div onClick={() => setExpandedItem(null)}>
                                {item.content}
                              </div>
                            ) : item.content.length > 500 ? (
                              <span>
                                {item.content
                                  .substring(0, 510)
                                  .replace(/\s\w*$/, "")}
                                <span
                                  style={{
                                    textDecoration: "none",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => setExpandedItem(index)}
                                >
                                  ...
                                </span>
                              </span>
                            ) : (
                              <div onClick={() => setExpandedItem(null)}>
                                {item.content}
                              </div>
                            )}
                          </Typography>
                          <Typography align="left" className={styles.amarnath}>
                            {item?.NAME}
                          </Typography>
                          <Typography
                            align="left"
                            className={styles.healthySubtitle}
                          >
                            {item.loc} &nbsp;&bull; &nbsp; {item.month}&nbsp;{" "}
                            {item.year}
                          </Typography>
                        </div>
                      )
                  )}
                </Box>
              </Box>
            </CardContent>

            <CardActions>
              <IconButton>
                <ArrowBackIcon
                  className={styles.healthyIcon}
                  sx={{ color: theme.palette.primary.dark }}
                  data-testid="arrow-back-button"
                  onClick={handleBackClick}
                />
              </IconButton>
              <IconButton>
                <ArrowForwardIcon
                  className={styles.healthyIcon}
                  sx={{ color: theme.palette.primary.dark }}
                  data-testid="arrow-forward-button"
                  onClick={handleNextClick}
                />
              </IconButton>
            </CardActions>

            <CardActions>
              <Button className={styles.healthyButton}>
                {everydayHealth?.STORIES?.READ}
              </Button>
            </CardActions>
          </Box>
        </Stack>
        <CardMedia
          component="img"
          alt="Healthy_img"
          image={Ladydoc}
          className={styles.healthyImg}
        />
      </Card>
    </Box>
  );
}
