import React from 'react';
import { Card, Typography, CardHeader, IconButton, Stack } from '@mui/material';
import { Schedule } from '@mui/icons-material';
import theme from '../../components/Themeify';
import useAppStyles from '../../hooks/useAppStyles';
import styles from '../../styling/Appointments.module.css';
const AppointmentCards = ({ name, designation, date, time, image, avatar }) => {
  const appStyles = useAppStyles();

  return (
    <Card elevation={3} className={styles.cardRoot} sx={{ backgroundColor: theme.palette.secondary.light }} data-testid="appointment_card">
      <CardHeader
        data-testid="appointment_card_header"
        
        avatar={<img src={avatar} className={styles.avatarimagehome}/>}
        titleTypographyProps={{ variant: 'h4', fontWeight: "bold", align: "left" }}
        title={name}
        subheaderTypographyProps={{ variant: "body2", align: "left", color: theme.palette.info.light }}
        subheader={designation}
        action={
          <IconButton aria-label="call">
            <img src={image} style={{ color: theme.palette.primary.dark }} className={styles.iconimagehome} />
          
          </IconButton>
        }
      />
      <Card className={styles['card-secondary']} data-testid="appointment_card_content">
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Stack direction="row" alignItems="center" spacing={1}>
            <Schedule sx={{ color: theme.palette.primary.light }} />
            <Typography className={styles.dateclass} variant="body2" color={appStyles.typographyStyle2} component="p">
              {date}
            </Typography>
          </Stack>
          <Typography className={styles.dateclass} variant="body2" color={appStyles.typographyStyle2} component="p">
            {time}
          </Typography>
        </Stack>
      </Card>
    </Card>
  );
};

export default AppointmentCards;
