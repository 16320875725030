import React from "react";
import {
  Typography,
  Card,
  CardContent,
  Stack,
  Avatar,
  Box,
} from "@mui/material";
import {DoctorCardsCompleted} from "../json/cancel";

import { AccessTime } from "@mui/icons-material";
import DocImage from "../assets/images/lady_doc.png";
import  styles from "../styling/UpcomingAppointments.module.css";
import {cancel} from "../json/cancel";

import { useSharedData } from "../Context/ShareContext";

const cards = ["Card1", "Card2", "Card3", "Card4"];

export const CancelledAppointment = ({openDetails}) => {
  const { setAppointmentSummaryData, setShowSummary} = useSharedData();
  const openFullDetails = () => {
    openDetails ? openDetails(true) : setShowSummary(true)
    setAppointmentSummaryData({
      appointmentId:"ID #19477657407",
      bookedTime:'Sat, 12 Nov 10:06 am',
      status:'Cancelled',
      appointmentType:'cancelled'
    })
  }
  const viewDetails = () => {
    setAppointmentSummaryData({
      appointmentId: "ID #19477657407",
      bookedTime: "Sat, 12 Nov 10:06 am",
      status: "Cancelled",
      appointmentType: "cancelled",
    });
    setShowSummary(true);
  };
  return (
    <div >
      {DoctorCardsCompleted?.map((card, index) => {
        return (
          <div onClick={viewDetails}>
          <CardContent key={index} className={styles.apponitment_cardContent} >
            <Stack className={styles.apponitment_mainStack} >
              <Stack direction="row" spacing={2} alignItems={"center"}>
                <Avatar
                  alt="Avatar"
                  src={card?.image}
                  className={styles.apponitment_avatarImage}
                />
                <Stack>
                  <Typography className={styles.apponitment_typoTitle}>
                    {card?.doctor}
                  </Typography>
                  <Typography className={styles.apponitment_typoSubTitle}>
                    {card?.occupation}
                  </Typography>
                </Stack>
              </Stack>
              <Avatar alt="Call Icon"  className={styles.apponitment_avatarIcons} onClick={()=>openFullDetails()}>
              <Box component="img" src={card?.icon} className={styles.appointments} />
              </Avatar>
            </Stack>

            <Stack className={styles.apponitment_bottomStack}>
              <Card className={styles.apponitment_bottomCard}>
                <Stack direction="row">
                  <Avatar className={styles.appointment_time_icon} alt="Time Icon" >
                    <AccessTime className={styles.appointment_time_icon_access}  />
                  </Avatar>
                  <Stack direction={"row"} alignItems={"center"} spacing={10}>
                    <Typography className={styles.apponitment_typoDateTime}>
                      {card?.date}
                    </Typography>
                    <Typography className={styles.apponitment_typoDateTime}>
                     {card?.time}
                    </Typography>
                  </Stack>
                </Stack>
              </Card>
            </Stack>
          </CardContent>
          </div>
        );
      })}
    </div>
  );
};
