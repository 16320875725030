import React, {useState} from "react";
import Dialog from "@mui/material/Dialog";

import Button from "@mui/material/Button";
import { useSharedData } from "../Context/ShareContext";
import { Box, Stack, Typography } from "@mui/material";
import img from "../assets/images/areyouokay.svg"
import styles from "../styling/AreyouSure.module.css"
import CustomDrawer from "./CustomDrawer";



export const AreYouSurePopup = ({onCancel}) => {
 

  const { showCancel, setShowCancel } = useSharedData();

  const handleClose = () => {
    setShowCancel(false);
  };

  const handleConfirm = () => {
   onCancel();
  };
  const handleBack = () => {
    setShowCancel(false);
   };
   


  return (
    <div>
    <Dialog open={showCancel} onClose={handleClose}  PaperProps={{ sx: { borderRadius: "25px" } }}>

<Box >

      <Stack p={3} pt={5} pb={5}  alignItems="center" spacing={2} className={styles.main_stack} >
        <Box component="img" src={img} alt="img" />
        <Typography className={styles.are_text}>
          Are you Sure?
        </Typography>
        <Typography  align="center">
        Credit note/voucher of this amount will be issued and will be credited with in 2-3 business days.
        </Typography>
        <Button className={styles.cancel_button} onClick={handleConfirm}>
        Yes, Cancel

        </Button>
        <Button className={styles.yes_button}onClick={handleBack}>
        Back

        </Button>
      </Stack>
      </Box>
      
    </Dialog>
          </div>

  );
};
