import React from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import styles from "../styling/MapOnsite.module.css";
import { corporate_dynamic } from "../json/corporateDynamic";

export const MapOnsite = () => {
  return (
    <Box data-testid="maponsite" id="map_center">
      <Box className={styles["mainmap_box"]}>
        <Stack
          direction={{ xs: "column", sm: "column", md: "row" }}
          spacing={1}
          justifyContent="space-evenly"
        >
          <Stack
            className={styles["map-stack"]}
            alignSelf={{ xs: "center", sm: "center", md: "flex-start" }}
          >
            <Typography
              className={styles["map_title"]}
              align="left"
              lineHeight={1.2}
            >
              {corporate_dynamic?.BANNER_7?.TITLE?.TITLE_1}
              <br />
              {corporate_dynamic?.BANNER_7?.TITLE?.TITLE_2}
              <br />
              <span className={styles["map_highlight"]}>
                {corporate_dynamic?.BANNER_7?.TITLE?.TITLE_3}
              </span>
            </Typography>
          </Stack>
          <Stack>
            <Stack padding={5}>
              <Box
                component="img"
                className={styles["img_map"]}
                src={corporate_dynamic?.BANNER_7?.MAP_IMAGE}
                alt="india_map"
              />
            </Stack>
            <Stack
              direction="column"
              alignSelf="flex-end"
              spacing={1}
              className={styles.loc}
            >
              {corporate_dynamic?.BANNER_7?.LEGENDS?.map((item) => (
                <Stack direction="row" spacing={1}>
                  <Box
                    key={item.ID}
                    component="img"
                    src={item.IMAGE}
                    alt="img_one"
                    className={styles.symbols}
                  ></Box>
                  <Typography align="left" className={styles["map_subtitle"]}>
                    {item?.LABEL}
                  </Typography>
                </Stack>
              ))}
            </Stack>
          </Stack>
        </Stack>
      </Box>
    </Box>
  );
};
