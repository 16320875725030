import React from "react";

import HealthyPatient from "./HealthyPatient";

export default function HealthyPatientTwo() {
  

  
  return (
        <HealthyPatient landing={true} />
 
 
    );
}
