import EmailRoundedIcon from "@mui/icons-material/EmailRounded";
import LocationOnRoundedIcon from "@mui/icons-material/LocationOnRounded";
import PermPhoneMsgRoundedIcon from "@mui/icons-material/PermPhoneMsgRounded";
import { Avatar, Box, Stack, Typography } from "@mui/material";
import { StyledEngineProvider } from "@mui/material/styles";
import React from "react";
import {  contactUs,contactUs_dynamic} from "../json/contactUs";
import Spacer from "../atoms/Spacer";
import styles from "../styling/GetInTouch.module.css";

const GetInTouch = () => {
  const handlePhoneClick = (phoneNumber) => {
    window.location.href = `tel:${phoneNumber}`;
  };

  const handleEmailClick = (emailAddress) => {
    window.location.href = `mailto:${emailAddress}`;
  };
  return (
    <StyledEngineProvider injectFirst>
      <Box>
        <Stack direction="column">
          <Typography variant="h1" className={styles.header_text}>
            {contactUs?.TITLE}
          </Typography>
          <Stack direction="row" className={styles.getintouch_element}>
            <Avatar className={styles.location_avatar}>
              <LocationOnRoundedIcon />
            </Avatar>
            <Stack alignItems="flex-start">
              <Typography variant="h4" className={styles.contact_title}>
                {contactUs?.HEADERS?.HEAD_OFFICE}
              </Typography>
              <Typography variant="h4" className={styles.contact_text}>
                {contactUs_dynamic?.ADDRESS?.LINE1}
                <Spacer />
                {contactUs_dynamic?.ADDRESS?.LINE2}
                <Spacer />
                {contactUs_dynamic?.ADDRESS?.LINE3}
              </Typography>
            </Stack>
          </Stack>
          <Stack direction="row" className={styles.getintouch_element}>
            <Avatar className={styles.phone_msg_avatar}>
              <PermPhoneMsgRoundedIcon />
            </Avatar>
            <Stack alignItems="flex-start">
              <Typography variant="h4" className={styles.contact_title}>
                {contactUs?.HEADERS?.CONTACT_US}
              </Typography>
              <div
                className={styles.mouseHover}
                onClick={() => {
                  handlePhoneClick(contactUs?.PHONE);
                }}
              >
                <Typography variant="h4" className={styles.contact_text}>
                  {contactUs_dynamic?.PHONE}
                </Typography>
              </div>
            </Stack>
          </Stack>
          <Stack direction="row" className={styles.getintouch_element}>
            <Avatar className={styles.email_individual_avatar}>
              <EmailRoundedIcon />
            </Avatar>
            <Stack alignItems="flex-start">
              <Typography variant="h4" className={styles.contact_title}>
                {contactUs?.HEADERS?.INDIVIDUAL_INQUIRY}
              </Typography>
              <div
                className={styles.mouseHover}
                onClick={() => {
                  handleEmailClick(contactUs_dynamic?.EMAIL);
                }}
              >
                <Typography variant="h4" className={styles.contact_text}>
                  {contactUs_dynamic?.EMAIL}
                </Typography>
              </div>
            </Stack>
          </Stack>
          <Stack direction="row" className={styles.getintouch_element}>
            <Avatar className={styles.email_corporate_avatar}>
              <EmailRoundedIcon />
            </Avatar>
            <Stack alignItems="flex-start">
              <Typography variant="h4" className={styles.contact_title}>
                {contactUs?.HEADERS?.CORPORATE_INQUIRY}
              </Typography>
              <div
                className={styles.mouseHover}
                onClick={() => {
                  handleEmailClick(contactUs_dynamic?.WEBSITE);
                }}
              >
                <Typography variant="h4" className={styles.contact_text}>
                  {contactUs_dynamic?.WEBSITE}
                </Typography>
              </div>
            </Stack>
          </Stack>
        </Stack>
      </Box>
    </StyledEngineProvider>
  );
};

export default GetInTouch;
