import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineDot,
  TimelineContent,
  TimelineOppositeContent,
} from "@mui/lab";
import { Box, Stack, Typography } from "@mui/material";
import styles from "../styling/MilestoneTimeline.module.css";
import { aboutUs_Dynamic } from "../json/aboutus";

import { useEffect } from "react";

export default function MilestoneTimeline() {
  useEffect(() => {
    window.scrollTo(0, 0);
    return () => {};
  }, []);
  return (
    <div>
      <Box className={styles.timelinebox}>
        <Stack className={styles.timelinestackheader}>
          <Typography className={styles.timelinetypoletter} variant="h1">
            {aboutUs_Dynamic?.BANNER_4?.MILESTONE_TITLE}
          </Typography>
        </Stack>
      </Box>
      <Box className={styles.timelinemain}>
        <Timeline>
          {aboutUs_Dynamic?.BANNER_4?.MILESTONES?.map((item, index) => {
            if (index === aboutUs_Dynamic?.BANNER_4?.MILESTONES?.length - 1) {
              return (
                <TimelineItem key={index}>
                  <TimelineOppositeContent
                    className={styles.timelineopposite}
                  />
                   <Typography
                    variant="h3"
                    
                    className={styles.milestoneyear}

                    // component="span"
                  >
                    {item?.YEAR}
                  </Typography>

                  <TimelineSeparator>
                    <div className={styles.dashedLine}></div>
                    <TimelineDot className={styles.milestonedot} />
                  </TimelineSeparator>
                  <TimelineContent>
                    <Typography  className={styles.milestoneheader}>
                      {item?.HEADER}
                    </Typography>
                    <Typography className={styles.milestonetext} align="left">
                      {item?.SUBTEXT}
                    </Typography>
                  </TimelineContent>
                </TimelineItem>
              );
            } else {
              return (
                <TimelineItem key={index}>
                  <TimelineOppositeContent
                    className={styles.timelineopposite}
                  />
                  <Typography
                    variant="h3"
                    className={styles.milestoneyear}
                    // component="span"
                  >
                    {item?.YEAR}
                  </Typography>
                  <TimelineSeparator>
                    <div className={styles.dashedLine}></div>
                    <TimelineDot className={styles.milestonedot} />
                    <div className={styles.dashedLineStyle}></div>
                    {/* <TimelineConnector className={styles.milestoneconnector} /> */}
                  </TimelineSeparator>
                  <TimelineContent>
                    <Typography  className={styles.milestoneheader}>
                      {item?.HEADER}
                    </Typography>
                    <Typography align="left"className={styles.milestonetext}>
                      {item?.SUBTEXT}
                    </Typography>
                  </TimelineContent>
                </TimelineItem>
              );
            }
          })}
        </Timeline>
      </Box>
    </div>
  );
}
