import React, { useState } from 'react';
import { Stack, Box } from '@mui/material';
import { Button } from '@mui/material';
import useAppStyles from '../../hooks/useAppStyles';
import { Typography } from '@mui/material';
import ReportCard from './ReportCard';
import { LabReport } from '../../json/LabReport';
import styles from "../../styling/RecentReport.module.css"; 
import { SiteContent } from '../../assets/content/SiteContent';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { useSharedData } from '../../Context/ShareContext';
import { useNavigate } from "react-router-dom";
export default function RecentReport() {
  const [activeIndex, setActiveIndex] = useState(0);
  const appStyles = useAppStyles();
  const navigate = useNavigate()
  const {  setitemSelectedPassed,setValue} = useSharedData();
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClickDot = (index) => {
    setActiveIndex(index);
  };
  const handleNavigateReport = (selected) => {
    setValue(null);
    navigate("/myaccount");
    setitemSelectedPassed(selected)
  };
  const renderCarouselDots = () => {
    return LabReport?.map((_, index) => (
      <span
        key={index}
        className={`${styles["dot"]} ${activeIndex === index ? styles["active"] : ''}`}
        onClick={() => handleClickDot(index)}
      />
    ));
  };

  const visibleCards = LabReport?.slice(activeIndex, activeIndex + 2);

  return (
    <Box>
      <Stack spacing={3} className={styles.recent_stack}>
        <Stack direction="row" alignContent="center">
          <Typography variant="h1" flexGrow={1} align="left" color={appStyles.typographyStyle2 }className={styles.bold}>
            {SiteContent.RECENT_REPORTS}
          </Typography>
          <Button>
            <Typography variant="h5" fontSize="15px" onClick={() => handleNavigateReport("My Reports")}>
              View All
            </Typography>
            <ChevronRightIcon />
          </Button>
        </Stack>
        <Stack direction={{ xs: "column", sm: "row", md: "row" }} spacing={2} justifyContent="space-between" >
          {visibleCards.map((report) => (
            <ReportCard
              key={report?.id}
              name={report?.Test}
              date={report?.date}
              image={report?.image}
            />
          ))}
        </Stack>
        {/* <div className={styles["carousel-dots"]}>{renderCarouselDots()}</div> */}
      </Stack>
    </Box>
  );
}
