import React, { useEffect} from "react";
import {  Stack  , Box } from "@mui/material";
import KickStartCareer from "../molecules/KickStartCareer";
import styles from '../styling/Career.module.css'
import { CareerForm } from "../molecules/CareerForm";


export const Career = () => {
  useEffect(() => {
    window.scrollTo(0,0);
    return () => {
      
    };
  }, []); 
  return (
    <>
    <Stack direction="column" className={styles.career_us}>
    <Stack direction={{ xs: "column", sm: "column",md:"row" }} alignContent="left">
    <Box className={styles.career_left_container}>
            <KickStartCareer />
          </Box>
          <Box className={styles.career_right_container}>
            <CareerForm />
          </Box>
    </Stack>
    </Stack>
    </>
  )
};
