import React from "react";
import { Stack, Typography, TextField, Button, Box } from "@mui/material";
import { StyledEngineProvider } from "@mui/material/styles";
import styles from "../styling/CareerForm.module.css";
import { FileUpload } from "../atoms/FileUpload";
import { useFormik } from "formik";
import * as yup from "yup";

export const CareerForm = () => {
  const initialValues = {
    name: "",
    email: "",
    phone: "",
    exp: "",
    currDesig: "",
    applyingPos: "",
    message: "",
  };

  const onSubmit = (values) => {

  };

  const validationSchema = yup.object({
    name: yup
      .string()
      .required("Name is required")
      .min(3, "Name is too short, must be at least 3 characters")
      .max(50, "Name is too long, must be at most 50 characters"),
    email: yup.string().email("Invalid email address").required("Email is required"),
    phone: yup
      .string()
      .required("Phone No. is required")
      .min(10, "Phone must be of 10 digits")
      .max(10, "Phone is too long, must be at most 10 digits"),
    exp: yup.number().required("Total Experience is required"),
    currDesig: yup.string().required("Current Designation is required"),
    applyingPos: yup.string().required("Applying For Position is required"),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  const showError = (field) =>
    formik?.touched[field] && formik?.errors[field] ? (
      <Typography variant="body2" color="error" align="left">
        {formik?.errors[field]}
      </Typography>
    ) : null;

  

  return (
    // <Box data-testid="Careerform">
    //   <StyledEngineProvider injectFirst>
    //     <form onSubmit={formik.handleSubmit}>
    //       <Stack className={styles.career_form_body}>
    //         <Stack className={styles.career_form_header}>
    //           <Typography variant="h4" id="name" className={styles.career_form_label}>
    //             Your Name
    //           </Typography>
    //           <TextField
    //             name="name"
    //             className={styles.career_form_input}
    //             value={formik?.values?.name}
    //             onChange={(e) => formik?.handleChange(e?.target?.name)(e?.target?.value?.replace(/[^A-Za-z\s\-']/g, ''))}
    //             onBlur={formik?.handleBlur}
    //             inputProps={{
    //               "data-testid": "name-input",
    //             }}
               
    //           />
    //           {showError("name")}
    //         </Stack>

    //         <Stack className={styles.form_header}>
    //           <Typography variant="h4" id="email" className={styles.career_form_label}>
    //             Email ID
    //           </Typography>
    //           <TextField
    //             name="email"
    //             className={styles.career_form_input}
    //             value={formik?.values?.email}
    //             onChange={formik?.handleChange}
    //             onBlur={formik?.handleBlur}
    //             inputProps={{
    //               "data-testid": "email-input",
    //             }}
    //           />
    //           {showError("email")}
    //         </Stack>

    //         <Stack direction={{ xs: "column", sm: "column", md: "row" }}
    //           spacing={2}>
    //            <Stack className={styles.maxwidth}>
    //             <Typography variant="h4" id="phone" className={styles.career_form_label}>
    //               Phone No.
    //             </Typography>
    //             <TextField
    //               name="phone"
    //               className={styles.career_form_input}
    //               value={formik?.values?.phone}
    //               onChange={(e) => formik?.handleChange(e?.target?.name)(e?.target?.value?.replace(/[^0-9]/g, ""))}

    //               onBlur={formik?.handleBlur}
    //               inputProps={{
    //                 "data-testid": "phone-input",
    //               }}
    //             />
    //             {showError("phone")}
    //           </Stack> 
    //           <Stack  className={styles.maxwidth}>
    //             <Typography variant="h4" id="exp" className={styles.career_form_label}>
    //               Total Experience
    //             </Typography>
    //             <TextField
    //               name="exp"
    //               className={styles.career_form_input}
    //               value={formik?.values?.exp}
    //               onChange={(e) => formik.handleChange(e?.target?.name)(e?.target?.value?.replace(/[^0-9]/g, ""))}
    //               onBlur={formik?.handleBlur}
    //               inputProps={{
    //                 "data-testid": "exp-input",
    //               }}
    //             />
    //             {showError("exp")}
    //           </Stack>
    //         </Stack>

    //         <Stack direction={{ xs: "column", sm: "column", md: "row" }} className={styles.careersss_form_stack_two} spacing={2}>
    //           <Stack className={styles.maxwidth} >
    //             <Typography variant="h4" id="currDesig" className={styles.career_form_label}>
    //               Current Designation
    //             </Typography>
    //             <TextField
    //               name="currDesig"
    //               className={styles.career_form_input}
    //               value={formik?.values?.currDesig}
    //               onChange={formik?.handleChange}
    //               onBlur={formik?.handleBlur}
    //               inputProps={{
    //                 "data-testid": "designation-input",
    //               }}
    //             />
    //             {showError("currDesig")}
    //           </Stack>
    //           <Stack className={styles.maxwidth}>
    //             <Typography variant="h4" id="applyingpos" className={styles.career_form_label}>
    //               Applying For Position
    //             </Typography>
    //             <TextField
    //               name="applyingPos"
    //               className={styles.career_form_input}
    //               value={formik?.values?.applyingPos}
    //               onChange={formik?.handleChange}
    //               onBlur={formik?.handleBlur}
    //               inputProps={{
    //                 "data-testid": "apply-input",
    //               }}
    //             />
    //             {showError("applyingPos")}
    //           </Stack>
    //         </Stack>

    //         <Stack className={styles.form_header}>
    //           <Typography variant="h4" id="message" className={styles.career_form_label}>
    //             Message
    //           </Typography>
    //           <TextField
    //             name="message"
    //             className={styles.career_form_input}
    //             multiline
    //             rows={4}
    //             value={formik?.values?.message}
    //             onChange={formik?.handleChange}
    //             onBlur={formik?.handleBlur}
    //             inputProps={{
    //               "data-testid": "message-input",
    //             }}
    //           />
    //           {showError("message")}
    //         </Stack>
    //         <FileUpload />
    //         <Stack className={styles.career_contact_button}>
    //           <Button type="submit" className={styles.career_form_button}>
    //             <Typography variant="h4" className={styles.career_form_button_text}>
    //               Submit
    //             </Typography>
    //           </Button>
    //         </Stack>
    //       </Stack>
    //     </form>
    //   </StyledEngineProvider>
    // </Box>
    
<iframe src="https://docs.google.com/forms/d/e/1FAIpQLSe19_qTKw8v-wswdcNLbUpomYvxIRhbcwj6aK9-Ks3eLmpirA/viewform?embedded=true" width="640" height="1261" frameborder="0" marginheight="0" marginwidth="0" className={styles.width}>Loading…</iframe>
  );
};
