import { Box, Stack } from "@mui/material";
import React from "react";
import GenericHeaderCard from "./GenericHeaderCard";
import GenericSearch from "./GenericSearch";
import styles from "../styling/HealthCardSearch.module.css";
import Bread from "./Bread";
import MembershipCard from "./MembershipCard";
import defaultStyles from "../styling/DefaultStyles.module.css";
import crown from "../assets/crown_img.png";

export default function TestPackageMembershipDetails() {
  return (
    <>
      <Stack className={defaultStyles.stack_with_max_width}>
        <Stack className={defaultStyles.bread_stack}>
          <Bread />
        </Stack>
        <Box
          className={styles.lab_test_new_box_main}
          data-testid="TestPackageMembershipDetailsContainer"
        >
          <Stack spacing={4}>
            <GenericHeaderCard
              headingtext={"Let's Buy a Memberships"}
              banner={crown}
            />
            <GenericSearch placeholder="Search for memberships" />
            <MembershipCard />
          </Stack>
        </Box>
      </Stack>
    </>
  );
}
