import React from "react";
import { Box, Typography, Stack } from "@mui/material";
import img from "../assets/phome.png";
import styles from "../styling/Transforming.module.css";
import bg from "../assets/transformbg.png";
import app from "../assets/app_badge.png";
import play from "../assets/play_badge.png";

const ImgTransform = () => {
  return (
    <div>
      <Box
        data-testid="img-transform"
        className={styles["services-box"]}
        style={{
          backgroundImage: `url(${bg})`,
        }}
      >
        <Stack
          flexDirection={{ xs: "column", sm: "row-reverse" }}
          justifyContent="space-around"
          alignItems="center"
        >
          <Box
            component="img"
            alt="Image"
            src={img}
            className={styles.img_transform}
          />
          <Stack >
            <Stack className={styles.transform_stack} spacing={1.8} >
              <Typography  className={styles.download_text}>
                Download the Healthspring App to Schedule an Appointment
              </Typography>
              <Typography
                
                className={styles.transform_text}
                
              >
                Transforming Healthing <br /> Improving Lives
              </Typography>
              
              <Stack direction="row" spacing={2} >
                <Box component="img" alt="Image" src={play}  className={styles.mini_pic} />
                <Box component="img" alt="Image" src={app} className={styles.mini_pic} />
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </Box>
    </div>
  );
};

export default ImgTransform;
