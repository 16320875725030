import pic1 from "../assets/images/lady_doc.png";
import pic2 from "../assets/images/doctorRajesh.png";
import pic3 from "../assets/images/labt.png";
import pic4 from "../assets/images/xscan.png";
import pic5 from "../assets/images/blank.svg";

import homepink from "../assets/images/homepink.svg";
import vcall from "../assets/images/vcall.svg";
import homepl from "../assets/images/homepl.svg";
import ser from "../assets/images/ser.svg";



export const cancel = {
  doctor: "Dr. Jenny Wilson",
  occupation: "Psychiatrist",
  date: `Mon • Sep 9, 2022`,
  time: `9:00 - 9:30 am`,
  details:"View Details",
};


export const DoctorCards=[
  {
    image: pic5,
    icon:vcall,
    doctor: "Dr. Jenny Wilson",
    occupation: "Psychiatrist",
    date: `Mon • Sep 9, 2022`,
    time: `9:00 - 9:30 am`,
    details:"View Details",
  },
  {
    image: pic2,
    icon:homepl,

    doctor: "Dr. Rajesh Vibhore",
    occupation: "Diet & Nutrition",
    date: `Mon • Sep 9, 2022`,
    time: `9:00 - 9:30 am`,
    details:"View Details",
  },
  {
    image: pic1,
    icon:homepink,

    doctor: "Dr. Jenny Wilson",
    occupation: "Psychiatrist",
    date: `Mon • Sep 9, 2022`,
    time: `9:00 - 9:30 am`,
    details:"View Details",
  },
  {
    image: pic5,
    icon:ser,

    doctor: "Dr. Jenny Wilson",
    occupation: "Psychiatrist",
    date: `Mon • Sep 9, 2022`,
    time: `9:00 - 9:30 am`,
    details:"View Details",
  },
  {
    image: pic3,
    icon:homepl,

    doctor: "Lab Tests",
    occupation: "Total IgE",
    date: `Mon • Sep 9, 2022`,
    time: `9:00 - 9:30 am`,
    details:"View Details",
  },
  {
    image: pic4,
    icon:homepl,

    doctor: "X-rays and Scans",
    occupation: "X-Ray Ankle Joint AP View",
    date: `Mon • Sep 9, 2022`,
    time: `9:00 - 9:30 am`,
    details:"View Details",
  },
  
]


export const DoctorCardsCompleted=[
  {
    image: pic1,
    icon:vcall,
    doctor: "Dr. Jenny Wilson",
    occupation: "Psychiatrist",
    date: `Mon • Sep 9, 2022`,
    time: `9:00 - 9:30 am`,
    details:"View Details",
  },
  {
    image: pic2,
    icon:homepl,

    doctor: "Dr. Rajesh Vibhore",
    occupation: "Diet & Nutrition",
    date: `Mon • Sep 9, 2022`,
    time: `9:00 - 9:30 am`,
    details:"View Details",
  },
  {
    image: pic5,
    icon:homepink,

    doctor: "Dr. Jenny Wilson",
    occupation: "Psychiatrist",
    date: `Mon • Sep 9, 2022`,
    time: `9:00 - 9:30 am`,
    details:"View Details",
  },
  {
    image: pic5,
    icon:ser,

    doctor: "Dr. Jenny Wilson",
    occupation: "Psychiatrist",
    date: `Mon • Sep 9, 2022`,
    time: `9:00 - 9:30 am`,
    details:"View Details",
  },
  {
    image: pic3,
    icon:homepl,

    doctor: "Lab Tests",
    occupation: "Total IgE",
    date: `Mon • Sep 9, 2022`,
    time: `9:00 - 9:30 am`,
    details:"View Details",
  },
  {
    image: pic4,
    icon:homepl,

    doctor: "X-rays and Scans",
    occupation: "X-Ray Ankle Joint AP View",
    date: `Mon • Sep 9, 2022`,
    time: `9:00 - 9:30 am`,
    details:"View Details",
  },
  
]