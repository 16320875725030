import React, { useState } from "react";
import {
  Typography,
  Card,
  CardContent,
  Stack,
  Button,
  Avatar,
  Box,
} from "@mui/material";
import { AccessTime } from "@mui/icons-material";
import styles from "../styling/UpcomingAppointments.module.css";
import { useSharedData } from "../Context/ShareContext";
import { DoctorCardsCompleted } from "../json/cancel";

import CustomDrawer from "./CustomDrawer";

export const CompletedAppointment = () => {
  const {
    setShowSummary,
    setAppointmentSummaryData,
    setAppointmentRescheduleModal,
  } = useSharedData();
  const [drawerIsOpen, setDrawerIsOpen] = useState(false);

  const viewDetails = () => {
    setAppointmentSummaryData({
      appointmentId: "ID #19477657407",
      bookedTime: "Sat, 12 Nov 10:06 am",
      status: "Completed",
      appointmentType: "completed",
    });
    setShowSummary(true);
  };
  return (
    <>
      {DoctorCardsCompleted?.map((card, index) => {
        return (
          <CardContent key={index} className={styles.apponitment_cardContent}>
            <Stack className={styles.apponitment_mainStack}>
              <Stack direction="row" spacing={2} alignItems={"center"}>
                <Avatar
                  alt="Avatar"
                  src={card?.image}
                  className={styles.apponitment_avatarImage}
                />
                <Stack>
                  <Typography className={styles.apponitment_typoTitle}>
                    {card?.doctor}
                  </Typography>
                  <Typography className={styles.apponitment_typoSubTitle}>
                    {card?.occupation}
                  </Typography>
                </Stack>
              </Stack>

              <Avatar
                alt="Call Icon"
                className={styles.apponitment_avatarIcons}
              >
                <Box
                  component="img"
                  src={card?.icon}
                  className={styles.appointments}
                />
              </Avatar>
            </Stack>

            <Stack
              direction={{ xs: "column", md: "row" }}
              justifyContent="space-between"
              spacing={2}
            >
              <Card className={styles.apponitment_bottomCard}>
                <Stack direction="row">
                  <Avatar
                    className={styles.appointment_time_icon}
                    alt="Time Icon"
                  >
                    <AccessTime
                      className={styles.appointment_time_icon_access}
                    />
                  </Avatar>
                  <Stack direction={"row"} alignItems={"center"} spacing={10}>
                    <Typography className={styles.apponitment_typoDateTime}>
                      {card?.date}
                    </Typography>
                    <Typography className={styles.apponitment_typoDateTime}>
                      {card?.time}
                    </Typography>
                  </Stack>
                </Stack>
              </Card>

              <Stack direction="row" spacing={2} justifyContent="space-between">
                <Button
                  className={styles.apponitment_leftBtn}
                  onClick={() => setDrawerIsOpen(true)}
                >
                  Book Again
                </Button>
                <Button
                  className={styles.apponitment_rightBtn}
                  onClick={() => viewDetails()}
                >
                  {card?.details}
                </Button>
              </Stack>
            </Stack>
          </CardContent>
        );
      })}
      <CustomDrawer
        anchor={"right"}
        isOpen={drawerIsOpen}
        onCloseHandler={() => setDrawerIsOpen(false)}
        onNextClick={() => setAppointmentRescheduleModal(true)}
        
      />
    </>
  );
};
