import React from "react";
import Booking from "../molecules/Booking";
import ray from "../assets/xrayscans.svg";
export const XrayScanpage = () => {
  return (
    <>
      <Booking iconImage={ray} headerText="X-Ray Ankle Joint AP View" />
    </>
  );
};
