import React, { useState, useEffect } from "react";
import {
  Grid,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Stack,
  Box,
  Drawer,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import styles from "../styling/DoctorSelection.module.css";
import MyLocationIcon from "@mui/icons-material/MyLocation";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import TuneIcon from "@mui/icons-material/Tune";
import DoctorCards from "./DoctorCards";
import { categories } from "../json/categories";
import { subCategories } from "../json/subCategories";
import useAppStyles from "../hooks/useAppStyles";
import { useParams } from "react-router-dom";
import Bread from "./Bread";
import LocationMenu from "./LocationMenu";
import FilterMenu from "./FilterMenu";
import defaultStyles from "../styling/DefaultStyles.module.css";

export default function DoctorSelection({ categorySelection }) {
  const { cat } = useParams();

  const appStyles = useAppStyles();
  const [subCatIndex, setSubCatIndex] = useState(0);
  const [activeAccordion, setActiveAccordion] = useState(null);
  const [expanded, setExpanded] = useState(cat);
  const [activePath, setActivePath] = useState(cat);

  const openLocationMenu = (bool) => {
    setLocationMenu(bool);
  };

  const openFilterMenu = (bool) => {
    setFilterMenu(bool);
  };
  const [locationMenu, setLocationMenu] = useState(false);
  const [filterMenu, setFilterMenu] = useState(false);
  const handleCloseLocationMenu = () => {
    setLocationMenu(false);
  };
  const handleCloseFilterMenu = () => {
    setFilterMenu(false);
  };

  const [mobileOpen, setMobileOpen] = useState(false);
  const [mobileFilterOpen, setMobileFilterOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
    openLocationMenu(!mobileOpen);
  };

  const handleFilterDrawerToggle = () => {
    setMobileFilterOpen(!mobileFilterOpen);
    openFilterMenu(!mobileFilterOpen);
  };

  useEffect(() => {
    if (!locationMenu) setMobileOpen(locationMenu);
  }, [locationMenu]);

  useEffect(() => {}, [categorySelection]);

  const handleChange = (panel) => (event, newExpanded) => {
    if (panel === "specialists") {
      setExpanded(newExpanded ? panel : false);
    }
  };

  const handleAccordionClick = (id, path) => {
    if (activeAccordion === id && activePath === path) {
      setActiveAccordion(null);
      setActivePath(null);
    } else {
      setActiveAccordion(id);
      setActivePath(path);
    }
  };

  return (
    <>
      <Stack className={defaultStyles.stack_with_max_width}>
        <Stack className={defaultStyles.bread_stack}>
          <Bread />
        </Stack>
        <Stack className={styles.bread_stack_border}></Stack>
        <Box
          className={styles.box_styling}
          data-testid="doctor_selection_wrapper"
        >
          <Stack
            container
            spacing={2}
            className={styles.main_stack_styling}
            direction={{ xs: "column", sm: "row" }}
            justifyContent="space-between"
            alignItems="flex-start"
          >
            {" "}
            <Stack
              className={styles.leftdrawer_consults}
              item
              justifyContent="center"
              data-testid="left_grid"
            >
              {categories?.map((item, index) => {
                return (
                  <React.Fragment key={`category_fragment_${index}`}>
                    <Accordion
                      className={styles.doctorselectioncardsaccordian}
                      expanded={expanded === item?.path && item?.accordian}
                      onChange={handleChange(`${item?.path}`)}
                      key={index}
                      disableGutters
                      elevation={0}
                      sx={{
                        "&:before": {
                          display: "none",
                        },
                        boxShadow: "none",
                      }}
                    >
                      <AccordionSummary
                        expandIcon={item.accordian ? <ExpandMoreIcon /> : ""}
                        aria-controls={`panel${index + 1}a-content`}
                        id={`panel${index + 1}a-header`}
                        onClick={() => handleAccordionClick(item.id, item.path)}
                        className={`
                          ${
                            activeAccordion === item.id ||
                            activePath === item.path
                              ? styles.accordion_styling
                              : ""
                          } `}
                      >
                        <Stack
                          spacing={1}
                          direction="row"
                          className={styles.centerAccordion}
                        >
                          <img src={item?.icon} />
                          <Typography variant="h5" className={styles.bold}>
                            {item?.name}
                          </Typography>
                        </Stack>
                      </AccordionSummary>
                      <AccordionDetails key={`category_details_${index}`}>
                        {subCategories?.map((item, index) => {
                          return (
                            <React.Fragment
                              key={`subcategory_fragment_${index}`}
                            >
                              <Stack
                                spacing={2}
                                className={styles.category_bottom}
                                key={index}
                              >
                                <Stack
                                  spacing={1}
                                  direction="row"
                                  className={`${
                                    subCatIndex === index
                                      ? styles.selection_accordian
                                      : ``
                                  }`}
                                  onClick={() => {
                                    setSubCatIndex(index);
                                  }}
                                >
                                  {subCatIndex === index ? (
                                    <></>
                                  ) : (
                                    <>
                                      <span className={styles.dropdown_span} />
                                    </>
                                  )}
                                  <img src={item?.icon} />
                                  <Typography
                                    variant="h5"
                                    alignSelf="center"
                                    className={styles.bold}
                                  >
                                    {item?.name}
                                  </Typography>
                                </Stack>
                              </Stack>
                            </React.Fragment>
                          );
                        })}
                      </AccordionDetails>
                    </Accordion>
                  </React.Fragment>
                );
              })}
            </Stack>
            <Stack
              item
              data-testid="right_grid"
              flexGrow={1}
              className={styles.card_stack}
            >
              <Stack spacing={2} direction="row">
                <Stack direction="row" alignItems="left" flexGrow={1}>
                  <Typography
                    variant="h4"
                    color={appStyles.typographyStyle2}
                    className={styles.bold}
                    pt={1}
                  >
                    Choose Doctor
                  </Typography>
                </Stack>

                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  onClick={handleDrawerToggle}
                  spacing={0.7}
                  className={styles.location_dropdown}
                  data-testid="location_dropdown"
                >
                  <MyLocationIcon color="success" />
                  <Typography>Navi Mumbai</Typography>
                  <span className={styles.dropdown_span} />
                  <KeyboardArrowDownIcon />
                </Stack>

                <Stack
                  direction="row"
                  justifyContent="flex-end"
                  data-testid="filter_btn"
                >
                  <TuneIcon
                    className={styles.filter_ouline}
                    onClick={handleFilterDrawerToggle}
                  />
                </Stack>
              </Stack>
              <DoctorCards item={cat} />
            </Stack>
          </Stack>
        </Box>
        <Drawer
          anchor="right"
          open={locationMenu}
          onClose={() => handleCloseLocationMenu()}
          sx={{
            display: { xs: "block", sm: "block" },
            "& .MuiDrawer-paper": { boxSizing: "border-box", width: 350 },
          }}
        >
          <LocationMenu onClose={() => handleCloseLocationMenu()} />
        </Drawer>

        <Drawer
          anchor="right"
          open={filterMenu}
          onClose={() => handleCloseFilterMenu()}
          sx={{
            display: { xs: "block", sm: "block" },
            "& .MuiDrawer-paper": { boxSizing: "border-box", width: 350 },
          }}
        >
          <FilterMenu onClose={() => handleCloseFilterMenu()} />
        </Drawer>
      </Stack>
    </>
  );
}
