import React, { useState } from "react";
import { Tab, Tabs, Typography, Box, Stack } from "@mui/material";
import MembershipBlankImg from "../assets/images/blank.png";
import { BlankPage } from "./BlankPage";
import { ActiveCard } from "./ActiveCard";
import { CompletedCard } from "./CompletedCard";
import { CancelCard } from "./CancelCard";
import { useSharedData } from "../Context/ShareContext";
import CustomModal from "../molecules/CustomModal";
import styles from "../styling/AccountInformation.module.css";
import MembershipSummary from "../pages/MembershipSummary";
import {membershipConstants} from "../json/membershipConstants";
import Summary from "../pages/Summary";

function a11yProps(index) {
  return {
    id: `nav-tab-${index}`,
    "aria-controls": `nav-tabpanel-${index}`,
  };
}

export const Membership = () => {
  const [value, setValue] = useState(-1);
  const { showPackage, packageSummaryData, rescheduleSuccessModal,appointmentSummaryData, setRescheduleSuccessModal,showSummary, } = useSharedData();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      {showPackage === false ? (
        <Box>
          <CustomModal setOpen={setRescheduleSuccessModal} open={rescheduleSuccessModal} onCloseHandler={() => setRescheduleSuccessModal(false)} />
          <Typography className={styles["accinfo-title"]} variant="h4">
            {membershipConstants?.pageTitle}
          </Typography>
          <Stack direction="row" className={styles["accinfo-tabStack"]}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="nav tabs example"
            >
              <Tab
                label={<Typography variant="h5">{membershipConstants?.activeTabLabel}</Typography>}
                {...a11yProps(0)}
              />
              <Tab
                label={<Typography variant="h5">{membershipConstants?.completedTabLabel}</Typography>}
                {...a11yProps(1)}
              />
              <Tab
                label={<Typography variant="h5">{membershipConstants?.cancelledTabLabel}</Typography>}
                {...a11yProps(2)}
              />
            </Tabs>
          </Stack>
          <Stack>
            {value === -1 && (
              <BlankPage
                blankImage={MembershipBlankImg}
                mainTxt={membershipConstants?.blankPageMainText}
                subTxt={membershipConstants?.blankPageSubText}
                btnTxt={membershipConstants?.blankPageButtonText}
                btnClickHandler={(e) => handleChange(e, 0)}
              />
            )}
          </Stack>
          <Stack>{value === 0 && <ActiveCard />}</Stack>
          <Stack>{value === 1 && <CompletedCard />}</Stack>
          <Stack>{value === 2 && <CancelCard />}</Stack>
        </Box>
      ) : (
        <>{showSummary === false ?
        <MembershipSummary
          PageTitle={membershipConstants?.modalTitle}
          ID={packageSummaryData?.appointmentId}
          CardTitle={membershipConstants?.cardTitleLabel}
          CardTime={packageSummaryData?.bookedTime}
          CardStatus={packageSummaryData?.status}
          packageType={packageSummaryData?.packageType}
        />:
        <Summary
            PageTitle={"Membership Summary"}
            ID={appointmentSummaryData?.appointmentId}
            CardTitle={"Booked on"}
            CardTime={appointmentSummaryData?.bookedTime}
            CardStatus={appointmentSummaryData?.status}
            appointmentType={appointmentSummaryData?.appointmentType}

            
          />}
          </>
      )}
    </>
  );
};
