import React from "react";
import {
  Button,
  Card,
  CardContent,
  Divider,
  Drawer,
  Paper,
  Radio,
  Stack,
  Typography,
  Box,
} from "@mui/material";
import alertabdomen from "../assets/images/alertabdomen.svg";
import { useState } from "react";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import ChevronRight from "@mui/icons-material/ChevronRight";
import MemberMenu from "./MemberMenu";
import EditMemberMenu from "./EditMemberMenu";
import ApplyPopupMenu from "./ApplyPopupMenu";
import AddFamilyMemberFlyH from "./AddFamilyMemberFlyH";
import onSite from "../assets/images/on-site.png";
import yourSelf from "../assets/images/your-self.png";
import anyoneElse from "../assets/images/anyone-else.png";
import percentage from "../assets/images/percentage.png";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import styles from "../styling/AppointmentChcekout.module.css";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Bread from "./Bread";
import defaultStyles from "../styling/DefaultStyles.module.css";
import { ur } from "../json/urgent_care";
import { useNavigate } from "react-router-dom";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { CongratulationPopup } from "./CongratulationPopup";
import { useSharedData } from "../Context/ShareContext";
import mainlogo from "../assets/company_logo.svg";
import useRazorpay from "react-razorpay";
export default function UrgentCareCheckout() {
  const [showMemberMenu, setMemberMenu] = useState(false);
  const [showMemberList, setMemberList] = useState(false);
  const [applyCoupon, setApplyCoupon] = useState(false);
  const [showAddFamilyMember, setShowAddFamilyMember] = useState(false);

  let drawerObj = {
    display: { xs: "block", sm: "block" },
    "& .MuiDrawer-paper": { boxSizing: "border-box", width: 350 },
  };
  const handleMemberMenu = () => {
    setMemberMenu((prev) => !prev);
    setMemberList(false);
    setApplyCoupon(false);
    setShowAddFamilyMember(false);
  };

  const handleMemberListMenu = () => {
    setMemberList((prev) => !prev);
    setMemberMenu(false);
    setApplyCoupon(false);
    setShowAddFamilyMember(false);
  };

  const handleCouponMenu = () => {
    setApplyCoupon((prev) => !prev);
    setMemberMenu(false);
    setMemberList(false);
    setShowAddFamilyMember(false);
  };

  const handleFamilyView = () => {
    setShowAddFamilyMember((prev) => !prev);
    setMemberMenu(false);
    setMemberList(false);
    setApplyCoupon(false);
  };
  return (
    <>
      <Stack className={defaultStyles.max_bread_14_5}>
        <Bread />
      </Stack>
      <Stack alignContent="center">
        <Stack
          direction={{ xs: "column", sm: "column", md: "row" }}
          justifyContent="center"
          data-testid="appointment_payment_container"
          spacing={4}
          className={styles.auto_margin}
        >
          <Stack flex={11}>
            <Cards
              img1={onSite}
              img2={alertabdomen}
              text1={"On Site Appointment"}
              text2={ur?.urgent}
              text3={ur?.abdomen}
              url={"/outpatient_care/urgent_care/abdomen_pain"}
            />
          </Stack>

          <Stack flex={10}>
            <PaymentDetailsCard
              handleMemberMenu={handleMemberMenu}
              handleCouponMenu={handleCouponMenu}
            />
          </Stack>

          <Drawer
            anchor="right"
            open={showMemberMenu}
            onClose={handleMemberMenu}
            sx={drawerObj}
          >
            <MemberMenu
              onClose={handleMemberMenu}
              handleMemberListMenu={handleMemberListMenu}
              handleFamilyView={handleFamilyView}
            />
          </Drawer>

          <Drawer
            anchor="right"
            open={showMemberList}
            onClose={handleMemberListMenu}
            sx={drawerObj}
          >
            <EditMemberMenu
              handleFamilyView={handleFamilyView}
              onClose={handleMemberListMenu}
            />
          </Drawer>

          <Drawer
            anchor="right"
            open={applyCoupon}
            onClose={handleCouponMenu}
            sx={drawerObj}
          >
            <ApplyPopupMenu onClose={handleCouponMenu} />
          </Drawer>

          <Drawer
            anchor="right"
            open={showAddFamilyMember}
            onClose={handleFamilyView}
            sx={drawerObj}
          >
            <AddFamilyMemberFlyH onClose={handleFamilyView} />
          </Drawer>
        </Stack>
      </Stack>
    </>
  );
}

export const PaymentDetailsCard = ({ handleMemberMenu, handleCouponMenu }) => {
  let redColor = "#db4042";
  let lightSilver = "#818a8a";

  const locationsDummyData = [
    {
      type: "Yourself only",

      image: yourSelf,
    },
    { type: "Anyone else", image: anyoneElse },
  ];
  const [selectedValue, setSelectedValue] = useState("Yourself only");
  const handleChangeLocation = (location) => {
    setSelectedValue(location);
  };
  const { showCongratulationsPopup, setShowCongratulationsPopup } =
    useSharedData();
    const [Razorpay] = useRazorpay();
    const handleCheckoutClick = () => {
      let finalRazorPayValue = 500 * 100;
  
      var options = {
        key: "rzp_test_zt5DDs1PmkkyDy",
        amount: finalRazorPayValue, // Example: 2000 paise = INR 20
        name: "Healthspring",
        description: "Healthspring checkout",
        image: mainlogo, // COMPANY LOGO
        handler: function (response) {
          if (response.razorpay_payment_id) setShowCongratulationsPopup(true);
          // AFTER TRANSACTION IS COMPLETE YOU WILL GET THE RESPONSE HERE.
        },
        prefill: {
          name: "User Name", // pass customer name
          email: "user@email.com", // customer email
          contact: "+919123456780", //customer phone no.
        },
        notes: {
          address: "address", //customer address
        },
        theme: {
          color: "#15b8f3", // screen color
        },
      };
      console.log(options);
      var propay = new Razorpay(options);
      propay.open();
      // setShowCongratulationsPopup(true);
    };
  return (
    <Box maxWidth="100%" data-testid="payment_details_card">
      <Stack direction={"column"}>
        <Typography
          align="left"
          variant="h4"
          className={`${styles.typo_Txt} ${styles.border_bottom} `}
        >
          {ur?.payment}
        </Typography>

        <Typography align="left" variant="h5" className={styles.payment_Txt}>
          {ur?.appo}
        </Typography>

        <Paper className={styles.paper_view} elevation={0}>
          <Stack spacing={1}>
            {locationsDummyData?.map((item, index) => (
              <React.Fragment key={item?.type}>
                <Stack
                  direction="row"
                  alignItems="center"
                  spacing={1}
                  className={styles.list_item_container}
                >
                  <Stack
                    flexGrow={1}
                    direction="row"
                    alignItems="center"
                    spacing={2}
                  >
                    <IconView img={item?.image} isBig={true} />
                    <Typography className={styles.list_item_text}>
                      {item?.type}
                    </Typography>
                  </Stack>
                  <Radio
                    checked={selectedValue === item?.type}
                    onChange={() => handleChangeLocation(item?.type)}
                    value={selectedValue}
                    className={styles.radio_button}
                    sx={{
                      "&, &.Mui-checked": {
                        color: "#B2C943",
                      },
                    }}
                  />
                </Stack>
                {index !== locationsDummyData.length - 1 && (
                  <Box>
                    <Divider className={styles.divider_one} />{" "}
                  </Box>
                )}
              </React.Fragment>
            ))}
          </Stack>
        </Paper>

        {selectedValue === "Anyone else" && (
          <Stack>
            <Typography
              align="left"
              variant="h5"
              className={styles.payment_Txt}
            >
              {ur?.member}
            </Typography>

            <Button
              onClick={handleMemberMenu}
              className={styles.selectBtn_Flex_Start}
              endIcon={
                <KeyboardArrowDownIcon className={styles.svg_image_right} />
              }
            >
              <Typography
                align="left"
                variant="h5"
                className={`${styles.typo_Txt} ${styles.select_text}`}
              >
                {ur?.select}
              </Typography>
            </Button>
          </Stack>
        )}

        <Divider className={styles.divider_two} />

        <Button
          onClick={handleCouponMenu}
          className={styles.selectBtn_Flex_Start}
          startIcon={
            <IconView img={percentage} className={styles.percentageIcon} />
          }
          endIcon={<ChevronRight className={styles.svg_image_right} />}
        >
          <Typography
            align="left"
            variant="h5"
            className={`${styles.typo_Txt} ${styles.use_coupon}`}
          >
            {ur?.coupon}
          </Typography>
        </Button>

        <Typography align="left" variant="h5" className={styles.payment_Txt}>
          {ur?.pay}
        </Typography>
        <Stack className={styles.payment_view}>
          <RowWiseChild
            color1={lightSilver}
            text1="Total Price"
            text2="₹1,999.00"
          />
          <RowWiseChild color1={lightSilver} text1="Tax" text2="₹0.00" />
          <Divider />
          <RowWiseChild
            text1="Grand Total"
            text2="₹1,999.00"
            color2={redColor}
          />
        </Stack>
        <Button
          className={styles.login_btn}
          onClick={handleCheckoutClick}
          endIcon={
            <Stack className={styles.backView}>
              <ChevronRightIcon className={styles.svg_image_white} />
            </Stack>
          }
        >
          {ur?.check}
        </Button>
        {showCongratulationsPopup && <CongratulationPopup />}
      </Stack>
    </Box>
  );
};

const RowWiseChild = ({
  color1 = "#000",
  color2 = "#000",
  text1 = "",
  text2 = "",
}) => {
  return (
    <Stack
      direction={"row"}
      alignItems={"center"}
      justifyContent={"space-between"}
    >
      <Typography
        align="left"
        variant="h5"
        className={`${styles.typo_Txt}`}
        sx={{ color: color1 }}
      >
        {text1}
      </Typography>

      <Typography
        align="left"
        variant="h5"
        className={`${styles.typo_Txt}`}
        sx={{ color: color2 }}
      >
        {text2}
      </Typography>
    </Stack>
  );
};
export const Cards = (props) => {
  const date = "Today, 17 Nov 2022";
  const time = "At 1:30 PM";
  const navigate = useNavigate();
  const navigateToUrl = (url) => {
    navigate(url);
  };
  return (
    <Card
      className={`${styles.doc_card} ${styles.card_view} `}
      key={1}
      data-testid="appointment_card"
      elevation={0}
    >
      <CardContent>
        <Stack
          className={`${styles.header} ${styles.grid_view}`}
          direction="row"
          alignItems="center"
        >
          <IconView img={props?.img1} isBig={true} />
          <span className={`${styles.on_site_text}`}>{props?.text1}</span>
        </Stack>

        <Stack className={`${styles.date_time_parent}`} direction="row">
          <Stack className={styles.date}>
            <Stack direction="row" spacing={{ xs: 0.5, sm: 1 }}>
              <CalendarMonthIcon className={styles.icon_fix} />
              <Typography className={`${styles.day_txt}`} align="left">
                {date}
              </Typography>
            </Stack>
            <span
              className={`${styles.green_color}`}
              onClick={() => navigateToUrl(props?.url)}
            >
              Change date & Time
            </span>
          </Stack>
          <Stack direction="row" spacing={{ xs: 0.5, sm: 1 }}>
            <AccessTimeIcon className={styles.icon_fix} />
            <Typography className={`${styles.day_txt}`} align="left">
              {time}
            </Typography>
          </Stack>
        </Stack>

        <Stack spacing={2} className={styles.doc_stack}>
          <Stack direction="row" spacing={2}>
            <Stack>
              <img
                src={props.img2}
                className={styles.avatar_icon_small}
                alt="img"
                width="100px"
              />
            </Stack>

            <Stack spacing={0} alignSelf="center">
              <Stack direction="row" spacing={1.5}>
                <Typography align="left" fontWeight="bold">
                  {props?.text2}
                </Typography>
              </Stack>
              <Stack direction="row" spacing={2}>
                <Typography align="left" className={styles.desc_txt}>
                  {props?.text3}
                </Typography>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );
};

const IconView = ({ img, isBig = false, className = "" }) => {
  return (
    <img
      alt="Avatar"
      src={img}
      className={
        className !== ""
          ? className
          : `${isBig ? styles.iconViewBig : styles.iconView}`
      }
    />
  );
};
