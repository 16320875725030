import { Box, Drawer, Stack, Typography } from "@mui/material";
import alertabdomen from "../assets/images/alertabdomen.svg";
import defaultStyles from "../styling/DefaultStyles.module.css";
import styles from "../styling/Booking.module.css";
import Bread from "./Bread";
import AbdomenPainDoctorSchedule from "./AbdomenPainDoctorSchedule";
import { InternalBanner } from "./BookingForTeethClening";
import LocationMenu from "./LocationMenu";
import { useState } from "react";
import DoctorScheduleForNew from "./DoctorScheduleForNew";

export default function AbdomenPain() {
  const headerText = "Abdomen Pain";
  const [locationMenu, setLocationMenu] = useState(false);

  const openLocationMenu = (bool) => {
    setLocationMenu(bool);
  };

  const handleCloseLocationMenu = () => {
    setLocationMenu(false);
  };
  return (
    <>
      <Stack className={defaultStyles.max_width_stack}>
        <Stack className={defaultStyles.bread_stack}>
          <Bread />
        </Stack>

        <InternalBanner text={headerText} img={alertabdomen} />
        <Stack justifyContent="center" alignItems="center"mt={1}>
          <DoctorScheduleForNew
            url={`/outpatient_care/urgent_care/abdomen_pain/checkout`}
            layout="outpatient"
          />
        </Stack>
        <Drawer
          anchor="right"
          open={locationMenu}
          onClose={() => handleCloseLocationMenu()}
          sx={{
            display: { xs: "block", sm: "block" },
            "& .MuiDrawer-paper": { boxSizing: "border-box", width: "350px" },
          }}
        >
          <LocationMenu onClose={() => handleCloseLocationMenu()} />
        </Drawer>
      </Stack>
    </>
  );
}
