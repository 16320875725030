import React from "react";
import { Typography, Stack, Button, Avatar, Grid, Paper, Box, Divider,Tab, Tabs } from "@mui/material";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import theme from "../components/Themeify";
import { SiteContent } from "../assets/content/SiteContent";
import styles from "../styling/Summary.module.css";
import { useSharedData } from "../Context/ShareContext";
import { CheckCircle } from "@mui/icons-material";
import { PaymentInfo } from "../molecules/PaymentInfo";
import { UseCoupon } from "../molecules/UseCoupon";
import appleIcon from "../assets/apple.svg";
import phy from "../assets/phy.svg";
import lightGreenPhy from "../assets/light_green_phy.svg";
import greenPhy from "../assets/green_phy.svg";
import redApple from "../assets/red_apple.svg";
import girlIcon from "../assets/girlIcon.svg";
import microscopeIcon from "../assets/microscope.svg";
import healthGoldIcon from "../assets/healthGold.svg";
import downloadIcon from "../assets/downloadIcon.svg";
import cancelIcon from "../assets/cancelIcon.svg";
import helpIcon from "../assets/helpIcon.svg";
import { CancelledAppointment } from "../molecules/CancelledAppointment";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import CustomDrawer from "../molecules/CustomDrawer";
import CustomStack from "../molecules/CustomStack";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Summary from "../pages/Summary";
import { CompletedAppointment } from "../molecules/CompletedAppointment";



const InfoWizard = ({ text, icon, severity = "success" }) => {
  let wizard_container = styles.wizard_container;
  switch (severity) {
    case "warning":
      wizard_container = `${wizard_container} ${styles.orange_color_wizard}`;
      break;
    case "error":
      wizard_container = `${wizard_container} ${styles.red_color_wizard}`;
      break;
    default:
      wizard_container = `${wizard_container} ${styles.green_color_wizard}`;

  }
  return (
    <Box className={wizard_container}>
      <Box>
        <img src={icon} alt={"wizard_icon"} />
      </Box>
      <Box>
        <Typography className={styles.info_wizard_text}>{text}</Typography>
      </Box>
    </Box>
  );
};


const PackageInfo = ({ mainIcon, validityText, icon, title, priceText }) => {
  return (
    <Box className={styles.package_info_container}>
      <Paper className={styles.package_info_paper}>
        <Box className={styles.package_data_container}>
          <Stack spacing={2} direction="row" alignItems="center">
            <Avatar src={mainIcon} />
            <Typography className="myreport-typoTitle">{title}</Typography>
          </Stack>
          <Avatar className={styles.rightArrowStyle} variant="rounded">
            <ArrowForwardIosIcon />
          </Avatar>
        </Box>
        <Box className={styles.package_vailidity_container}>
          <Box className={styles.vailidity_box}>
            <img src={icon} alt="validty" />
            <span className={styles.marg}>{validityText}</span>
          </Box>
          <Box className={styles.package_price}>{priceText}</Box>
        </Box>
      </Paper>
    </Box>
  );
};

function a11yProps(index) {
  return {
    id: `nav-tab-${index}`,
    "aria-controls": `nav-tabpanel-${index}`,
  };
}

const MembershipSummary = ({ PageTitle, ID, CardTitle, CardTime, CardStatus, packageType }) => {
  const { setShowPackage, appointmentSummaryData } = useSharedData();
  const { setShowSummary, setAppointmentSummaryData, setAppointmentRescheduleModal} = useSharedData();

  const [value, setValue] = React.useState(0);
  const [showAppointmentSummary, setShowAppointmentSummary] = React.useState(false);
  const [drawerIsOpen, setDrawerIsOpen] = React.useState(false);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const viewDetails = () => {
    setAppointmentSummaryData({
      appointmentId:"ID #1947765740987498",
      bookedTime:'Sat, 12 Nov 10:06 am',
      status:'Completed',
      appointmentType:'completed'
    })
    setShowSummary(true)
  }
  const handleBack = () => {
    setShowPackage(false);
  };

  return (
    <>
     <CustomDrawer type={"custom"} title={"Booking For"} anchor={'right'} isOpen={drawerIsOpen} onCloseHandler={() => setDrawerIsOpen(false)}>
        <Accordion sx={{boxShadow:"none"}} expanded={true}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>{`Consultation (4)`}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <CustomStack title={"Physician Consultant"} number={"1/4"} iconPath={greenPhy} color="green" />
            <CustomStack title={"Physician Consultant"} number={"4/4"} iconPath={phy}/>
            <CustomStack title={"Diet Consultation"} number={"4/3"} iconPath={redApple} color="red" />
            <CustomStack title={"Physio Session"} number={"1/3"} iconPath={lightGreenPhy} color="light_green" />
          </AccordionDetails>
        </Accordion>
        <Accordion sx={{boxShadow:"none"}} >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>{`Laboratory Tests (6)`}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <CustomStack title={"Physician Consultant"} number={"1/4"} iconPath={greenPhy} color="green" />
            <CustomStack title={"Physician Consultant"} number={"4/4"} iconPath={phy}/>
            <CustomStack title={"Diet Consultation"} number={"4/3"} iconPath={redApple} color="red" />
            <CustomStack title={"Physio Session"} number={"1/3"} iconPath={lightGreenPhy} color="light_green" />
          </AccordionDetails>
        </Accordion>
      </CustomDrawer>

      {
        !showAppointmentSummary ? <>
          <Stack direction="row" onClick={handleBack}>
            <Button
              id="basic-button"
              startIcon={
                <Avatar className={styles.back_icon_container}>
                  <KeyboardBackspaceIcon className={styles.back_icon} />
                </Avatar>
              }
              className={styles.back_btn}
            >
              <Typography variant="h5" color={theme.palette.info.grey}>
                {SiteContent.BACK}
              </Typography>
            </Button>
          </Stack>
          <Stack className={styles.summary_stack}>
            <Typography className={styles.summary_title} variant="h4">
              {PageTitle}
            </Typography>
            <Typography className={styles.summary_id} variant="h5">
              {ID}
            </Typography>
          </Stack>

          <Stack direction="row" className={styles["accinfo-tabStack"]}>
            <Tabs value={value} onChange={handleChange} aria-label="nav tabs example">
              <Tab label={<Typography variant="h5">Info</Typography>} {...a11yProps(0)} />
              <Tab label={<Typography variant="h5">Activites</Typography>} {...a11yProps(1)} />
            </Tabs>
          </Stack>

          {value === 0 ? (
            <>
              <Stack className={styles.summary_card_stack}>
                <Stack>
                  <Typography className={styles.summary_card_title}>{CardTitle}</Typography>
                  <Typography className={styles.summary_card_desc}>{CardTime}</Typography>
                </Stack>
                <Typography className={packageType === "completed" ? styles.summary_completed : styles.summary_card_status}>
                  <CheckCircle className={styles.summary_card_status_icon} />
                  {CardStatus}
                </Typography>
              </Stack>

              <Grid className={styles.container_grid} container spacing={2}>
                <Grid item xs={12} md={5} className={styles.content_left_grid}>
                  <Typography>Activites</Typography>
                  {packageType === "completed" ? (
                    <>
                      <CustomStack title={"Physician Consultant"} number={"4/4"} iconPath={phy} />
                      <CustomStack title={"Diet Consultation"} number={"3/3"} iconPath={appleIcon} />
                      <CustomStack title={"Diet Follow-ups"} number={"3/3"} iconPath={appleIcon} />
                    </>
                  )
                  : 
                  (
                    <>
                      <CustomStack title={"Physician Consultant"} number={"1/4"} iconPath={greenPhy} color="green" />
                      <CustomStack title={"Diet Consultation"} number={"4/3"} iconPath={redApple} color="red" />
                      <CustomStack title={"Diet Follow-ups"} number={"3/3"} iconPath={appleIcon} color="grey" />
                      <CustomStack title={"Physio Session"} number={"1/3"} iconPath={lightGreenPhy} color="light_green" />
                    </>)}
                </Grid>

                <Grid item xs={12} md={7} textAlign={"left"}>
                  <Box>
                    <Typography>Package Info</Typography>
                    {packageType === "completed" ? <PackageInfo title={"Women Care (Age above 40)"} mainIcon={girlIcon} icon={microscopeIcon} priceText={"₹5,499"} validityText={"3 Months Validity"} /> : <PackageInfo title={"Health Gold"} mainIcon={healthGoldIcon} icon={microscopeIcon} priceText={"₹5,499"} validityText={"3 Months Validity"} />}
                  </Box>

                  <UseCoupon />
                  <PaymentInfo />

                  <Box textAlign={"center"} marginTop={2}>
                  <Button
                      onClick={() => setDrawerIsOpen(true)}
                      className={styles.next_btn}
                      endIcon={
                        <Box className={styles.next_btn_icon_container}>
                          <ArrowForwardIosIcon className={styles.next_btn_icon} />
                        </Box>
                      }
                    >
                      <span className={styles.next_btn_label}>{SiteContent.BOOK_ACTIVITIES}</span>
                    </Button>

                  </Box>

                  <Box marginTop={2}>
                    <Divider />
                  </Box>

                  <Box marginTop={3}>
                    <Grid container spacing={3}>
                      <Grid item md={packageType === "completed" ? 6 : 4 } textAlign={"center"}>
                        <InfoWizard icon={downloadIcon} text={"Download"} />
                      </Grid>

                      { packageType !== "completed"  && 
                        <Grid item md={4} textAlign={"center"}>
                          <InfoWizard severity="error" icon={cancelIcon} text={"Cancel Booking"} />
                        </Grid>
                      }

                      <Grid item md={packageType === "completed" ? 6 : 4 } textAlign={"center"}>
                        <InfoWizard severity="warning" icon={helpIcon} text={"Need Help"} />
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
              </Grid>
            </>
          ) : (
            <div onClick={() => viewDetails()}>
             <CancelledAppointment openDetails={setShowAppointmentSummary} />
             </div>
          

          )}
        </>
        :
        <Summary
              PageTitle={"Appointment Summary"}
              ID={appointmentSummaryData?.appointmentId}
              CardTitle={"Booked on"}
              CardTime={appointmentSummaryData?.bookedTime}
              CardStatus={appointmentSummaryData?.status}
              appointmentType={appointmentSummaryData?.appointmentType
            }
            
        />
      }
      
    </>
  );
};
export default MembershipSummary;
