import React from 'react';
import { Line } from 'react-chartjs-2';
import styles from "../styling/VitalsBodyParam.module.css"


const WaistHipChart = () => {
  const labels = ['1 Jan', '15 Jan', '28 Jan', '12 Feb', '30 Feb', '14 Mar'];
  
  const dataset1 = [0.81, 0.79, 0.76, 0.82, 0.83, 0.80];

  const datasets = [
    {
      label: '',
      data: dataset1,
      borderColor: 'black',
      backgroundColor: 'rgba(255, 255, 255, 0.5)',
    },
  ];

  const chartData = { labels, datasets };

  const options = {
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
      
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        grid: {
          display: false,
        },
      },
    },
  };

  return (
    <div>
      <Line data={chartData} options={options} className={styles.canvas_width} />
    </div>
  );
};

export default WaistHipChart;
