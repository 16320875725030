import React, {useEffect} from "react";
import { Box, Stack, CardActions, IconButton, } from "@mui/material";
import { useNavigate, Link } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import theme from "../components/Themeify";
import Typography from "@mui/material/Typography";
import Run from "../assets/images/ico98.png";
import CardMedia from "@mui/material/CardMedia";
import styles from "../styling/BlogsReadMore.module.css";
import { BlogReadMoreCard } from "../molecules/BlogReadMoreCard";
import { Facebook, Twitter } from "@mui/icons-material";
import InsertLinkIcon from "@mui/icons-material/InsertLink";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import Divider from "@mui/material/Divider";
import { NeedtoKnow } from "../molecules/NeedtoKnow";
import {blogs_dynamic} from "../json/BlogsDynamic";

import { useSharedData } from "../Context/ShareContext";
import NewGenericPopup from "../molecules/NewGenericPopup";

export const BlogsReadmore = () => {
  const { showPopup, setShowPopup } = useSharedData();
  const navigate = useNavigate();
  const back = () => {
    navigate("/login/blogs");
  };
  useEffect(() => {
    window.scrollTo(0,0);
    return () => {
      
    };
  }, []); 

    
  return (
    <Box className={styles.blogreadmore_main_new_box}>
      <Stack direction="row" spacing={2}>
        <Stack>
          <CardActions>
            <IconButton>
              <ArrowBackIcon
                className={styles.blogreadmore_icon}
                sx={{ color: theme.palette.primary.dark }}
                onClick={back}
                data-testid="back-btn"
              />
            </IconButton>
          </CardActions>
        </Stack>
        <Stack className={styles.blogreadmore_explore}>
          <Typography
            className={styles.blogreadmore_explore_typosss}
            variant="h3"
            component="div"
          >
            {blogs_dynamic?.BANNER_1?.BLOGS_READ_MORE?.BACK_BUTTON}
          </Typography>
        </Stack>
      </Stack>
      <Stack
        className={styles.blogReadMore_mainstack_one_ones}
        direction={{ xs: "column-reverse", sm: "column-reverse", md: "row" }}
        justifyContent="space-between"
        spacing={10}
        alignItems={{ sm: "center", md: "start" }}
      >
        <Stack className={styles.blogReadMore_mainstack_ones}>
          <Stack className={styles.blogsReadmore_secondstack}>
            <Typography
              className={styles.blogs_fitnessss}
              variant="h3"
              component="div"
              align="left"
            >
              {blogs_dynamic?.BANNER_1?.FITNESS?.FITNESS_REGIME_HEADER}
            </Typography>
          </Stack>
          <Stack>
            <Typography className={styles.blogsReadmore_typodates} align="left">
              {blogs_dynamic?.BANNER_1?.FITNESS?.DATE}
            </Typography>
          </Stack>
          <Stack className={styles.run_img_newone}>
            <CardMedia
              component="img"
              image={Run}
              alt="Live from space album cover"
            />
          </Stack>
          <Stack>
            <Typography
              className={styles.blogsreadme_third_stackss}
              mt={4}
              align="left"
            >
              {blogs_dynamic?.BANNER_1?.FITNESS?.BENEFIT}
            </Typography>
          </Stack>

          {blogs_dynamic?.BANNER_1?.FITNESS?.FLAX?.map((item) => {
            return (
              <>
                <Stack className={styles.blogsreadme_third_stack}>
                  <Typography
                    className={styles.blogsreadme_typoss}
                    align="left"
                    variant="h2"
                                      >
                    {item?.HEADER}
                  </Typography>
                </Stack>
                <Stack className={styles.blogsreadme_four_stack}>
                  <Typography
                    variant="h4"
                    align="left"
                    className={styles.blogsreadme_typoss_inner}
                  >
                    {item?.DESCRIPTION}
                  </Typography>
                </Stack>
              </>
            );
          })}
          
          <Stack className={styles.blogsreadme_third_stack}>
            <Typography
              className={styles.blogsreadme_typoss}
              align="left"
              variant="h2"
            >
              {blogs_dynamic?.BANNER_1?.FITNESS?.MEAL_HEAD}
            </Typography>
          </Stack>
          <Stack className={styles.blogsreadme_four_stack}>
            <Typography
              variant="h4"
              align="left"
              className={styles.blogsreadme_typoss_inner}
            >
              {blogs_dynamic?.BANNER_1?.FITNESS?.MEAL_DESC}
            </Typography>
            <Stack className={styles.dot_padding}>
              <Typography
                variant="h4"
                align="left"
                className={styles.blogsreadme_typoss_inner}
              >
                <ul className={styles.ul_blogsreadmorecorporate}>
                  {blogs_dynamic?.BANNER_1?.FITNESS?.PROCEDURE?.map((item) => {
                    return <li>{item?.TEXT}</li>;
                  })}
                </ul>
              </Typography>
            </Stack>
          </Stack>

          <BlogReadMoreCard />

          <Stack className={styles.conclusion_readmore_stack}>
            <Typography className={styles.blogsreadme_typoss} variant="h3">
              {blogs_dynamic?.BANNER_1?.FITNESS?.CONCLUSION}
            </Typography>
          </Stack>
          <Stack className={styles.blogsreadme_four_stack}>
            <Typography
              variant="h4"
              className={styles.blogsreadme_typoss_inner}
              align="left"
            >
              {blogs_dynamic?.BANNER_1?.FITNESS?.CONCLUSIONDESC}
            </Typography>
          </Stack>
          <Stack className={styles.needtoknow_stackss}>
            <NeedtoKnow />
          </Stack>
          <Divider className={styles.need_divider} />

          <Stack
            direction={{ xs: "column", sm: "row" }}
            justifyContent="space-between"
          >
            <Stack direction="row" spacing={2} className={styles.new_stack} sx={{cursor:'pointer'}} onClick={()=>navigate('/login/blogs')}>
              <ArrowBackIcon
                className={styles.blogreadmore_icon}
                sx={{ color: theme.palette.primary.dark }}
                onClick={back}
                data-testid="back-btn"
              />
              <Typography
                className={styles.blogreadmore_explore_typosss}
                variant="h4"
                component="div"
                alignContent="center"
                
              >
                {blogs_dynamic?.BANNER_1?.BLOGS_READ_MORE?.BACK_BUTTON}
              </Typography>
            </Stack>
            <Stack
              className={styles.shareOnMainStack}
              direction="row"
              spacing={2}
              pt={5}
            >
              
              <Typography className={styles.shareOnMainStackTypos}pt={2}>
                {blogs_dynamic?.BANNER_1?.BLOGS_READ_MORE?.SHARE}
              </Typography>
              <Link to='https://urldefense.com/v3/__https://www.facebook.com/HealthspringIndia__;!!Nyu6ZXf5!u2oskUm8zRXvNrAiFmxpYk05q5lc2ZSJsrqgAU7eo2vZFROfAV1F-fzEDAV1t7DUsvcPeFcmworGKBLJs7U_jPvbdHpNsw$'>
              <IconButton sx={{ color: theme.palette.primary.dark }} 
              // onClick={() => setShowPopup(true)}
              >
                <Facebook className={styles.blogreadmorenew_icon} />
              </IconButton>
              </Link>
              <Link to='https://urldefense.com/v3/__https://twitter.com/Healthspring___;!!Nyu6ZXf5!u2oskUm8zRXvNrAiFmxpYk05q5lc2ZSJsrqgAU7eo2vZFROfAV1F-fzEDAV1t7DUsvcPeFcmworGKBLJs7U_jPvmUVD9Mg$'>
              <IconButton sx={{ color: theme.palette.primary.dark }} 
              // onClick={() => setShowPopup(true)}
              > 
                <Twitter className={styles.blogreadmorenew_icon} />
              </IconButton>
              </Link>
              <Link to='https://urldefense.com/v3/__https://www.linkedin.com/company/3543986/admin/feed/posts/__;!!Nyu6ZXf5!u2oskUm8zRXvNrAiFmxpYk05q5lc2ZSJsrqgAU7eo2vZFROfAV1F-fzEDAV1t7DUsvcPeFcmworGKBLJs7U_jPu0MI6BEg$'>
              <IconButton sx={{ color: theme.palette.primary.dark }} 
              // onClick={() => setShowPopup(true)}
              >
                <LinkedInIcon className={styles.blogreadmorenew_icon} />
              </IconButton>
              </Link>
              <Link to='https://urldefense.com/v3/__https://www.instagram.com/thehealthspring/__;!!Nyu6ZXf5!u2oskUm8zRXvNrAiFmxpYk05q5lc2ZSJsrqgAU7eo2vZFROfAV1F-fzEDAV1t7DUsvcPeFcmworGKBLJs7U_jPvzP0YnNg$'>
              <IconButton sx={{ color: theme.palette.primary.dark }} 
              // onClick={() => setShowPopup(true)}
              >
                <InsertLinkIcon className={styles.blogreadmorenew_icon} />
              </IconButton>
              </Link>
            </Stack>
          </Stack>
        </Stack>
        <Stack className={styles.blogReadMore_mainstack_twos}>
          <Box className={styles.blogsreadmoreright_box}>
            <Typography
              className={styles.blogsreadmorerighter_typoone}
              variant="h3"
              align="left"
            >
              Feature posts
            </Typography>
            <Divider
              variant="fullWidth"
              className={styles.blogsreadmore_dividerone}
            />
            {blogs_dynamic?.BANNER_1?.FEATURE_POST?.map((item, index) => {
              return (
                <Stack direction="row" spacing={3}>
                  <Stack>
                    <Typography
                      className={styles.blogsreadmoreleft_typoone}
                      variant="h3"
                      align="left"
                    >
                      {item?.ID}
                    </Typography>
                  </Stack>
                  <Stack>
                    {" "}
                    <Typography
                    sx={{cursor:'pointer'}}
                      className={styles.blogsreadmoreright_typoone}
                                            variant="h4"
                      align="left"
                    >
                      {item?.DESCRIPTION}
                    </Typography>
                    {blogs_dynamic?.BANNER_1?.FEATURE_POST?.length - 1 !== index && <Divider />}
                  </Stack>
                </Stack>
              );
            })}
            
          </Box>

          <Box className={styles.blogsreadmorerightone_box}>
            <Typography
              className={styles.blogsreadmoreright_typotwo}
              variant="h3"
              align="left"
            >
              {blogs_dynamic?.BANNER_1?.BLOGS_READ_MORE?.CATEGORY_TEXTS}
            </Typography>
            <Divider
              variant="fullWidth"
              className={styles.blogsreadmore_dividerone}
            />
            <Stack>
              {blogs_dynamic?.BANNER_1?.BLOGS_READ_MORE?.CATEGORY_NAMES.map((category, index) => (
                <Typography
                  key={index}
                  className={styles.blogsreadmoreright_typothree}
                  variant="h4"
                  align="left"
                >
                  {category}
                </Typography>
              ))}
            </Stack>
          </Box>
        </Stack>
      </Stack>
      {/* {showPopup &&   <NewGenericPopup
           
           SubTxt="Thank you for visiting, the new website is temporarily unavailable, we will redirect you to our existing website for now. Thank you for your patience and understanding ." 
           SubBtnTxt='Cancel'
           ShowSubBtn={true}
           PlaceholderTxt={""}
         /> } */}
    </Box>
  );
};
