import React from "react";
import { Stack } from "@mui/system";
import styles from "../styling/LabtestCheckOut.module.css";
import teethcircle from "../assets/images/teethcircle.png";

import { OnsiteAppointmentLeft } from "../molecules/OnsiteAppointmentLeft";
import { AppointmentInclinicPayment } from "../molecules/AppointmentInclinicPayment";

export const OnsiteAppointment = () => {
  return (
    <>
       <Stack spacing={4} direction="row" data-testid="OnsiteAppointment_main">
        <Stack className={styles.lab_test_appointments_main}>
          <OnsiteAppointmentLeft imagetwo={teethcircle}  text='On Site Appointment' textOne='Urgent Care' textTwo='Abdomen Pain'/>
        </Stack>

       <AppointmentInclinicPayment />
      </Stack>
    </>
  )
}
