import React from "react";
import { Paper, Typography, Stack, Box, Avatar } from "@mui/material";
import styles from "../styling/ContactCard.module.css";
import { StyledEngineProvider } from "@mui/material/styles";
import DirectionsOutlinedIcon from "@mui/icons-material/DirectionsOutlined";
import PhoneIcon from "@mui/icons-material/Phone";
import Spacer from "../atoms/Spacer";

const ContactCard = ({
  name,
  address1,
  address2,
  address3,
  address4,
  phone,
}) => {
  const direction = "Get Directions";

  const handlePhoneClick = (phoneNumber) => {
    window.location.href = `tel:${phoneNumber}`;
  };
  return (
    <StyledEngineProvider injectFirst>
      <Paper elevation={5} className={styles.contact_card_paper}>
        <Stack spacing={1} p={2}>
          <Typography variant="h4" className={styles.contact_name}>
            {name}
          </Typography>
          <Typography variant="h5" className={styles.contact_address}>
            {address1}
            <Spacer />
            {address2}
            <Spacer />
            {address3}
            <Spacer />
            {address4}
            <Spacer />
          </Typography>
          <Stack
            direction={{ xs: "column", sm: "row" }}
            justifyContent="space-between"
            spacing={{xs:1,sm:2}}
          >
            <Stack>
              <Stack direction="row" spacing={1} >
                <Typography fontWeight="bold" align="left" className={styles.get_directions}>
                  {/* {direction} */}
                </Typography>
                <Stack className={styles.get_directions} pt={0.3}>
                {/* <DirectionsOutlinedIcon fontSize="small" /> */}
                </Stack>
              </Stack>
            </Stack>
            <Box>
              <Stack direction="row">
                <Avatar className={styles.phone_avatar}>
                  <PhoneIcon fontSize="small" />
                </Avatar>
                <div
                  className={styles.mouseHover}
                  onClick={() => {
                    handlePhoneClick(phone);
                  }}
                >
                  <Typography
                    fontWeight="bold"
                    className={styles.contact_card_phone}
                  >
                    {phone}
                  </Typography>
                </div>
              </Stack>
            </Box>
          </Stack>
        </Stack>
      </Paper>
    </StyledEngineProvider>
  );
};

export default ContactCard;
