import React, {useState} from "react";
import { Grid, Typography, CardContent, Stack, Button } from "@mui/material";
import styles from "../styling/ActiveCard.module.css";
import { Cancelled } from "../json/Cancelled";
import { useSharedData } from "../Context/ShareContext";
import CustomDrawer from "./CustomDrawer";


export const CancelCard = () => {
  const { setShowPackage, setPackageSummaryData, setRescheduleSuccessModal, setBookActivityModal } = useSharedData();
  const [drawerIsOpen, setDrawerIsOpen] = useState(false);
  const viewDetails = () => {
    setPackageSummaryData({
      appointmentId:"ID #19477657407",
      bookedTime:'Sat, 12 Nov 10:06 am',
      status:'Cancelled',
      packageType:'cancelled'
    })
    setShowPackage(true)
  }

  const nextBtnClick = () => {
    setRescheduleSuccessModal(true);
    setBookActivityModal(true);
  }

  return (
    <>
      {Cancelled?.map((card, index) => {
        return (
          <CardContent key={index} className={styles["membpack-cardContent"]}>
            <Stack direction={{xs:"column",sm:"row"}} justifyContent="space-between" spacing={2}>
              <Stack>
                <Stack className={styles["membpack-subStack"]}>
                  {card?.icon}
                  <Stack className={styles["membpack-subStack2"]}>
                    <Typography className={styles["membpack-typoTitle"]}>
                      {card?.name}
                    </Typography>
                    <Stack direction={"row"}>
                      <Typography
                        className={
                          card?.type === "Cancelled"
                            ? styles["membpack-typoDateExpire"]
                            : styles["membpack-typoDate"]
                        }
                      >
                        {card?.date}
                      </Typography>
                      {card?.leftdays && (
                        <>
                          <Typography className={styles["membpack-typoDot"]}>
                            {" "}
                            •{" "}
                          </Typography>
                          <Typography className={styles["membpack-typoDesc"]}>
                            {card?.leftdays}
                          </Typography>
                        </>
                      )}
                    </Stack>
                  </Stack>
                </Stack>
              </Stack>
              <Stack>
                <Stack className={styles["membpack-subStack3"]}>
                  <Button className={styles["membpack-leftBtn"]} onClick={() => setDrawerIsOpen(true)}>Buy Now</Button>
                  <Button className={styles["membpack-rightBtn"]} onClick={() => viewDetails()}>View Details</Button>
                </Stack>
              </Stack>
            </Stack>
          </CardContent>
        );
      })}
      <CustomDrawer anchor={'right'} isOpen={drawerIsOpen} onCloseHandler={() => setDrawerIsOpen(false)} onNextClick={() => nextBtnClick()}/>
    </>
  );
};
