import React, { useState } from "react";
import {
  Card,
  CardContent,
  Typography,
  CardMedia,
  Stack,
  Box,
  Divider,
} from "@mui/material";
import syringe from "../assets/syringe.png";

import theme from "./Themeify";
import ReactiveProtein from "./ReactiveProtein";
import styles from "../styling/OutPatientCare.module.css";
// import styles from "../styling/HealthCardSearch.module.css";
import { Link } from "react-router-dom";
import Bread from "../molecules/Bread";
import defaultStyles from "../styling/DefaultStyles.module.css";
import { vaccination } from "../json/urgent_care";
import GenericSearch from "../molecules/GenericSearch";
import { GreenBanner } from "./Urgentcare";
import GenericSearchList from "../molecules/GenericSearchList";
import { useNavigate } from "react-router-dom";

export const Vaccination = () => {
  const [showReactive] = useState(false);
  const SearchClick = (i) => {};
  const navigate = useNavigate();

  return (
    <>
      <Stack className={defaultStyles.max_bread_14_5}>
        <Bread />
      </Stack>
      {showReactive ? (
        <ReactiveProtein />
      ) : (
        <>
          <GreenBanner text={vaccination?.title} img={syringe} />

          <Stack className={styles.urgentcare_stack}>
            <GenericSearch placeholder={vaccination?.search} />

            <Typography
              
            >
              <GenericSearchList
                title={vaccination?.recentsearch?.title}
                searchlist={vaccination?.recentsearch?.recentSearch_Vaccine}
                SearchClick={SearchClick}
                showclear={true}
                clickable={true}
                url="/outpatient_care/vaccination/c_Reactive_protien_crp_blood_test"
              />
            </Typography>

            <Typography>
              <GenericSearchList
                title={vaccination?.recommendation?.title}
                SearchClick={SearchClick}
                searchlist={
                  vaccination?.recommendation?.recommendationSearch_Vaccine
                }
              />
            </Typography>
            <Typography>
              <GenericSearchList
                title={vaccination?.popular?.title}
                searchlist={vaccination?.popular?.popularSearch_Vaccine}
                SearchClick={SearchClick}
              />
            </Typography>
          </Stack>
        </>
      )}
    </>
  );
};
export default Vaccination;
