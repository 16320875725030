import React, { useState } from "react";
import {
  Typography,
  Button,
  Menu,
  MenuItem,
  Stack,
  Avatar,
} from "@mui/material";
import theme from "../components/Themeify";
import { useNavigate } from "react-router-dom";
import { KeyboardArrowUp, KeyboardArrowDown } from "@mui/icons-material";
import {
  LocationDropDowns,
  ServicesDropDowns,
} from "../json/SubHeaderDropDtls";
import styles from "../styling/SubHeaderDropDowns.module.css";
import makeStyles from "@material-ui/styles/makeStyles";

const useStyles = makeStyles({
  popOverRoot: {
    pointerEvents: "none",
  },
});

const SubHeaderDropDowns = ({ setIndexOfSubheader }) => {
  let currentlyHovering = false;
  const [anchorEl, setAnchorEl] = useState(null);
  const [showUpIcon, setShowUPIcon] = useState(false);
  const [showUpBellIcon, setShowUPBellIcon] = useState(false);
  const [bellAnchorEl, setBellAnchorEl] = useState(null);
  const navigate = useNavigate();
  const open = Boolean(anchorEl);
  const bellOpen = Boolean(bellAnchorEl);

  const handleClick = (event) => {
    if (anchorEl !== event.currentTarget) {
      setAnchorEl(event.currentTarget);
    }
    setShowUPIcon(true);
  };
  const handleClickClose = () => {
    setShowUPIcon(false);
    currentlyHovering = false;
    setTimeout(() => {
      if (!currentlyHovering) {
        setAnchorEl(null);
      }
    }, 50);
  };

  const handleBellClick = (event) => {
    setBellAnchorEl(event?.currentTarget);
    setShowUPBellIcon(true);
  };
  const handleClose = (path) => {
    if (path === "For Individuals and Families") {
      navigate("/login/everydayhealth");
      setIndexOfSubheader(0);
      setShowUPIcon(false);
    } else if (path === "For Corporates") {
      navigate("/login/corporatehealth");
      setIndexOfSubheader(1);
      setShowUPBellIcon(false);
    }
    setAnchorEl(null);
  };

  const handleLocationClose = (path) => {
    if (path === "Retail") {
      navigate("/login/everydayhealth");

      setTimeout(() => {
        var element = document.getElementById("box_center");

        element?.scrollIntoView({ behavior: "smooth" });
    }, 500);
      setIndexOfSubheader(0);
      setShowUPBellIcon(false);
    } else if (path === "Corporate") {
      navigate("/login/corporatehealth", { state: { scroll: true } });
      setTimeout(() => {
        var element = document.getElementById("map_center");

        element?.scrollIntoView({ behavior: "smooth" });
    }, 500);
      setIndexOfSubheader(1);
      setShowUPBellIcon(false);
    }

    setBellAnchorEl(null);
  };
  const style = useStyles();

  const handleHover = () => {
    currentlyHovering = true;
  };
  const handleBellClose = () => {
    setShowUPBellIcon(false);
    currentlyHovering = false;
    setTimeout(() => {
      if (!currentlyHovering) {
        setBellAnchorEl(null);
      }
    }, 50);
  };

  return (
    <>
      <Button
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onMouseOver={handleClick}
        onMouseLeave={handleClickClose}
        endIcon={
          showUpIcon === true ? <KeyboardArrowUp /> : <KeyboardArrowDown />
        }
      >
        <Typography variant="h5" color={theme.palette.info.main}>
          Services
        </Typography>
      </Button>
      <Button
        id="location-button"
        aria-controls={bellOpen ? "notification-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={bellOpen ? "true" : undefined}
        onMouseOver={handleBellClick}
        onMouseLeave={handleBellClose}
        endIcon={
          showUpBellIcon === true ? <KeyboardArrowUp /> : <KeyboardArrowDown />
        }
      >
        <Typography variant="h5" color={theme.palette.info.main}>
          Locations
        </Typography>
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          onMouseEnter: handleHover,
          onMouseLeave: handleClickClose,
          style: { pointerEvents: "auto" },
        }}
        PopoverClasses={{
          root: style.popOverRoot,
        }}
        sx={{
          "& .MuiPaper-root": {
            borderRadius: "20px",
          },
        }}
      >
        <Stack direction="row" className={styles.services_menu_stack}>
          {ServicesDropDowns?.map((item, index) => {
            return (
              <MenuItem
                key={index}
                onClick={() => handleClose(item?.name)}
                className={styles.service_menu_item}
              >
                <Stack>
                  <Stack
                    direction="row"
                    className={styles.dropdown_header_stack}
                  >
                    <Avatar
                      className={styles.dropdown_avatarIcon}
                      sx={{ bgcolor: item?.iconBG }}
                    >
                      {item?.icon}
                    </Avatar>
                    <Avatar className={styles.dropdown_avatarIcon_arrow}>
                      {item?.frwdicon}
                    </Avatar>
                  </Stack>
                  <Stack className={styles.txt_container}>
                    <Typography className={styles.service_main_txt}>
                      {item?.name}
                    </Typography>
                    <Typography className={styles.service_sub_txt}>
                      {item?.subtxt}
                    </Typography>
                  </Stack>
                </Stack>
              </MenuItem>
            );
          })}
        </Stack>
      </Menu>
      <Menu
        id="notification-menu"
        anchorEl={bellAnchorEl}
        open={bellOpen}
        onClose={handleLocationClose}
        MenuListProps={{
          "aria-labelledby": "location-button",
          onMouseEnter: handleHover,
          onMouseLeave: handleBellClose,
          style: { pointerEvents: "auto" },
        }}
        PopoverClasses={{
          root: style.popOverRoot,
        }}
        sx={{
          "& .MuiPaper-root": {
            borderRadius: "20px",
          },
        }}
      >
        {LocationDropDowns?.map((item, index) => {
          return (
            <MenuItem
              key={index}
              onClick={() => handleLocationClose(item?.name)}
              className={styles.service_menu_item}
            >
              <Avatar
                className={styles.dropdown_avatarIcon}
                sx={{ bgcolor: item?.iconBG }}
              >
                {item?.icon}
              </Avatar>
              <Typography className={styles.location_main_txt}>
                {item?.name}
              </Typography>
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
};

export default SubHeaderDropDowns;
