import React from "react";
import { Box, Stack, Typography } from "@mui/material";
import { Button } from "@mui/base";
import styles from "../styling/NeedtoKnow.module.css";
import {needToKnowTexts} from "../json/needToKnowTexts"; 
import { useNavigate } from "react-router-dom";


export const NeedtoKnow = () => {
  const navigate=useNavigate();
  const handleContact=()=>{
    navigate("/login/contactus");
  }
  return (
    <Box className={styles.needto_know_box}>
      <Stack position="relative">
        <Stack className={styles.needto_know_stackone} direction="row" spacing={2}>
          <Stack>
            <Typography className={styles.needtoknow_typo_one}>
              {needToKnowTexts?.ctaText}
            </Typography>
          </Stack>
          <Stack className={styles.needtoknow_typo_two}>
            <Button className={styles.needtoknow_contact_btn} onClick={handleContact}>
              {needToKnowTexts?.contactButtonText}
            </Button>
          </Stack>
        </Stack>
      </Stack>
    </Box>
  );
};
