import React, { useState } from "react";
import {
  Grid,
  Typography,
  Stack,
  Box,
  InputAdornment,
  Button,
} from "@mui/material";
import {  Select,  MenuItem,} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import TextField from "@mui/material/TextField";
import {
  AccountCircleOutlined,
  Diversity1Outlined,
  CallOutlined,
} from "@mui/icons-material";
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import styles from "../styling/YourEmergencyDetails.module.css";
import { useFormik } from "formik";
import * as Yup from "yup";
import { BackButton } from "./BackButton";

const validationSchema = Yup.object().shape({
  primaryContactPerson: Yup.string().required(
    "Primary Contact Person is required"
  ),
  primaryContactRelation: Yup.string().required(
    "Primary Contact Relation is required"
  ),
  primaryContactMobile: Yup.string().required(
    "Primary Contact Mobile Number is required"
  ),
  secondaryContactPerson: Yup.string().required(
    "Secondary Contact Person is required"
  ),
  secondaryContactRelation: Yup.string().required(
    "Secondary Contact Relation is required"
  ),
  secondaryContactMobile: Yup.string().required(
    "Secondary Contact Mobile Number is required"
  ),
});
const countries = [
  { code: "+1", label: "+1" },
  { code: "+91", label: "+91" },
  // Add more countries as needed
];
export const EmergencyDetails = () => {
  const [editMode, setEditMode] = useState(false);
  const [selectedCountryCode, setSelectedCountryCode] = useState(
    countries[1].code
  );
  const formik = useFormik({
    initialValues: {
      primaryContactPerson: "Elina Johnson",
      primaryContactRelation: "Mother",
      primaryContactMobile: "7894561230",
      secondaryContactPerson: "Suli Johnson",
      secondaryContactRelation: "Sister",
      secondaryContactMobile: "7894561230",
    },
    validationSchema,
    onSubmit: (values) => {
      // can be used later
    },
  });

  const editClickhandler = () => {
    setEditMode(true);
  };

  const handleBack = () => {
    setEditMode(false);
  };
  const mobile = "Mobile Number";
  const relation = "Relation";
  const contact = "Contact Person";
  const secondary = "Secondary Contact";
  return (
    <Box>
      {!editMode && (
        <Box className={styles.page_info}>
          <Typography className={styles["yourdetails-mainTitle"]} variant="h4">
            Primary Contact
          </Typography>
          <Button
            severity="success"
            onClick={() => editClickhandler()}
            startIcon={<EditIcon />}
            className={styles.edit_btn}
          >
            Edit
          </Button>
        </Box>
      )}
      
      {editMode && <BackButton onClickHandler={() => handleBack()} />}
      {editMode && (
        <Box className={styles.page_info_edit}>
          <Typography className={styles["yourdetails-mainTitle"]} variant="h4">
            Edit Primary Contact
          </Typography>
        </Box>
      )}
      <Grid container mb={3} direction={{ xs: "column", md: "row" }}>
        <Stack direction="column">
          <Stack direction={{ xs: "column", md: "row" }} spacing={4}>
            <Stack item xs={6}>
              <Typography
                className={styles["yourdetails-textfieldTitle"]}
                variant="h5"
              >
                {contact}
              </Typography>
              <TextField
                disabled={!editMode}
                className={styles["yourdetails-textfield"]}
                variant="standard"
                name="primaryContactPerson"
                value={formik?.values?.primaryContactPerson}
                onChange={formik?.handleChange}
                onBlur={formik?.handleBlur}
                error={
                  formik?.touched?.primaryContactPerson &&
                  Boolean(formik?.errors?.primaryContactPerson)
                }
                InputProps={{
                  className: styles["yourdetails-textfieldInput"],
                  disableUnderline: true,
                  endAdornment: (
                    <InputAdornment>
                      <AccountCircleOutlined className={styles.color_three} />
                    </InputAdornment>
                  ),
                }}
              />
              {formik?.touched?.primaryContactPerson &&
              formik?.errors?.primaryContactPerson ? (
                <Typography
                  className={styles["yourdetails-errorText"]}
                  color="error"
                >
                  {formik?.errors?.primaryContactPerson}
                </Typography>
              ) : null}
            </Stack>
            <Stack item xs={6} className={styles.inputFieldWrapper}>
              <Typography
                className={styles["yourdetails-textfieldTitle"]}
                variant="h5"
              >
                {relation}
              </Typography>
              <TextField
                className={styles["yourdetails-textfield"]}
                variant="standard"
                disabled={!editMode}
                name="primaryContactRelation"
                value={formik?.values?.primaryContactRelation}
                onChange={(e) => {
                  const value = e?.target?.value?.replace(
                    /[^A-Za-z\s\-']/g,
                    ""
                  );
                  formik?.handleChange(e?.target?.name)(value);
                }}
                onBlur={formik?.handleBlur}
                error={
                  formik?.touched?.primaryContactRelation &&
                  Boolean(formik?.errors?.primaryContactRelation)
                }
                InputProps={{
                  className: styles["yourdetails-textfieldInput"],
                  disableUnderline: true,
                  endAdornment: (
                    <InputAdornment>
                      <PersonOutlineOutlinedIcon className={styles.color_three} />
                    </InputAdornment>
                  ),
                }}
              />
              {formik?.touched?.primaryContactRelation &&
              formik?.errors?.primaryContactRelation ? (
                <Typography
                  className={styles["yourdetails-errorText"]}
                  color="error"
                >
                  {formik?.errors?.primaryContactRelation}
                </Typography>
              ) : null}
            </Stack>
          </Stack>
          <Stack item xs={6}>
            <Typography
              className={styles["yourdetails-textfieldTitle"]}
              variant="h5"
            >
              {mobile}
            </Typography>
            <Stack direction="row" alignItems="center">
              <TextField
                disabled={true}
                className={styles["yourdetails-textfield"]}
                variant="standard"
                InputProps={{
                  className: styles["yourdetails-textfieldInput"],
                  disableUnderline: true,
                  startAdornment: (
                    <InputAdornment position="start">
                      <Select
                        disabled={true}
                        value={selectedCountryCode}
                        onChange={(e) => setSelectedCountryCode(e.target.value)}
                        className={styles["yourdetails-countryCodeSelect"]}
                        style={{ border: "none", boxShadow: "none" }}
                        sx={{ boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: 0 } }}

                      >
                        {countries.map((country) => (
                          <MenuItem key={country.code} value={country.code}>
                            {country.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <CallOutlined className={styles.three} />
                    </InputAdornment>
                  ),
                }}
                name="mobileNumber"
                value={formik?.values?.primaryContactMobile}
                onChange={(e) => {
                  const value = e?.target?.value?.replace(/\D/g, "");
                  formik?.handleChange(e?.target?.name)(value);
                }}
                onBlur={formik?.handleBlur}
                error={
                  formik?.touched?.primaryContactMobile &&
                  Boolean(formik?.errors?.primaryContactMobile)
                }
              />
            </Stack>
            {formik?.touched?.primaryContactMobile &&
            formik?.errors?.primaryContactMobile ? (
              <Typography
                className={styles["yourdetails-errorText"]}
                color="error"
              >
                {formik?.errors?.primaryContactMobile}
              </Typography>
            ) : null}
          </Stack>
        </Stack>
      </Grid>
      
      {!editMode && (
        <Typography className={styles["yourdetails-mainTitle"]} variant="h4">
        {secondary}
      </Typography>
      )}
      {editMode && (
        <Typography className={styles["yourdetails-mainTitle"]} variant="h4">
        Edit Secondary Contact
      </Typography>
      )}
      <Grid container mb={3} direction={{ xs: "column", md: "row" }}>
        <Stack direction="column">
          <Stack direction={{ xs: "column", md: "row" }} spacing={4}>
            <Stack item xs={6}>
              <Typography
                className={styles["yourdetails-textfieldTitle"]}
                variant="h5"
              >
                {contact}
              </Typography>
              <TextField
                className={styles["yourdetails-textfield"]}
                variant="standard"
                disabled={!editMode}
                name="secondaryContactPerson"
                value={formik?.values?.secondaryContactPerson}
                onChange={(e) => {
                  const value = e?.target?.value?.replace(
                    /[^A-Za-z\s\-']/g,
                    ""
                  );
                  formik?.handleChange(e?.target?.name)(value);
                }}
                onBlur={formik?.handleBlur}
                error={
                  formik?.touched?.secondaryContactPerson &&
                  Boolean(formik?.errors?.secondaryContactPerson)
                }
                InputProps={{
                  className: styles["yourdetails-textfieldInput"],
                  disableUnderline: true,
                  endAdornment: (
                    <InputAdornment>
                      <AccountCircleOutlined className={styles.color_three} />
                    </InputAdornment>
                  ),
                }}
              />
              {formik?.touched?.secondaryContactPerson &&
              formik?.errors?.secondaryContactPerson ? (
                <Typography
                  className={styles["yourdetails-errorText"]}
                  color="error"
                >
                  {formik?.errors?.secondaryContactPerson}
                </Typography>
              ) : null}
            </Stack>

            <Stack item xs={6} className={styles.inputFieldWrapper}>
              <Typography
                className={styles["yourdetails-textfieldTitle"]}
                variant="h5"
              >
                {relation}
              </Typography>
              <TextField
                className={styles["yourdetails-textfield"]}
                variant="standard"
                disabled={!editMode}
                name="secondaryContactRelation"
                value={formik?.values?.secondaryContactRelation}
                onChange={(e) => {
                  const value = e?.target?.value?.replace(
                    /[^A-Za-z\s\-']/g,
                    ""
                  );
                  formik?.handleChange(e?.target?.name)(value);
                }}
                onBlur={formik?.handleBlur}
                error={
                  formik?.touched?.secondaryContactRelation &&
                  Boolean(formik?.errors?.secondaryContactRelation)
                }
                InputProps={{
                  className: styles["yourdetails-textfieldInput"],
                  disableUnderline: true,
                  endAdornment: (
                    <InputAdornment>
                      <PersonOutlineOutlinedIcon className={styles.color_three} />
                    </InputAdornment>
                  ),
                }}
              />
              {formik?.touched?.secondaryContactRelation &&
              formik?.errors?.secondaryContactRelation ? (
                <Typography
                  className={styles["yourdetails-errorText"]}
                  color="error"
                >
                  {formik?.errors?.secondaryContactRelation}
                </Typography>
              ) : null}
            </Stack>
          </Stack>
          <Stack item xs={6}>
            <Typography
              className={styles["yourdetails-textfieldTitle"]}
              variant="h5"
            >
              {mobile}
            </Typography>
           
            <Stack direction="row" alignItems="center">
              <TextField
                disabled={true}
                className={styles["yourdetails-textfield"]}
                variant="standard"
                InputProps={{
                  className: styles["yourdetails-textfieldInput"],
                  disableUnderline: true,
                  startAdornment: (
                    <InputAdornment position="start">
                      <Select
                        disabled={true}
                        value={selectedCountryCode}
                        onChange={(e) => setSelectedCountryCode(e.target.value)}
                        className={styles["yourdetails-countryCodeSelect"]}
                        style={{ border: "none", boxShadow: "none" }}
                        sx={{ boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: 0 } }}

                      >
                        {countries.map((country) => (
                          <MenuItem key={country.code} value={country.code}>
                            {country.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <CallOutlined className={styles.three} />
                    </InputAdornment>
                  ),
                }}
                name="mobileNumber"
                value={formik?.values?.secondaryContactMobile}
                onChange={(e) => {
                  const value = e?.target?.value?.replace(/\D/g, "");
                  formik?.handleChange(e?.target?.name)(value);
                }}
                onBlur={formik?.handleBlur}
                error={
                  formik?.touched?.secondaryContactMobile &&
                  Boolean(formik?.errors?.secondaryContactMobile)
                }
              />
            </Stack>
            {formik?.touched?.secondaryContactMobile &&
            formik?.errors?.secondaryContactMobile ? (
              <Typography
                className={styles["yourdetails-errorText"]}
                color="error"
              >
                {formik?.errors?.secondaryContactMobile}
              </Typography>
            ) : null}
          </Stack>
        </Stack>
        {editMode && (
          <Stack direction="row" spacing={4}>
            <Stack item xs={6}>
              <Button onClickHandler={() => handleBack()}  className={styles.custom_btn}>Save</Button>
            </Stack>
          </Stack>
        )}
      </Grid>
    </Box>
  );
};
