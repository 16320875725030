import React from "react";
import { Paper, Typography, Stack, Box } from "@mui/material";
import styles from "../styling/WhyChooseUsCard.module.css";

const WhyChooseUsCard = ({ icon, title, description }) => {
  return (
    <Paper elevation={1} className={styles["why_paper"]} >
      <Stack spacing={1} p={2} sx={{maxWidth:"400px"}}>
        <Box component="img" src={icon} className={styles["why_img"]} />
        <Typography variant="h4" align="left" className={styles["why_bold"]}>
          {title}
        </Typography>
        <Typography variant="body2" color="text.secondary" align="left">
          {description}
        </Typography>
      </Stack>
    </Paper>
  );
};

export default WhyChooseUsCard;
