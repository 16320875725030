import React from "react";
import {
  Typography,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  List,
  Divider,
  Stack,
} from "@mui/material";
import styles from "../styling/MemberMenu.module.css";
import Person from "@mui/icons-material/Person";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
export default function EditMemberMenu({ onClose ,handleFamilyView }) {
  const locationsDummyData = [
    "Rahul Sharma",
    "Shruti Sharma",
    "Kishan Sharma"
  ];
  const edit="Edit Member";
  return (
    <>
      <Stack direction={"row"}>
        <ArrowBackIcon className={styles.back_icon} onClick={onClose} />
        <Typography varinat="h4"  className={styles.selectName} >
          {edit}
        </Typography>
      </Stack>

      <List>
        {locationsDummyData?.map((text, index) => (
          <>
            <ListItem onClick={handleFamilyView}   className={styles.list_View_height} key={text}>
              <ListItemButton>
                <ListItemIcon>
                  <Stack className={styles.personView}>
                    <Person className={styles.personIcon} />
                  </Stack>
                </ListItemIcon>
                <ListItemText
                  primary={text}
                  secondary="7894561230  • 26 Yrs"
                 className={styles.bold_text}
                />
                <ArrowForwardIosIcon fontSize="15px" />
              </ListItemButton>
            </ListItem>
            <Divider className={styles.divider_line} />
          </>
        ))}
      </List>
    </>
  );
}
