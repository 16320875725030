import React from "react";
import { Typography, Button, Avatar, Stack } from "@mui/material";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import styles from "../styling/BackButton.module.css";
import theme from "../components/Themeify";

export const BackButton = ({ onClickHandler }) => {
  const handleBack = () => {
    onClickHandler();
  };

  return (
    <Stack direction="row" onClick={() => handleBack()}>
      <Button
        id="basic-button"
        startIcon={
          <Avatar className={styles.back_icon_container}>
            <KeyboardBackspaceIcon className={styles.back_icon} />
          </Avatar>
        }
        className={styles.back_btn}
      >
        <Typography variant="h5" color={theme.palette.info.grey}>
          Back
        </Typography>
      </Button>
    </Stack>
  );
};
