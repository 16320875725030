import React from "react";
import { Stack } from "@mui/system";
import LabtestAppointment from "../molecules/LabtestAppointment";
import styles from "../styling/LabtestCheckOut.module.css";
import lab from "../assets/images/lab.png";
import { LabtestCheckoutPayment } from "../molecules/LabtestCheckoutPayment";
import defaultStyles from "../styling/DefaultStyles.module.css";
import Bread from "../molecules/Bread";

export const LabtestCheckout = () => {
  return (
    <>
      <Stack className={defaultStyles.stack_with_max_width_responsive}>
        <Stack className={defaultStyles.bread_stack}>
          <Bread />
        </Stack>
        <Stack
          className={styles.xrayscan_main_stack_inner_main}
          spacing={4}
          direction={{ xs: "column", sm: "row" }}
        >
          <Stack className={styles.xrayscan_main_stack_inner}>
            <LabtestAppointment
              img={lab}
              textone="Lab Tests Appointment"
              textTwo="Liver Function test(LFT)"
            />
          </Stack>

          <LabtestCheckoutPayment />
        </Stack>
      </Stack>
    </>
  );
};
