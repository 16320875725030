import React from "react";
import { Avatar, Card, CardHeader,  Typography } from "@mui/material";
import styles from "../styling/GenericHeader.module.css";
import theme from "../components/Themeify";
import bg from "../assets/dental bg.png"
const CustomAvatar = (props) => {
  return (
    <Avatar
      sx={{
        backgroundColor: theme.palette.primary.main,
      }}
      aria-label="icon"
      className={styles.avatar_img}
    >
      <img src={props.render} className={styles.banner_img} alt="" />
    </Avatar>
  );
};

const Header = (props) => {
  return (
    <Typography
      variant="h2"
      sx={{
        color: theme.palette.info.contrastText,
      }}
      className={styles.header_text}
    >
      {props.txt}
    </Typography>
  );
};

const GenericHeaderCard = (props) => {
  return (
    <Card
      data-testid="generic_header_card"
      elevation={3}
      className={styles["cardRoot"]}
      style={{
        backgroundImage: `url(${bg})`,
      }}
    >
      <CardHeader
        titleTypographyProps={{
          variant: "h2",
        }}
        title={<Header txt={props.headingtext} />}
        data-testid="generic_header_element"
        className={styles.header_card}
        avatar={<CustomAvatar render={props.banner} />}
      />
    </Card>
  );
};

export default GenericHeaderCard;
