import React from "react";
import { Stack, Typography } from "@mui/material";
import styles from "../styling/VitalsBodyParam.module.css";
import { vitals } from "../json/HealthProfile";

const renderVitalsStack = (item, index) => (
  <Stack
    key={index}
    bgcolor={item?.bgColor}
    className={styles["bodyparam-subStack"]}
  >
    {item?.icon}
    <Stack className={styles["vitals-subStack2"]} direction="column" textAlign="left">
      <Typography className={styles["vitals-typoTitle"]} fontSize="12px" color="#3d4a48">
        {item?.title}
      </Typography>
      <Stack direction="row">
        <Typography className={styles["vitals-typoDesc"]} fontSize="16px" fontWeight={600}>
          {item?.desc}
        </Typography>
        <Typography className={styles["vitals-typoDesc1"]} fontSize="12px" marginLeft="5px" marginBottom="1px" fontWeight={600} display="flex" alignItems="flex-end">
          {item?.desc1}
        </Typography>
      </Stack>
    </Stack>
  </Stack>
);

export const Vitals = () => {
  return (
    <Stack className={styles["vitals-mainStack"]} direction="row" alignItems="center" flexWrap="wrap">
      {vitals?.map(renderVitalsStack)}
    </Stack>
  );
};

