import React, { useState } from "react";
import {
  Typography,
  Stack,
  Card,
  CardContent,
  CardMedia,
  Box,
} from "@mui/material";
import ArrowOutwardOutlinedIcon from "@mui/icons-material/ArrowOutwardOutlined";
import theme from "../components/Themeify";
import styles from "../styling/EverydayHealth.module.css";
import { useNavigate } from "react-router-dom";
import { useSharedData } from "../Context/ShareContext";
import { everydayHealth } from "../json/EverydayHealth";

export default function CenterLocation() {
  const { setIndexOfSubheader, setActiveButtonIndex } = useSharedData();

  const navigate = useNavigate();
  const [isShown, setIsShown] = useState(false);
  const [index, setIndex] = useState(0);

  const handleReadMore = (selectedLocation) => {
    setIndexOfSubheader(false);
    setActiveButtonIndex(2);

    navigate("/login/contactus", { state: { selectedLocation } });
  };

  return (
    <Box
      className={styles.locationBg}
      data-testid="center-location"
      id="box_center"
    >
      <Box className={styles["location-stack"]}>
        <Stack className={styles.locationStack}>
          <Typography variant="h2" className={`${styles.headerRoot}`}>
            {everydayHealth?.LOCATION?.TITLE}
          </Typography>
          <Stack
            direction={{ xs: "column", sm: "column", md: "row" }}
            justifyContent="space-between"
            spacing={{ xs: 8, sm: 8, md: 2 }}
            className={styles.stackmargin}
          >
            {everydayHealth?.LOCATION?.LIST?.map((item, i) => {
              return (
                <Card
                  className={styles.cardRootLocation}
                  key={item}
                  data-testid="location-card"
                  onClick={()=>handleReadMore(item)}
                >
                  <CardMedia
                    className={`${
                      isShown && index === i
                        ? styles.cardImageHover
                        : styles.cardImage
                    }`}
                    image={item.ICON}
                    data-testid="card-image"
                  />
                  <CardContent
                    onMouseEnter={() => {
                      setIsShown(true);
                      setIndex(i);
                    }}
                    onMouseLeave={() => {
                      setIsShown(false);
                      setIndex(i);
                    }}
                  >
                    <Stack
                      direction="row"
                      justifyContent="center"
                      mt={7}
                      spacing={1}
                    >
                      <Typography className={styles.cardtitle}>
                        {item.NAME}
                      </Typography>
                      <Stack>
                        <ArrowOutwardOutlinedIcon
                          className={styles.locationArrow}
                          sx={{ color: theme.palette.primary.dark }}
                          data-testid="arrow-icon"
                        />
                      </Stack>
                    </Stack>
                  </CardContent>
                </Card>
              );
            })}
          </Stack>
        </Stack>
      </Box>
    </Box>
  );
}
