import React, { useState } from "react";
import { Drawer, Stack, Typography } from "@mui/material";
import styles from "../styling/LabtestCheckOut.module.css";
import Divider from "@mui/material/Divider";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import {constants} from "../json/labtest";
// import coupon from '../assets/coupons.svg';
import percentage from "../assets/images/percentage.png";

import Button from "@mui/material/Button";
import { ChevronRight } from "@mui/icons-material";
import ApplyPopupMenu from "./ApplyPopupMenu";
import { CongratulationPopup } from "./CongratulationPopup";
import { useSharedData } from "../Context/ShareContext";
import mainlogo from "../assets/company_logo.svg";
import useRazorpay from "react-razorpay";
export const LabtestCheckoutPayment = () => {
  const coupon = "Use Coupon";
  const [applyCoupon, setApplyCoupon] = useState(false);
  const { showCongratulationsPopup, setShowCongratulationsPopup } = useSharedData();
  const [Razorpay] = useRazorpay();
  const handleCheckoutClick = () => {
    let finalRazorPayValue = 500 * 100;

    var options = {
      key: "rzp_test_zt5DDs1PmkkyDy",
      amount: finalRazorPayValue, // Example: 2000 paise = INR 20
      name: "Healthspring",
      description: "Healthspring checkout",
      image: mainlogo, // COMPANY LOGO
      handler: function (response) {
        if (response.razorpay_payment_id) setShowCongratulationsPopup(true);
        // AFTER TRANSACTION IS COMPLETE YOU WILL GET THE RESPONSE HERE.
      },
      prefill: {
        name: "User Name", // pass customer name
        email: "user@email.com", // customer email
        contact: "+919123456780", //customer phone no.
      },
      notes: {
        address: "address", //customer address
      },
      theme: {
        color: "#15b8f3", // screen color
      },
    };
    console.log(options);
    var propay = new Razorpay(options);
    propay.open();
    // setShowCongratulationsPopup(true);
  };
  let drawerObj = {
    display: { xs: "block", sm: "block" },
    "& .MuiDrawer-paper": { boxSizing: "border-box", width: 350 },
  };
  const handleCouponMenu = () => {
    setApplyCoupon((prev) => !prev);
  };
  return (
    <>
      <Stack align="left" className={styles.lab_test_appointments_main_one}>
        <Stack>
          <Typography
            variant="h2"
            className={styles.lab_test_appointments_typo_one}
            mb={2}
          >
            {constants?.paymentDetails}
          </Typography>
        </Stack>
        <Divider mb={2} />
        <Stack>
          <Typography
            variant="h3"
            className={styles.lab_test_appointments_typo_one}
            mt={2}
            mb={2}
          >
            {constants?.patientDetails}
          </Typography>
        </Stack>
        <Stack className={styles.lab_test_pmtinfo_stack}>
          <Stack>
            <Stack className={styles.lab_test_spacerClass_stack}>
              <Typography className={styles.lab_test_pkgDtls_stack}>
                {constants?.nameLabel}
              </Typography>
              <Typography className={styles.lab_test_pkgQty_stack}>
                Rahul Sharma
              </Typography>
            </Stack>
            <Stack className={styles.lab_test_spacerClass_stack}>
              <Typography className={styles.lab_test_pkgDtls_stack}>
                {constants?.emailLabel}
              </Typography>
              <Typography className={styles.lab_test_pkgQty_stack}>
                rahul.sharma@gmail.com
              </Typography>
            </Stack>
            <Stack className={styles.lab_test_spacerClass_stack}>
              <Typography className={styles.lab_test_pkgDtls_stack}>
                {constants?.phoneLabel}
              </Typography>
              <Typography className={styles.lab_test_pkgQty_stack}>
                9830124568
              </Typography>
            </Stack>
            <Stack className={styles.lab_test_spacerClass_stack}>
              <Typography className={styles.lab_test_pkgDtls_stack}>
                {constants?.genderLabel}
              </Typography>
              <Typography className={styles.lab_test_pkgQty_stack}>
                Male
              </Typography>
            </Stack>
            <Stack className={styles.lab_test_spacerClass_stack}>
              <Typography className={styles.lab_test_pkgDtls_stack}>
                {constants?.dobLabel}
              </Typography>
              <Typography className={styles.lab_test_pkgQty_stack}>
                26/03/1992
              </Typography>
            </Stack>
          </Stack>
        </Stack>

        <Divider mb={2} />
        <Button
          onClick={handleCouponMenu}
          className={styles.selectBtn_Flex_Start}
          startIcon={
            <IconView img={percentage} className={styles.percentageIcon} />
          }
          endIcon={<ChevronRight className={styles.svg_image_right} />}
        >
          <Typography
            align="left"
            variant="h5"
            className={`${styles.typo_Txt} ${styles.use_coupon}`}
          >
            {coupon}
          </Typography>
        </Button>
        <Typography className={styles.lab_test_descHeader_stack}>
          {constants?.paymentInfoLabel}
        </Typography>
        <Stack className={styles.lab_test_pmtinfo_stack}>
          <Stack className={styles.lab_test_finalInvoiceDtls_stack}>
            <Stack className={styles.lab_test_spacerClass_stack}>
              <Typography className={styles.lab_test_pkgDtls_stack}>
                {constants?.totalPriceLabel}
              </Typography>
              <Typography className={styles.lab_test_pkgQty_stack}>
                &#8377;1,999.00
              </Typography>
            </Stack>
            <Stack className={styles.lab_test_spacerClass_stack}>
              <Typography className={styles.lab_test_pkgDtls_stack}>
                {constants?.taxLabel}
              </Typography>
              <Typography className={styles.lab_test_pkgQty_stack}>
                &#8377;0.00
              </Typography>
            </Stack>
          </Stack>
          <Stack className={styles.lab_test_spacerClass_stack}>
            <Typography className={styles.lab_test_pkgQty_stack1}>
              {constants?.grandTotalLabel}
            </Typography>
            <Typography className={styles.lab_test_pkgTotal_stack}>
              &#8377;1,999.00
            </Typography>
          </Stack>
        </Stack>
        <Stack className={styles.lab_test_chkkbutton_stack} direction="row" onClick={handleCheckoutClick}>
          <Typography className={styles.lab_test_chkoutt_stack}>
            {constants?.checkoutLabel}
          </Typography>
          <ArrowForwardIosIcon className={styles.lab_test_arowoutlined_stack} />
        </Stack>
        {showCongratulationsPopup && <CongratulationPopup  />}
      </Stack>
      <Drawer
            anchor="right"
            open={applyCoupon}
            onClose={handleCouponMenu}
            sx={drawerObj}
          >
            <ApplyPopupMenu onClose={handleCouponMenu} />
          </Drawer>
    </>
  );
};


const IconView = ({ img, isBig = false, className = "" }) => {
  return (
    <img
      alt="Avatar"
      src={img}
      className={
        className !== ""
          ? className
          : `${isBig ? styles.iconViewBig : styles.iconView}`
      }
    />
  );
};