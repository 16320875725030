import { BrowserRouter as Router } from "react-router-dom";
import "./App.css";
import { AuthProvider } from "./hooks/useAuth";
import RouteFlow from "./Routes/Routes";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./components/Themeify";
import { ShareContextProvider } from "./Context/ShareContext";
function App() {
  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <Router>
          <AuthProvider>
            <ShareContextProvider>
              <RouteFlow />
            </ShareContextProvider>
          </AuthProvider>
        </Router>
      </div>
    </ThemeProvider>
  );
}

export default App;
