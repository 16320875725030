export const otpPageTexts = {
  enterYourOtpText: "Enter your OTP",
  weSentItToTheNumberText: "We sent it to the number",
  verifyOtpText: "Verify OTP",
  resendCodeInText: "Resend code in",
  codeMismatchErrorText: "Doesn't match your code. Please try again.",
  doYouHaveAbhaIdText: "Do you have ABHA ID?",
  abhaIdMainText:
    "A unique identifier that enables you to share and access your health record digitally.",
  yesText: "Yes",
  noText: "No",
  verifiedSuccessfullyText: "Verified Successfully",
  abhaAddressAndIdVerifiedText:
    "Your ABHA address and ABHA id verified successfully",
  continueText: "Continue",
};
