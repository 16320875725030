import React from "react";
import {    Drawer , Stack} from "@mui/material";
import injection from "../assets/images/injections.svg";
import { useState } from "react";
import MemberMenu from "./MemberMenu";
import EditMemberMenu from "./EditMemberMenu";
import ApplyPopupMenu from "./ApplyPopupMenu";
import AddFamilyMemberFlyH from "./AddFamilyMemberFlyH";
import styles from "../styling/AppointmentChcekout.module.css";
import Bread from "./Bread";
import defaultStyles from "../styling/DefaultStyles.module.css";
import { PaymentDetailsCard } from "./UrgentCareCheckout";
import { Cards } from "./UrgentCareCheckout";
import homclinic from "../assets/homeclinic.png"

export default function HomeAppointment() {
  const [showMemberMenu, setMemberMenu] = useState(false);
  const [showMemberList, setMemberList] = useState(false);
  const [applyCoupon, setApplyCoupon] = useState(false);
  const [showAddFamilyMember, setShowAddFamilyMember] = useState(false);

  let drawerObj = {
    display: { xs: "block", sm: "block" },
    "& .MuiDrawer-paper": { boxSizing: "border-box", width: 350 },
  };
  const handleMemberMenu = () => {
    setMemberMenu((prev) => !prev);
    setMemberList(false);
    setApplyCoupon(false);
    setShowAddFamilyMember(false);
  };

  const handleMemberListMenu = () => {
    setMemberList((prev) => !prev);
    setMemberMenu(false);
    setApplyCoupon(false);
    setShowAddFamilyMember(false);
  };

  const handleCouponMenu = () => {
    setApplyCoupon((prev) => !prev);
    setMemberMenu(false);
    setMemberList(false);
    setShowAddFamilyMember(false);
  };

  const handleFamilyView = () => {
    setShowAddFamilyMember((prev) => !prev);
    setMemberMenu(false);
    setMemberList(false);
    setApplyCoupon(false);
  };
  return (
    <>
      <Stack className={defaultStyles.max_bread_14_5}>
        <Bread />
      </Stack>
      <Stack alignContent="center">
      <Stack
  direction={{xs:"column",sm:"column",md:"row"}}
  justifyContent="center"
  data-testid="appointment_payment_container"
  spacing={4}
  className={styles.auto_margin}
  
 
  
>
 <Stack flex={11}>
    <Cards img1={homclinic} img2={injection} text1={"At Home Appointment"} text2={"Vaccination"} text3={"C-reactive protein (CRP) blood test"}  url={"/outpatient_care/vaccination/c_Reactive_protien_crp_blood_test"}/>
    </Stack>
  
    <Stack flex={10}>
    <PaymentDetailsCard
      handleMemberMenu={handleMemberMenu}
      handleCouponMenu={handleCouponMenu}
    />
    </Stack>
 

   <Drawer
    anchor="right"
    open={showMemberMenu}
    onClose={handleMemberMenu}
    sx={drawerObj}
  >
    <MemberMenu
      onClose={handleMemberMenu}
      handleMemberListMenu={handleMemberListMenu}
      handleFamilyView={handleFamilyView}
    />
  </Drawer>

  <Drawer
    anchor="right"
    open={showMemberList}
    onClose={handleMemberListMenu}
    sx={drawerObj}
  >
    <EditMemberMenu
      handleFamilyView={handleFamilyView}
      onClose={handleMemberListMenu}
    />
  </Drawer>

  <Drawer
    anchor="right"
    open={applyCoupon}
    onClose={handleCouponMenu}
    sx={drawerObj}
  >
    <ApplyPopupMenu onClose={handleCouponMenu} />
  </Drawer>

  <Drawer
    anchor="right"
    open={showAddFamilyMember}
    onClose={handleFamilyView}
    sx={drawerObj}
  >
    <AddFamilyMemberFlyH onClose={handleFamilyView} />
  </Drawer> 
</Stack>
</Stack>

    </>
  );
}


