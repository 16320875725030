import React, { useEffect } from "react";
import { useRef } from "react";
import Stack from "@mui/material/Stack";
import CorporateCard from "../molecules/CorporateCard";
import { WorkplaceCard } from "../molecules/WorkplaceCard";
import { WhyChooseus } from "../molecules/WhyChooseus";
import { Ourservices } from "../molecules/Ourservices";
import CaseStudies from "../molecules/CaseStudies";
import { WorkSpringHealth } from "../molecules/WorkSpringHealth";
import { MapOnsite } from "../molecules/MapOnsite";
import { Marque } from "../molecules/Marque";
import { Award } from "../molecules/Award";
import HeathSolution from "../molecules/HeathSolution";

const CorporateHealth = () => {
  useEffect(() => {
    window.scrollTo(0,0);
    return () => {
      
    };
  }, []); 
  
  const scrollToRef = useRef(null);


  return (
    <Stack spacing={5}>
      <CorporateCard  scrollToRef={scrollToRef}/>
      <WorkplaceCard />
      <WhyChooseus />
      <Ourservices />
      {/* <CaseStudies /> */}
      <WorkSpringHealth />
      <MapOnsite />
      <Marque />
      <Award />
      <HeathSolution scrollToRef={scrollToRef} />
    </Stack>
  );
};

export default CorporateHealth;
