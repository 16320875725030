import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import ModalImage from "../assets/nextModal.svg";
import styles from "../styling/CustomModal.module.css";
import { useNavigate } from "react-router-dom";
import ModalImagetwo from "../assets/images/tick.svg";
import { useSharedData } from "../Context/ShareContext";

export default function CustomModal({ setOpen, open, type, ...props }) {
  const { setValue } = useSharedData();

  const navigate = useNavigate();

 
  const handleCloseHome = () => {
    
    navigate('/home')
    setValue(0)
    setOpen(false);
  };
  const handleClose = () => {
    setOpen(false);
  };


  return type === "success" ? (
    <div>
      <Modal
        open={open}
        slotProps={{ backdrop: { style: { backdropFilter: "blur(5px)" } } }}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className={styles.modal_content_style}>
          <Box sx={{ textAlign: "center" }}>
            <Box>
              <img src={ModalImage} alt="modal-logo" />
            </Box>
            <Box>
              <Typography className={`${styles.modal_title}`}>
                Reschedule Success!
              </Typography>
              <Typography
                variant="subtitle2"
                gutterBottom
                className={`${styles.modal_description}`}
              >
                Appointment successfully changed. You will receive a
                notification.
              </Typography>
            </Box>
            <Box className={`${styles.custom_modal_container}`}>
              <Button
                className={`${styles.view_appointment_btn} ${styles.custom_modal_btn}`}
                onClick={handleClose}
              >
                View Appointment
              </Button>
              <Button
                className={`${styles.back_to_home_btn} ${styles.custom_modal_btn}`}
                onClick={handleCloseHome}
              >
                Back to Home
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
    </div>
  ) : type === "custom" ? (
    <div>
      <Modal
        open={open}
        slotProps={{ backdrop: { style: { backdropFilter: "blur(5px)" } } }}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className={styles.modal_content_style}>
          <Box  className={styles.box_align}>
            <Box>
              <img src={ModalImagetwo} alt="modal-logo" />
            </Box>
            <Box>
              <Typography className={`${styles.modal_title}`}>
                Appointment Cancelled
              </Typography>
              <Typography
                variant="subtitle2"
                gutterBottom
                className={`${styles.modal_description}`}
              >
                Your appointment has cancelled successfully!{" "}
              </Typography>
            </Box>
            <Box className={`${styles.custom_modal_container}`}>
              <Button
                className={`${styles.view_appointment_btn} ${styles.custom_modal_btn}`}
                onClick={handleClose}
              >
                Okay
              </Button>
              
            </Box>
          </Box>
        </Box>
      </Modal>
    </div>
  ) : null;
}
